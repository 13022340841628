import React from 'react';
import CreatableSelect from 'react-select/creatable';
import Select, { components } from 'react-select';

import {
  SortableContainer,
  SortableElement,
  SortableHandle,
  arrayMove,
} from 'react-sortable-hoc';

const ReactSelect = ({
  value,
  onChange,
  options,
  disabled,
  className,
  placeholder,
  isMulti,
  getOptionValue,
  getOptionLabel,
  menuPlacement,
  filterOption,
  isCreatable,
  onCreateOption,
  addTopOption,
  menuPosition,
  isOptionDisabled,
  isSortable,
  menuPortalTarget,
  closeMenuOnSelect
}) => {
  const styles = {
    menuPortal: (base) => ({ ...base, zIndex: 9999 }),
    control: (base, state) => ({
      ...base,
      minHeight: 40,
      borderColor: '#D4DCE7',
    }),
    menu: (base, state) => ({
      ...base,
      zIndex: 1000,
    }),
    option: (base, state) => ({
      ...base,
      paddingLeft: '16px',
      paddingRight: '16px',
      backgroundColor: state.isSelected ? '#F0F0F0' : '#FFFFFF',
      color: '#090925',

      '&:hover': {
        backgroundColor: state.isSelected ? '#F0F0F0' : '#F8F8F8',
      },
    }),
    placeholder: (base, state) => ({
      ...base,
      color: '#7C92B3',
    }),
    multiValue: (base, state) => ({
      ...base,
      borderRadius: '30px',
      alignItems: 'center',
      padding: '2px 7px 2px 5px',
      backgroundColor: '#E3E6ED',
    }),
    multiValueRemove: (base, state) => ({
      width: 20,
      height: 20,
      lineHeight: '18px',
      textAlign: 'center',
      borderRadius: '30px',
      backgroundColor: '#BABEC7',
      color: '#FFFFFF',
      marginLeft: '5px',
      cursor: 'pointer',
      '&:hover': {
        backgroundColor: '#a9acb3',
      },
    }),
    indicatorSeparator: () => ({
      display: 'none',
    }),
    indicatorsContainer: (base, state) => ({
      ...base,
      paddingRight: '6px',
    }),
  };

  const DropdownIndicator = props => {
    return (
      components.DropdownIndicator && (
        <components.DropdownIndicator {...props}>
          <div className='select-dropdown' />
        </components.DropdownIndicator>
      )
    );
  };

  const Option = props => {
    const hasIcon = !!props.data.img_url;

    return (
      <components.Option {...props}>
        {hasIcon && (
          <img
            className='object-fit__cover object-position__center rounded__50 pull-y-1 mr-1'
            src={props.data.img_url}
            height='20px'
            width='20px'
            alt='user'
          />
        )}
        {props.children}
      </components.Option>
    );
  };

  const onSortEnd = ({ oldIndex, newIndex }) => {
    const newValue = arrayMove(value, oldIndex, newIndex);
    onChange(newValue)
  };

  if (isSortable) {
    const SortableSelect = SortableContainer(Select);

    const SortableMultiValue = SortableElement(props => {
      const onMouseDown = e => {
        e.preventDefault();
        e.stopPropagation();
      };
      const innerProps = { ...props.innerProps, onMouseDown };
      return <components.MultiValue {...props} innerProps={innerProps} />;
    });

    const SortableMultiValueLabel = SortableHandle(props => (
      <components.MultiValueLabel {...props} />
    ));

    return (
      <SortableSelect
        useDragHandle
        axis='xy'
        onSortEnd={onSortEnd}
        distance={4}
        getHelperDimensions={({ node }) => node.getBoundingClientRect()}
        components={{
          MultiValue: SortableMultiValue,
          MultiValueLabel: SortableMultiValueLabel,
        }}
        value={value}
        onChange={onChange}
        isMulti={isMulti}
        options={addTopOption ? [addTopOption, ...options] : options}
        className={className}
        placeholder={placeholder}
        styles={styles}
        isDisabled={disabled}
        getOptionValue={getOptionValue}
        getOptionLabel={getOptionLabel}
        menuPlacement={menuPlacement}
        filterOption={filterOption}
        menuPosition={menuPosition}
        isOptionDisabled={isOptionDisabled}
        closeMenuOnSelect={closeMenuOnSelect}
      />
    );
  }

  const MainComponent = isCreatable ? CreatableSelect : Select;

  return (
    <MainComponent
      components={{ DropdownIndicator, Option }}
      value={value}
      onChange={onChange}
      isMulti={isMulti}
      options={addTopOption ? [addTopOption, ...options] : options}
      className={className}
      placeholder={placeholder}
      styles={styles}
      isDisabled={disabled}
      getOptionValue={getOptionValue}
      getOptionLabel={getOptionLabel}
      menuPlacement={menuPlacement}
      filterOption={filterOption}
      onCreateOption={onCreateOption}
      menuPosition={menuPosition}
      isOptionDisabled={isOptionDisabled}
      menuPortalTarget={menuPortalTarget}
      closeMenuOnSelect={closeMenuOnSelect}
    />
  );
};
export default ReactSelect;
