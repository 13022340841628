import React from 'react';
import { default as TooltipWrapper } from '@material-ui/core/Tooltip';
import { createMuiTheme, MuiThemeProvider } from '@material-ui/core';

const Tooltip = props => {
  const { children, ...restProps } = props;
  const tooltipProps = { ...restProps };

  const theme = createMuiTheme({
    overrides: {
      MuiTooltip: {
        tooltip: {
          fontSize: '14px',
          lineHeight: '21px',
          fontWeight: 400,
          padding: '20px',
          maxWidth: 400,
          borderRadius: 0,
          boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
          color: '#1A3C6F',
          backgroundColor: '#FFFFFF',
        },
        arrow: {
          fontSize: 20,
          color: '#FFFFFF',
        },
      },
    },
  });

  return (
    <MuiThemeProvider theme={theme}>
      <TooltipWrapper {...tooltipProps}>{children}</TooltipWrapper>
    </MuiThemeProvider>
  );
};

export default Tooltip;
