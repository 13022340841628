import React from 'react';

const UploadProgress = ({ progress, className = 'my-4 p-3' }) => {
  if (!progress) return null;
  return (
    <div className={`border-style border__dashed border__default text-center rounded__4 ${className}`}>
      <div className='progress'>
        <div className={`progress-bar`} style={{ width: `${progress || 0}%` }} />
      </div>
    </div>
  );
};

export default UploadProgress;
