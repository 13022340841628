import React from 'react';

export const languages = [
  { name: 'English', key: 'en' },
  { name: 'Հայերեն', key: 'hy' },
  { name: 'Русский', key: 'ru' },
];

const LangToggle = ({ lang, setLang, disabled, exceptions = [] }) => {
  return (
    <div className='lang-toggle border__inset d-flex'>
      {languages.map(item => {
        if (exceptions.includes(item.key)) return null;
        const isActive = item.key === lang;
        return (
          <button
            key={item.key}
            className={`btn btn-sm weight-300 px-6 ${isActive ? 'active' : ''}`}
            onClick={setLang.bind(null, item.key)}
            disabled={disabled}
          >
            {item.name}
          </button>
        );
      })}
    </div>
  );
};

export default LangToggle;
