import React from 'react';
import { useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { CSVLink } from 'react-csv';
import IconDownload from 'assets/images/icons/download.svg';
import { formatDate } from 'utils/analyticsHelpers';
import { extractIdFromURL } from 'utils/appHelpers';
import _ from 'lodash';

const getFileName = ({ from, to }) => {
  from = formatDate(from);
  to = formatDate(to);
  return `reports_${from}_${to}.csv`;
};

const CSVManager = ({ className }) => {
  const { pathname } = useLocation();
  const { analytics, overview, filters } = useSelector(state => state);

  const isOverviewPage = /overview/.test(pathname);
  const source = isOverviewPage ? overview : analytics;

  const ids = extractIdFromURL(pathname);

  const csvDataLocationsByURL = {
    // Overview
    '/analytics/overview': 'CSV',

    // Views
    '/analytics/views/country': 'views.CSV',
    '/analytics/views/device_type': 'views.CSV',

    // Watchtime
    '/analytics/watchtime/country': 'watchtime.CSV',
    '/analytics/watchtime/device_type': 'watchtime.CSV',

    // Data Usage
    '/analytics/usage/country': 'usage.CSV',
    '/analytics/usage/device_type': 'usage.CSV',

    // Revenue
    '/analytics/revenue/country': 'revenue.CSV',
    '/analytics/revenue/device_type': 'revenue.CSV',
    '/analytics/revenue/stream': 'revenue.CSV',

    // Content
    [`/analytics/content/tv-show/${ids[0]}`]: `content.tvShowsById[${ids[0]}].CSV`,
    [`/analytics/content/country/${ids[0]}`]: `content.mediaItemById[${ids[0]}].CSV`,
    [`/analytics/content/country/${ids[0]}/${ids[1]}`]: `content.mediaItemById[${ids[0]}].CSV`,
    [`/analytics/content/device_type/${ids[0]}`]: `content.mediaItemById[${ids[0]}].CSV`,
    [`/analytics/content/device_type/${ids[0]}/${ids[1]}`]: `content.mediaItemById[${ids[0]}].CSV`,
  };

  const props = {
    separator: ';',
    filename: getFileName(filters.dates),
    data: _.get(source, csvDataLocationsByURL[pathname]) ?? [],
    className: `btn btn-primary text-nowrap download-btn ${className}`,
  };

  return (
    <CSVLink {...props}>
      DOWNLOAD CSV <img src={IconDownload} alt='download' className='ml-3' width='20' height='20' />
    </CSVLink>
  );
};

export default CSVManager;
