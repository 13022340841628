import React from 'react';
import useAnalyticsData from '../../hooks/useAnalyticsData';
import LineChart from './../../components/LineChart';
import PieChart from './../../components/PieChart';
import PageTitle from './../../components/PageTitle';
import Loading from 'shared/components/loadings/Loading';
import { useSelector } from 'react-redux';

const PageContent = () => {
  const data = useAnalyticsData('/reporting/revenue/search', 'revenue');

  const metricType = useSelector(state => state.filters.metric_type);
  const unit = metricType === 'unit' ? '' : '$';

  if (!data) {
    return (
      <div className='bg-white d-flex justify-content-center py-15'>
        <Loading />
      </div>
    );
  }

  const { line, pie, totalValues, biggestValue } = data;

  return (
    <div className='row'>
      <div className='col-8'>
        <div className='bg-white mh-788 px-6 py-4'>
          <PageTitle total={totalValues} title='revenue' unit={unit} className='mb-8' />
          <LineChart data={line} biggestValue={biggestValue} unit={unit} />
        </div>
      </div>
      <div className='col-4'>
        <div className='bg-white mh-788 px-6 py-4'>
          <PageTitle total={totalValues} title='revenue' unit={unit} className='mb-8' />
          <PieChart data={pie} totalValues={totalValues} listItems={line.datasets} unit={unit} />
        </div>
      </div>
    </div>
  );
};

export default PageContent;
