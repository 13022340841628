import React, { useState } from 'react';
import { useSnackbar } from 'notistack';
import Modal from 'shared/components/modals/Modal';
import { Api } from 'utils/connectors';
import { getError, getImageUrl } from 'utils/appHelpers';
import Select from 'react-select';
import SelectMediaModal from 'shared/components/SelectMediaModal';

const HomeItemManageModal = ({ onClose, state, onSuccess, activeCategory, categories }) => {
  const { enqueueSnackbar } = useSnackbar();
  const [mediaModal, setMediaModal] = useState(false);
  const [fetching, setFetching] = useState(false);
  const [categoryId, setCategoryId] = useState(activeCategory.id);
  const [medias, setMedias] = useState(state.data ? [state.data.media] : []);

  const createItem = () => {
    const mediaIds = medias.map(media => media.id);
    return Api.post(`/home/items?categoryId=${categoryId}`, {
      category_id: categoryId,
      mediaIds,
    }, { params: { mediaIds: mediaIds.join(',') } });
  };

  const updateItem = () => {
    const promises = medias.map(media => {
      return Api.put(`/home/items/${state.data.id}?categoryId=${categoryId}&mediaId=${media.id}`, {
        category_id: categoryId,
        id: state.data.id,
      });
    });
    return Promise.all(promises);
  };

  const onFormSubmit = async e => {
    e.preventDefault();
    try {
      setFetching(true);
      state.data ? await updateItem() : await createItem();
      if (onSuccess) await onSuccess();
      enqueueSnackbar('Successfully done', { variant: 'success' });
      setFetching(false);
      onClose();
    } catch (err) {
      enqueueSnackbar(getError(err), { variant: 'error' });
      setFetching(false);
    }
  };

  const onSelectMedia = medias => {
    setMedias(medias);
  };

  const categoryOptions = categories.map(item => ({ label: item.name, value: item.id }));

  return (
    <>
      <Modal size='sm' onClose={onClose} className='mx-2 mt-2'>
        <div className='modal-title bg-purple__light row px-4 py-3 mb-6'>
          <div className='text-primary__light'>{state.data ? 'Edit' : 'Create'} Home Item</div>
        </div>
        <div className='signup-modal d-flex flex-column align-items-center'>
          <div
            className={`add-media-block d-flex justify-content-center ${
              medias?.length > 1 ? 'is-multi' : ''
            }`}
            onClick={() => setMediaModal(true)}
          >
            <button className='select-btn'>
              <svg xmlns='http://www.w3.org/2000/svg' width='28' height='16' viewBox='0 0 28 16'>
                <path
                  d='M2,8V24H24V20.625l6,3V8.375l-6,3V8Zm2,2H22V22H4Zm24,1.625v8.75l-4-2v-4.75Z'
                  transform='translate(-2 -8)'
                  fill='#20c997'
                />
              </svg>
            </button>
            <div className='select-media'>
              {!!medias?.length &&
                medias.map((media, i) => (
                  <img width='200' key={i} src={getImageUrl(media.image)} alt='media' />
                ))}
            </div>
          </div>
          <form onSubmit={onFormSubmit} className='w-100 pt-3'>
            <Select
              defaultValue={categoryOptions.find(i => i.value === categoryId)}
              onChange={catgeory => setCategoryId(catgeory.value)}
              options={categoryOptions}
              className='mb-4'
              placeholder='Category'
              menuPortalTarget={document.querySelector('#react-select-portal')}
            />
            <div className='text-right mt-4'>
              <button
                className='btn btn-outline-primary text-md text-uppercase mnw-110 btn-sm py-2 weight-300 mx-2'
                onClick={onClose}
              >
                Close
              </button>
              <button
                className='btn btn-primary btn-sm py-2 text-md text-uppercase weight-300 mnw-110 ml-2'
                disabled={fetching}
                type='submit'
              >
                Save
              </button>
            </div>
          </form>
        </div>
      </Modal>
      {mediaModal && (
        <SelectMediaModal
          isMulti={state.data ? false : true}
          medias={medias ? medias.map(i => i.id) : []}
          onClose={() => setMediaModal(false)}
          onSelectMedia={onSelectMedia}
        />
      )}
    </>
  );
};

export default HomeItemManageModal;
