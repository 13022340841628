import React from 'react';
import { withRouter } from 'react-router';
import IconArrowClose from 'assets/images/icons/arrow_close.svg';

const BackButton = ({ history, to, onClick = () => {} }) => {
  const handleClick = () => {
    onClick();
    to ? history.push(to) : history.goBack();
  };
  return (
    <button className='back-btn rounded__30 mr-3' onClick={handleClick}>
      <img src={IconArrowClose} alt='back' className='op-6' /> Back
    </button>
  );
};

export default withRouter(BackButton);
