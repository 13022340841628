import React, { useState, useEffect, useRef } from 'react';
import { Api } from 'utils/connectors';
import { useSnackbar } from 'notistack';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import Tabs from 'shared/components/Tabs';
import useOutsideClick from 'shared/hooks/useOutsideClick';
import SearchBox from 'shared/components/forms/SearchBox';
import { getError, getImageUrl } from 'utils/appHelpers';
import { getMediaNavigationUrl } from 'utils/analyticsHelpers';
import InfiniteScroll from 'react-infinite-scroll-component';
import useDebounce from 'shared/hooks/useDebounce';
import Loading from 'shared/components/loadings/Loading';

const navTabs = [
  { name: 'All', value: 0 },
  { name: 'Movies', key: 'MOVIE', value: 1 },
  { name: 'TV Shows', key: 'TV_SHOW', value: 2 },
];

// NOTE: Need to keep the data of MediaSearch & Content separated, because:
//     - data in Content page sorted by different analytics keys
//     - MediaSearch component has a search

const MediaSearch = ({ className }) => {
  const { enqueueSnackbar } = useSnackbar();
  const history = useHistory();
  const container = useRef();
  const dropContent = useRef();

  const { fetching } = useSelector(state => state);

  const [localFetching, setLocalFetching] = useState(false);
  const [open, setOpen] = useState(false);
  const [activeTab, setActiveTab] = useState(0);
  const [data, setData] = useState(null);
  const [search, setSearch] = useState('');
  const debouncedSearch = useDebounce(search, 500);

  const tab = navTabs[activeTab];

  const getMedia = async isNext => {
    try {
      setLocalFetching(true);
      const page = isNext ? data.number : 0;
      const res = await Api.get('/medias', {
        params: {
          size: 20,
          category: tab.key,
          sort: 'updateDate:desc',
          page: isNext ? page + 1 : page,
          text: search,
        },
      });
      if (isNext) res.data.content = [...data.content, ...res.data.content];
      setData(res.data);
    } catch (err) {
      enqueueSnackbar(getError(err), { variant: 'error' });
    } finally {
      setLocalFetching(false);
    }
  };

  const navigateItem = media => {
    setOpen(false);
    const { category, title } = media;
    history.push({
      pathname: getMediaNavigationUrl(media),
      search: category === 'MOVIE' ? `?category=${category}&title=${title}` : '',
    });
  };

  useOutsideClick(container, () => setOpen(false));

  useEffect(() => {
    dropContent.current.scrollTop = 0;
    getMedia();
  }, [activeTab, debouncedSearch]);

  return (
    <div ref={container} className={`dropdown-container ${className}`}>
      <div className={`search-dropdown ${open ? 'active' : ''}`}>
        <SearchBox
          onChange={getMedia}
          search={search}
          setSearch={setSearch}
          onFocus={() => setOpen(true)}
          className='w-100'
          disabled={fetching}
        />
        <div ref={dropContent} className='drop-content position-relative' id='drop-content'>
          <Tabs items={navTabs} value={activeTab} onChange={key => setActiveTab(key)} />
          <div className='py-3 px-3 text-light'>Recent</div>

          {!!data && (
            <InfiniteScroll
              scrollableTarget='drop-content'
              dataLength={(data.content && data.content.length) || 0}
              next={() => getMedia(true)}
              hasMore={data.content && data.content.length < data.total_elements}
              loader={<div className='px-3'>Loading...</div>}
            >
              <ul className='drop-items'>
                {data.content.map((item, k) => {
                  const img = getImageUrl(item.image);
                  return (
                    <li
                      className='drop-item'
                      key={item.id}
                      onClick={() => navigateItem(item)}
                      role='presentation'
                    >
                      <img src={img} alt='img' />
                      {item.category === 'TV_SHOW' && (
                        <span className='badge badge-secondary media-badge'>TV Show</span>
                      )}
                      {item.title}
                    </li>
                  );
                })}
              </ul>
            </InfiniteScroll>
          )}
          {open && localFetching && (
            <div className='position-absolute t-0 l-0 b-0 r-0 d-flex align-items-center justify-content-center bg-transparent-white z-index-2'>
              <Loading />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default MediaSearch;
