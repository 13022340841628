import React, { useState, useEffect } from 'react';
import { useSnackbar } from 'notistack';
import { Api } from 'utils/connectors';
import { useDispatch, useSelector } from 'react-redux';
import { getError } from 'utils/appHelpers';
import { onSetCastAndCrew, onSetRoles } from 'app/Main/actions';
import { PermissionService } from 'services/PermissionService';
import { Link } from 'react-router-dom';
import Modal from 'shared/components/modals/Modal';
import ReactSelect from 'shared/components/forms/ReactSelect';
import CreateButton from 'shared/components/CreateButton';
import { IconClose } from 'shared/components/layouts/Icons';

const initialForm = { cast_ids: [], role_id: '' };

const CastAndCrewTabManageModal = ({ data, mediaId, lang, getData, onClose }) => {
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch();
  const castAndCrew = useSelector(state => state.castAndCrew[lang]);
  const roles = useSelector(state => state.roles[lang]);
  const [fetching, setFetching] = useState(false);
  const [form, setForm] = useState([{ ...initialForm }]);
  const [errors, setErrors] = useState([]);
  const isEdit = !!data;

  const getCastAndCrew = async () => {
    try {
      const { data } = await Api.get(`/casts`, {
        params: { page: 0, size: 10000, locale: lang },
      });
      dispatch(onSetCastAndCrew({ [lang]: data }));
    } catch (err) {
      enqueueSnackbar(getError(err), { variant: 'error' });
    }
  };

  const getRoles = async () => {
    try {
      const { data } = await Api.get(`/casts/roles`, {
        params: { page: 0, size: 1000, locale: lang },
      });
      dispatch(onSetRoles({ [lang]: data }));
    } catch (err) {
      enqueueSnackbar(getError(err), { variant: 'error' });
    }
  };

  const onFormChange = (key, value, index) => {
    form[index][key] = value;
    setForm([...form]);
  };

  const validateForm = () => {
    let hasErrors = false;
    const errors = [...form].map(item => {
      return {
        cast: !item.cast_ids?.length ? 'Please select a cast' : '',
        role: item.role_id === '' ? 'Please select a role' : '',
      };
    });
    hasErrors = errors.every(item => Object.values(item).every(value => value === ''));
    setErrors(errors);
    return hasErrors;
  };

  const onFormUpdate = async () => {
    return Api.put(`/medias/${mediaId}/assign/casts/${data.media_cast_role_link_id}`, {
      cast_id: form[0].cast_ids[0]?.id,
      role_id: form[0].role_id.id,
      media_id: mediaId,
    });
  };

  const onFormCreate = async () => {
    const body = form.map(item => {
      return {
        cast_ids: item.cast_ids.map(item => item.id),
        role_id: item.role_id.id,
      };
    });
    await Api.post(`/medias/${mediaId}/assign/casts`, body);
  };

  const onFormSubmit = async e => {
    e.preventDefault();
    const isFormValid = validateForm();
    if (!isFormValid) return;
    try {
      setFetching(true);
      isEdit ? await onFormUpdate() : await onFormCreate();
      await getData();
      enqueueSnackbar(`Successfully ${isEdit ? 'updated' : 'created'}`, { variant: 'success' });
      onClose();
    } catch (err) {
      console.log(err);
      enqueueSnackbar(getError(err), { variant: 'error' });
      setFetching(false);
    }
  };

  const initForm = () => {
    const castItem = castAndCrew?.content?.find(i => i.id === data.id);
    setForm([
      {
        cast_ids: castItem ? [castItem] : [],
        role_id: roles?.content?.find(i => i.name === data.role),
      },
    ]);
  };

  useEffect(() => {
    if (!castAndCrew) getCastAndCrew();
    if (!roles) getRoles();
  }, []);

  useEffect(() => {
    if (isEdit && castAndCrew?.content && roles?.content) initForm();
  }, [isEdit, castAndCrew, roles]);

  useEffect(() => {
    document.body.classList.add('overflow-hidden');
    return () => document.body.classList.remove('overflow-hidden');
  }, []);

  const onCreateNewGroup = () => {
    setForm([...form, { ...initialForm }]);
  };

  const onRemoveRow = index => {
    const newForm = [...form];
    newForm.splice(index, 1);
    setForm(newForm);
  };

  return (
    <Modal size='xs' onClose={onClose} className='mx-2 mt-2 overflow-initial'>
      <h6 className='modal-title bg-purple__light row px-6 py-3'>
        {isEdit ? 'Edit' : 'Add'} Cast and Crew
      </h6>
      <form onSubmit={onFormSubmit}>
        <div className='row align-items-center'>
          <div className='col-12'>
            <div className='row'>
              <div className='col-9'>
                <label className='weight-500 mb-0 mt-4 w-100 pl-0 mb-2 d-flex justify-content-between'>
                  Person
                  {!isEdit && (
                    <Link to='/cast-and-crew?action=create-cast' className='text-sm'>
                      + Add new person
                    </Link>
                  )}
                </label>
              </div>
              <div className='col-3 pl-0'>
                <label className='weight-500 mb-0 mt-4 w-100 mb-2 d-flex justify-content-between'>
                  Role
                  {!isEdit && (
                    <Link to='/cast-and-crew?action=create-role' className='text-sm'>
                      + Add new role
                    </Link>
                  )}
                </label>
              </div>
            </div>
          </div>
        </div>
        <div className='cast-and-crew-form-items max-300-scroll pl-5 overflow-x-hidden'>
          {form.map((item, index) => {
            const isNotOneItem = form.length > 1;
            return (
              <div className='row align-items-center mb-3' key={index}>
                <div className='col-12'>
                  {isNotOneItem && (
                    <button
                      type='button'
                      onClick={onRemoveRow.bind(null, index)}
                      className='btn p-0 position-absolute cast-crew-remove'
                    >
                      <IconClose />
                    </button>
                  )}
                  <div className='row'>
                    <div className='col-9'>
                      <ReactSelect
                        value={item.cast_ids}
                        onChange={value =>
                          onFormChange('cast_ids', isEdit ? [value] : value, index)
                        }
                        getOptionValue={i => i.id}
                        getOptionLabel={i => i.name || `${i.first_name} ${i.last_name}`}
                        options={castAndCrew?.content || []}
                        placeholder='Person'
                        disabled={!PermissionService.hasAccess('media_pricing')}
                        isMulti={isEdit ? false : true}
                        menuPortalTarget={document.querySelector('.cast-and-crew-form-items')}
                        menuPosition={'fixed'}
                        closeMenuOnSelect={false}
                      />
                      {errors[index]?.cast && (
                        <p className='text-danger mb-0'>
                          <small>{errors[index]?.cast}</small>
                        </p>
                      )}
                    </div>
                    <div className='col-3 pl-0'>
                      <ReactSelect
                        value={item.role_id}
                        onChange={value => onFormChange('role_id', value, index)}
                        options={roles?.content || []}
                        getOptionValue={i => i.id}
                        getOptionLabel={i => i.name}
                        placeholder='Role'
                        disabled={!PermissionService.hasAccess('media_pricing')}
                        menuPortalTarget={document.querySelector('.cast-and-crew-form-items')}
                        menuPosition={'fixed'}
                      />
                      {errors[index]?.role && (
                        <p className='text-danger mb-0'>
                          <small>{errors[index]?.role}</small>
                        </p>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
        {!isEdit && (
          <CreateButton
            type='button'
            name={`Add new group`}
            onClick={onCreateNewGroup}
            size='sm'
            className='text-nowrap border-0 p-sm-0 mt-4 addNewGroups'
          />
        )}
        <div className='d-flex align-items-center justify-content-end mt-85'>
          <button
            type='button'
            className='btn btn-outline-primary text-md btn-sm btn-crud weight-400 mx-2'
            onClick={onClose}
          >
            Cancel
          </button>
          <button
            type='submit'
            disabled={fetching}
            className='btn btn-primary btn-sm btn-crud text-md weight-400 ml-1'
          >
            {isEdit ? 'Update' : 'Add'}
          </button>
        </div>
      </form>
    </Modal>
  );
};

export default CastAndCrewTabManageModal;
