import React, { useEffect, useState } from 'react';
import { useSnackbar } from 'notistack';
import InputGroup from 'shared/components/forms/InputGroup';
import Modal from 'shared/components/modals/Modal';
import { Api } from 'utils/connectors';
import { getError } from 'utils/appHelpers';
import CheckBox from 'shared/components/forms/CheckBox';
import LangToggle from 'shared/components/LangToggle';

const GenresManageModal = ({ onClose, state, onSuccess, lang, setLang }) => {
  const { enqueueSnackbar } = useSnackbar();
  const [fetching, setFetching] = useState(false);
  const [name, setName] = useState(state.data ? state.data.name : '');
  const [types, setTypes] = useState(state.data ? state.data.types : []);

  const createItem = () => Api.post(`/genres?locale=${lang}`, { name, types });

  const updateItem = () => Api.put(`/genres/${state.data.id}?locale=${lang}`, { name, types });

  const onFormSubmit = async e => {
    e.preventDefault();
    try {
      setFetching(true);
      state.data ? await updateItem() : await createItem();
      if (onSuccess) onSuccess();
      enqueueSnackbar('Successfully done', { variant: 'success' });
      setFetching(false);
      onClose();
    } catch (err) {
      enqueueSnackbar(getError(err), { variant: 'error' });
      setFetching(false);
    }
  };

  const onCheckBoxChange = ({ target }) => {
    const tempTypes = [...types];
    if (tempTypes.includes(target.name)) {
      tempTypes.splice(tempTypes.indexOf(target.name), 1);
    } else {
      tempTypes.push(target.name);
    }
    setTypes(tempTypes);
  };

  useEffect(() => {
    if (state.data) {
      setName(state.data.name);
    }
  }, [state.data]);

  return (
    <Modal size='xs' onClose={onClose} className='mx-2 mt-2'>
      <div className='modal-title bg-purple__light row px-4 py-3'>
        <div className='text-primary__light'>{state.data ? 'Edit' : 'Create'} TV Shows Genre</div>
      </div>
      <div className='d-flex justify-content-start mb-2'>
        <LangToggle lang={lang} setLang={setLang} />
      </div>
      <div className='signup-modal d-flex flex-column align-items-center'>
        <form onSubmit={onFormSubmit} className='w-100 pt-3'>
          <InputGroup
            type='text'
            name='name'
            value={name}
            placeholder={'Name'}
            onChange={({ target }) => setName(target.value)}
            required
          />
          <div className='d-flex'>
            <CheckBox
              name='MOVIE'
              checked={types.includes('MOVIE')}
              onChange={onCheckBoxChange}
              label={'Movies'}
              className='mr-4'
            />
            <CheckBox
              name='TV_SHOW'
              checked={types.includes('TV_SHOW')}
              onChange={onCheckBoxChange}
              label={'TV shows'}
            />
          </div>
          <div className='text-right mt-4'>
            <button
              className='btn btn-outline-primary text-md text-uppercase mnw-110 btn-sm py-2 weight-300 mx-2'
              onClick={onClose}
              type='button'
            >
              Close
            </button>
            <button
              className='btn btn-primary btn-sm py-2 text-md text-uppercase weight-300 mnw-110 ml-2'
              disabled={fetching}
              type='submit'
            >
              Save
            </button>
          </div>
        </form>
      </div>
    </Modal>
  );
};

export default GenresManageModal;
