import * as React from 'react';

const SeasonTabs = ({ data, activeTab, onChange }) => {
  return (
    <div className='d-flex align-items-center'>
      {data.map((season, i) => (
        <button
          className={`btn ${activeTab === i ? 'btn-primary' : 'text-light'} download-btn mr-2`}
          key={season.id}
          onClick={() => onChange(i)}
        >
          {season.name}
        </button>
      ))}
    </div>
  );
};

export default SeasonTabs;
