import React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import PageContent from './PageContent';

const Views = ({ match }) => (
  <Switch>
    <Route path={`${match.path}/:type`} component={PageContent} />
    <Redirect from='*' to={`${match.path}/country`} />
  </Switch>
);

export default Views;
