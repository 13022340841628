import React, { useState, useRef } from 'react';
import { useSelector, connect } from 'react-redux';
import { logout } from 'app/Auth/actions';
import useOutsideClick from 'shared/hooks/useOutsideClick';
import packageJson from '../../../../package.json';

const Header = ({ children, className, logout }) => {
  const uploader = useSelector(state => state.uploader);
  const dropdownRef = useRef();
  const btnRef = useRef();

  const [active, setActive] = useState(false);
  const account = useSelector(state => state.account);

  useOutsideClick(dropdownRef, () => setActive(false), btnRef);

  const uploadItems = uploader && Object.values(uploader).filter(i => i).length;

  return (
    <div
      className={`header z-1 d-flex align-items-center justify-content-between px-6 ${className}`}
    >
      {children && children}
      <div className='d-flex align-items-center'>
        {!!uploadItems && (
          <div>
            <button
              type='button'
              className='btn-sm btn-outline-secondary weight-100 border mr-3 d-flex align-items-center'
              disabled
            >
              <span
                className='spinner-grow spinner-grow-sm mr-1 text-info'
                role='status'
                aria-hidden='true'
              />
              uploading movies <span className='badge badge-light ml-1'>{uploadItems}</span>
            </button>
          </div>
        )}
        <div className='header-dropdown position-relative'>
          <button
            ref={btnRef}
            className='dropdown-toggler pointer btn btn-clean btn-sm weight-100 text-light p-2'
            onClick={e => {
              e.stopPropagation();
              setActive(!active);
            }}
          >
            {account.email} <span className={`arrow arrow-${active ? 'up' : 'down'}`} />
          </button>
          {active && (
            <div
              ref={dropdownRef}
              className='dropdown dropdown__left dropdown__down bg-white overflow-hidden rounded__3 transform-y-12'
            >
              <div className='bg-primary p-4 text-white d-flex justify-content-between align-items-center'>
                {account.name}
                <span>V{packageJson.version}</span>
              </div>
              <div className='p-4'>
                <span className='op-9'>E-Mail:</span>
                <p className='text-sm text-primary__light'>{account.email}</p>
                <button className='btn btn-sm btn-outline-primary' onClick={logout}>
                  Sign Out
                </button>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default connect(
  null,
  { logout },
)(Header);
