import React, { useState } from 'react';
import Modal from 'shared/components/modals/Modal';
import { Api } from 'utils/connectors';
import { useSnackbar } from 'notistack';
import { getError } from 'utils/appHelpers';
import PhoneInput from 'react-phone-input-2';

const CheckPhoneMTSModal = ({ onClose }) => {
  const { enqueueSnackbar } = useSnackbar();
  const [phone, setPhone] = useState('374');
  const [fetching, setFetching] = useState(false);
  const [message, setMessage] = useState(null);

  const onFormSubmit = async e => {
    try {
      setFetching(true);
      e.preventDefault();
      const params = { 'phone-number': `+${phone}` };
      const { data } = await Api.get('/clients/mts-eligibility', { params });
      setMessage(data.message);
      setPhone('374');
    } catch (err) {
      enqueueSnackbar(getError(err), { variant: 'error' });
    } finally {
      setFetching(false);
    }
  };

  return (
    <Modal size='xs' onClose={onClose} className='mx-2 mt-2'>
      <div className='modal-title bg-purple__light row px-4 py-3'>
        <div className='text-primary__light'>Check eligibility for x/y subscription</div>
      </div>
      <div className='signup-modal d-flex flex-column align-items-center'>
        <form onSubmit={onFormSubmit} className='w-100 pt-3'>
          <PhoneInput
            value={phone}
            specialLabel=''
            placeholder={`+374 XXXXXXXX`}
            onChange={v => setPhone(v)}
            disabled={fetching}
            inputProps={{
              autoFocus: true,
            }}
          />
          {message && <p className='mt-3 px-2 text-light-blue'>{message}</p>}
          <div className='text-right mt-4'>
            <button
              className='btn btn-outline-primary text-md text-uppercase mnw-110 btn-sm py-2 weight-300 mx-2'
              onClick={onClose}
              disabled={fetching}
              type='button'
            >
              Cancel
            </button>
            <button
              className='btn btn-primary btn-sm py-2 text-md text-uppercase weight-300 mnw-110 ml-2'
              disabled={fetching}
              type='submit'
            >
              Check
            </button>
          </div>
        </form>
      </div>
    </Modal>
  );
};

export default CheckPhoneMTSModal;
