import React, { useState, useEffect } from 'react';
import { useSnackbar } from 'notistack';
import InputGroup from 'shared/components/forms/InputGroup';
import Modal from 'shared/components/modals/Modal';
import { Api } from 'utils/connectors';
import { getError } from 'utils/appHelpers';

const RoleManageModal = ({ onClose, onSuccess, data, lang }) => {
  const { enqueueSnackbar } = useSnackbar();
  const [fetching, setFetching] = useState(false);
  const [form, setForm] = useState({ name: '' });
  const isEdit = !!data;

  const onFormSubmit = async e => {
    e.preventDefault();
    try {
      setFetching(true);
      const url = isEdit ? `/casts/roles/${data.id}?locale=${lang}` : `/casts/roles?locale=${lang}`;
      const action = isEdit ? 'put' : 'post';
      const successMsg = `Successfully ${isEdit ? 'updated' : 'created'}`;
      await Api[action](url, form);
      enqueueSnackbar(successMsg, { variant: 'success' });
      onSuccess && onSuccess();
      onClose();
    } catch (err) {
      enqueueSnackbar(getError(err), { variant: 'error' });
    } finally {
      setFetching(false);
    }
  };

  const handleChange = ({ target }) => {
    const { name, value } = target;
    setForm({ ...form, [name]: value });
  };

  useEffect(() => {
    if (data) setForm(data);
  }, [data]);

  return (
    <Modal size='xs' onClose={onClose} className='mx-2 mt-2'>
      <div className='modal-title bg-purple__light row px-4 py-3'>
        <div className='text-primary__light'>{isEdit ? 'Update' : 'Create'} Role</div>
      </div>
      <div className='signup-modal d-flex flex-column align-items-center'>
        <form onSubmit={onFormSubmit} className='w-100 pt-3'>
          <InputGroup
            type='text'
            name='name'
            value={form.name}
            placeholder='Role name'
            onChange={handleChange}
            required
            autoFocus
          />
          <div className='text-right mt-4'>
            <button
              className='btn btn-outline-primary text-md text-uppercase mnw-110 btn-sm py-2 weight-300 mx-2'
              onClick={onClose}
              disabled={fetching}
              type='button'
            >
              Cancel
            </button>
            <button
              className='btn btn-primary btn-sm py-2 text-md text-uppercase weight-300 mnw-110 ml-2'
              disabled={fetching}
              type='submit'
            >
              {isEdit ? 'Update' : 'Create'}
            </button>
          </div>
        </form>
      </div>
    </Modal>
  );
};

export default RoleManageModal;
