import React from 'react';
import { chartColors } from './../configs/consts';
import { bindDurationHours, formatNumber } from 'utils/appHelpers';

const ItemsList = ({
  data,
  ignoreItems,
  showAll,
  totalValues,
  showPercentage,
  onChange,
  className,
  unit,
}) => {
  if (!data || !data.length) return null;

  const isPricing = unit === '$';
  const isWatchtime = unit === 'mins';

  return (
    <ul className={`no-list-style mt-2 mb-0 p-0 ${className}`}>
      {data.map(({ label, total }, i) => {
        if (!showAll && i > 4) return null;
        const color = chartColors[i] || chartColors[0];
        const ignored = ignoreItems.includes(i);
        return (
          <li
            className={`d-flex align-items-center indicator-container mb-1 ${
              ignored ? 'indicator-container-disabled' : ''
            }`}
            key={i}
            onClick={() => onChange(i)}
            role='presentation'
          >
            <span className='indicator-content'>
              <span className='indicator-square mr-2' style={{ backgroundColor: color }} />
              <span className='text-blue-dark text-ellipsis indicator-label text-capitalize'>
                {label.replace('_', ' ')}
              </span>
            </span>
            <span className='text-light indicator-label'>
              {isPricing && '$'}
              {isWatchtime ? bindDurationHours(total) : formatNumber(total)}{' '}
              {isWatchtime || isPricing ? '' : unit.toLowerCase()}{' '}
              {showPercentage && totalValues && (
                <span>{` (${((total / totalValues) * 100).toFixed(1)}%)`}</span>
              )}
            </span>
          </li>
        );
      })}
    </ul>
  );
};

ItemsList.defaultProps = {
  ignoreItems: [],
  className: '',
};

export default ItemsList;
