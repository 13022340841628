import React, { useEffect, useState } from 'react';
import Header from 'shared/components/layouts/Header';
import { useSnackbar } from 'notistack';
import { useDispatch, useSelector } from 'react-redux';
import { Api } from 'utils/connectors';
import { confirmBox, getError } from 'utils/appHelpers';
import { onProductsSet, setTotalValue } from 'app/Main/actions';
import { PermissionService } from 'services/PermissionService';
import InfiniteScroll from 'react-infinite-scroll-component';
import Loading from 'shared/components/loadings/Loading';
import ProductManageModall from './components/ProductManageModall';
import ConsumableList from './components/ConsumableList';
import RenewableList from './components/RenewableList';
import LangToggle from 'shared/components/LangToggle';
import { sortDataWithKey } from 'utils/sortingHelper';

const typeInfos = {
  consumable: {
    title: 'In-app products',
    listComponent: ConsumableList,
  },
  renewable: {
    title: 'Subscriptions',
    listComponent: RenewableList,
  },
};

const Products = ({ match, location, history }) => {
  const type = match?.params?.type;
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch();
  const allProducts = useSelector(state => state.products) || {};
  const [lang, setLang] = useState('en');
  const [manageModal, setManageModal] = useState({
    state: !!location.state?.form,
    product: null,
  });

  const products = allProducts[type]?.[lang];

  const getProducts = async isNext => {
    try {
      const page = isNext ? products.number : 0;
      const res = await Api.get('/products', {
        params: {
          locale: lang,
          type: String(type).toUpperCase(),
          size: 100,
          page: isNext ? page + 1 : page,
        },
      });
      res.data.content = sortDataWithKey(res.data.content);
      if (isNext) res.data.content = [...products.content, ...res.data.content];
      dispatch(onProductsSet({ [type]: { ...allProducts[type], [lang]: res.data } }));
      dispatch(setTotalValue(`all_products_${type}`, res.data.total_elements));
    } catch (err) {
      enqueueSnackbar(getError(err), { variant: 'error' });
    }
  };

  const removeItem = async (id, index) => {
    try {
      await Api.delete(`/products/${id}`);
      const tempItems = [...products.content];
      tempItems.splice(index, 1);
      dispatch(onProductsSet({ [type]: { ...products, content: tempItems } }));
      enqueueSnackbar('Successfully done', { variant: 'success' });
      getProducts();
    } catch (err) {
      enqueueSnackbar(getError(err), { variant: 'error' });
    }
  };

  const onItemRemove = (id, index) => {
    const options = {
      onSuccess: removeItem.bind(null, id, index),
      message: `Are you sure you want to delete current product?`,
      confirm: 'Delete',
    };
    confirmBox(options);
  };

  const onItemEdit = e => {
    setManageModal({ product: e, state: true });
  };

  const onItemCreate = () => {
    setManageModal({ product: null, state: true });
  };

  const onModalClose = () => {
    if (location.state?.form) {
      const form = { ...location.state?.form };
      history.push('/plans', { form });
    } else {
      setManageModal({ product: null, state: false });
    }
  };

  const onProductCreateSuccess = product => {
    if (location.state?.form) {
      const form = { ...location.state?.form };
      form.in_app_product_ids.push(product?.data.id);
      history.push('/plans', { form });
    } else {
      getProducts();
    }
  };

  useEffect(() => {
    getProducts();
    //eslint-disable-next-line
  }, [type, lang]);

  const activeType = typeInfos[type];
  const ListComponent = activeType.listComponent;

  return (
    <>
      <Header>
        <div className='d-flex align-items-center'>{activeType.title}</div>
      </Header>
      <main className='main-content'>
        <div className='d-flex align-items-center justify-content-end mb-3'>
          <LangToggle lang={lang} setLang={setLang} />
        </div>
        <div className='px-3 bg-white pb-3'>
          <div className='bg-purple__light row px-4 py-2 d-flex align-items-center justify-content-between'>
            <div className='text-primary__light'>Created {activeType.title}</div>
            <button
              className='btn btn-sm btn-primary btn-crud'
              onClick={onItemCreate}
              disabled={!PermissionService.hasAccess('product_create')}
            >
              Create New
            </button>
          </div>
          {products?.content ? (
            <InfiniteScroll
              dataLength={(products.content && products.content.length) || 0}
              next={() => getProducts(true)}
              hasMore={products.content && products.content.length < products.total_elements}
              loader={<div>Loading...</div>}
            >
              {
                <ListComponent
                  products={products?.content}
                  onItemEdit={onItemEdit}
                  onItemRemove={onItemRemove}
                  reloadItems={getProducts}
                />
              }
            </InfiniteScroll>
          ) : (
            <div className='text-center py-6'>
              <Loading />
            </div>
          )}
        </div>
      </main>
      {manageModal.state && (
        <ProductManageModall
          lang={lang}
          type={type}
          state={manageModal}
          onSuccess={onProductCreateSuccess}
          onClose={onModalClose}
          title={`${activeType.title}`}
        />
      )}
    </>
  );
};

export default Products;
