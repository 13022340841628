import React, { useState } from 'react';
import { useSnackbar } from 'notistack';
import { confirmBox, getError } from 'utils/appHelpers';
import { Api } from 'utils/connectors';
import { reorder } from 'utils/appHelpers';
import { useSelector } from 'react-redux';
import { PermissionService } from 'services/PermissionService';
import { allLanguages } from 'configs';
import MediaVideoItem from 'shared/components/MediaVideoItem';
import DragList from 'shared/components/layouts/DragList';
import EpisodesManageModal from './EpisodesManageModal';
import MediaVideoUploader from 'shared/components/MediaVideoUploader/index';
import Slider from 'shared/components/Slider';
import EpisodePublishDialog from 'shared/components/EpisodePublishDialog';
import MediaAudioUploaderModal from 'shared/components/MediaAudioUploaderModal';

const EpisodesTab = ({ media, updateMovieStore, lang, setLang, updateMovie, getMovie }) => {
  const [mediaAudioSettings, setMediaAudioSettings] = useState({ show: false });
  const uploader = useSelector(state => state.uploader);
  const [replaceState, setReplaceState] = useState({ show: false });
  const [openPublishingDialogData, setOpenPublishingDialogData] = useState(null);
  const [isFetching, setIsFetching] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const [activeSeason, setActiveSeason] = useState(0);
  const [audioTrackLang, setAudioTrackLang] = useState(allLanguages.find(i => i.code === 'hy'));
  const [manageModal, setManageModal] = useState({
    state: false,
    data: null,
  });

  if (!media || !media.seasons)
    return <div className='mt-3 mb-1 text-primary__light'>Please create seasons first</div>;

  const season = media.seasons[activeSeason];
  const episodes = season.episodes;
  const params = { seasonId: season.id, locale: lang };

  const onRemoveEpisode = async (id, index) => {
    try {
      await Api.delete(`tv-shows/${media.id}/episodes/${id}`);
      const tempMedia = { ...media };
      tempMedia.seasons[activeSeason].episodes.splice(index, 1);
      updateMovieStore(tempMedia);
    } catch (err) {
      enqueueSnackbar(getError(err), { variant: 'error' });
    }
  };

  const onItemRemove = (id, index) => {
    const options = {
      onSuccess: onRemoveEpisode.bind(null, id, index),
      message: `Are you sure you want to delete the episode media?`,
      confirm: 'Delete',
    };
    confirmBox(options);
  };

  const createAndGetEpisodeId = async () => {
    try {
      const body = { title: 'Episode', media_type: 'NATIVE', audio_track_lang: audioTrackLang?.code };
      const { data } = await Api.post(`/tv-shows/${media.id}/episodes`, body, { params });
      pushEpisodeItem(data);
      return data.id;
    } catch (err) {
      enqueueSnackbar(getError(err), { variant: 'error' });
      return null;
    }
  };

  const pushEpisodeItem = video => {
    const tempMedia = { ...media };
    const episodes = tempMedia.seasons[activeSeason].episodes || [];
    episodes.push(video);
    tempMedia.seasons[activeSeason].episodes = episodes;
    updateMovieStore(tempMedia);
  };

  const onFileSuccess = async () => {
    getMovie();
    setTimeout(getMovie, 5000);
  };

  const onYoutubeLinkSubmit = async itemObj => {
    try {
      setIsFetching(true);
      const body = { title: 'Episode', media_type: 'YOUTUBE', ...itemObj };
      const res = await Api.post(`/tv-shows/${media.id}/episodes`, body, { params });
      pushEpisodeItem(res.data);
      setIsFetching(false);
    } catch (err) {
      enqueueSnackbar(getError(err), { variant: 'error' });
      setIsFetching(false);
    }
  };

  const onDragEpisodeEnd = async e => {
    if (!e.destination) return;
    try {
      const tempMedia = { ...media };
      tempMedia.seasons[activeSeason].episodes = reorder(
        tempMedia.seasons[activeSeason].episodes,
        e.source.index,
        e.destination.index,
      );
      updateMovieStore(tempMedia);
      await Api.put(
        `/tv-shows/${media.id}/episodes/${e.draggableId}/reorder?order=${e.destination.index}`,
      );
    } catch (err) {
      enqueueSnackbar(getError(err), { variant: 'error' });
    }
  };

  const checkItemPublish = item => {
    const { title, image } = item;
    if (!title || !image) {
      return false;
    }
    return true;
  };

  const onTogglePublish = (item, index) => {
    if (!item.published && !checkItemPublish(item)) {
      enqueueSnackbar(`You can't publish the item, media is incomplete`);
      return;
    }

    setOpenPublishingDialogData({ item, index });
  };

  const onItemEdit = e => {
    setManageModal({ data: e, state: true });
  };

  const onModalClose = () => {
    setManageModal({ data: null, state: false });
  };

  const onHandleReplaceVideoClick = (item) => {
    if(item.replacement_status === 'PROGRESSING') {
      enqueueSnackbar('Unable to perform media replacement as an ongoing replacement process is still in progress', { variant: 'error' });
      return;
    }
    setReplaceState({ show: true, type: media.category, itemId: item.id, id: media.id })
  }

  const onClickOpenAudioMenu = (item) => {
    setMediaAudioSettings({ show: true, item });
  };

  return (
    <div>
      <Slider items={media.seasons} activeIndex={activeSeason} onItemSelect={setActiveSeason} />
      <MediaVideoUploader
        onYoutubeSubmit={onYoutubeLinkSubmit}
        isFetching={isFetching}
        onFileSuccess={onFileSuccess}
        showOptionsBeforeUpload={true}
        optionsState={{ audioTrackLang, setAudioTrackLang }}
        isMulti
        uploadOptions={{
          type: 'TV_SHOW',
          id: media.id,
          getItemId: createAndGetEpisodeId,
        }}
      />
      {episodes && (
        <>
          <p className='sub-title'>Episodes list</p>
          <div className='episodes'>
            <DragList
              onDragEnd={onDragEpisodeEnd}
              items={episodes}
              className='mt-3'
              isDragDisabled={!PermissionService.hasAccess('media_edit')}
            >
              {(item, index) => {
                const progress = uploader[item.id];
                return (
                  <MediaVideoItem
                    num={index + 1}
                    key={item.id}
                    video={{ ...item, movieId: media.id }}
                    videoProgress={progress}
                    onItemEdit={onItemEdit.bind(null, item)}
                    onItemRemove={onItemRemove.bind(null, item.id, index)}
                    onPublish={onTogglePublish.bind(null, item, index)}
                    showPublishState
                    published={!!item.published}
                    publishDate={item.publish_date}
                    onEpisodeVideoCancel={onRemoveEpisode.bind(null, item.id, index)}
                    onClickReplaceVideo={onHandleReplaceVideoClick.bind(null, item)}
                    showReplaceOption={true}
                    onClickOpenAudioMenu={onClickOpenAudioMenu.bind(null, item)}
                  />
                );
              }}
            </DragList>
          </div>
        </>
      )}
      {manageModal.state && (
        <EpisodesManageModal
          state={manageModal}
          activeSeason={activeSeason}
          media={media}
          lang={lang}
          setLang={setLang}
          onClose={onModalClose}
          updateMovieStore={updateMovieStore}
          updateMovie={updateMovie}
        />
      )}
      {openPublishingDialogData && (
        <EpisodePublishDialog
          mediaId={media.id}
          item={openPublishingDialogData.item}
          onClose={() => setOpenPublishingDialogData(null)}
          onSuccess={() => {
            getMovie();
            setOpenPublishingDialogData(null);
          }}
        />
      )}
      {replaceState.show && (
        <MediaVideoUploader
          isMediaReplace={true}
          showUploadOptionsInitial={true}
          onFileSuccess={onFileSuccess}
          optionsState={{ audioTrackLang, setAudioTrackLang }}
          onCloseSuccess={() => setReplaceState({ show: false })}
          onHandleConvertSuccess={() => setReplaceState({ show: false })}
          uploadOptions={{ ...replaceState }}
        />
      )}
      {mediaAudioSettings.show && (
        <MediaAudioUploaderModal
          movieId={media.id}
          item={mediaAudioSettings.item}
          onClose={() => {
            setMediaAudioSettings({ show: false });
            getMovie();
          }}
        />
      )}
    </div>
  );
};

export default EpisodesTab;
