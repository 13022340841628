import React, { useState, useEffect } from 'react';
import { Pie } from 'react-chartjs-2';
import ItemsList from './ItemsList';
import { duplicate } from 'utils/appHelpers';
import NoChartData from './NoChartData';

const options = {
  plugins: {
    legend: {
      display: false,
    },
    tooltip: {
      enabled: false,
    },
    datalabels: {
      display: true,
      color: 'white',
      font: {
        size: '16px',
        lineHeight: '18px',
        weight: '700',
      },
      formatter: (value, ctx) => {
        const totalValues = ctx.dataset.data.reduce((val, item) => val + item, 0);
        const percent = ((value / totalValues) * 100).toFixed(1);
        return `${percent}%`;
      },
    },
  },
};

const defaultDescription = '* Total number of views when a video is watched at least 120 seconds';

const PieChart = ({ data, listItems, totalValues, unit, description = defaultDescription }) => {
  const [chartData, setChartData] = useState(data);
  const [ignoreIndexes, setIgnoreIndexes] = useState([]);
  const [showAll, setShowAll] = useState(false);

  const filterSetIds = index => {
    let newIndexes = duplicate(ignoreIndexes);
    if (newIndexes.includes(index)) {
      newIndexes = newIndexes.filter(v => v !== index);
    } else {
      newIndexes.push(index);
    }
    setIgnoreIndexes(newIndexes);
  };

  const filterElements = src => {
    return src.filter((v, i) => !ignoreIndexes.includes(i));
  };

  const filterData = () => {
    const newData = duplicate(data);
    newData.datasets[0].data = filterElements(newData.datasets[0].data);
    newData.datasets[0].backgroundColor = filterElements(newData.datasets[0].backgroundColor);
    newData.datasets[0].borderColor = filterElements(newData.datasets[0].borderColor);
    newData.labels = filterElements(newData.labels);
    setChartData(newData);
  };

  useEffect(() => {
    filterData();
  }, [ignoreIndexes]);

  useEffect(() => {
    setChartData(data);
  }, [data]);

  return (
    <>
      {chartData.labels?.length ? (
        <>
          <div className='pie-chart'>
            <Pie data={chartData} options={options} />
          </div>
          <ItemsList
            data={listItems}
            ignoreItems={ignoreIndexes}
            totalValues={totalValues}
            showPercentage
            onChange={i => filterSetIds(i)}
            showAll={showAll}
            unit={unit}
            className='mt-85'
          />
          <hr className='mt-3 mb-4' />
          <button
            className='btn btn-outline-primary btn-sm show-more-btn'
            onClick={() => setShowAll(!showAll)}
            disabled={listItems.length <= 5}
          >
            Show {showAll ? 'Less' : 'More'}
          </button>
          <p className='mt-6 mb-0 text-light text-sm'>{description}</p>
        </>
      ) : (
        <NoChartData />
      )}
    </>
  );
};

export default PieChart;
