import React from 'react';
import Select from 'react-select';
import { countries } from 'configs';

const systemOptions = countries.map(({ key, name }) => ({
  label: name,
  value: name,
  key: key.toLocaleLowerCase(),
}));

const CountrySelectContainer = ({ title, onChange, selectedCountry }) => {
  const labelAndValue = systemOptions.find(item => item.key === selectedCountry);

  const selectValue = {
    label: labelAndValue?.label,
    value: labelAndValue?.label,
  };

  return (
    <div className='row border-bottom mb-3 mt-1'>
      <div className='col-4 mr-5 p-0'>
        <h6>{title}</h6>
      </div>
      <div className='col-7 pl-5 pr-4 pb-4'>
        <div className='row d-flex align-items-center'>
          <Select
            value={selectValue}
            onChange={({ value }) => {
              const countryObject = systemOptions.find(item => item.value === value);
              value = countryObject.key;
              onChange({ target: { name: `country_based_ad_configs`, value } });
            }}
            options={systemOptions}
            className='col p-0'
            placeholder='Select country'
          />
        </div>
      </div>
    </div>
  );
};

export default CountrySelectContainer;
