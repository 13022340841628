import { useEffect } from 'react';
import { AnalyticsApi } from 'utils/connectors';
import { useDispatch, useSelector } from 'react-redux';
import { useSnackbar } from 'notistack';
import { useParams } from 'react-router-dom';
import { bindDurationHours, getError } from 'utils/appHelpers';
import { onAnalyticsSet, onFetchingSet } from 'app/Main/actions';
import { generateChartData, buildParams, generateDefaultCSVData } from 'utils/analyticsHelpers';
import cloneDeep from 'lodash/cloneDeep';

const useAnalyticsData = (
  url,
  storeKey,
  parseChartData = generateChartData,
  parseCSVData = generateDefaultCSVData,
) => {
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const { type } = useParams();
  const { filters, analytics } = useSelector(state => state);
  const { dates, items } = filters;

  const getAnalytics = async () => {
    try {
      dispatch(onFetchingSet(true));
      const params = buildParams(filters, type);
      const { data } = await AnalyticsApi.post(url, params);
      const CSVPreparedData =
        storeKey === 'watchtime'
          ? cloneDeep(data.data).map(group => {
              group.data.map(item => {
                item.value = bindDurationHours(item.value);
                return item;
              });
              return group;
            })
          : data.data;
      const result = { ...parseChartData(data.data), CSV: parseCSVData(CSVPreparedData) };
      dispatch(onAnalyticsSet({ [storeKey]: result }));
      dispatch(onFetchingSet(false));
    } catch (err) {
      enqueueSnackbar(getError(err), { variant: 'error' });
      dispatch(onFetchingSet(false));
    }
  };

  useEffect(() => {
    getAnalytics();
  }, [dates, items, type, filters.revenue_type, filters.metric_type]);

  return analytics[storeKey];
};

export default useAnalyticsData;
