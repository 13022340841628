import React, { useState, useEffect } from 'react';
import { withRouter } from 'react-router';
import { NavLink, Link } from 'react-router-dom';
import logo from 'assets/images/logo.svg';
import { useSelector } from 'react-redux';
import { PermissionService } from 'services/PermissionService';
import { useLocation } from 'react-router-dom';

import IconArrowClose from 'assets/images/icons/arrow_close.svg';
import IconDashboard from 'assets/images/icons/dashboard.svg';
import IconDashboardHover from 'assets/images/icons/dashboard_hover.svg';
import IconMovies from 'assets/images/icons/movies.svg';
import IconMoviesHover from 'assets/images/icons/movies_hover.svg';
import IconTvShow from 'assets/images/icons/tvshow.svg';
import IconTvShowHover from 'assets/images/icons/tvshow_hover.svg';
import IconUpcoming from 'assets/images/icons/upcoming.svg';
import IconUpcomingHover from 'assets/images/icons/upcoming_hover.svg';
import IconPosters from 'assets/images/icons/posters.svg';
import IconPostersHover from 'assets/images/icons/posters_hover.svg';
import IconCategories from 'assets/images/icons/categories.svg';
import IconCategoriesHover from 'assets/images/icons/categories_hover.svg';
import IconGenres from 'assets/images/icons/genres.svg';
import IconGenresHover from 'assets/images/icons/genres_hover.svg';
import IconCasts from 'assets/images/icons/casts.svg';
import IconCastsHover from 'assets/images/icons/casts_hover.svg';
import IconTags from 'assets/images/icons/tags.svg';
import IconTagsHover from 'assets/images/icons/tags_hover.svg';
import IconUser from 'assets/images/icons/user.svg';
import IconUserHover from 'assets/images/icons/user_hover.svg';
import IconChannels from 'assets/images/icons/channels.svg';
import IconChannelsHover from 'assets/images/icons/channels_hover.svg';
import IconGramophone from 'assets/images/icons/gramophone.svg';
import IconGramophoneHover from 'assets/images/icons/gramophone_hover.svg';
import IconSubscriptions from 'assets/images/icons/subscriptions.svg';
import IconSubscriptionsHover from 'assets/images/icons/subscriptions_hover.svg';
import IconConfigurations from 'assets/images/icons/configurations.svg';
import IconConfigurationsHover from 'assets/images/icons/configurations_hover.svg';
import IconProducts from 'assets/images/icons/products.svg';
import IconProductsHover from 'assets/images/icons/products_hover.svg';
import IconPlans from 'assets/images/icons/plans.svg';
import IconPlansHover from 'assets/images/icons/plans_hover.svg';
import IconPromo from 'assets/images/icons/promo.svg';
import IconPromoHover from 'assets/images/icons/promo_hover.svg';
import IconUsd from 'assets/images/icons/usd.svg';
import IconUsdHover from 'assets/images/icons/usd_hover.svg';

const navs = [
  {
    name: 'MEDIAS',
    childs: [
      {
        name: 'Movies',
        to: '/movies',
        icon: IconMovies,
        activeIcon: IconMoviesHover,
        total: 'all_movies',
        permission: 'movies_view',
      },
      {
        name: 'TV Shows',
        to: '/tv-shows',
        icon: IconTvShow,
        activeIcon: IconTvShowHover,
        total: 'all_tvshows',
        permission: 'tvshow_view',
      },
      {
        name: 'Upcoming',
        to: '/upcoming',
        total: 'all_upcoming',
        icon: IconUpcoming,
        activeIcon: IconUpcomingHover,
        permission: 'upcoming_view',
      },
    ],
  },
  {
    name: 'HOMEPAGE',
    childs: [
      {
        name: 'Posters',
        to: '/posters',
        icon: IconPosters,
        activeIcon: IconPostersHover,
        permission: 'poster_view',
      },
      {
        name: 'Categories',
        to: '/categories',
        icon: IconCategories,
        activeIcon: IconCategoriesHover,
        permission: 'categories_view',
      },
    ],
  },
  {
    name: 'OTHERS',
    childs: [
      {
        name: 'Genres',
        to: '/genres',
        icon: IconGenres,
        activeIcon: IconGenresHover,
        permission: 'genres_view',
      },
      {
        name: 'Tags',
        to: '/tags',
        icon: IconTags,
        activeIcon: IconTagsHover,
        permission: 'tags_view',
      },
      {
        name: 'Cast & Crew',
        to: '/cast-and-crew',
        icon: IconCasts,
        activeIcon: IconCastsHover,
        permission: 'cast_and_crew_view',
        className: 'custom_icons',
      },
      {
        name: 'User Dashboard',
        to: '/clients',
        icon: IconUser,
        activeIcon: IconUserHover,
        permission: 'clients_view',
        className: 'custom_icons',
      },
    ],
  },
  {
    name: 'DATA',
    childs: [
      {
        name: 'Analytics',
        to: '/analytics',
        icon: IconDashboard,
        activeIcon: IconDashboardHover,
        permission: 'analytics_view',
      },
    ],
  },
  {
    name: 'SETTINGS',
    childs: [
      {
        name: 'Permissions',
        to: '/users',
        icon: IconUser,
        activeIcon: IconUserHover,
        permission: 'users_view',
      },
      {
        name: 'Channels',
        to: '/channels',
        icon: IconChannels,
        activeIcon: IconChannelsHover,
        permission: 'channels_view',
      },
      {
        name: 'Configurations',
        to: '/configurations',
        icon: IconConfigurations,
        activeIcon: IconConfigurationsHover,
        permission: 'configurations_view',
        className: 'custom_icons',
      },
      {
        name: 'Web Pages',
        to: '/pages',
        icon: IconCategories,
        activeIcon: IconCategoriesHover,
        permission: 'pages_view',
      },
    ],
  },
  {
    name: 'REVENUE MANAGEMENT',
    childs: [
      {
        name: 'Ads',
        to: '/ads',
        icon: IconGramophone,
        activeIcon: IconGramophoneHover,
        permission: 'ads_view',
      },
      {
        name: 'In-app Products',
        to: '/products/consumable',
        icon: IconProducts,
        activeIcon: IconProductsHover,
        permission: 'products_view',
        total: 'all_products_consumable',
      },
      {
        name: 'Subscriptions',
        to: '/products/renewable',
        icon: IconSubscriptions,
        activeIcon: IconSubscriptionsHover,
        permission: 'products_view',
        total: 'all_products_renewable',
      },
      {
        name: 'Subscription Plans',
        to: '/plans',
        icon: IconPlans,
        activeIcon: IconPlansHover,
        permission: 'plans_view',
        total: 'all_plans',
      },
      {
        name: 'Promo codes',
        to: '/promos',
        icon: IconPromo,
        activeIcon: IconPromoHover,
        permission: 'promos_view',
        total: 'all_promos',
        className: 'custom_promo',
      },
      {
        name: 'Finance',
        to: '/finance',
        icon: IconUsd,
        activeIcon: IconUsdHover,
        permission: 'finance_view',
        className: 'custom_finance',
      },
    ],
  },
];

const LinkItem = ({ item, totals }) => (
  <NavLink className='d-flex align-items-center' to={item.to}>
    <img src={item.icon} alt='icon' className={`nav-passive ${item.className}`} />
    <img src={item.activeIcon} alt='icon' className={`nav-active ${item.className}`} />
    <span className='text-nowrap'>
      {item.name}{' '}
      {item.total && totals[item.total] ? (
        <span className='count'>{`(${totals[item.total]})`}</span>
      ) : (
        ''
      )}
    </span>
  </NavLink>
);

const Sidebar = () => {
  const totals = useSelector(state => state.totals);
  const [active, setActive] = useState(true);

  const location = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location.pathname]);

  return (
    <div className={`sidebar${active ? ' active' : ''}`}>
      <div className='sidebar-header d-flex align-items-center'>
        <Link to='/'>
          <img src={logo} alt='logo' className='logo' />
        </Link>
        <img
          src={IconArrowClose}
          alt='arrow'
          onClick={() => setActive(!active)}
          role='presentation'
          className='sidebar-toggle'
        />
      </div>
      <div className='sidebar-content d-flex flex-column'>
        {navs.map((item, i) => {
          const permissions = item.childs.map(item => item.permission);
          if (!PermissionService.hasSomeAccess(permissions)) return null;
          return (
            <div key={i} className='d-flex flex-column'>
              <p>{active ? item.name : '...'}</p>
              {item.childs.map(
                (child, j) =>
                  PermissionService.hasAccess(child.permission) && (
                    <LinkItem key={j} item={child} totals={totals} />
                  ),
              )}
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default withRouter(Sidebar);
