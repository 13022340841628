import React from 'react';

const CheckBox = ({
  name,
  checked,
  onChange,
  disabled,
  label,
  subLabel,
  className,
  labelClassName,
  indeterminate,
}) => (
  <div className={`custom-control custom-checkbox ${className || ''}`}>
    <input
      type='checkbox'
      name={name}
      id={`check${name}`}
      className='custom-control-input'
      checked={checked}
      onChange={onChange}
      disabled={disabled}
    />
    <label
      htmlFor={`check${name}`}
      className={`custom-control-label pl-2 pointer ${labelClassName} ${
        indeterminate ? 'is-indeterminate' : ''
      }`}
    >
      {label}
      {subLabel && <p className='text-secondary small'>{subLabel}</p>}
    </label>
  </div>
);

export default CheckBox;
