import React, { useState, useEffect, useRef } from 'react';
import { useSnackbar } from 'notistack';
import { useSelector } from 'react-redux';
import Modal from './modals/Modal';
import SearchBox from './forms/SearchBox';
import SortBox from './forms/SortBox';
import InfiniteScroll from 'react-infinite-scroll-component';
import MovieBox from './MovieBox';
import { Api } from 'utils/connectors';
import { constructSort, getError } from 'utils/appHelpers';
import LoadingLine from './loadings/LoadingLine';
import { PermissionService } from 'services/PermissionService';

const sortFilters = [
  { name: 'Last Modified', key: 'updateDate' },
  { name: 'Create Date', key: 'createDate' },
  { name: 'Name', key: 'title' },
];

const typeFilters = [
  { name: 'All', key: 'all' },
  { name: 'Movies', key: 'MOVIE' },
  { name: 'TV Shows', key: 'TV_SHOW' },
];

const SelectMediaModal = ({ onClose, medias, onSelectMedia, isMulti, disabledItems = [] }) => {
  const { enqueueSnackbar } = useSnackbar();
  const channels = useSelector(state => state.channels);
  const [selected, setSelected] = useState(medias || []);
  const [fetching, setFetching] = useState(false);
  const [type, setType] = useState('all');
  const [movies, setMovies] = useState({});
  const [moviesIdObj, setMoviesIdObj] = useState({});
  const [totals, setTotals] = useState({});
  const [search, setSearch] = useState('');
  const [channelsOptions, setChannelsOptions] = useState([]);
  const [selectedChannel, setSelectedChannel] = useState({ key: null });
  const [sort, setSort] = useState({
    key: 'updateDate',
    bool: false,
  });
  const modalContent = useRef();

  const collectMoviesObjectDatas = data => {
    const result = { ...moviesIdObj };
    data.forEach(item => (result[item.id] = item));
    setMoviesIdObj(result);
  };

  const getMovies = async isNext => {
    try {
      setFetching(true);
      const page = isNext ? movies.number : 0;
      const res = await Api.get('/medias', {
        params: {
          category: type === 'all' ? undefined : type,
          published: true,
          text: search || undefined,
          size: 60,
          page: isNext ? page + 1 : page,
          sort: constructSort(sort),
          channel_id: selectedChannel.key || undefined,
        },
      });
      collectMoviesObjectDatas(res.data.content);
      if (isNext) res.data.content = [...movies.content, ...res.data.content];
      setTotals({ ...totals, [type]: res.data.total_elements });
      setMovies(res.data);
    } catch (err) {
      enqueueSnackbar(getError(err), { variant: 'error' });
    } finally {
      setFetching(false);
    }
  };

  const onSelectSubmit = () => {
    const movies = selected.map(id => moviesIdObj[id]).filter(i => !!i);
    onSelectMedia(isMulti ? movies : movies[0]);
    onClose();
  };

  const onMovieClick = movie => {
    let items = [...selected];
    if (isMulti) {
      items = items.includes(movie.id) ? items.filter(i => i !== movie.id) : [...items, movie.id];
    } else {
      items = [movie.id];
    }
    setSelected(items);
  };

  useEffect(() => {
    getMovies();
    modalContent.current = document.querySelector('.select-media-modal.modal-content');
    //eslint-disable-next-line
  }, [sort, type, selectedChannel.key]);

  useEffect(() => {
    if (channels.en && channels.en.content.length) {
      const channelsList = channels.en.content.map(i => ({ name: i.name, key: i.id }));
      setChannelsOptions([{ name: 'All', key: null }, ...channelsList]);
    }
  }, [channels.en]);

  useEffect(() => {
    document.body.classList.add('overflow-hidden');
    return () => document.body.classList.remove('overflow-hidden');
  }, []);

  return (
    <Modal size='full' onClose={onClose} className='mx-2 mt-2 select-media-modal' hideCloseButton>
      {fetching && <LoadingLine />}
      <div className='media-modal-header d-flex justify-content-between align-items-center mb-4 min-40-h'>
        <p className='text-primary__light'>
          Select movie(s) <span className='ml-1'>({selected.length} Movies selected)</span>
        </p>
        <div>
          <button
            className='btn btn-outline-blue rounded__30 mr-2 btn-sm px-5 py-2'
            onClick={onClose}
          >
            CLOSE
          </button>
          <button
            className='btn btn-primary rounded__30 btn-sm px-5 py-2'
            disabled={!selected || !selected.length}
            onClick={onSelectSubmit}
          >
            ADD
          </button>
        </div>
      </div>
      <div className='media-modal-body' id='scrollableTarget'>
        <div className='filter-block media-modal-filter'>
          <div className='d-flex justify-content-between flex-column flex-md-row'>
            <div className='d-flex align-items-center flex-fill'>
              <div className='d-flex align-items-center mr-3 type-filters'>
                {typeFilters.map(item => {
                  return (
                    <button
                      key={item.key}
                      className={`btn btn-sm mr-4 px-3 ${type === item.key ? 'btn-primary' : ''}`}
                      onClick={() => setType(item.key)}
                    >
                      {item.name}
                      {totals[item.key] && <span>{`(${totals[item.key]})`}</span>}
                    </button>
                  );
                })}
              </div>
              <SearchBox
                className='flex-fill'
                onChange={getMovies}
                search={search}
                setSearch={setSearch}
              />
            </div>
            <div className='flex-fill d-flex align-items-center justify-content-end mt-3 mt-md-0'>
              {PermissionService.hasAccess('media_channel_assign') && (
                <SortBox
                  sort={selectedChannel}
                  setSort={setSelectedChannel}
                  items={channelsOptions}
                  label='Select channel'
                  className='mr-2'
                />
              )}
              <SortBox sort={sort} setSort={setSort} items={sortFilters} />
            </div>
          </div>
        </div>
        {modalContent.current && (
          <InfiniteScroll
            dataLength={(movies.content && movies.content.length) || 0}
            next={() => {
              getMovies(true);
            }}
            hasMore={movies.content && movies.content.length < movies.total_elements}
            loader={<div>Loading...</div>}
            scrollableTarget='scrollableTarget'
          >
            <div className='overflow-hidden'>
              <div className='movies d-flex flex-wrap mt-5'>
                {movies.content &&
                  movies.content.map(item => (
                    <MovieBox
                      item={item}
                      key={item.id}
                      className='pointer select-movie'
                      onClick={onMovieClick.bind(null, item)}
                      disabled={disabledItems.includes(item.id)}
                      selectable
                      selected={selected.includes(item.id)}
                    />
                  ))}
              </div>
            </div>
          </InfiniteScroll>
        )}
      </div>
    </Modal>
  );
};

export default SelectMediaModal;
