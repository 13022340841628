import React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import MediaList from './routes/MediaList';
import TVShowList from './routes/TVShowList';
import MediaView from './routes/MediaView';

const Content = ({ match }) => (
  <Switch>
    <Route path={`${match.path}/tv-show/:id`} component={TVShowList} />
    <Route path={`${match.path}/:type/:id/:ep_id?`} component={MediaView} />
    <Route path={`${match.path}/:type`} component={MediaList} />
    <Redirect from='*' to={`${match.path}/all`} />
  </Switch>
);

export default Content;
