import React from 'react';
import Dropzone from 'react-dropzone';
import UploadProgress from './UploadProgress';

const UploadZone = ({
  onDrop,
  name,
  imageProgress,
  placeholder = 'Drop file here or click upload',
  maxSize,
  className = 'my-4 py-3',
  accept,
  disabled,
}) => {
  if (imageProgress) return <UploadProgress progress={imageProgress} />;

  return (
    <Dropzone onDrop={onDrop} maxSize={maxSize} accept={accept} disabled={disabled}>
      {({ getRootProps, getInputProps }) => {
        return (
          <div
            {...getRootProps()}
            className={`upload-zone border-style border__dashed border__default text-center rounded__4 ${className} ${
              disabled ? 'disabled-bg cursor-disabled' : ''
            }`}
          >
            <p className='mb-1'>{name}</p>
            <input {...getInputProps()} />
            <p className='text-primary__light text-md mb-0'>{placeholder}</p>
          </div>
        );
      }}
    </Dropzone>
  );
};

export default UploadZone;
