import React from 'react';
import { render } from 'react-dom';
import { Provider } from 'react-redux';
import { SnackbarProvider } from 'notistack';
import { Chart } from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import store from './redux/store';
import 'react-dates/initialize';
import 'scss/app.scss';
import App from './app';
import 'react-confirm-alert/src/react-confirm-alert.css';
import { initSentry } from 'configs';
import ErrorBoundary from 'shared/components/layouts/ErrorBoundary';

Chart.register(ChartDataLabels);

const snackOpts = { vertical: 'top', horizontal: 'right' };
initSentry();

render(
  <Provider store={store}>
    <SnackbarProvider maxSnack={3} anchorOrigin={snackOpts} autoHideDuration={3000}>
      <ErrorBoundary>
        <App />
      </ErrorBoundary>
    </SnackbarProvider>
  </Provider>,
  document.getElementById('root'),
);
