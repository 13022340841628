import React, { useState, useEffect, memo } from 'react';
import { useSelector } from 'react-redux';
import { useSnackbar } from 'notistack';
import { useHistory, useParams } from 'react-router-dom';
import Modal from 'shared/components/modals/Modal';
import { Api } from 'utils/connectors';
import { confirmBox, getError } from 'utils/appHelpers';
import cloneDeep from 'lodash/cloneDeep';
import update from 'lodash/update';
import RollContainer from './RollContainer';
import FrequencyContainer from './FrequencyContainer';
import LocationsContainer from './LocationsContainer';
import CountrySelectContainer from './CountrySelectContainer';

const AdManageModal = ({ getAds, isSpecialAdd }) => {
  const { id, location } = useParams();
  const { enqueueSnackbar } = useSnackbar();
  const { push } = useHistory();
  const ads = useSelector(state => state.ads);
  const [fetching, setFetching] = useState(false);
  let [data, setData] = useState(null);
  const [errors, setErrors] = useState({});
  const [newLocation, setNewLocation] = useState(false);

  const handleClose = () => {
    push('/ads/list');
    setData(null);
    setErrors({});
    setNewLocation(false);
    getAds();
  };

  const handleChange = e => {
    let { name, value } = e.target;
    if (name.includes('_count') || name.includes('frequency')) value = +value;
    const newState = update(cloneDeep(data), name, () => value);
    setData(newState);
  };

  const validateForm = obj => {
    const errors = {};

    if (obj.preroll.max_count < 1 || !obj.preroll.max_count) {
      errors['ad_config_detail.preroll.max_count'] = 'The Preroll Count must 1 at least';
    }
    if (obj.preroll && !obj.preroll.url) {
      errors['ad_config_detail.preroll.url'] = 'The Preroll URL must be a valid URL';
    }
    if (obj.midroll.max_count < 1 || !obj.midroll.max_count) {
      errors['ad_config_detail.midroll.max_count'] = 'The Max Midroll Count must 1 at least';
    }
    if (obj.midroll && !obj.midroll.url) {
      errors['ad_config_detail.midroll.url'] = 'The Midroll URL must be a valid URL';
    }
    if (obj.endroll.max_count < 1 || !obj.endroll.max_count) {
      errors['ad_config_detail.endroll.max_count'] = 'The Max Endroll Count must 1 at least';
    }
    if (obj.endroll && !obj.endroll.url) {
      errors['ad_config_detail.endroll.url'] = 'The Endroll URL must be a valid URL';
    }
    if (obj.ad_frequency < 30 || !obj.ad_frequency) {
      errors['ad_config_detail.ad_frequency'] = 'The frequency must 30 at least';
    }

    setErrors(errors);
    return !Object.keys(errors).length;
  };

  const onFormSubmit = async e => {
    e.preventDefault();
    const isFormValid = validateForm(data.ad_config_detail);
    if (!isFormValid) return;
    if (location) {
      if (location !== 'new-country-add') {
        ads[id - 1].country_based_ad_configs[location] = data.ad_config_detail;
        data = ads[id - 1];
      } else {
        const currentData = ads.find((group, groupIndex) => groupIndex + 1 === +id);
        currentData.country_based_ad_configs = {
          ...currentData.country_based_ad_configs,
          [data.country_based_ad_configs]: data.ad_config_detail,
        };
        setData(currentData);
        setNewLocation(false);
        push(`/ads/list/${id}`);
        return;
      }
    }
    try {
      setFetching(true);
      await Api.put(`/ad-configs/${data.id}`, data);
      enqueueSnackbar('Successfully done', { variant: 'success' });
      handleClose();
      getAds();
    } catch (err) {
      enqueueSnackbar(getError(err), { variant: 'error' });
    } finally {
      setFetching(false);
    }
  };

  const removeCountry = async key => {
    const dataAfterDelete = cloneDeep(data);
    delete dataAfterDelete.country_based_ad_configs[key];
    setData(dataAfterDelete);
  };

  const onAcountryRemove = key => {
    const options = {
      onSuccess: removeCountry.bind(null, key),
      message: `Are you sure you want to delete current country add ?`,
      confirm: 'Delete',
    };
    confirmBox(options);
  };

  useEffect(() => {
    if (!ads || !ads.length || isSpecialAdd) return;
    const data = ads.find((group, groupIndex) => groupIndex + 1 === +id);
    if (location && data) {
      setErrors({});
      if (location !== 'new-country-add') {
        const locationData = data.country_based_ad_configs[location];
        if (locationData) setData({ ad_config_detail: locationData });
        return;
      } else {
        setNewLocation(true);
        const newCountry = cloneDeep(data);
        newCountry.ad_config_detail.ad_frequency = 30;
        newCountry.ad_config_detail.preroll.ad_system_type = 'ADFOX';
        newCountry.ad_config_detail.preroll.url = '';
        newCountry.ad_config_detail.preroll.max_count = 1;
        newCountry.ad_config_detail.midroll.ad_system_type = 'ADFOX';
        newCountry.ad_config_detail.midroll.url = '';
        newCountry.ad_config_detail.midroll.max_count = 1;
        newCountry.ad_config_detail.endroll.ad_system_type = 'ADFOX';
        newCountry.ad_config_detail.endroll.url = '';
        newCountry.ad_config_detail.endroll.max_count = 1;
        newCountry.country_based_ad_configs = 'am';
        setData(newCountry);
        return;
      }
    }
    if (id && data) setData(data);
  }, [ads, id, location]);

  if (!data) return null;

  data.ad_config_detail = data.ad_config_detail || {};
  data.country_based_ad_configs = data.country_based_ad_configs || {};

  return (
    <Modal size='xs' className='mx-2 mt-2 ads-new-modal vahe_modal' onClose={handleClose}>
      <h6 className='modal-title bg-purple__light row px-6 py-3'>
        {location ? 'Add Country Based' : data.name}
      </h6>
      <form onSubmit={onFormSubmit} className='d-flex flex-column h-100 pt-3 w-100 px-3'>
        {newLocation && (
          <CountrySelectContainer
            title={'Country'}
            onChange={handleChange}
            selectedCountry={data.country_based_ad_configs}
          />
        )}
        <RollContainer
          title='PreRoll'
          description='Nulla molestie, dui non.'
          source='preroll'
          values={data.ad_config_detail}
          errors={errors}
          onChange={handleChange}
          className={newLocation ? `mt-3` : ''}
        />
        <RollContainer
          title='MidRoll'
          description='Class aptent taciti sociosqu ad.'
          source='midroll'
          values={data.ad_config_detail}
          errors={errors}
          onChange={handleChange}
        />
        <RollContainer
          title='EndRoll'
          description='Nulla molestie, dui non.'
          source='endroll'
          values={data.ad_config_detail}
          errors={errors}
          onChange={handleChange}
        />
        <FrequencyContainer
          title='Frequency'
          description='Class aptent taciti sociosqu ad.'
          value={data.ad_config_detail.ad_frequency}
          errors={errors}
          onChange={handleChange}
        />
        {!location && (
          <LocationsContainer
            title='Location'
            values={data.country_based_ad_configs}
            onAcountryRemove={onAcountryRemove}
          />
        )}
        <div className='btn-toolbar justify-content-between mt-4'>
          <div>
            {location && (
              <button
                className='btn btn-outline-primary text-md text-uppercase mnw-110 btn-sm py-2 weight-300 mx-2'
                onClick={() => {
                  if (newLocation) {
                    setNewLocation(false);
                  }
                  push(`/ads/list/${id}`);
                  setErrors({});
                }}
                type='button'
                disabled={fetching}
              >
                Back
              </button>
            )}
          </div>
          <div>
            <button
              className='btn btn-outline-primary text-md text-uppercase mnw-110 btn-sm py-2 weight-300 mx-2'
              onClick={handleClose}
              type='button'
              disabled={fetching}
            >
              Cancel
            </button>
            <button
              className='btn btn-primary btn-sm py-2 text-md text-uppercase weight-300 mnw-110 ml-2'
              type='submit'
              disabled={fetching}
            >
              {newLocation ? 'Add' : 'Save'}
            </button>
          </div>
        </div>
      </form>
    </Modal>
  );
};

export default memo(AdManageModal);
