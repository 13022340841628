import React, { useState, useEffect } from 'react';
import { Api } from 'utils/connectors';
import { onSetChannelUsers } from 'app/Main/actions';
import { useSnackbar } from 'notistack';
import { useDispatch, useSelector } from 'react-redux';
import { confirmBox, getError } from 'utils/appHelpers';
import InfiniteScroll from 'react-infinite-scroll-component';
import CreateButton from 'shared/components/CreateButton';
import ActionButton from 'shared/components/ActionButton';
import UserManageModal from 'shared/components/UserManageModal';
import StateHolder from 'shared/components/StateHolder';
import Loading from 'shared/components/loadings/Loading';
import { roleNames } from 'configs';
import { PermissionService } from 'services/PermissionService';

const ChannelUsersList = ({ channel }) => {
  const { enqueueSnackbar } = useSnackbar();
  const id = channel && channel.id;
  const users = useSelector(state => state.channelUsers[id]);
  const dispatch = useDispatch();

  const [fetching, setFetching] = useState(false);
  const [manageModal, setManageModal] = useState({
    user: null,
    state: false,
  });

  const getUsers = async isNext => {
    if (!isNext) setFetching(true);
    try {
      const page = isNext ? users.number : 0;
      const params = {
        size: 100,
        page: isNext ? page + 1 : page,
      };
      const res = await Api.get(`/users?channel_id=${id}`, { params });
      if (isNext) res.data.content = [...users.content, ...res.data.content];
      setFetching(false);
      dispatch(onSetChannelUsers({ id: id, data: res.data }));
    } catch (err) {
      setFetching(false);
      enqueueSnackbar(getError(err), { variant: 'error' });
    }
  };

  const removeUser = async (id, index) => {
    try {
      await Api.delete(`/users/${id}`);
      const tempItems = [...users.content];
      tempItems.splice(index, 1);
      enqueueSnackbar('Successfully removed.', { variant: 'success' });
      getUsers();
    } catch (err) {
      enqueueSnackbar(getError(err), { variant: 'error' });
    }
  };

  const onUserRemove = (id, index) => {
    const options = {
      onSuccess: removeUser.bind(null, id, index),
      message: `Are you sure you want to delete this user?`,
      confirm: 'Delete',
    };
    confirmBox(options);
  };

  const onUserEdit = user => setManageModal({ user, state: true });

  const onUserCreate = () => setManageModal({ user: null, state: true });

  const onModalClose = () => setManageModal({ user: null, state: false });

  useEffect(() => {
    if (channel) getUsers();
  }, [channel]);

  return (
    <>
      <div className='bg-white h-whitespace-height overflow-auto' id='usersListContainer'>
        <div className='bg-purple__light px-4 py-3 d-flex align-items-center justify-content-between'>
          <div className='text-primary__light'>Users</div>
        </div>
        {!channel ? (
          <StateHolder>Please select a channel.</StateHolder>
        ) : !fetching && (!users || !users.content.length) ? (
          <StateHolder>
            <span className='mb-4'>There is no admins yet.</span>
            {PermissionService.hasAccess('channel_user_create') && (
              <CreateButton name='INVITE' size='sm' onClick={onUserCreate} />
            )}
          </StateHolder>
        ) : fetching && !users ? (
          <StateHolder>
            <Loading />
          </StateHolder>
        ) : (
          <InfiniteScroll
            scrollableTarget='usersListContainer'
            dataLength={(users && users.content.length) || 0}
            next={() => getUsers(true)}
            hasMore={users && users.content.length < users.total_elements}
            loader={<div className='text-center'>Loading...</div>}
          >
            {users && users.content && (
              <div>
                <table className='table'>
                  <thead>
                    <tr>
                      <th className='border-0 text-primary'>
                        <span className='mnw-30 d-inline-block' />
                        Name
                      </th>
                      <th className='border-0 text-primary'>Email</th>
                      <th className='border-0 text-primary' colSpan='2'>
                        <div className='d-flex align-items-center justify-content-between'>
                          Role
                          {PermissionService.hasAccess('channel_user_create') && (
                            <CreateButton name='INVITE' size='sm' onClick={onUserCreate} />
                          )}
                        </div>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {users.content.map((item, index) => (
                      <tr key={item.id}>
                        <td>
                          <b className='mnw-30 d-inline-block'>{item.id}</b>
                          {item.name ?? '-'}
                        </td>
                        <td>{item.email ?? '-'}</td>
                        <td>
                          <span
                            className={
                              /owner/i.test(roleNames[item.role]) ? 'badge-pill badge-primary' : ''
                            }
                          >
                            {roleNames[item.role] ?? '-'}
                          </span>
                        </td>
                        <td colSpan='2' className='text-right'>
                          {PermissionService.hasAccess('channel_user_edit') && (
                            <ActionButton type='edit' onClick={onUserEdit.bind(null, item)} />
                          )}
                          {PermissionService.hasAccess('channel_user_delete') &&
                            item.role !== 'ROLE_CHANNEL_OWNER' && (
                              <ActionButton onClick={onUserRemove.bind(null, item.id, index)} />
                            )}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            )}
          </InfiniteScroll>
        )}
      </div>
      {manageModal.state && (
        <UserManageModal
          user={manageModal.user}
          onSuccess={getUsers}
          onClose={onModalClose}
          channel={channel}
        />
      )}
    </>
  );
};

export default ChannelUsersList;
