import React, { useState } from 'react';
import { useRef } from 'react';
import useOutsideClick from 'shared/hooks/useOutsideClick';
import CheckBox from './CheckBox';

const DropMenuMultiSelect = ({
  title,
  className,
  placeholder,
  activeItems,
  items,
  onChange,
  onToggleAll,
  footerLeftSide,
}) => {
  const dropMenu = useRef();
  const [opened, setOpened] = useState(false);

  useOutsideClick(dropMenu, () => {
    setOpened(false);
  });

  const isAllChecked = items?.length === activeItems?.length;

  return (
    <div
      className={`drop-multi-select ${className || ''} ${opened ? 'active' : ''}`}
      ref={dropMenu}
    >
      <div tabIndex={-1} role='button' className='active-item' onClick={e => setOpened(!opened)}>
        {activeItems?.length ? `${activeItems.length} selected` : placeholder}
      </div>
      <div className={`drop-menu`}>
        {title && <div className='drop-title'>{title}</div>}
        <ul className='mb-0 drop-list list-unstyled'>
          {items?.map((item, i) => {
            return (
              <li key={i}>
                <CheckBox
                  name={item.name}
                  checked={activeItems?.includes(item.id)}
                  onChange={onChange.bind(null, item)}
                  label={
                    <span>
                      {item.name} • <span className='text-secondary'>${item.price}</span>
                    </span>
                  }
                  labelClassName='is-small'
                  className='d-flex align-items-center'
                />
              </li>
            );
          })}
        </ul>
        <div className='drop-footer d-flex align-items-center justify-content-between'>
          <div>{footerLeftSide}</div>
          <div>
            <button
              className='btn p-0 btn-sm text-primary'
              type='button'
              onClick={() => onToggleAll(isAllChecked)}
            >
              {isAllChecked ? 'Deselect All' : 'Select All'}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DropMenuMultiSelect;
