import React from 'react';
import { countries } from 'configs';
import SelectGroup from 'shared/components/forms/SelectGroup';
import InputGroup from 'shared/components/forms/InputGroup';
import ActionButton from 'shared/components/ActionButton';
import CreateButton from 'shared/components/CreateButton';

const normalizedCountriesArr = countries.map(({ key, name }) => ({ value: key, name }));

const normalizedCountriesObj = countries.reduce((acc, item) => {
  acc[item.key] = item;
  return acc;
}, {});

const CountryBasedForm = ({ data = {}, onFormChange }) => {
  let unusedCountries = normalizedCountriesArr.filter(item =>
    [Object.values(item)[0]].every(key => !data.hasOwnProperty(key)),
  );

  const normalizedData = Object.keys(data).reduce((acc, key) => {
    acc.push({ key, value: data[key] });
    return acc;
  }, []);

  const onRemove = key => {
    const { [key]: removed, ...rest } = data;
    onFormChange({ target: { name: 'product_country_based_price_amd', value: rest } });
  };

  const onCountryChange = (key, value) => {
    let tempData = Object.entries(data);
    let indexToChange = tempData.findIndex(item => item[0] === key);
    if (indexToChange !== -1) {
      tempData[indexToChange][0] = value.value;
    }
    onFormChange({
      target: { name: 'product_country_based_price_amd', value: Object.fromEntries(tempData) },
    });
  };

  const onPriceChange = (key, value) => {
    onFormChange({
      target: { name: 'product_country_based_price_amd', value: { ...data, [key]: +value } },
    });
  };

  const onCreateNewCountry = () => {
    onFormChange({
      target: { name: 'product_country_based_price_amd', value: { ...data, '': '' } },
    });
  };

  return (
    <div className='mh-585'>
      <div className='information-box'>
        <h6>Why need this function?</h6>
        <p>
          Using this editor you can set fixed subscription amounts for each country as needed. You
          can add subscriptions for specific countries by clicking "Add new country and price".
        </p>
      </div>
      {normalizedData.map(item => {
        const currentCountryValue = {
          value: item?.key,
          name: normalizedCountriesObj[item.key]?.name,
        };
        return (
          <div className='row align-items-center mb-3' key={item.key}>
            <div className='col-11'>
              <div className='row'>
                <div className='col-6'>
                  <SelectGroup
                    name={'item.key'}
                    value={currentCountryValue}
                    onChange={value => {
                      onCountryChange(item.key, value);
                    }}
                    options={unusedCountries}
                    containerClass='mb-0 mnw-100-p'
                  />
                </div>
                <div className='col-6 pl-0'>
                  <InputGroup
                    type='number'
                    value={item.value}
                    onChange={e => onPriceChange(item.key, e.target.value)}
                    min={1}
                    max={9999}
                    required
                    containerClass='mb-0 mnw-100-p country-price-input'
                  />
                </div>
              </div>
            </div>
            <div className='flex-1'>
              <ActionButton type='remove' onClick={() => onRemove(item.key)} />
            </div>
          </div>
        );
      })}
      <CreateButton
        type='button'
        name={`Add new country and price`}
        onClick={onCreateNewCountry}
        size='sm'
        className='text-nowrap border-0 p-sm-0 addNewGroups'
      />
    </div>
  );
};

export default CountryBasedForm;
