import React, { useState, useEffect } from 'react';
import { useSnackbar } from 'notistack';
import InputGroup from 'shared/components/forms/InputGroup';
import Modal from 'shared/components/modals/Modal';
import { Api } from 'utils/connectors';
import { getError } from 'utils/appHelpers';
import { PermissionService } from 'services/PermissionService';

const ChannelManageModal = ({ onClose, onSuccess, channel, lang }) => {
  const { enqueueSnackbar } = useSnackbar();
  const hasRevenueManageAccess = PermissionService.hasAccess('channel_revenue_info_assign');
  const [fetching, setFetching] = useState(false);
  const [form, setForm] = useState({
    name: '',
    email: '',
    ...(hasRevenueManageAccess && {
      ad_shared_percent: 100,
      purchase_shared_percent: 100,
    }),
  });
  const isEdit = !!channel;

  const onFormSubmit = async e => {
    e.preventDefault();
    try {
      setFetching(true);
      const url = isEdit ? `/channels/${channel.id}?locale=${lang}` : `/channels?locale=${lang}`;
      const action = isEdit ? 'put' : 'post';
      const successMsg = `Successfully ${isEdit ? 'updated' : 'created'}`;
      await Api[action](url, {
        ...form,
        ...(hasRevenueManageAccess && {
          ad_shared_percent: +form.ad_shared_percent,
          purchase_shared_percent: +form.purchase_shared_percent,
        }),
      });
      enqueueSnackbar(successMsg, { variant: 'success' });
      setFetching(false);
      onSuccess && onSuccess();
      onClose();
    } catch (err) {
      enqueueSnackbar(getError(err), { variant: 'error' });
      setFetching(false);
    }
  };

  const handleChange = ({ target }) => {
    const { name, value } = target;
    setForm({ ...form, [name]: value });
  };

  useEffect(() => {
    if (channel)
      setForm({
        name: channel.name,
        ...(hasRevenueManageAccess && {
          ad_shared_percent: String(channel.ad_shared_percent ?? 0),
          purchase_shared_percent: String(channel.purchase_shared_percent ?? 0),
        }),
      });
  }, [channel]);

  return (
    <Modal size='xs' onClose={onClose} className='mx-2 mt-2'>
      <div className='modal-title bg-purple__light row px-4 py-3'>
        <div className='text-primary__light'>{isEdit ? 'Update' : 'Create'} Channel</div>
      </div>
      <div className='signup-modal d-flex flex-column align-items-center'>
        <form onSubmit={onFormSubmit} className='w-100 pt-3'>
          {!isEdit && (
            <InputGroup
              type='text'
              name='email'
              value={form.email}
              placeholder='Email address'
              onChange={handleChange}
              required
              autoFocus
            />
          )}
          <InputGroup
            type='text'
            name='name'
            value={form.name}
            placeholder='Channel Name'
            onChange={handleChange}
            required
            autoFocus={isEdit}
          />
          {hasRevenueManageAccess && (
            <>
              <InputGroup
                type='number'
                name='ad_shared_percent'
                value={form.ad_shared_percent}
                placeholder='Ad Revenue Share (%)'
                onChange={handleChange}
                min={0}
                max={100}
                required
              />
              <InputGroup
                type='number'
                name='purchase_shared_percent'
                value={form.purchase_shared_percent}
                placeholder='Rent Revenue Share (%)'
                onChange={handleChange}
                min={0}
                max={100}
                required
              />
            </>
          )}
          <div className='text-right mt-4'>
            <button
              className='btn btn-outline-primary text-md text-uppercase mnw-110 btn-sm py-2 weight-300 mx-2'
              onClick={onClose}
              type='button'
            >
              Cancel
            </button>
            <button
              className='btn btn-primary btn-sm py-2 text-md text-uppercase weight-300 mnw-110 ml-2'
              disabled={fetching}
              type='submit'
            >
              {isEdit ? 'Update' : 'Create'}
            </button>
          </div>
        </form>
      </div>
    </Modal>
  );
};

export default ChannelManageModal;
