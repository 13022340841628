import React from 'react';

const RadioBox = ({
  name,
  checked,
  onChange,
  disabled,
  label,
  className,
  labelClass,
  required,
  icon,
}) => (
  <div className={`custom-control d-flex align-items-center custom-radio ${className || ''}`}>
    <input
      type='radio'
      name={name}
      id={`radio${label}`}
      className='custom-control-input'
      checked={checked}
      onChange={onChange}
      disabled={disabled}
      required={required}
    />
    <label htmlFor={`radio${label}`} className={`custom-control-label pointer ${labelClass || ''}`}>
      {icon && <span>{icon}</span>}
      {label}
    </label>
  </div>
);

export default RadioBox;
