import packJson from '../../package.json';
import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';

const generic = {
  production: {
    api_domain: 'https://api.kinodaran.com:9099',
    app_api_domain: 'https://api.kinodaran.com',
    sentry_env: 'production',
    analytics_api_domain: 'https://reporting.kinodaran.com:9096/api/v1',
  },
  development: {
    api_domain: 'https://devadminapi.kinodaran.com:9099/api',
    app_api_domain: 'https://devapi.kinodaran.com',
    sentry_env: 'development',
    analytics_api_domain: 'https://devapi.kinodaran.com/api/v1',
  },
  local: {
    api_domain: 'http://localhost:9099/api',
    app_api_domain: 'http://localhost:9090',
    analytics_api_domain: 'https://devapi.kinodaran.com/api/v1',
  },
  stage: {
    api_domain: "https://admin-api.stage.kinodaran.com/api",
    app_api_domain: "https://api.stage.kinodaran.com",
    analytics_api_domain: "https://api.stage.kinodaran.com/api/v1",
  },
};

const getENV = () => {
  let env = process.env.REACT_APP_API_URL_ENV || process.env.NODE_ENV;
  env = env === 'test' ? 'development' : env;
  return env;
};

const main = generic[getENV() || 'development'];

export const HOST = {
  API: {
    URL: main.api_domain,
    AUTH_HEADER: 'Authentication',
    AUTH_PREFIX: 'Bearer',
    ANALYTICS_URL: main.analytics_api_domain,
    APP_URL: main.app_api_domain,
  },
};

export const initSentry = () => {
  if (!main.sentry_env) return;
  const options = {
    dsn: 'https://c5a4b31a54224dd4b1385d95b548eebc@o568217.ingest.sentry.io/6061400',
    environment: main.sentry_env,
    release: packJson.version,
    integrations: [new Integrations.BrowserTracing()],
    tracesSampleRate: 1.0,
  };
  Sentry.init(options);
};
