import React from 'react';
import LineChart from './../../components/LineChart';
import PieChart from './../../components/PieChart';
import PageTitle from './../../components/PageTitle';
import Loading from 'shared/components/loadings/Loading';
import useAnalyticsData from '../../hooks/useAnalyticsData';

const PageContent = () => {
  const data = useAnalyticsData('/reporting/watch-time/search', 'watchtime');

  if (!data) {
    return (
      <div className='bg-white d-flex justify-content-center py-15'>
        <Loading />
      </div>
    );
  }

  const { line, pie, totalValues, biggestValue } = data;

  return (
    <div className='row'>
      <div className='col-8'>
        <div className='bg-white mh-788 px-6 py-4'>
          <PageTitle total={totalValues} title='Watchtime' unit='mins' className='mb-8' />
          <LineChart
            data={line}
            biggestValue={biggestValue}
            unit='mins'
            description='Total duration of videos played.'
          />
        </div>
      </div>
      <div className='col-4'>
        <div className='bg-white mh-788 px-6 py-4'>
          <PageTitle total={totalValues} title='Watchtime' unit='mins' className='mb-8' />
          <PieChart
            data={pie}
            totalValues={totalValues}
            listItems={line.datasets}
            unit='mins'
            description='Total duration of videos played.'
          />
        </div>
      </div>
    </div>
  );
};

export default PageContent;
