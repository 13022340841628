import React, { useState } from 'react';
import { Api } from 'utils/connectors';
import InputGroup from 'shared/components/forms/InputGroup';
import { getError, getQueryParam, isMatchingPasswords } from 'utils/appHelpers';
import { useSnackbar } from 'notistack';
import { useDispatch } from 'react-redux';
import { loginUser } from './../../actions';

const Verify = () => {
  const { enqueueSnackbar } = useSnackbar();

  const dispatch = useDispatch();

  const [fetch, setFetch] = useState(false);
  const [errorText, setError] = useState('');
  const [form, setForm] = useState({
    password: '',
    confirm_password: '',
    name: getQueryParam('name', null),
    channel: getQueryParam('channel', null),
    email: getQueryParam('email', null).replace(' ', '+'),
    token: getQueryParam('token', null),
  });

  const handleChange = ({ target }) => {
    setError('');
    const { name, value } = target;
    setForm({ ...form, [name]: value });
  };

  const onSubmit = async e => {
    e.preventDefault();

    if (!isMatchingPasswords(form.password, form.confirm_password)) {
      setError('Password missmatch!');
      return;
    }

    setFetch(true);
    try {
      await Api.put('/users/verify', {
        token: form.token,
        password: form.password,
      });

      enqueueSnackbar('Successfully verified.', { variant: 'success' });

      // Immediatly login admin after verification
      const data = await Api.post('/auth', {
        email: form.email,
        password: form.password,
      });
      dispatch(loginUser(data.data));
    } catch (err) {
      setError(getError(err));
      setFetch(false);
    }
  };

  return (
    <div className='col-md-7 col-12 right-part px-md-7 py-md-6 p-3 d-flex flex-column justify-content-center'>
      <form onSubmit={onSubmit} className='col-8 d-flex flex-column justify-content-center m-auto'>
        <h4 className='mb-6'>Set up password</h4>
        {form.name && (
          <InputGroup
            type='text'
            value={form.name}
            placeholder='Full name'
            className='is-one-line'
            containerClass='mb-3'
            readOnly
          />
        )}
        {form.email && (
          <InputGroup
            type='email'
            value={form.email}
            placeholder='Email'
            className='is-one-line'
            containerClass='mb-3'
            readOnly
          />
        )}
        {form.channel && (
          <InputGroup
            type='email'
            value={form.channel}
            placeholder='Channel'
            className='is-one-line'
            containerClass='mb-3'
            readOnly
          />
        )}
        <InputGroup
          type='password'
          name='password'
          value={form.password}
          placeholder='Password'
          onChange={handleChange}
          errorText={errorText}
          required
          className='is-one-line'
          containerClass='mb-3'
          disabled={!form.token}
          autoFocus
        />
        <InputGroup
          type='password'
          name='confirm_password'
          value={form.confirm_password}
          placeholder='Confirm Password'
          onChange={handleChange}
          required
          className='is-one-line'
          containerClass='mb-3'
          disabled={!form.token}
        />
        <p className='text-right'>
          <button
            type='submit'
            className='btn btn-md btn-warning btn-rounded'
            disabled={fetch || !form.token}
          >
            SAVE AND CONTINUE
          </button>
        </p>
      </form>
    </div>
  );
};

export default Verify;
