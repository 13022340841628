import { planPeriods } from 'configs';
import React from 'react';
import { PermissionService } from 'services/PermissionService';
import ActionButton from 'shared/components/ActionButton';
import ListStatusChanger from 'shared/components/ListStatusChanger';

const RenewableList = ({ products, onItemEdit, onItemRemove, reloadItems }) => {
  return (
    <div>
      <table className='table'>
        <thead>
          <tr>
            <th className='border-0 text-primary w-40'>#</th>
            <th className='border-0 text-primary'>Id</th>
            <th className='border-0 text-primary'>Title</th>
            <th className='border-0 text-primary'>Description</th>
            <th className='border-0 text-primary'>Price</th>
            <th className='border-0 text-primary'>Period</th>
            <th className='border-0 text-primary'>Status</th>
            <th className='border-0 text-primary text-right w-78' />
          </tr>
        </thead>
        <tbody>
          {products
            ? products.map((item, index) => {
                const period = planPeriods.find(i => i.value === item.period);
                return (
                  <tr key={index}>
                    <td>{index + 1}</td>
                    <td>{item.id}</td>
                    <td>{item.name || '-'}</td>
                    <td>{item.description || '-'}</td>
                    <td>${item.price}</td>
                    <td>
                      <span className='badge-light'>{period?.name || item.period}</span>
                    </td>
                    <td width='20%'>
                      <ListStatusChanger productID={item.id} status={item.status} onSuccess={reloadItems} type='product' />
                    </td>
                    <td>
                      <div className='text-right'>
                        <ActionButton
                          className='mr-1'
                          type='edit'
                          onClick={onItemEdit.bind(null, item)}
                          disabled={!PermissionService.hasAccess('product_edit')}
                        />
                        <ActionButton
                          onClick={onItemRemove.bind(null, item.id, index)}
                          disabled={!PermissionService.hasAccess('product_delete')}
                        />
                      </div>
                    </td>
                  </tr>
                );
              })
            : null}
        </tbody>
      </table>
    </div>
  );
};

export default RenewableList;
