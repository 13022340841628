import React, { useState, useEffect } from 'react';
import { AnalyticsApi } from 'utils/connectors';
import { useSnackbar } from 'notistack';
import { useSelector, useDispatch } from 'react-redux';
import AddFilterButton from './components/AddFilterButton';
import FilterItem from './components/FilterItem';
import { onResetFilters, onFetchingSet } from 'app/Main/actions';
import { duplicate, sortArrayByKey } from 'utils/appHelpers';
import { onModifyFilters } from 'app/Main/actions';
import { getError } from 'utils/appHelpers';

const Filters = () => {
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const { filters, fetching } = useSelector(state => state);
  const [localFilters, setLocalFilters] = useState({});

  const getFilters = async () => {
    try {
      dispatch(onFetchingSet(true));
      const { data } = await AnalyticsApi.get('/reporting/filters');
      data.countries = sortArrayByKey(data.countries, 'name');
      dispatch(onModifyFilters({ ...filters, ...data }));
    } catch (err) {
      enqueueSnackbar(getError(err), { variant: 'error' });
    } finally {
      dispatch(onFetchingSet(false));
    }
  };

  const resetFilters = () => dispatch(onResetFilters());

  const toggleFilter = key => {
    const modifiedFilter = duplicate(localFilters);
    if (!modifiedFilter[key]) {
      modifiedFilter[key] = [];
    } else {
      if (modifiedFilter[key].length) {
        delete modifiedFilter[key];
        dispatch(onModifyFilters({ ...filters, items: modifiedFilter }));
      }
      delete modifiedFilter[key];
    }
    setLocalFilters(modifiedFilter);
  };

  const toggleFilterItem = ({ key, value }) => {
    let modifiedFilterItems = duplicate(localFilters[key]);
    if (modifiedFilterItems.includes(value)) {
      modifiedFilterItems = modifiedFilterItems.filter(item => item !== value);
    } else {
      modifiedFilterItems.push(value);
    }
    const result = { ...localFilters, [key]: modifiedFilterItems };
    dispatch(onModifyFilters({ ...filters, items: result }));
  };

  useEffect(() => {
    getFilters();
  }, []);

  useEffect(() => {
    setLocalFilters(filters.items);
  }, [filters.items]);

  const filterKeys = Object.keys(localFilters);

  return (
    <div className='filters-container d-flex flex-wrap align-items-start'>
      <AddFilterButton selectedKeys={filterKeys} disabled={fetching} onChange={toggleFilter} />
      {!!filterKeys.length && (
        <>
          {filterKeys.map((key, i) => {
            return (
              <FilterItem
                filterKey={key}
                selectedItems={localFilters[key]}
                disabled={fetching}
                key={i}
                onChange={toggleFilterItem}
              />
            );
          })}
          <span className='text-light reset-filter-btn' onClick={resetFilters} role='presentation'>
            Clear All
          </span>
        </>
      )}
    </div>
  );
};

export default Filters;
