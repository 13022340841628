import React, { useState } from 'react';
import { useSnackbar } from 'notistack';
import Modal from 'shared/components/modals/Modal';
import { Api } from 'utils/connectors';
import { getError, getImageUrl, hasImageType, imageUploadProgress } from 'utils/appHelpers';
import SelectMediaModal from 'shared/components/SelectMediaModal';
import UploadZone from 'shared/components/UploadZone';
import ActionButton from 'shared/components/ActionButton';
import UpdateImageUpload from 'shared/components/UpdateImageUpload';
import UploadProgress from 'shared/components/UploadProgress';
import { MAX_SIZES } from 'configs/sizes';

const posterImages = [
  {
    key: 'mobileImage',
    name: 'Mobile Poster',
    type: 'POSTER_MOBILE',
    maxSize: MAX_SIZES.POSTER_MOBILE,
  },
  {
    key: 'tabletImage',
    name: 'Tablet Poster',
    type: 'POSTER_TABLET',
    maxSize: MAX_SIZES.POSTER_TABLET,
  },
  { key: 'tvImage', name: 'TV Poster', type: 'POSTER_TV', maxSize: MAX_SIZES.POSTER_TV },
  { key: 'webImage', name: 'WEB Poster', type: 'POSTER_WEB', maxSize: MAX_SIZES.POSTER_WEB },
];

const getPostersMediaIds = data => {
  if (!data || !data.length) return [];
  return data.map(item => item.media && item.media.id);
};

const PostersManageModal = ({ onClose, state, onSuccess, posters }) => {
  const [imageProgress, setImageProgress] = useState({});
  const { enqueueSnackbar } = useSnackbar();
  const [mediaModal, setMediaModal] = useState(false);
  const [fetching, setFetching] = useState(false);
  const [media, setMedia] = useState(state.data ? state.data.media : null);
  const [poster, setPoster] = useState(state.data);
  const alreadyExistIds = getPostersMediaIds(posters && posters.content);

  const createItem = async () => {
    const res = await Api.post(`/home/posters?mediaId=${media.id}`);
    setPoster(res.data);
  };

  const updateItem = () => {
    return Api.put(`/home/posters/${poster.id}?mediaId=${media.id}`);
  };

  const onFormSubmit = async e => {
    e.preventDefault();
    try {
      setFetching(true);
      const isUpdate = !!poster;
      isUpdate ? await updateItem() : await createItem();
      if (onSuccess) await onSuccess();
      enqueueSnackbar('Successfully done', { variant: 'success' });
      setFetching(false);
      isUpdate && onClose();
    } catch (err) {
      enqueueSnackbar(getError(err), { variant: 'error' });
      setFetching(false);
    }
  };

  const removePosterImage = async image => {
    try {
      await Api.delete(`home/posters/${poster.id}/images?imageType=${image.type}`);
      const tempPoster = { ...poster };
      tempPoster.image.types = tempPoster.image.types.filter(item => item.type !== image.type);
      setPoster(tempPoster);
    } catch (err) {
      enqueueSnackbar(getError(err), { variant: 'error' });
    }
  };

  const onUploadFile = async (image, file, status) => {
    if (status?.[0]?.errors?.[0]?.code === 'file-too-large') {
      enqueueSnackbar(status?.[0]?.errors?.[0]?.message, { variant: 'error' });
      return;
    }
    if (!file.length) return;
    const config = {
      onUploadProgress: imageUploadProgress.bind(null, setImageProgress, image.type),
    };

    try {
      setImageProgress({ ...imageProgress, [image.type]: 1 });
      const form = new FormData();
      form.append('image', file[0]);
      const { data } = await Api.post(
        `home/posters/${poster.id}/images?imageType=${image.type}`,
        form,
        config,
      );
      setPoster(data);
    } catch (err) {
      enqueueSnackbar(getError(err), { variant: 'error' });
    } finally {
      setImageProgress({ ...imageProgress, [image.type]: false });
    }
  };

  return (
    <>
      <Modal size='sm' onClose={onClose} className='mx-2 mt-2'>
        <div className='modal-title bg-purple__light row px-4 py-3 mb-6'>
          <div className='text-primary__light'>{poster ? 'Edit' : 'Add'} Poster Homepage</div>
        </div>
        <div className='signup-modal d-flex flex-column align-items-center'>
          <div className='add-media-block'>
            <button className='select-btn' onClick={() => setMediaModal(true)}>
              <svg xmlns='http://www.w3.org/2000/svg' width='28' height='16' viewBox='0 0 28 16'>
                <path
                  d='M2,8V24H24V20.625l6,3V8.375l-6,3V8Zm2,2H22V22H4Zm24,1.625v8.75l-4-2v-4.75Z'
                  transform='translate(-2 -8)'
                  fill='#20c997'
                />
              </svg>
            </button>
            <div className='select-media'>
              {media && <img width='200' src={getImageUrl(media.image)} alt='media' />}
            </div>
          </div>
          {poster && (
            <div className='images-area mt-3 w-100'>
              {posterImages.map(item => {
                const hasImage = poster && hasImageType(poster.image, item.type);

                if (hasImage) {
                  if (imageProgress[item.type]) {
                    return <UploadProgress key={item.type} progress={imageProgress[item.type]} />;
                  }
                  return (
                    <div
                      key={item.type}
                      className='mb-3 d-flex align-items-center justify-content-between'
                    >
                      <div>
                        <img
                          width='120'
                          height='80'
                          className='object-fit__cover object-position__center rounded__3 mr-3'
                          src={getImageUrl(poster.image, item.type)}
                          alt='poster'
                        />
                        {item.name}
                      </div>
                      <div className='d-flex'>
                        <UpdateImageUpload
                          maxSize={item.maxSize}
                          onDrop={onUploadFile.bind(null, item)}
                        />
                        <ActionButton onClick={removePosterImage.bind(null, item)} />
                      </div>
                    </div>
                  );
                } else {
                  return (
                    <UploadZone
                      key={item.type}
                      imageProgress={imageProgress[item.type]}
                      maxSize={item.maxSize}
                      onDrop={onUploadFile.bind(null, item)}
                      name={item.name}
                    />
                  );
                }
              })}
            </div>
          )}
          <div className='text-right w-100 mt-5'>
            <button
              className='btn btn-outline-primary text-md text-uppercase mnw-110 btn-sm py-2 weight-300 mx-2'
              onClick={onClose}
            >
              Close
            </button>
            <button
              className='btn btn-primary btn-sm py-2 text-md text-uppercase weight-300 mnw-110 ml-2'
              disabled={fetching}
              onClick={onFormSubmit}
              type='submit'
            >
              Save
            </button>
          </div>
        </div>
      </Modal>
      {mediaModal && (
        <SelectMediaModal
          medias={media ? [media.id] : []}
          onClose={() => setMediaModal(false)}
          disabledItems={alreadyExistIds}
          onSelectMedia={setMedia}
        />
      )}
    </>
  );
};

export default PostersManageModal;
