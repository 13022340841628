import React, { useState, useEffect } from 'react';
import { Api } from 'utils/connectors';
import { useSnackbar } from 'notistack';
import { useDispatch, useSelector } from 'react-redux';
import { confirmBox, getError } from 'utils/appHelpers';
import { onSetChannels } from 'app/Main/actions';
import InfiniteScroll from 'react-infinite-scroll-component';
import CreateButton from 'shared/components/CreateButton';
import IconArrowRight from 'assets/images/icons/arrow_right.svg';
import ChannelManageModal from './ChannelManageModal';
import StateHolder from 'shared/components/StateHolder';
import Loading from 'shared/components/loadings/Loading';
import ActionButton from 'shared/components/ActionButton';
import { PermissionService } from 'services/PermissionService';
import { getChannels } from '../../../actions';
import SearchBox from 'shared/components/forms/SearchBox';

const ChannelsList = ({ selectedChannel, onSelectChannel, lang }) => {
  const { enqueueSnackbar } = useSnackbar();
  const channels = useSelector(state => state.channels) || {};
  const dispatch = useDispatch();
  const [search, setSearch] = useState('');
  const [manageModal, setManageModal] = useState({
    channel: null,
    state: false,
  });

  const getChannelsList = bool => dispatch(getChannels(bool, search, lang));

  const removeChannel = async (id, index) => {
    try {
      await Api.delete(`/channels/${id}?locale=${lang}`);
      const tempItems = [...channels[lang].content];
      tempItems.splice(index, 1);
      dispatch(onSetChannels({ ...channels[lang], content: tempItems }));
      enqueueSnackbar('Successfully removed.', { variant: 'success' });
      getChannelsList();
    } catch (err) {
      enqueueSnackbar(getError(err), { variant: 'error' });
    }
  };

  const onChannelRemove = (e, id, index) => {
    e.stopPropagation();
    const options = {
      onSuccess: removeChannel.bind(null, id, index),
      message: `Are you sure you want to delete this channel?`,
      confirm: 'Delete',
    };
    confirmBox(options);
  };

  const onChannelEdit = (e, channel) => {
    e.stopPropagation();
    setManageModal({ channel, state: true });
  };

  const onChannelCreate = () => setManageModal({ channel: null, state: true });

  const onModalClose = () => setManageModal({ channel: null, state: false });

  useEffect(() => {
    getChannelsList();
  }, [lang]);

  const activeChannel = channels[lang];

  return (
    <>
      <div className='bg-white h-whitespace-height overflow-auto' id='channelsListContainer'>
        <div className='bg-purple__light px-4 py-3 d-flex align-items-center justify-content-between'>
          <div className='text-primary__light'>Channels</div>
        </div>
        {!channels[lang]?.content ? (
          <StateHolder>
            <Loading />
          </StateHolder>
        ) : !channels[lang]?.content.length ? (
          <StateHolder>
            <span className='mb-4'>There is no channels yet.</span>
            {PermissionService.hasAccess('channel_create') && (
              <CreateButton name='CREATE' onClick={onChannelCreate} size='sm' />
            )}
          </StateHolder>
        ) : (
          <>
            <div className='d-flex align-items-center justify-content-between pt-4 pb-2 px-4'>
              <SearchBox
                onChange={getChannelsList}
                search={search}
                setSearch={setSearch}
                className='col-12 col-md-7'
              />
              {PermissionService.hasAccess('channel_create') && (
                <CreateButton name='CREATE' onClick={onChannelCreate} size='sm' />
              )}
            </div>
            <InfiniteScroll
              scrollableTarget='channelsListContainer'
              dataLength={(activeChannel.content && activeChannel.content.length) || 0}
              next={() => getChannelsList(true)}
              hasMore={
                activeChannel.content && activeChannel.content.length < activeChannel.total_elements
              }
              loader={<div className='text-center'>Loading...</div>}
            >
              {activeChannel.content && (
                <div>
                  <table className='table table-hover'>
                    <thead>
                      <tr>
                        <th className='border-0 text-primary pl-5'>Name</th>
                      </tr>
                    </thead>
                    <tbody>
                      {activeChannel.content.map((item, index) => (
                        <tr
                          key={item.id}
                          className={`pointer ${
                            selectedChannel && selectedChannel.id === item.id ? 'active' : ''
                          }`}
                          onClick={() => onSelectChannel(item)}
                        >
                          <td className='pl-5'>{item.name ?? '-'}</td>
                          <td className='pr-5'>
                            <div className='d-flex align-items-center justify-content-end'>
                              {PermissionService.hasAccess('channel_edit') && (
                                <ActionButton type='edit' onClick={e => onChannelEdit(e, item)} />
                              )}
                              {PermissionService.hasAccess('channel_delete') && (
                                <ActionButton onClick={e => onChannelRemove(e, item.id, index)} />
                              )}
                              <img src={IconArrowRight} alt='icon' className='table-icon ml-2' />
                            </div>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              )}
            </InfiniteScroll>
          </>
        )}
      </div>
      {manageModal.state && (
        <ChannelManageModal
          channel={manageModal.channel}
          onSuccess={getChannelsList}
          onClose={onModalClose}
          lang={lang}
        />
      )}
    </>
  );
};

export default ChannelsList;
