import React from 'react';
import CountrySelection from 'shared/components/forms/CountrySelection';

const GeoBlockingTab = ({ media = {}, updateMovieStore, onSubmit, fetching }) => {
  const { geo_blocked_locations = [] } = media;
  const onGeoBlockingChange = locations => {
    updateMovieStore({ ...media, geo_blocked_locations: locations });
  };

  return (
    <>
      <p className='text-muted mt-3'>
        <small>You can choose to restrict access to this content in certain countries.</small>
      </p>
      <CountrySelection
        value={geo_blocked_locations}
        onChange={onGeoBlockingChange}
        onSubmit={onSubmit}
        fetching={fetching}
        showActionButtons
      />
    </>
  );
};

export default GeoBlockingTab;
