import React, { useState } from 'react';
import { useSelector } from 'react-redux';

const ConfirmModal = ({
  title = 'Warning',
  message,
  cancel = 'Cancel',
  confirm = 'Yes',
  onSuccess,
  onClose,
  showDissmissBtn,
  component,
  className,
}) => {
  const [fetching, setFetch] = useState(false);
  const options = useSelector(state => state.options);

  const handleConfirmClick = async () => {
    if (fetching) return;
    setFetch(true);
    await onSuccess(options);
    setFetch(false);
    onClose();
  };

  return (
    <div className={`confirm-modal text-center ${className || ''}`}>
      <div className='modal-header d-flex justify-content-between bg-purple__light text-light p-3'>
        {title}
        {showDissmissBtn && <button className='modal-dismiss btn close-icon' onClick={onClose} />}
      </div>
      <div className='modal-body p-3'>
        <p className='text-xlg mt-3 mb-4'>{message}</p>
        {component && component}
      </div>
      <div className='modal-footer p-3'>
        <button
          className='btn btn-outline-primary text-md text-uppercase mnw-110 btn-lg weight-300 mx-2'
          onClick={onClose}
        >
          {cancel}
        </button>
        <button
          className='btn btn-primary btn-lg text-md text-uppercase weight-300 mnw-110 mx-2'
          disabled={fetching}
          onClick={handleConfirmClick}
        >
          {confirm}
        </button>
      </div>
    </div>
  );
};

export default ConfirmModal;
