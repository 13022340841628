import React from 'react';
import InputGroup from 'shared/components/forms/InputGroup';
import SelectGroup from 'shared/components/forms/SelectGroup';

const systemOptions = [
  { name: 'ADFOX', value: 'ADFOX' },
  { name: 'ADMANAGER', value: 'ADMANAGER' },
];

const RollContainer = ({
  title,
  description,
  className,
  source,
  values = {},
  errors = {},
  onChange,
}) => {
  const selectValue = {
    name: values[source]?.ad_system_type,
    value: values[source]?.ad_system_type,
  };

  return (
    <div className={`row border-bottom mb-3 ${className}`}>
      <div className='col-4 mr-5 p-0'>
        <h6>{title}</h6>
        <p className='text-secondary small'>{description}</p>
      </div>
      <div className='col-7'>
        <div className='row d-flex align-items-center'>
          <span className='mb-3 col-2 mr-2 small'>System</span>
          <div className='col-5 px-0'>
            <SelectGroup
              name='system'
              placeholder='Select System'
              value={selectValue}
              onChange={({ value }) => {
                onChange({ target: { name: `ad_config_detail.${source}.ad_system_type`, value } });
              }}
              options={systemOptions}
              containerClass='system-min-width'
              required
            />
          </div>
          <span className='mr-n2 mb-3 col-2 mr-2 small'>Count</span>
          <div className='col-3 pl-2 pr-1'>
            <InputGroup
              type='number'
              name={`ad_config_detail.${source}.max_count`}
              value={values[source]?.[`max_count`] === 0 ? '' : values[source]?.[`max_count`]}
              onChange={onChange}
              errorText={errors[`ad_config_detail.${source}.max_count`]}
              containerClass='count-min-width'
              min={1}
              required
            />
          </div>
        </div>
        <div className='row mt-1 pl-0'>
          <InputGroup
            type='text'
            name={`ad_config_detail.${source}.url`}
            placeholder={`Enter ${source} url`}
            value={values[source]?.url}
            onChange={onChange}
            containerClass='pl-3 pr-1'
            className='col-12 pl-0'
            errorText={errors[`ad_config_detail.${source}.url`]}
            required
          />
        </div>
      </div>
    </div>
  );
};

export default RollContainer;
