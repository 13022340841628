/* eslint-disable eqeqeq */
import React from 'react';
import { confirmAlert } from 'react-confirm-alert';
import defaultImg from 'assets/images/thumbnail-default.jpg';
import ConfirmModal from 'shared/components/modals/ConfirmModal';
import moment from 'moment';
import { Provider } from 'react-redux';
import store from 'redux/store';

const getErrorArrays = error => {
  console.log(error, error['Validation Failed']);
  if (!error['Validation Failed']) return null;
  const errors = error['Validation Failed'];
  console.log(errors);
  const messages = Object.values(errors);
  if (!messages) return null;
  let result = '';
  messages.map(item => (result += `${item} \n`));
  return result;
};

export const getError = err => {
  if (err && err.response && err.response.data) {
    const msg = err.response.data.message;
    const error = getErrorArrays(err.response.data?.error || err.response.data);
    if (error) return error;
    if (msg) return msg;
  }
  if (err && err.response && err.response.statusText) {
    return err && err.response && err.response.statusText;
  }
  if (err && err.data && err.data.message) {
    return err.data.message;
  }
  if (err && err.message) {
    return err.message;
  }
  return err;
};

export const getImageUrl = (image, type = 'THUMBNAIL') => {
  if (!image || !image.types || !image.types.length) return defaultImg;
  const typeImage = image.types.find(i => i.type === type);
  return typeImage ? typeImage.url : image.types[0].url;
  // return `${HOST.API.URL}/${HOST.API.API_PREFIX}/medias/images/${image.id}?imageType=${name}`;
};

export const hasImageType = (image, type) => {
  return image && image.types && !!image.types.find(item => item.type === type);
};

export const constructSort = ({ key, bool }) => {
  return `${key}:${bool ? 'asc' : 'desc'}`;
};

export const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);
  return result;
};

export const confirmBox = options => {
  confirmAlert({
    customUI: ({ onClose }) => (
      <Provider store={store}>
        <ConfirmModal onClose={onClose} {...options} />
      </Provider>
    ),
  });
};

export const getYoutubeId = url => {
  if (!url) return false;
  const regExp = /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#&?]*).*/;
  const match = url.match(regExp);
  return match && match[7].length === 11 ? match[7] : false;
};

export const bindDuration = duration => {
  if (!duration) return '-';
  return moment.utc(duration).format('HH:mm:ss');
};

export const imageUploadProgress = (setProgress, type, progressEvent) => {
  setProgress(item => {
    return { ...item, [type]: Math.round((progressEvent.loaded * 100) / progressEvent.total) };
  });
};

export const getQueryParam = (name, defaultValue = '') => {
  const url = new URL(window.location.href);
  const param = url.searchParams.get(name);
  return param || defaultValue;
};

export const isMatchingPasswords = (p1, p2) => !!(p1 && p2 && p1 === p2);

export const getInitialChannel = (channels, id) => {
  if (!channels || !channels.length) return {};
  const defaultChannel = channels[0];
  const item = id ? channels.find(item => item.id === id) ?? defaultChannel : defaultChannel;
  return {
    id: item.id,
    value: item.id,
    label: item.name,
  };
};

export const duplicate = obj => JSON.parse(JSON.stringify(obj));

export const formatNumber = (num, dlm = ' ') =>
  +num ? num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, dlm) : 0;

export const capitalize = (str = '') => {
  str = str.replace('_', ' ');

  return (
    str
      .toUpperCase()
      .charAt(0)
      .toUpperCase() + str.slice(1)
  );
};

export const extractIdFromURL = (str = '') => {
  return str
    .split('/')
    .filter(item => typeof +item === 'number' && !isNaN(+item) && item)
    .map(v => +v);
};

export const pluralize = (count, noun, suffix = 's') => {
  return `${formatNumber(count)} ${noun}${count !== 1 ? suffix : ''}`;
};

const durChunk = (duration, noun, separate) => {
  const dur = duration ? pluralize(duration, noun) : '';
  return `${dur}${separate ? ' ' : ''}`;
};

export const bindDurationHours = seconds => {
  const m = Math.round((seconds / 60) % 60);
  const h = Math.floor(seconds / 60 / 60);
  const duration =
    h || m
      ? `${durChunk(h, 'hour', true)} ${durChunk(m, 'min')}`
      : seconds
      ? durChunk(seconds, 'second')
      : '0 mins';
  return duration.trim();
};

export const sortArrayByKey = (data = [], key) => {
  return data.sort((a, b) => {
    if (a[key] < b[key]) {
      return -1;
    }
    if (a[key] > b[key]) {
      return 1;
    }
    return 0;
  });
};

export const downloadFile = (filename, text) => {
  const element = document.createElement('a');
  element.setAttribute('href', 'data:text/plain;charset=utf-8,' + encodeURIComponent(text));
  element.setAttribute('download', filename);
  element.style.display = 'none';
  document.body.appendChild(element);
  element.click();
  document.body.removeChild(element);
};
