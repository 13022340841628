import React, { useState, useEffect } from 'react';
import { useSnackbar } from 'notistack';
import { useDispatch, useSelector } from 'react-redux';
import InputGroup from './forms/InputGroup';
import TextAreaGroup from './forms/TextAreaGroup';
import { Api } from 'utils/connectors';
import { getError } from 'utils/appHelpers';
import { PermissionService } from 'services/PermissionService';
import MediaPublishDialog from './MediaPublishDialog';
import Switcher from './forms/Switcher';
import MediaPublishState from './MediaPublishState';
import ReactSelect from './forms/ReactSelect';
import { getChannels } from 'app/Main/actions';

const MediaManageForm = ({
  handleChange,
  setFormData,
  formData,
  onSubmitForm,
  updateMovieStore,
  lang,
  isEdit,
  onDelete,
  fetching,
  media,
  type,
}) => {
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch();
  const channels = useSelector(state => state.channels);
  const [openPublishingDialog, setOpenPublishingDialog] = useState(false);
  const [tagsOptions, setTagsOptions] = useState([]);
  const [genresOptions, setGenresOptions] = useState([]);

  const formGenresIds = formData.genres?.map(i => i.id);
  const [localGenres, setLocalGenres] = useState(formGenresIds || []);
  const genresItems = localGenres.map(i => genresOptions.find(o => o.id === i));

  const onGengresChange = value => {
    setLocalGenres(value?.map(i => i.id) || []);
    handleChange({ target: { name: 'genres', value } })
  }

  const getTags = async () => {
    try {
      const res = await Api.get('/tags', { params: { size: 1000, page: 0 } });
      setTagsOptions(res.data.content.map(i => ({ value: i.id, label: i.name, id: i.id })));
    } catch (err) {
      enqueueSnackbar(getError(err), { variant: 'error' });
    }
  };

  const getGenres = async () => {
    try {
      const res = await Api.get(`/genres`, {
        params: { size: 1000, page: 0, locale: lang },
      });
      setGenresOptions(res.data.content);
    } catch (err) {
      enqueueSnackbar(getError(err), { variant: 'error' });
    }
  };

  const checkMoviePublish = media => {
    const { title, year, image, genres, item } = media;
    const hasVideo =
      item &&
      (item.remote_video_id || ['CREATED', 'PROGRESSING', 'COMPLETE'].includes(item.status));
    if (!title || !year || !image || image.types.length < 3 || !item || !genres || !hasVideo) {
      return false;
    }
    return true;
  };

  const checkTvShowPublish = media => {
    const { title, year, image, genres, seasons } = media;
    const hasEpisode = seasons && seasons.find(item => item.episodes);
    const episodePublished = seasons && seasons.find(item => item.episodes?.find(i => i.published));
    if (!title || !year || !image || image.types.length < 3 || !seasons || !genres || !hasEpisode || !episodePublished) {
      return false;
    }
    return true;
  };

  const onTogglePublish = published => {
    if (type === 'movie' && !published && !checkMoviePublish(media)) {
      enqueueSnackbar(`You can't publish the item, media is incomplete`);
      return;
    }

    if (type === 'tv_show' && !published && !checkTvShowPublish(media)) {
      enqueueSnackbar(`You can't publish the item, media is incomplete`);
      return;
    }

    setOpenPublishingDialog(true);
  };

  useEffect(() => {
    getTags();
    getGenres();
    dispatch(getChannels(null, null, lang));
    //eslint-disable-next-line
  }, [lang]);

  const actionsDisabled = fetching || !PermissionService.hasAccess('media_edit');

  return (
    <div className='px-3 pb-2 bg-white'>
      <div className='bg-purple__light row px-3 py-2 d-flex align-items-center justify-content-between mnh-57'>
        <div className='text-primary__light'>Main</div>
        <MediaPublishState
          published={media?.published}
          publishDate={media?.publish_date}
          className='bg-white rounded__4 px-2 py-1'
        />
      </div>
      <form className='px-1 pt-7 pb-2' onSubmit={onSubmitForm}>
        <InputGroup
          type='text'
          name='title'
          value={formData.title}
          placeholder={'Name'}
          onChange={handleChange}
          required
          disabled={actionsDisabled}
        />
        <InputGroup
          type='text'
          name='secondary_info'
          value={formData.secondary_info}
          placeholder={'Secondary Info'}
          onChange={handleChange}
          disabled={actionsDisabled}
        />
        <TextAreaGroup
          name='description'
          value={formData.description}
          placeholder={'Description'}
          onChange={handleChange}
          disabled={actionsDisabled}
        />
        <div className='row'>
          <div className='col-3'>
            <InputGroup
              type='number'
              name='allowed_age'
              value={formData.allowed_age}
              placeholder={'Age'}
              onChange={handleChange}
              containerClass='mnw-auto'
              min={1}
              max={99}
              disabled={actionsDisabled}
            />
          </div>
          <div className='col-3 pl-0'>
            <InputGroup
              type='number'
              name='year'
              value={formData.year}
              placeholder={'Year'}
              onChange={handleChange}
              required
              containerClass='mnw-auto'
              min={1800}
              max={new Date().getFullYear()}
              disabled={actionsDisabled}
            />
          </div>
          <div className='col-6 pl-0'>
            {PermissionService.hasAccess('media_channel_assign') && (
              <ReactSelect
                className='mb-4'
                value={formData.channel}
                onChange={value => handleChange({ target: { name: 'channel', value } })}
                options={channels[lang]?.content}
                placeholder='Select channel'
                getOptionValue={i => i.id}
                getOptionLabel={i => i.name || i.label || '-'}
                disabled={isEdit || actionsDisabled} // NOTE: by the request of Adam channel ID can't be editable because the pricing for each channel is different
              />
            )}
          </div>
        </div>
        <ReactSelect
          value={genresItems}
          onChange={onGengresChange}
          options={genresOptions}
          className='mb-4'
          placeholder='Genres'
          getOptionValue={i => i.id}
          getOptionLabel={i => i.name || i.label || '-'}
          disabled={actionsDisabled}
          isMulti={true}
          isSortable={true}
        />
        <ReactSelect
          value={formData.tags}
          onChange={value => handleChange({ target: { name: 'tags', value } })}
          options={tagsOptions}
          className='mb-4'
          placeholder='Tags'
          disabled={actionsDisabled}
          isMulti={true}
          isSortable={true}
        />
        <div className='d-flex justify-content-between mt-5 pt-3'>
          <div>
            {isEdit && (
              <Switcher
                className='d-flex align-items-center'
                labelClass='text-sm ml-2'
                label='Publish in application'
                switchCheck={formData.published}
                onSwitchChange={onTogglePublish.bind(null, formData.published)}
                disabled={!PermissionService.hasAccess('media_publish')}
              />
            )}
          </div>
          <div>
            <button
              disabled={
                actionsDisabled ||
                fetching ||
                !formData.genres ||
                !formData.genres.length ||
                !formData.channel?.id
              }
              type='submit'
              className='btn btn-sm btn-outline-primary mnw-100 py-1 mr-2 text-uppercase weight-400 fz-12'
            >
              Save
            </button>
            {isEdit && (
              <button
                disabled={!PermissionService.hasAccess('media_delete') || fetching}
                type='button'
                onClick={onDelete}
                className='btn btn-sm btn-danger mnw-100 py-1 text-uppercase weight-400 fz-12'
              >
                Delete
              </button>
            )}
          </div>
        </div>
      </form>
      {openPublishingDialog && (
        <MediaPublishDialog
          type={type}
          media={media}
          onClose={() => setOpenPublishingDialog(false)}
          onSuccess={(newValues = {}) => {
            setFormData({ ...formData, ...newValues, published: !formData.published });
            updateMovieStore({ ...media, ...newValues, published: !formData.published });
            setOpenPublishingDialog(false);
          }}
        />
      )}
    </div>
  );
};

export default MediaManageForm;
