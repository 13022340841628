import React, { useEffect, useState } from 'react';
import { AnalyticsApi } from 'utils/connectors';
import { useDispatch, useSelector } from 'react-redux';
import { formatDate, generateBarChartData } from 'utils/analyticsHelpers';
import { getError } from 'utils/appHelpers';
import { onOverviewSet } from 'app/Main/actions';
import { Link } from 'react-router-dom';
import Loading from 'shared/components/loadings/Loading';
import PageTitle from './../../../components/PageTitle';
import BarChart from './../../../components/BarChart';

const ChartBox = ({ apiUrl, title, unit, storeKey, redirectUrl }) => {
  const dispatch = useDispatch();
  const { filters, overview } = useSelector(state => state);
  const [fetching, setFetching] = useState(true);
  const [error, setError] = useState(null);

  const dataSource = overview[storeKey];

  const getData = async () => {
    setError(null);

    try {
      const { from, to } = filters.dates;
      const { data } = await AnalyticsApi.get(apiUrl, {
        params: {
          start_date: formatDate(from),
          end_date: formatDate(to),
        },
      });
      data.data = generateBarChartData(data.data);
      dispatch(onOverviewSet({ [storeKey]: data.data }));
      setFetching(false);
    } catch (err) {
      setError(getError(err));
      setFetching(false);
    }
  };

  useEffect(() => {
    getData();
  }, [filters.dates]);

  return (
    <div className='chart-container col-6 d-flex flex-column px-2 mt-4'>
      <div className='flex-fill d-flex flex-column bg-white py-6 px-4'>
        <div>
          <div className='d-flex align-items-start justify-content-between mb-3'>
            <PageTitle total={dataSource?.totalValues} title={title} unit={unit} />
            {!!dataSource?.rows.length && (
              <Link to={redirectUrl} className='btn btn-outline-primary show-more-btn btn-sm'>
                See more
              </Link>
            )}
          </div>
        </div>
        {fetching && !dataSource ? (
          <div className='d-flex justify-content-center py-5'>
            <Loading />
          </div>
        ) : error ? (
          <div className='text-lg text-center text-blue-dark py-5'>
            <strong>{title}</strong> data not found
          </div>
        ) : (
          <div className='flex-fill'>
            <BarChart
              data={dataSource?.rows}
              biggestValue={dataSource?.biggestValue}
              unit={unit}
              chartOptions={{ maintainAspectRatio: false }}
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default ChartBox;
