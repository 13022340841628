import React, { useEffect, useState } from 'react';
import { useSnackbar } from 'notistack';
import { useDispatch, useSelector } from 'react-redux';
import { onSetGenres } from 'app/Main/actions';
import { Api } from 'utils/connectors';
import { confirmBox, getError } from 'utils/appHelpers';
import Header from 'shared/components/layouts/Header';
import InfiniteScroll from 'react-infinite-scroll-component';
import Loading from 'shared/components/loadings/Loading';
import GenresManageModal from './components/GenresManageModal';
import ActionButton from 'shared/components/ActionButton';
import TabsButtons from 'shared/components/layouts/TabsButtons';
import CreateButton from 'shared/components/CreateButton';
import LangToggle from 'shared/components/LangToggle';
import { PermissionService } from 'services/PermissionService';

const typesList = [
  { name: 'All', key: 'all' },
  { name: 'MOVIE', key: 'MOVIE' },
  { name: 'TV SHOW', key: 'TV_SHOW' },
];

const Genres = () => {
  const { enqueueSnackbar } = useSnackbar();
  const genres = useSelector(state => state.genres);
  const dispatch = useDispatch();
  const [lang, setLang] = useState('en');
  const [type, setType] = useState('all');
  const [manageModal, setManageModal] = useState({
    state: false,
    id: null,
  });

  const currentGenres = (genres && genres[lang]) || [];
  const activeGenre = currentGenres[type];

  const getGenres = async isNext => {
    try {
      const page = isNext ? activeGenre.number : 0;
      const res = await Api.get(`/genres`, {
        params: {
          types: type === 'all' ? undefined : type,
          size: 100,
          page: isNext ? page + 1 : page,
          locale: lang,
        },
      });
      if (isNext) res.data.content = [...activeGenre.content, ...res.data.content];
      dispatch(onSetGenres({ ...genres, [lang]: { ...genres[lang], [type]: res.data } }));
    } catch (err) {
      enqueueSnackbar(getError(err), { variant: 'error' });
    }
  };

  const removeItem = async (id, index) => {
    try {
      await Api.delete(`/genres/${id}?locale=${lang}`);
      const tempGenres = { ...genres };
      genres[lang][type].content.splice(index, 1);
      dispatch(onSetGenres(tempGenres));
      enqueueSnackbar('Successfully done', { variant: 'success' });
    } catch (err) {
      enqueueSnackbar(getError(err), { variant: 'error' });
    }
  };

  const onItemRemove = (id, index) => {
    const options = {
      onSuccess: removeItem.bind(null, id, index),
      message: `Are you sure you want to delete current genre?`,
      confirm: 'Delete',
    };
    confirmBox(options);
  };

  const onItemEdit = e => {
    setManageModal({ id: e.id, state: true });
  };

  const onItemCreate = () => {
    setManageModal({ data: null, state: true });
  };

  const onModalClose = () => {
    setManageModal({ data: null, state: false });
  };

  const getSelectedGenre = () => {
    if (!manageModal.id) return manageModal;
    return { manageModal, data: activeGenre.content.find(i => i.id === manageModal.id) };
  };

  useEffect(() => {
    getGenres();
    //eslint-disable-next-line
  }, [type, lang]);

  if (!activeGenre) return <Loading className='w-100 mt-5' />;

  return (
    <>
      <Header>
        <div className='d-flex align-items-center'>Genres</div>
      </Header>
      <main className='main-content'>
        <div className='d-flex justify-content-end mb-2'>
          <LangToggle lang={lang} setLang={setLang} />
        </div>
        <div className='px-3 bg-white'>
          <div className='bg-purple__light row px-4 py-2 d-flex align-items-center justify-content-between'>
            <div className='text-primary__light'>Media Genres</div>
            <div className='d-flex align-items-center'>
              <TabsButtons setTab={setType} tabs={typesList} tab={type} />
            </div>
          </div>
          <div className='inner-filter d-flex justify-content-end align-items-center pt-4 pb-1 px-2'>
            <div className='d-flex'>
              <CreateButton
                name='ADD GENRE'
                onClick={onItemCreate}
                disabled={!PermissionService.hasAccess('media_create')}
              />
            </div>
          </div>
          <InfiniteScroll
            dataLength={(activeGenre.content && activeGenre.content.length) || 0}
            next={() => getGenres(true)}
            hasMore={activeGenre.content && activeGenre.content.length < activeGenre.total_elements}
            loader={<div>Loading...</div>}
            className='px-2'
          >
            {activeGenre.content &&
              activeGenre.content.map((item, index) => {
                return (
                  <div className='border-bottom d-flex justify-content-between py-3' key={item.id}>
                    <div className='d-flex align-items-center col pl-0'>
                      <div className='col-3 pl-0'>
                        <span className='weight-700 mnw-30 d-inline-block'>{index + 1}</span>
                        {item.name}
                      </div>
                      <div>
                        {item.types.map(
                          item =>
                            (item === type || type === 'all') && (
                              <span className='badge badge-info ml-2' key={item}>
                                {item}
                              </span>
                            ),
                        )}
                      </div>
                    </div>
                    <div>
                      <ActionButton
                        type='edit'
                        onClick={onItemEdit.bind(null, item)}
                        disabled={!PermissionService.hasAccess('media_edit')}
                      />
                      <ActionButton
                        onClick={onItemRemove.bind(null, item.id, index)}
                        disabled={!PermissionService.hasAccess('media_delete')}
                      />
                    </div>
                  </div>
                );
              })}
          </InfiniteScroll>
        </div>
      </main>
      {manageModal.state && (
        <GenresManageModal
          lang={lang}
          setLang={setLang}
          state={getSelectedGenre()}
          onSuccess={getGenres}
          onClose={onModalClose}
          activeCategory={type}
        />
      )}
    </>
  );
};

export default Genres;
