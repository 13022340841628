import React, { useState, useEffect } from 'react';
import NavTabs from 'app/Main/routes/Analytics/components/NavTabs';
import BackButton from 'shared/components/BackButton';
import { AnalyticsApi } from 'utils/connectors';
import { useDispatch, useSelector } from 'react-redux';
import { useSnackbar } from 'notistack';
import { useParams } from 'react-router-dom';
import { bindDurationHours, getError, getQueryParam } from 'utils/appHelpers';
import { onAnalyticsSet } from 'app/Main/actions';
import { generateChartData, buildParams, generateDefaultCSVData } from 'utils/analyticsHelpers';
import PageTitle from 'app/Main/routes/Analytics/components/PageTitle';
import LineChart from 'app/Main/routes/Analytics/components/LineChart';
import Loading from 'shared/components/loadings/Loading';
import GroupBySelect from './components/GroupBySelect';
import cloneDeep from 'lodash/cloneDeep';

const MediaView = ({ match, location }) => {
  const dispatch = useDispatch();
  const { id, ep_id } = match.params;
  const { search } = location;
  const { enqueueSnackbar } = useSnackbar();
  const { type } = useParams();
  const { filters, analytics, fetching } = useSelector(state => state);
  const { dates, items } = filters;
  const [data, setData] = useState(null);
  const [groupBy, setGroupBy] = useState('watch-time');
  const dataSource = analytics.content ?? {};

  const isView = groupBy === 'view-count';

  const navigation = [
    {
      name: 'Countries',
      route: `/analytics/content/country/${id}${ep_id ? `/${ep_id}` : ''}${search}`,
    },
    {
      name: 'Device type',
      route: `/analytics/content/device_type/${id}${ep_id ? `/${ep_id}` : ''}${search}`,
    },
  ];

  const getAnalytics = async groupBy => {
    try {
      const params = buildParams({ ...filters, media_id: id, episode_id: ep_id }, type);
      let { data } = await AnalyticsApi.post(`/reporting/${groupBy}/search`, params);
      const CSVPreparedData = cloneDeep(data.data).map(group => {
        group.data.map(item => {
          item.value = bindDurationHours(item.value);
          return item;
        });
        return group;
      });
      dispatch(
        onAnalyticsSet({
          content: {
            ...dataSource,
            mediaItemById: {
              ...dataSource.mediaItemById,
              [id]: {
                ...generateChartData(data.data),
                CSV: generateDefaultCSVData(CSVPreparedData),
              },
            },
          },
        }),
      );
      setGroupBy(groupBy);
    } catch (err) {
      enqueueSnackbar(getError(err), { variant: 'error' });
    }
  };

  useEffect(() => {
    getAnalytics(groupBy);
  }, [id, dates, items, type]);

  useEffect(() => {
    if (dataSource?.mediaItemById?.[id]) {
      setData(dataSource.mediaItemById[id]);
    }
  }, [dataSource]);

  if (!data) {
    return (
      <div className='bg-white d-flex justify-content-center py-15'>
        <Loading />
      </div>
    );
  }

  const setBreadcrumbs = () => {
    const category = getQueryParam('category');
    const title = getQueryParam('title');
    const season = getQueryParam('season');
    const episode = getQueryParam('episode');
    let text = `${category === 'TV_SHOW' ? 'TV Shows' : 'Movies'} / ${title}`;
    if (category === 'TV_SHOW') text += ` / ${season} / ${episode}`;
    return text;
  };

  const { line, totalValues, biggestValue } = data;

  return (
    <div>
      <NavTabs items={navigation} className='media-tabs' />
      <div className='d-flex align-items-center mb-4'>
        <BackButton />
        <span className='text-light ml-3 text-capitalize'>{setBreadcrumbs()}</span>
      </div>
      <div className='bg-white px-6 py-4'>
        <div className='mb-8 d-flex align-items-start justify-content-between'>
          <div>
            <PageTitle
              total={totalValues}
              title={isView ? 'Views' : 'Watchtime'}
              unit={isView ? 'views' : 'mins'}
            />
          </div>
          <GroupBySelect
            value={groupBy}
            onChange={getAnalytics}
            disabled={fetching}
            className='mw-125'
          />
        </div>
        <div>
          <LineChart
            data={line}
            biggestValue={biggestValue}
            unit={isView ? 'views' : 'mins'}
            chartOptions={{ maintainAspectRatio: false }}
            chartHolderClass='h-individual-size'
          />
        </div>
      </div>
    </div>
  );
};

export default MediaView;
