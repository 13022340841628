import React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import PagesHeader from './components/PagesHeader';
import Privacy from './routes/Privacy';
import Terms from './routes/Terms';

const Pages = ({ match }) => {
  return (
    <>
      <PagesHeader />
      <main className='main-content d-flex flex-column'>
        <div className='flex-fill d-flex flex-column bg-white p-4'>
          <Switch>
            <Route path={`${match.path}/privacy`} component={Privacy} />
            <Route path={`${match.path}/terms`} component={Terms} />
            <Redirect to={`${match.path}/privacy`} />
          </Switch>
        </div>
      </main>
    </>
  );
};

export default Pages;
