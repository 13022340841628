import { combineReducers } from 'redux';
import { isAuthReducer, accountReducer } from 'app/Auth/actions';
import { uploadMediaReducer } from 'shared/components/MediaVideoUploader/actions';
import {
  moviesReducer,
  totalsValueReducer,
  tvshowsReducer,
  upcomingReducer,
  categoriesReducer,
  postersReducer,
  tagsReducer,
  configurationsReducer,
  genresReducer,
  moviesItemsReducer,
  tvshowsItemsReducer,
  optionsReducer,
  usersReducer,
  channelsReducer,
  channelUsersReducer,
  adsReducer,
  adsAssetsReducer,
  filtersReducer,
  analyticsReducer,
  overviewReducer,
  castAndCrewReducer,
  rolesReducer,
  fetchingReducer,
  productsReducer,
  plansReducer,
  promosReducer,
} from 'app/Main/actions';

const rootReducer = combineReducers({
  isAuthenticated: isAuthReducer,
  account: accountReducer,
  movies: moviesReducer,
  totals: totalsValueReducer,
  tvshows: tvshowsReducer,
  upcoming: upcomingReducer,
  categories: categoriesReducer,
  posters: postersReducer,
  tags: tagsReducer,
  genres: genresReducer,
  moviesItems: moviesItemsReducer,
  tvshowsItems: tvshowsItemsReducer,
  uploader: uploadMediaReducer,
  options: optionsReducer,
  users: usersReducer,
  channels: channelsReducer,
  channelUsers: channelUsersReducer,
  filters: filtersReducer,
  analytics: analyticsReducer,
  overview: overviewReducer,
  castAndCrew: castAndCrewReducer,
  roles: rolesReducer,
  fetching: fetchingReducer,
  ads: adsReducer,
  adsAssets: adsAssetsReducer,
  configurations: configurationsReducer,
  products: productsReducer,
  plans: plansReducer,
  promos: promosReducer,
});

export default rootReducer;
