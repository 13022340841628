import moment from 'moment';

export const planPeriods = [
  { name: '1 month', value: 'P1M' },
  { name: '3 months', value: 'P3M' },
  { name: '6 months', value: 'P6M' },
  { name: '1 year', value: 'P1Y' },
];

export const roleNames = {
  // Kinodaran Admin Roles
  ROLE_SUPER_ADMIN: 'Super Admin',
  ROLE_ADMIN: 'Admin (Manager)',
  ROLE_EDITOR: 'Editor',
  ROLE_VIEWER: 'Viewer / Analyst',

  // Channel Admin Roles
  ROLE_CHANNEL_OWNER: 'OWNER',
  ROLE_CHANNEL_ADMIN: 'Admin (Manager)',
  ROLE_CHANNEL_EDITOR: 'Editor',
  ROLE_CHANNEL_VIEWER: 'Viewer / Analyst',
};

export const roles = [
  {
    label: 'Admin (Manager)',
    role: 'ROLE_ADMIN',
    channelRole: 'ROLE_CHANNEL_ADMIN',
    permissions: [
      'Can edit everything',
      'Can add and remove admins',
      'Can add and remove channels',
    ],
    chanelPermissions: ['Can edit everything', 'Can add and remove admins'],
  },
  {
    label: 'Editor',
    role: 'ROLE_EDITOR',
    channelRole: 'ROLE_CHANNEL_EDITOR',
    permissions: [
      'Can edit everytning',
      `Can't add or remove Admins`,
      `Can't add or remove Channels`,
      `Can't see revenue information`,
    ],
    chanelPermissions: [
      'Can edit everything',
      `Can't add or remove others`,
      `Can't see revenue information`,
    ],
  },
  {
    label: 'Viewer / Analyst',
    role: 'ROLE_VIEWER',
    channelRole: 'ROLE_CHANNEL_VIEWER',
    permissions: ['Can see everytning', `Can't edit/create anything`],
    chanelPermissions: ['Can see everything', `Can't edit/create anything`],
  },
];
export const predefinedPermissions = {
  // Routes
  analytics_view: true,
  analytics_views_view: true, // All roles
  analytics_watchtime_view: true, // All roles
  analytics_content_view: true, // All roles
  analytics_usage_view: true, // All roles
  analytics_revenue_view: true, // All roles except ROLE_CHANNEL_EDITOR and ROLE_CHANNEL_VIEWER
  movies_view: true,
  tvshow_view: true,
  upcoming_view: true,
  poster_view: true,
  categories_view: true,
  genres_view: true,
  tags_view: true,
  cast_and_crew_view: true,
  clients_view: true,
  users_view: true,
  channels_view: true,
  ads_view: true,
  ads_assets_view: true,
  ads_assets_create: true,
  configurations_view: true,
  configurations_edit: true,
  pages_view: true,
  pages_edit: true,
  products_view: true,
  plans_view: true,
  promos_view: true,
  promos_create: true,
  promos_edit: true,
  promos_delete: true,
  finance_view: true,

  // Components
  media_create: true,
  media_edit: true,
  media_delete: true,
  media_related: true,
  media_youtube: true,
  media_pricing: true,
  media_publish: true,
  media_channel_assign: true,
  user_create: true,
  user_edit: true,
  user_delete: true,
  user_filters: true,
  channel_create: true,
  channel_edit: true,
  channel_delete: true,
  channel_user_create: true,
  channel_user_edit: true,
  channel_user_delete: true,
  channel_revenue_info_assign: true,
  cast_and_crew_create: true,
  cast_and_crew_edit: true,
  cast_and_crew_delete: true,
  product_create: true,
  product_edit: true,
  product_delete: true,
  plan_create: true,
  plans_edit: true,
  plans_delete: true,
  movie_pricing: true,
};

export const perRoleDeniedPermissions = {
  // For Kinodaran Admins
  ROLE_SUPER_ADMIN: [],
  ROLE_ADMIN: ['channel_delete'],
  ROLE_EDITOR: [
    'user_create',
    'user_edit',
    'user_delete',
    'channel_create',
    'channel_edit',
    'channel_delete',
    'channel_user_create',
    'channel_user_edit',
    'channel_user_delete',
    'channel_revenue_info_assign',
    'analytics_revenue_view',
    'ads_view',
    'ads_assets_view',
    'ads_assets_create',
    'configurations_view',
    'configurations_edit',
    'products_view',
    'plans_view',
    'promos_view',
    'promos_create',
    'promos_edit',
    'promos_delete',
    'movie_pricing',
    'finance_view',
  ],
  ROLE_VIEWER: [
    'users_view',
    'user_create',
    'user_edit',
    'user_delete',
    'channels_view',
    'channel_create',
    'channel_edit',
    'channel_delete',
    'channel_user_create',
    'channel_user_edit',
    'channel_user_delete',
    'channel_revenue_info_assign',
    'media_create',
    'media_edit',
    'media_delete',
    'media_youtube',
    'cast_and_crew_create',
    'cast_and_crew_edit',
    'cast_and_crew_delete',
    'product_create',
    'product_delete',
    'plan_create',
    'plans_delete',
    'media_publish',
    'configurations_edit',
    'pages_edit',
  ],
  // For Channel Admins
  ROLE_CHANNEL_OWNER: [
    'poster_view',
    'categories_view',
    'genres_view',
    'tags_view',
    'cast_and_crew_view',
    'upcoming_view',
    'user_filters',
    'channels_view',
    'channel_create',
    'channel_edit',
    'channel_delete',
    'channel_user_create',
    'channel_user_edit',
    'channel_user_delete',
    'channel_revenue_info_assign',
    'media_related',
    'media_youtube',
    'media_channel_assign',
    'media_pricing',
    'media_publish',
    'cast_and_crew_create',
    'cast_and_crew_edit',
    'cast_and_crew_delete',
    'ads_view',
    'ads_assets_view',
    'ads_assets_create',
    'configurations_view',
    'configurations_edit',
    'pages_view',
    'pages_edit',
    'products_view',
    'plans_view',
    'promos_view',
    'promos_create',
    'promos_edit',
    'promos_delete',
    'product_create',
    'product_edit',
    'product_delete',
    'plan_create',
    'plans_edit',
    'plans_delete',
    'movie_pricing',
    'clients_view',
    'finance_view'
  ],
  ROLE_CHANNEL_ADMIN: [
    'poster_view',
    'categories_view',
    'genres_view',
    'tags_view',
    'cast_and_crew_view',
    'upcoming_view',
    'user_filters',
    'channels_view',
    'channel_create',
    'channel_edit',
    'channel_delete',
    'channel_user_create',
    'channel_user_edit',
    'channel_user_delete',
    'channel_revenue_info_assign',
    'media_related',
    'media_youtube',
    'media_channel_assign',
    'media_pricing',
    'media_publish',
    'cast_and_crew_create',
    'cast_and_crew_edit',
    'cast_and_crew_delete',
    'ads_view',
    'ads_assets_view',
    'ads_assets_create',
    'configurations_view',
    'configurations_edit',
    'pages_view',
    'pages_edit',
    'products_view',
    'plans_view',
    'promos_view',
    'promos_create',
    'promos_edit',
    'promos_delete',
    'product_create',
    'product_edit',
    'product_delete',
    'plan_create',
    'plans_edit',
    'plans_delete',
    'movie_pricing',
    'clients_view',
    'finance_view'
  ],
  ROLE_CHANNEL_EDITOR: [
    'analytics_revenue_view', // This permission should be always denied for this role
    'poster_view',
    'categories_view',
    'genres_view',
    'tags_view',
    'cast_and_crew_view',
    'upcoming_view',
    'user_filters',
    'channels_view',
    'channel_create',
    'channel_edit',
    'channel_delete',
    'channel_user_create',
    'channel_user_edit',
    'channel_user_delete',
    'channel_revenue_info_assign',
    'media_related',
    'media_youtube',
    'media_channel_assign',
    'media_create',
    'media_delete',
    'media_pricing',
    'media_publish',
    'cast_and_crew_create',
    'cast_and_crew_edit',
    'cast_and_crew_delete',
    'ads_view',
    'ads_assets_view',
    'ads_assets_create',
    'configurations_view',
    'configurations_edit',
    'pages_view',
    'pages_edit',
    'products_view',
    'plans_view',
    'promos_view',
    'promos_create',
    'promos_edit',
    'promos_delete',
    'product_create',
    'product_edit',
    'product_delete',
    'plan_create',
    'plans_edit',
    'plans_delete',
    'movie_pricing',
    'clients_view',
    'finance_view'
  ],
  ROLE_CHANNEL_VIEWER: [
    'analytics_revenue_view', // This permission should be always denied for this role
    'poster_view',
    'categories_view',
    'genres_view',
    'tags_view',
    'cast_and_crew_view',
    'upcoming_view',
    'user_filters',
    'user_create',
    'user_edit',
    'user_delete',
    'channels_view',
    'channel_create',
    'channel_edit',
    'channel_delete',
    'channel_user_create',
    'channel_user_edit',
    'channel_user_delete',
    'channel_revenue_info_assign',
    'media_create',
    'media_edit',
    'media_delete',
    'media_related',
    'media_youtube',
    'media_channel_assign',
    'media_pricing',
    'media_publish',
    'cast_and_crew_create',
    'cast_and_crew_edit',
    'cast_and_crew_delete',
    'ads_view',
    'ads_assets_view',
    'ads_assets_create',
    'configurations_view',
    'configurations_edit',
    'pages_view',
    'pages_edit',
    'products_view',
    'plans_view',
    'promos_view',
    'promos_create',
    'promos_edit',
    'promos_delete',
    'product_create',
    'product_edit',
    'product_delete',
    'plan_create',
    'plans_edit',
    'plans_delete',
    'movie_pricing',
    'clients_view',
    'finance_view'
  ],
};

const yesterday = moment()
  .utc()
  .subtract(1, 'days');

export const dateRanges = {
  this_month: {
    name: 'This Month',
    startDate: moment()
      .utc()
      .startOf('month'),
    endDate: yesterday,
  },
  last_day: {
    name: 'Last Day',
    startDate: yesterday,
    endDate: yesterday,
  },
  last_month: {
    name: 'Last Month',
    startDate: moment()
      .utc()
      .subtract(1, 'month')
      .startOf('month'),
    endDate: moment()
  },
  // last_24_hours: {
  //   name: 'Last 24 hours',
  //   startDate: moment.utc().subtract(24, 'hours'),
  //   endDate: moment().utc(),
  // },
  last_7_days: {
    name: 'Last 7 Days',
    startDate: moment()
      .utc()
      .subtract(1, 'week')
      .subtract(1, 'day')
      .startOf('day'),
    endDate: yesterday,
  },
  last_30_days: {
    name: 'Last 30 Days',
    startDate: moment()
      .utc()
      .subtract(1, 'month')
      .subtract(1, 'day')
      .startOf('day'),
    endDate: yesterday,
  },
  last_90_days: {
    name: 'Last 90 Days',
    startDate: moment()
      .utc()
      .subtract(3, 'months')
      .subtract(1, 'day')
      .startOf('day'),
    endDate: yesterday,
  }
  // last_365_days: {
  //   name: 'Last 365 Days',
  //   startDate: moment()
  //     .utc()
  //     .subtract(1, 'year')
  //     .subtract(1, 'day')
  //     .startOf('day'),
  //   endDate: yesterday,
  // },
  // lifetime: {
  //   name: 'Lifetime',
  //   startDate: moment([2022]),
  //   endDate: yesterday,
  // },
};

export const seoTitleLength = 60;
export const seoDescriptionLength = 170;
