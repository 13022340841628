import React, { useState, useEffect, useRef } from 'react';
import { DateRangePicker as DatePicker, isInclusivelyBeforeDay } from 'react-dates';
import IconCarretDown from 'assets/images/icons/carret-down.svg';
import moment from 'moment';
import { dateRanges } from 'configs';
import { getDatesStr } from 'utils/analyticsHelpers';
import useOutsideClick from 'shared/hooks/useOutsideClick';

const rangeKeys = Object.keys(dateRanges);

const DateRangePicker = ({ value, onChange, disabled, className }) => {
  const [open, setOpen] = useState(false);
  const [focusedInput, setFocusedInput] = useState(null);
  const [localDates, setLocalDates] = useState(null);
  const container = useRef();

  const handleClick = () => {
    !disabled && setOpen(!open);
  };

  const onDatesChange = ({ startDate, endDate, name }) => {
    setLocalDates({ from: startDate, to: endDate, name });
    setOpen(false);
  };

  useEffect(() => {
    // NOTE: Setting the initial local value
    if (value && !localDates) setLocalDates(value);
  }, [value]);

  useEffect(() => {
    // NOTE: Fire an event when dropdown closes and the dates were changed
    if (
      localDates &&
      !focusedInput &&
      (localDates.from !== value.from || localDates.to !== value.to)
    ) {
      onChange(localDates);
    }
  }, [focusedInput, localDates]);

  useOutsideClick(container, () => setOpen(false));

  if (!localDates) return null;

  return (
    <div
      ref={container}
      className={`d-inline-flex analytics-dropdown mw-230${open ? ' open' : ''} ${className}`}
    >
      <button
        className={`filter-item text-light no-decoration btn-block d-inline-flex justify-content-between align-items-center ${
          disabled ? 'disabled' : ''
        }`}
        onClick={handleClick}
      >
        {getDatesStr(value)}
        <img
          src={IconCarretDown}
          width='20'
          height='20'
          alt='carret'
          className={`ml-3 transition-150 ${open ? 'flip-x' : ''}`}
        />
      </button>
      <ul className='dropdown-list'>
        {rangeKeys.map(key => (
          <li
            className='dropdown-item'
            key={key}
            onClick={() => onDatesChange(dateRanges[key])}
            role='presentation'
          >
            {dateRanges[key].name}
          </li>
        ))}
        <li className='dropdown-item dropdown-item__range'>
          <hr className='mt-1 mb-2' />
          <p className='text-light mb-1'>Custom Range</p>
          <DatePicker
            startDateId='startDate'
            endDateId='endDate'
            startDate={localDates.from}
            endDate={localDates.to}
            onDatesChange={onDatesChange}
            focusedInput={focusedInput}
            onFocusChange={setFocusedInput}
            isOutsideRange={day => !isInclusivelyBeforeDay(day, moment())}
            withPortal
            hideKeyboardShortcutsPanel
            minimumNights={0}
          />
        </li>
      </ul>
    </div>
  );
};

export default DateRangePicker;
