import React, { useEffect, useState } from 'react';
import { useSnackbar } from 'notistack';
import InputGroup from 'shared/components/forms/InputGroup';
import RadioBox from 'shared/components/forms/RadioBox';
import Modal from 'shared/components/modals/Modal';
import { Api } from 'utils/connectors';
import { getError } from 'utils/appHelpers';
import { roles } from 'configs';
import { PermissionService } from 'services/PermissionService';

const UserManageModal = ({ onClose, user, channel, onSuccess }) => {
  const { enqueueSnackbar } = useSnackbar();
  const isChannelAdminManage = channel || !PermissionService.hasAccess('media_channel_assign');

  const [fetching, setFetching] = useState(false);
  const [form, setForm] = useState({
    name: '',
    email: '',
    role: isChannelAdminManage ? 'ROLE_CHANNEL_ADMIN' : 'ROLE_ADMIN',
  });
  const isEdit = !!user;

  const getUrl = () => {
    return isEdit
      ? `/users/${user.id}` // Update admin
      : channel
      ? `/channels/${channel.id}` // Create channel admin
      : '/users'; // Create admin
  };

  const onFormSubmit = async e => {
    e.preventDefault();
    try {
      setFetching(true);
      const action = isEdit ? 'put' : 'post';
      const url = getUrl();
      const successMsg = isEdit ? 'Successfully updated.' : 'The invitation was sent successfully.';
      await Api[action](url, form);
      enqueueSnackbar(successMsg, { variant: 'success' });
      setFetching(false);
      onSuccess && onSuccess();
      onClose();
    } catch (err) {
      enqueueSnackbar(getError(err), { variant: 'error' });
      setFetching(false);
    }
  };

  const handleChange = ({ target }) => {
    const { name, value } = target;
    setForm({ ...form, [name]: value });
  };

  useEffect(() => {
    if (user) setForm(user);
  }, [user]);

  return (
    <Modal size='xs' onClose={onClose} className='mx-2 mt-2'>
      <div className='modal-title bg-purple__light row px-4 py-3'>
        <div className='text-primary__light'>{isEdit ? 'Edit' : 'Invite'} User (Admin)</div>
      </div>
      <div className='signup-modal d-flex flex-column align-items-center'>
        <form onSubmit={onFormSubmit} className='w-100 pt-3'>
          <InputGroup
            type='text'
            name='name'
            value={form.name}
            placeholder='Full Name'
            onChange={handleChange}
            required
            autoFocus
          />
          <InputGroup
            type='text'
            name='email'
            value={form.email}
            placeholder='Email address'
            onChange={handleChange}
            required
            disabled={isEdit}
          />
          {channel && (
            <InputGroup
              type='text'
              name='email'
              value={channel.name}
              placeholder='Channel Name'
              disabled
            />
          )}
          <div className='row'>
            {roles.map(({ label, role, channelRole, permissions, chanelPermissions }) => {
              const roleKey = isChannelAdminManage ? channelRole : role;
              const userPermissions = isChannelAdminManage ? chanelPermissions : permissions;
              return (
                <div key={label} className='col-6 mb-4'>
                  <RadioBox
                    name='role'
                    label={label}
                    labelClass='weight-900 pl-2'
                    checked={form.role === roleKey}
                    onChange={() => handleChange({ target: { name: 'role', value: roleKey } })}
                  />
                  <ul className='pl-10'>
                    {userPermissions.map((perm, i) => (
                      <li key={i}>{perm}</li>
                    ))}
                  </ul>
                </div>
              );
            })}
          </div>
          <div className='text-right mt-4'>
            <button
              className='btn btn-outline-primary text-md text-uppercase mnw-110 btn-sm py-2 weight-300 mx-2'
              onClick={onClose}
              type='button'
            >
              Cancel
            </button>
            <button
              className='btn btn-primary btn-sm py-2 text-md text-uppercase weight-300 mnw-110 ml-2'
              disabled={fetching}
              type='submit'
            >
              {isEdit ? 'Update' : 'Invite'}
            </button>
          </div>
        </form>
      </div>
    </Modal>
  );
};

export default UserManageModal;
