import React, { useState, useRef } from 'react';
import useOutsideClick from 'shared/hooks/useOutsideClick';

const SortBox = ({ sort, setSort, items, label = 'Label', className }) => {
  const sortContainer = useRef();
  const [active, setActive] = useState(false);

  const onSortChange = value => {
    setSort(item => ({ ...item, key: value }));
    setActive(false);
  };

  const onSortBoolChange = e => {
    if (sort.bool === undefined) return;
    setSort(item => ({ ...item, bool: !item.bool }));
    setActive(false);
    e.preventDefault();
    e.stopPropagation();
  };

  const selected = items.find(v => v.key === sort.key);

  useOutsideClick(sortContainer, () => setActive(false));

  return (
    <div className='d-flex align-items-center mr-3' ref={sortContainer}>
      <span className='mr-2 text-primary__light'>{label}: </span>
      <div className='sort-box'>
        <div
          className='sort-header bg-grey__light d-flex align-items-center justify-content-between rounded__3'
          onClick={() => setActive(!active)}
          role='presentation'
        >
          <span>{selected ? selected.name : 'All'}</span>
          <button onClick={onSortBoolChange}>
            <span className={`arrow arrow-up ${!sort.bool ? 'op-5' : ''}`} />
            <span className={`arrow arrow-down ${sort.bool ? 'op-5' : ''}`} />
          </button>
        </div>
        {active && (
          <div className='sort-content rounded__3 bg-white'>
            <ul>
              {items &&
                items.map(item => (
                  <li
                    key={item.key}
                    value={item.key}
                    onClick={() => onSortChange(item.key)}
                    role='presentation'
                  >
                    {item.name}
                  </li>
                ))}
            </ul>
          </div>
        )}
      </div>
    </div>
  );
};

export default SortBox;
