/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React, { useEffect, useState } from 'react';
import { useSnackbar } from 'notistack';
import Modal from 'shared/components/modals/Modal';
import { Api } from 'utils/connectors';
import { getError, getImageUrl, hasImageType, imageUploadProgress } from 'utils/appHelpers';
import UploadZone from 'shared/components/UploadZone';
import ActionButton from 'shared/components/ActionButton';
import UpdateImageUpload from 'shared/components/UpdateImageUpload';
import UploadProgress from 'shared/components/UploadProgress';
import InputGroup from 'shared/components/forms/InputGroup';
import TextAreaGroup from 'shared/components/forms/TextAreaGroup';
import LangToggle from 'shared/components/LangToggle';
import MediaVideoUploader from 'shared/components/MediaVideoUploader';
import defaultImg from 'assets/images/thumbnail-default.jpg';
import MediaPreviewModal from 'shared/components/MediaPreviewModal';
import { MAX_SIZES } from 'configs/sizes';

const mediaImages = [
  { key: 'Thumbnail', name: 'Thumbnail', type: 'THUMBNAIL', maxSize: MAX_SIZES.UPCOMING_THUMBNAIL },
];

const UpcomingManageModal = ({ onClose, state, onSuccess, lang, setLang }) => {
  const [preview, setPreview] = useState(false);
  const [imageProgress, setImageProgress] = useState({});
  const { enqueueSnackbar } = useSnackbar();
  const [fetching, setFetching] = useState(false);
  const [media, setMedia] = useState();
  const [form, setForm] = useState({});

  const handleChange = ({ target }) => {
    const { name, value } = target;
    setForm({ ...form, [name]: value });
  };

  const createItem = async () => {
    const res = await Api.post(`/upcoming-medias?locale=${lang}`, form);
    setMedia(res.data);
  };

  const updateItem = () => {
    return Api.put(`/upcoming-medias/${media.id}?locale=${lang}`, form);
  };

  const onFormSubmit = async e => {
    e.preventDefault();
    try {
      setFetching(true);
      const isUpdate = !!media;
      isUpdate ? await updateItem() : await createItem();
      if (onSuccess) await onSuccess();
      enqueueSnackbar('Successfully done', { variant: 'success' });
      setFetching(false);
      isUpdate && onClose();
    } catch (err) {
      enqueueSnackbar(getError(err), { variant: 'error' });
      setFetching(false);
    }
  };

  const removeImage = async image => {
    try {
      await Api.delete(`upcoming-medias/${media.id}/images?imageType=${image.type}`);
      const tempPoster = { ...media };
      tempPoster.image.types = tempPoster.image.types.filter(item => item.type !== image.type);
      setMedia(tempPoster);
    } catch (err) {
      enqueueSnackbar(getError(err), { variant: 'error' });
    }
  };

  const removeVideo = async () => {
    try {
      await Api.delete(`/upcoming-medias/${media.id}/trailer`);
      const tempPoster = { ...media };
      delete tempPoster.file_name;
      setMedia(tempPoster);
    } catch (err) {
      enqueueSnackbar(getError(err), { variant: 'error' });
    }
  };

  const onUploadFile = async (image, file, status) => {
    if (status?.[0]?.errors?.[0]?.code === 'file-too-large') {
      enqueueSnackbar(status?.[0]?.errors?.[0]?.message, { variant: 'error' });
      return;
    }
    if (!file.length) return;
    const config = {
      onUploadProgress: imageUploadProgress.bind(null, setImageProgress, image.type),
    };

    try {
      setImageProgress({ ...imageProgress, [image.type]: 1 });
      const form = new FormData();
      form.append('image', file[0]);
      const { data } = await Api.post(
        `upcoming-medias/${media.id}/images?imageType=${image.type}`,
        form,
        config,
      );
      setMedia(data);
    } catch (err) {
      enqueueSnackbar(getError(err), { variant: 'error' });
    } finally {
      setImageProgress({ ...imageProgress, [image.type]: false });
    }
  };

  const trailerFileValidation = file => {
    let validated = true;
    const size = Math.round(file.size / 1024);
    if (size < 10240) {
      validated = false;
      enqueueSnackbar('File too small, please select a file greater than 10mb', {
        variant: 'error',
      });
    }
    return validated;
  };

  useEffect(() => {
    setMedia(state.data);
    setForm({
      title: state.data ? state.data.title : '',
      description: state.data ? state.data.description : '',
    });
  }, [state.data]);

  const showVideoItem = media && media.file_name;

  return (
    <>
      <Modal size='sm' onClose={onClose} className='mx-2 mt-2'>
        <div className='modal-title bg-purple__light row px-4 py-3 mb-6'>
          <div className='text-primary__light'>{media ? 'Edit' : 'Add'} Upcoming Media</div>
        </div>
        <div className='d-flex justify-content-start mb-2'>
          <LangToggle lang={lang} setLang={setLang} />
        </div>
        <div className='signup-modal d-flex flex-column align-items-center'>
          <form onSubmit={onFormSubmit} className='w-100 pt-3'>
            <InputGroup
              type='text'
              name='title'
              value={form.title || ''}
              placeholder={'Title'}
              onChange={handleChange}
              required
            />
            <TextAreaGroup
              name='description'
              value={form.description || ''}
              placeholder={'Description'}
              onChange={handleChange}
              className='mb-4'
            />
            {media && (
              <div className='images-area mt-3 w-100'>
                {mediaImages.map(item => {
                  const hasImage = media && hasImageType(media.image, item.type);

                  if (hasImage) {
                    if (imageProgress[item.type]) {
                      return <UploadProgress key={item.type} progress={imageProgress[item.type]} />;
                    }
                    return (
                      <div
                        key={item.type}
                        className='mb-3 d-flex align-items-center justify-content-between'
                      >
                        <div>
                          <img
                            width='120'
                            height='80'
                            className='object-fit__cover object-position__center rounded__3 mr-3'
                            src={getImageUrl(media.image, item.type)}
                            alt='poster'
                          />
                          {item.name}
                        </div>
                        <div className='d-flex'>
                          <UpdateImageUpload
                            onDrop={onUploadFile.bind(null, item)}
                            maxSize={item.maxSize}
                          />
                          <ActionButton onClick={removeImage.bind(null, item)} />
                        </div>
                      </div>
                    );
                  } else {
                    return (
                      <UploadZone
                        key={item.type}
                        imageProgress={imageProgress[item.type]}
                        maxSize={item.maxSize}
                        onDrop={onUploadFile.bind(null, item)}
                        name={item.name}
                      />
                    );
                  }
                })}
              </div>
            )}
            {media &&
              (showVideoItem ? (
                <div className='mb-3 d-flex align-items-center justify-content-between'>
                  <div>
                    <img
                      width='120'
                      height='80'
                      className='object-fit__cover object-position__center rounded__3 mr-3 pointer'
                      src={defaultImg}
                      onClick={() => setPreview(true)}
                      alt='poster'
                    />
                    Trailer <span className='small text-secondary'>(Status: {media.status})</span>
                  </div>
                  <div className='d-flex'>
                    <ActionButton onClick={removeVideo} />
                  </div>
                </div>
              ) : (
                <MediaVideoUploader
                  placeholder='Upload Trailer'
                  onFileSuccess={onSuccess}
                  fileValidation={trailerFileValidation}
                  uploadOptions={{
                    type: 'UPCOMING',
                    id: media.id,
                    itemId: media.id,
                  }}
                />
              ))}
            <div className='text-right w-100 mt-5'>
              <button
                type='button'
                className='btn btn-outline-primary text-md text-uppercase mnw-110 btn-sm py-2 weight-300 mx-2'
                onClick={onClose}
              >
                Close
              </button>
              <button
                className='btn btn-primary btn-sm py-2 text-md text-uppercase weight-300 mnw-110 ml-2'
                disabled={fetching}
                onClick={onFormSubmit}
                type='submit'
              >
                Save
              </button>
            </div>
          </form>
        </div>
      </Modal>
      {preview && (
        <MediaPreviewModal
          onClose={() => setPreview(false)}
          item={{
            media_files: media.media_files,
          }}
        />
      )}
    </>
  );
};

export default UpcomingManageModal;
