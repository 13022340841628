import React, { useState, useEffect, useRef } from 'react';
import IconCarretDown from 'assets/images/icons/carret-down.svg';
import useOutsideClick from 'shared/hooks/useOutsideClick';

const options = [
  {
    name: 'Watchtime',
    key: 'watch-time',
  },
  {
    name: 'Views',
    key: 'view-count',
  },
  {
    name: 'Revenue',
    key: 'revenue',
  },
];

const GroupBySelect = ({ value, onChange, disabled, className }) => {
  const [open, setOpen] = useState(false);
  const [title, setTitle] = useState(null);
  const container = useRef();

  const handleChange = key => {
    if (value !== key) onChange(key);
    setOpen(false);
  };

  const handleClick = () => {
    !disabled && setOpen(!open);
  };

  const handleInitialValue = () => {
    if (value) {
      const { name = '' } = options.find(v => v.key === value);
      setTitle(name);
    }
  };

  useEffect(handleInitialValue, [value]);

  useOutsideClick(container, () => setOpen(false));

  return (
    <div
      ref={container}
      className={`d-inline-flex analytics-dropdown${open ? ' open' : ''} ${className}`}
    >
      <button
        className={`filter-item text-light no-decoration btn-block px-2 py-2 d-inline-flex justify-content-between align-items-center ${
          disabled ? 'disabled' : ''
        }`}
        onClick={handleClick}
      >
        {title}
        <img
          src={IconCarretDown}
          alt='carret'
          className={`ml-3 transition-150 ${open ? 'flip-x' : ''}`}
        />
      </button>
      <ul className='dropdown-list'>
        {options.map(item => (
          <li
            className='dropdown-item'
            key={item.name}
            onClick={() => handleChange(item.key)}
            role='presentation'
          >
            {item.name}
          </li>
        ))}
      </ul>
    </div>
  );
};

export default GroupBySelect;
