import React, { useState } from 'react';
import Switcher from './forms/Switcher';
import IconDrag from 'assets/images/icons/dd_icon.svg';
import ActionButton from './ActionButton';
import defaultImg from 'assets/images/thumbnail-default.jpg';
import MediaPreviewModal from './MediaPreviewModal';
import VideoUploadProgress from './MediaVideoUploader/components/VideoUploadProgress';
import MediaPublishState from './MediaPublishState';
import { bindDuration, hasImageType, getImageUrl } from 'utils/appHelpers';
import { PermissionService } from 'services/PermissionService';

const YoutubeMediaContent = ({ video }) => {
  const url = `https://youtu.be/${video.remote_video_id}`;
  return (
    <div>
      {video.title && <p className='mb-0 text-capitalize'>{video.title}</p>}
      <a
        href={url}
        target='_blank'
        rel='noopener noreferrer'
        className='fz-12 weight-300 text-nowrap'
      >
        {url}
      </a>
      <p className='mb-0 text-sm'>Length: {bindDuration(video.duration)}</p>
    </div>
  );
};

const Badge = ({ children, type }) => (
  <span className={`badge badge-${type} mr-1`}>{children}</span>
);

const VideoMediaContent = ({ video, published, publishDate, showPublishState }) => {
  const name = video.file_name || video.fileName;
  const isMediaComplete = video.status === 'COMPLETE';
  const status = isMediaComplete && !video?.replacement_status ? video.status : video?.replacement_status ? video?.replacement_status : video.status;

  const showFileSize = sizeByte => {
    if (sizeByte > 1024 * 1024 * 1024) {
      return `${(sizeByte / (1024 * 1024 * 1024)).toFixed(2)} GB`;
    }
    return `${(sizeByte / (1024 * 1024)).toFixed(2)} MB`;
  };

  const statuses = {
    COMPLETE: 'success',
    PROGRESSING: 'warning',
    FAILED: 'danger',
    ERROR: 'danger',
    CREATED: 'info',
  };

  return (
    <div>
      <div className='d-flex align-items-center mb-1'>
        {!!video.title && <p className='text-capitalize mr-3 mb-0'>{video.title}</p>}
        {showPublishState && (
          <MediaPublishState published={published} publishDate={publishDate} className='text-sm' />
        )}
      </div>
      <div className='d-flex align-items-center'>
        {!!video.size && (
          <p className='mb-0 weight-300 small mr-3'>
            Size: <span className='fw-400'>{showFileSize(video.size)}</span>
          </p>
        )}
        {!!video.duration && (
          <p className='mb-0 weight-300 small'>
            Duration: <span className='fw-400'>{bindDuration(video.duration)}</span>
          </p>
        )}
      </div>
      {
        <p className='mb-0 text-sm'>
          {!!name && <Badge type='primary'>File: {name}</Badge>}
          {!!video.encrypted && <Badge type='warning'>DRM encrypted</Badge>}
          {!!status && <Badge type={statuses[status] || 'info'}>Status: {status}</Badge>}
        </p>
      }
    </div>
  );
};

const MediaVideoItem = ({
  video,
  num,
  className,
  published,
  publishDate,
  onPublish,
  onItemRemove,
  onItemEdit,
  isDragging,
  disabled,
  videoProgress,
  onEpisodeVideoCancel,
  showPublishState,
  onClickReplaceVideo,
  showReplaceOption = false,
  onClickOpenAudioMenu,
}) => {
  const [preview, setPreview] = useState(false);
  const isMediaYoutube = video.media_type === 'YOUTUBE' && video.remote_video_id;
  const Content = isMediaYoutube ? YoutubeMediaContent : VideoMediaContent;

  const getVideoImg = () => {
    if (hasImageType(video.image, 'THUMBNAIL')) {
      return getImageUrl(video.image);
    }
    if (isMediaYoutube) {
      return `https://img.youtube.com/vi/${video.remote_video_id}/0.jpg`;
    }
    return defaultImg;
  };

  return (
    <>
      <div className={`media-video-box d-flex justify-content-between ${className || ''}`}>
        <div className='d-flex align-items-center w-100'>
          {num && (
            <span className='mr-3'>{isDragging ? <img src={IconDrag} alt='drag' /> : num}</span>
          )}
          <div className='d-flex w-100'>
            <div className='img-area'>
              <img
                src={getVideoImg()}
                onClick={() => setPreview(true)}
                role='presentation'
                width='130'
                alt='img'
                className={`mr-3 pointer rounded__3${disabled ? ' op-5' : ''}`}
              />
            </div>
            {videoProgress && (
              <div className='d-flex align-items-center w-100'>
                <VideoUploadProgress
                  className='w-100 mb-2'
                  progress={videoProgress}
                  onEpisodeVideoCancel={onEpisodeVideoCancel}
                />
              </div>
            )}
            {!videoProgress && (
              <div>
                <Content
                  video={video}
                  published={published}
                  publishDate={publishDate}
                  showPublishState={showPublishState}
                />
              </div>
            )}
          </div>
        </div>
        {!videoProgress && (
          <div className='actions d-flex align-items-center'>
            {PermissionService.hasAccess('media_edit') && onClickOpenAudioMenu && (
              <ActionButton className='mr-1' type='audio' onClick={onClickOpenAudioMenu} />
            )}
            {PermissionService.hasAccess('media_edit') && onItemEdit && (
              <ActionButton className='mr-1' type='edit' onClick={onItemEdit} />
            )}
            {PermissionService.hasAccess('media_delete') && onItemRemove && (
              <ActionButton
                className='mr-1'
                type='remove'
                onClick={onItemRemove.bind(null, video)}
              />
            )}
            {showReplaceOption && (
              <ActionButton disabled={disabled} className='mr-1' type='refresh' onClick={onClickReplaceVideo} />
            )}
            {PermissionService.hasAccess('media_edit') && onPublish && (
              <Switcher switchCheck={published} onSwitchChange={onPublish} disabled={disabled} />
            )}
          </div>
        )}
      </div>
      {preview && (
        <MediaPreviewModal
          onClose={() => setPreview(false)}
          item={video}
          isMediaYoutube={isMediaYoutube}
        />
      )}
    </>
  );
};

export default MediaVideoItem;
