import React from 'react';
import * as Sentry from '@sentry/react';

class ErrorBoundary extends React.Component {
  state = { error: null, errorInfo: null };

  componentDidCatch(error, errorInfo) {
    Sentry.captureException(error);
    this.setState({
      error: error,
      errorInfo: errorInfo,
    });
  }

  render() {
    if (this.state.errorInfo) {
      return (
        <div className='alert alert-danger'>
          An error occurred. Please contact to support administrator.
        </div>
      );
    }

    return this.props.children;
  }
}

export default ErrorBoundary;
