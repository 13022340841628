/* eslint-disable jsx-a11y/no-static-element-interactions */
import React from 'react';
import { getImageUrl } from 'utils/appHelpers';
import IconCheck from 'assets/images/icons/check.svg';
import IconUncheck from 'assets/images/icons/uncheck.svg';

const MovieBox = ({
  item,
  publishable,
  published,
  onClick,
  className,
  selectable,
  selected,
  disabled,
}) => {
  const img = getImageUrl(item.image);
  return (
    <div
      className={`movie-box-container col-sm-6 col-md-4 col-lg-2_5 col-xl-2 ${className || ''}`}
      onClick={disabled ? () => {} : onClick}
    >
      <div className={`movie-box ${selected ? 'selected' : ''} ${disabled ? 'is-disabled' : ''}`}>
        <div className='img-area d-flex'>{img && <img src={img} alt='img' />}</div>
        <div className='py-2 px-3'>
          {item.title}
          {publishable && (
            <div className='mt-1'>
              <img src={published ? IconCheck : IconUncheck} alt='check' className='mr-1' />
              <span className='text-sm text-primary__light weight-300'>
                {published ? 'Published' : 'Unpublished'}
              </span>
            </div>
          )}
          {selectable && (
            <div className={`select-mark${selected ? ' selected' : ''}`}>
              <img src={IconCheck} alt='check' className='mr-1' />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default MovieBox;
