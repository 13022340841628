/* eslint-disable consistent-return */
import React, { useRef, useState } from 'react';
import { useSnackbar } from 'notistack';
import { getError, getYoutubeId } from 'utils/appHelpers';
import AWS from 'aws-sdk';
import { Api } from 'utils/connectors';
import Switcher from '../forms/Switcher';
import InputGroup from '../forms/InputGroup';
import { IconVideoAdd } from '../layouts/Icons';
import UploadZone from '../UploadZone';
import { useDispatch, useSelector } from 'react-redux';
import { setVideoUpload } from './actions';
import VideoUploadProgress from './components/VideoUploadProgress';
import { PermissionService } from 'services/PermissionService';
import UploadOptionsModal from './components/UploadOptionsModal';

const MediaVideoUploader = ({
  onYoutubeSubmit,
  isFetching,
  onFileSuccess,
  uploadOptions,
  isMulti,
  placeholder,
  fileValidation,
  showOptionsBeforeUpload,
  isMediaReplace = false,
  showUploadOptionsInitial = false,
  onCloseSuccess,
  onHandleConvertSuccess,
  disabled,
  lang,
  progressKey,
  optionsState
}) => {
  const { enqueueSnackbar } = useSnackbar();
  const uploader = useSelector(state => state.uploader);
  const dispatch = useDispatch();
  const [isYoutube, setIsYoutube] = useState(false);
  const [showUploadOptions, setShowUploadOptions] = useState(showUploadOptionsInitial);
  const [youtubeLink, setYoutubeLink] = useState('');
  const [tempFiles, setTempFiles] = useState();
  const progressKeyName = useRef();
  const [convertOptions, setConvertOptions] = useState({
    resolution: 'FHD',
    priority: 0,
    encrypt: false,
  });

  const onYoutubeLinkSubmit = async e => {
    e.preventDefault();
    await onYoutubeSubmit({
      remote_video_id: getYoutubeId(youtubeLink),
    });
    setYoutubeLink('');
  };

  const setItemProgress = (id, request, progress) => {
    const body = progress === null ? null : { ...progress, request };
    dispatch(setVideoUpload({ [id]: body }));
  };

  const uploadToS3 = (awsConfig, file) => {
    AWS.config.update(awsConfig);

    const s3 = new AWS.S3({ httpOptions: { timeout: 20 * 60 * 1000 } });
    const params = { Bucket: awsConfig.bucket, Key: awsConfig.objectKey, Body: file };

    // const limitQueueSize = file.size / 10000 / 1024 / 1024;
    // const partSize = limitQueueSize < 5 ? 5 * 1024 * 1024 : limitQueueSize * 1024 * 1024;
    // const queueSize = Math.ceil(file.size / partSize);
    // const options = { partSize: partSize, queueSize: queueSize };

    const s3UploadCallback = (err, data) => {
      setItemProgress(progressKeyName.current, null, null);
      if (!err) {
        enqueueSnackbar(`Successfully uploaded ${file.name}`, { variant: 'success' });
        if (onFileSuccess) onFileSuccess();
      } else {
        enqueueSnackbar(getError(err), { variant: 'error' });
      }
    };

    const request = s3.upload(params, {}, s3UploadCallback);
    request.on('httpUploadProgress', setItemProgress.bind(null, progressKeyName.current, request));
    return request;
  };

  const onUploadFile = async file => {
    try {
      const validated = fileValidation ? fileValidation(file) : true;
      if (!validated) return;
      const { type, id, itemId, getItemId, isTrailer } = uploadOptions;
      const uploadId = itemId ? itemId : getItemId ? await getItemId(file) : undefined;
      if (!uploadId) return;
      progressKeyName.current = progressKey || uploadId;
      setItemProgress(progressKeyName.current, null, { loaded: 0, total: file.size });
      let params = {
        media_type: isTrailer ? 'TRAILER' : type,
        file_name: file.name,
        episode_id: uploadId,
        media_id: id,
        is_media_replace: isMediaReplace,
        lang: lang,
      };
      params = { ...params, ...convertOptions };
      const { data } = await Api.post('/medias/federation-token', params);
      return uploadToS3(data, file, progressKeyName.current);
    } catch (err) {
      setItemProgress(uploadOptions.itemId, null, null);
    }
  };

  const onUploadFiles = async (onlyKeepFiles, files) => {
    if (onlyKeepFiles) {
      setTempFiles(files);
      return;
    }

    if (!isMulti || files.length < 2) {
      onUploadFile(files[0]);
      return;
    }

    try {
      await Promise.all(files.map(onUploadFile));
    } catch (err) {
      enqueueSnackbar(getError(err), { variant: 'error' });
    }
  };

  const onHandleConvert = async () => {
    if (!tempFiles?.length) {
      enqueueSnackbar('Please select file', { variant: 'error' });
      return;
    }
    onUploadFiles(false, tempFiles);
    setShowUploadOptions(false);
    onHandleConvertSuccess && onHandleConvertSuccess();
  };

  const progress = uploader[progressKeyName.current];

  return (
    <>
      {onYoutubeSubmit && (
        <div className='d-flex align-items-center justify-content-end'>
          <Switcher
            onSwitchChange={() => setIsYoutube(item => !item)}
            className='pt-1'
            switchCheck={isYoutube}
            disabled={!PermissionService.hasAccess('media_youtube') || !!progress}
          />
          <span className='ml-2 mb-1 fz-13'>Youtube</span>
        </div>
      )}
      {PermissionService.hasAccess('media_youtube') && isYoutube ? (
        <div className='mt-4'>
          <form
            onSubmit={onYoutubeLinkSubmit}
            className='mt-3 d-flex align-items-start youtube-form'
          >
            <InputGroup
              type='text'
              name='name'
              value={youtubeLink}
              placeholder='Youtube Link'
              onChange={({ target }) => setYoutubeLink(target.value)}
              required
              containerClass='flex-fill add-input'
            />
            <button
              className='add-btn bg-white'
              disabled={!getYoutubeId(youtubeLink) || isFetching}
              type='submit'
            >
              <IconVideoAdd color={getYoutubeId(youtubeLink) ? '#5867dd' : '#b2b8c1'} />
            </button>
          </form>
        </div>
      ) : (
        <>
          {PermissionService.hasAccess('media_create') && !progress && (
            <>
              {!showOptionsBeforeUpload && (
                <UploadZone
                  name={placeholder || 'Upload Multiple Movies'}
                  onDrop={onUploadFiles.bind(null, false)}
                  disabled={disabled}
                />
              )}
              {showOptionsBeforeUpload && (
                <button
                  className='btn btn-block border my-4 fw-400'
                  onClick={() => setShowUploadOptions(true)}
                >
                  Upload Movie
                </button>
              )}
            </>
          )}
          {progress && <VideoUploadProgress progress={progress} />}
        </>
      )}
      {showUploadOptions && (
        <UploadOptionsModal
          onHandleConvert={onHandleConvert}
          optionsState={optionsState}
          onClose={() => {
            setShowUploadOptions(false);
            onCloseSuccess && onCloseSuccess();
          }}
          convertOptions={convertOptions}
          setConvertOptions={setConvertOptions}
        >
          <UploadZone
            name={tempFiles ? 'Selected Files' : placeholder || 'Upload Multiple Movies'}
            placeholder={tempFiles ? tempFiles.map(i => i.name) : undefined}
            onDrop={onUploadFiles.bind(null, true)}
          />
        </UploadOptionsModal>
      )}
    </>
  );
};

export default MediaVideoUploader;
