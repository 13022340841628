import React from 'react';
import { useHistory } from 'react-router-dom';

const LocationsContainer = ({ title, onAcountryRemove, values = {} }) => {
  const { location, push } = useHistory();
  const keys = Object.keys(values);

  return (
    <div className='row'>
      <div className='col-5 p-0'>
        <h6>{title}</h6>
      </div>
      <div className='col-7'>
        <div
          className='row'
          tabIndex={-1}
          role='button'
          onClick={() => push(`${location.pathname}/new-country-add`)}
        >
          <button className='btn btn-primary btn-circle btn-xl' type='button'>
            +
          </button>
          <p className='text-primary small col-8 text-pt-top' type='button'>
            Add country based
          </p>
        </div>
      </div>
      <div className='col-12 p-0'>
        {!!keys?.length && (
          <div>
            <table className='table ads-table-new-modal'>
              <thead>
                <tr>
                  <th className='pl-0 new-add-country border-0 text-secondary small'>Country</th>
                  <th className='pl-1 border-0 text-secondary small'>PreRoll</th>
                  <th className='pl-1 border-0 text-secondary small'>MidRoll</th>
                  <th className='pl-1 border-0 text-secondary small'>EndRoll</th>
                  <th className='pl-2 border-0 text-secondary small'>Frequency</th>
                </tr>
              </thead>
              <tbody>
                {keys.map((key, locIndex) => {
                  const details = values[key] || {};
                  return (
                    <tr key={locIndex} className='border-bottom'>
                      <td className='d-flex flex-column align-items-start'>
                        <h6>{key}</h6>
                      </td>
                      <td className='pl-1'>
                        <div>
                          <span className='text-ellipsis badge badge-pill'>
                            System: {details.preroll?.ad_system_type}
                          </span>
                        </div>
                        <div>
                          <span className='text-ellipsis badge badge-pill'>
                            Count: {details.preroll?.max_count}
                          </span>
                        </div>
                      </td>
                      <td className='pl-1'>
                        <div>
                          <span className='text-ellipsis badge badge-pill'>
                            System: {details.midroll?.ad_system_type}
                          </span>
                        </div>
                        <div>
                          <span className='text-ellipsis badge badge-pill'>
                            Count: {details.midroll?.max_count}
                          </span>
                        </div>
                      </td>
                      <td className='pl-1'>
                        <div>
                          <span className='text-ellipsis badge badge-pill'>
                            System: {details.endroll?.ad_system_type}
                          </span>
                        </div>
                        <div>
                          <span className='text-ellipsis badge badge-pill'>
                            Count: {details.endroll?.max_count}
                          </span>
                        </div>
                      </td>
                      <td className='pl-1'>
                        <div>
                          <span className='text-ellipsis badge badge-pill'>
                            Second: {details.ad_frequency}
                          </span>
                        </div>
                      </td>
                      <td className='pl-1'>
                        <div className='d-flex flex-column justify-content-end'>
                          <p
                            className='text-primary'
                            type='button'
                            onClick={() => push(`${location.pathname}/${key}`)}
                          >
                            Edit
                          </p>
                          <p
                            className='text-danger'
                            type='button'
                            onClick={() => onAcountryRemove(key)}
                          >
                            Delete
                          </p>
                        </div>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        )}
      </div>
    </div>
  );
};

export default LocationsContainer;
