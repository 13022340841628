import React from 'react';
import IconPlusFilled from 'assets/images/icons/plus_filled.svg';

const CreateButton = ({ onClick, name, className, size, disabled, type }) => {
  return (
    <button
      className={`create-btn rounded__30 bg-transparent border-style border__light d-flex align-items-center no-decoration ${className ||
        ''} ${size === 'sm' ? 'px-1 py-1' : 'px-2 py-2'}`}
      onClick={onClick}
      disabled={disabled}
      type={type}
    >
      <img src={IconPlusFilled} alt='plus' className='mr-3' width='22' />{' '}
      <span className='text-light pr-2'>{name}</span>
    </button>
  );
};

export default CreateButton;
