import React, { useEffect, useState } from 'react';
import { Api } from 'utils/connectors';
import { useSnackbar } from 'notistack';
import { useDispatch, useSelector } from 'react-redux';
import { confirmBox, getError, getQueryParam } from 'utils/appHelpers';
import { onSetUsers } from 'app/Main/actions';
import Header from 'shared/components/layouts/Header';
import InfiniteScroll from 'react-infinite-scroll-component';
import CreateButton from 'shared/components/CreateButton';
import Tabs from 'shared/components/Tabs';
import ActionButton from 'shared/components/ActionButton';
import UserManageModal from 'shared/components/UserManageModal';
import StateHolder from 'shared/components/StateHolder';
import Loading from 'shared/components/loadings/Loading';
import { roleNames } from 'configs';
import { PermissionService } from 'services/PermissionService';

// TODO: Channel admins will have an ability to create their admins from this page
// Need to send channel related roles when creating them
// Also backend will need a channel ID from frontend to know in which channel create admins

const navTabs = [
  { name: 'All', value: null },
  { name: 'SUPER ADMIN', value: 'ROLE_SUPER_ADMIN' },
  { name: 'ADMIN (MANAGER)', value: 'ROLE_ADMIN' },
  { name: 'EDITOR', value: 'ROLE_EDITOR' },
  { name: 'VIEWER / ANALYST', value: 'ROLE_VIEWER' },
];

const Users = () => {
  const { enqueueSnackbar } = useSnackbar();
  const users = useSelector(state => state.users);
  const dispatch = useDispatch();

  const [fetching, setFetching] = useState(false);
  const [roleFilter, setRoleFilter] = useState(getQueryParam('role', null));
  const [manageModal, setManageModal] = useState({
    user: null,
    state: false,
  });

  const getUsers = async isNext => {
    setFetching(true);
    try {
      const page = isNext ? users.number : 0;
      const params = {
        size: 100,
        page: isNext ? page + 1 : page,
        role: roleFilter,
      };
      const res = await Api.get('/users', { params });
      if (isNext) res.data.content = [...users.content, ...res.data.content];
      setFetching(false);
      dispatch(onSetUsers(res.data));
    } catch (err) {
      setFetching(false);
      enqueueSnackbar(getError(err), { variant: 'error' });
    }
  };

  const removeUser = async (id, index) => {
    try {
      await Api.delete(`/users/${id}`);
      const tempItems = [...users.content];
      tempItems.splice(index, 1);
      dispatch(onSetUsers({ ...users, content: tempItems }));
      enqueueSnackbar('Successfully removed.', { variant: 'success' });
      getUsers();
    } catch (err) {
      enqueueSnackbar(getError(err), { variant: 'error' });
    }
  };

  const onUserRemove = (id, index) => {
    const options = {
      onSuccess: removeUser.bind(null, id, index),
      message: `Are you sure you want to delete this user?`,
      confirm: 'Delete',
    };
    confirmBox(options);
  };

  const onUserEdit = user => setManageModal({ user, state: true });

  const onUserCreate = () => setManageModal({ user: null, state: true });

  const onModalClose = () => setManageModal({ user: null, state: false });

  useEffect(() => {
    getUsers();
    //eslint-disable-next-line
  }, [roleFilter]);

  return (
    <>
      <Header>
        <div className='d-flex align-items-center'>Users (Admins)</div>
      </Header>
      <main className='main-content'>
        <div className='px-3 bg-white pb-3'>
          <div className='bg-purple__light row px-4 py-3 d-flex align-items-center justify-content-between'>
            <div className='text-primary__light'>Admins</div>
          </div>
          <div className='inner-filter d-flex justify-content-between align-items-center pt-4 pb-1 px-2 mb-4'>
            {PermissionService.hasAccess('user_filters') && (
              <Tabs items={navTabs} value={roleFilter} onChange={setRoleFilter} />
            )}
            {PermissionService.hasSomeAccess(['user_create', 'channel_user_create']) && (
              <CreateButton name='INVITE' onClick={onUserCreate} />
            )}
          </div>
          {!fetching && (!users.content || !users.content.length) ? (
            <div className='text-center text-primary py-5'>There is no admins yet.</div>
          ) : fetching && !users.content ? (
            <StateHolder>
              <Loading />
            </StateHolder>
          ) : (
            <InfiniteScroll
              dataLength={(users.content && users.content.length) || 0}
              next={() => getUsers(true)}
              hasMore={users.content && users.content.length < users.total_elements}
              loader={<div className='text-center'>Loading...</div>}
              className='px-2'
            >
              {users.content && (
                <div>
                  <table className='table'>
                    <thead>
                      <tr>
                        <th className='border-0 text-primary'>
                          <span className='mnw-30 d-inline-block' />
                          Name
                        </th>
                        <th className='border-0 text-primary'>Email</th>
                        <th className='border-0 text-primary' colSpan='2'>
                          Role
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {users.content.map((item, index) => (
                        <tr key={item.id}>
                          <td>
                            <b className='mnw-30 d-inline-block'>{item.id}</b>
                            {item.name ?? '-'}
                          </td>
                          <td>{item.email ?? '-'}</td>
                          <td>
                            <span
                              className={
                                /owner/i.test(roleNames[item.role])
                                  ? 'badge-pill badge-primary'
                                  : ''
                              }
                            >
                              {roleNames[item.role] ?? '-'}
                            </span>
                          </td>
                          <td colSpan='2' className='text-right'>
                            {PermissionService.hasSomeAccess(['user_edit', 'channel_user_edit']) &&
                              item.role !== 'ROLE_SUPER_ADMIN' && (
                                <ActionButton type='edit' onClick={onUserEdit.bind(null, item)} />
                              )}
                            {PermissionService.hasSomeAccess([
                              'user_delete',
                              'channel_user_delete',
                            ]) &&
                              item.role !== 'ROLE_SUPER_ADMIN' && (
                                <ActionButton onClick={onUserRemove.bind(null, item.id, index)} />
                              )}
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              )}
            </InfiniteScroll>
          )}
        </div>
      </main>
      {manageModal.state && (
        <UserManageModal user={manageModal.user} onSuccess={getUsers} onClose={onModalClose} />
      )}
    </>
  );
};

export default Users;
