import React, { useState } from 'react';
import Header from 'shared/components/layouts/Header';
import CheckPhoneMTSModal from './components/CheckPhoneMTSModal';
import ClientInfoModal from './components/ClientInfoModal';
import { Api } from 'utils/connectors';
import InfiniteScroll from 'react-infinite-scroll-component';
import { useSnackbar } from 'notistack';
import { getError } from 'utils/appHelpers';
import SearchBox from 'shared/components/forms/SearchBox';
import Loading from 'shared/components/loadings/Loading';
import { formatDate } from 'utils/analyticsHelpers';
import ActionButton from 'shared/components/ActionButton';

const Clients = () => {
  const { enqueueSnackbar } = useSnackbar();
  const [phoneMTSPlanCheck, setPhoneMTSPlanCheck] = useState(false);
  const [clientIdInfo, setClientIdInfo] = useState(false);
  const [clients, setClients] = useState({});
  const [search, setSearch] = useState('');
  const [fetching, setFetching] = useState(false);

  const getClients = async isNext => {
    if (!search) return;
    try {
      setFetching(true);
      const page = isNext ? clients.number : 0;
      const params = { size: 15, page: isNext ? page + 1 : page };
      const res = await Api.post('/clients/search', { key: search }, { params });
      if (isNext) res.data.content = [...clients.content, ...res.data.content];
      setClients(res.data);
    } catch (err) {
      enqueueSnackbar(getError(err), { variant: 'error' });
    } finally {
      setFetching(false);
    }
  };

  const onItemViewHanlder = user => {
    setClientIdInfo(user.id);
  };

  return (
    <>
      <Header>
        <div className='d-flex align-items-center'>User Dashboard</div>
      </Header>
      <main className='main-content'>
        <div className='d-flex justify-content-between flex-column flex-md-row align-items-center mb-3'>
          <SearchBox
            onChange={getClients}
            search={search}
            setSearch={setSearch}
            className='col-md-3 col-12'
            disabled={fetching}
            debounceTime={1600}
          />
          <button
            className='btn btn-sm py-2 px-2 btn-primary'
            onClick={() => setPhoneMTSPlanCheck(true)}
          >
            Check eligibility for x/y subscription
          </button>
        </div>
        <div className='px-3 bg-white pb-3'>
          <div className='bg-purple__light row px-4 py-2 d-flex align-items-center justify-content-between'>
            <div className='text-primary__light'>Clients List</div>
          </div>
          {clients?.content ? (
            <InfiniteScroll
              dataLength={(clients.content && clients.content.length) || 0}
              next={() => getClients(true)}
              hasMore={clients.content && clients.content.length < clients.total_elements}
              loader={<div>Loading...</div>}
            >
              {
                <div>
                  <table className='table'>
                    <thead>
                      <tr>
                        <th className='border-0 text-primary w-40'>Id</th>
                        <th className='border-0 text-primary'>Name</th>
                        <th className='border-0 text-primary'>Email</th>
                        <th className='border-0 text-primary mxw-200'>Email Status</th>
                        <th className='border-0 text-primary'>Phone</th>
                        <th className='border-0 text-primary'>Gender</th>
                        <th className='border-0 text-primary'>DOB</th>
                        <th className='border-0 text-primary text-right'>Actions</th>
                      </tr>
                    </thead>
                    <tbody>
                      {clients.content.length
                        ? clients.content.map((item, index) => {
                            return (
                              <tr key={index}>
                                <td>{item.id}</td>
                                <td>{item.name}</td>
                                <td>{item.email}</td>
                                <td>{item.email_status}</td>
                                <td>{item.phone_number || '-'}</td>
                                <td>{item.gender || '-'}</td>
                                <td>{item.dob ? formatDate(item.dob, 'MMM DD, YYYY') : '-'}</td>
                                <td className='text-right'>
                                  <ActionButton
                                    type='view'
                                    onClick={onItemViewHanlder.bind(null, item)}
                                  />
                                </td>
                              </tr>
                            );
                          })
                        : null}
                    </tbody>
                  </table>
                </div>
              }
            </InfiniteScroll>
          ) : fetching ? (
            <div className='text-center py-6'>
              <Loading />
            </div>
          ) : (
            <p className='mt-3 mb-0 text-center'>Please search client</p>
          )}
        </div>
      </main>
      {phoneMTSPlanCheck && <CheckPhoneMTSModal onClose={() => setPhoneMTSPlanCheck(false)} />}
      {clientIdInfo && <ClientInfoModal id={clientIdInfo} onClose={() => setClientIdInfo(false)} />}
    </>
  );
};

export default Clients;
