import React from 'react';
import Switcher from './forms/Switcher';
import IconDrag from 'assets/images/icons/dd_icon.svg';
import ActionButton from './ActionButton';
import { PermissionService } from 'services/PermissionService';

const ListEditBox = ({
  num,
  img,
  description,
  title,
  published,
  className,
  onSwitchChange,
  switchCheck,
  onItemRemove,
  onItemEdit,
  isDragging,
  disabled,
}) => {
  return (
    <div className={`list-edit-box d-flex justify-content-between ${className || ''}`}>
      <div className='d-flex align-items-center'>
        <span className='mr-3 mnw-20'>{isDragging ? <img src={IconDrag} alt='drag' /> : num}</span>
        <div className='d-flex'>
          <div className='img-area'>
            {img && (
              <img
                src={img}
                width='130'
                height='80'
                alt='img'
                className={`mr-3 object-fit__cover object-position__center rounded__3${
                  disabled ? ' op-5' : ''
                }`}
              />
            )}
          </div>
          <div>
            {!!title && <p className={`mb-1 ${disabled ? ' op-5' : ''}`}>{title}</p>}
            {!!description && <p className='text-sm text-primary__light mb-0'>{description}</p>}
          </div>
        </div>
      </div>
      <div className='actions d-flex align-items-center'>
        {onItemEdit && (
          <ActionButton
            className='mr-1'
            type='edit'
            onClick={onItemEdit}
            disabled={!PermissionService.hasAccess('media_edit')}
          />
        )}
        <ActionButton
          className='mr-2'
          type='remove'
          onClick={onItemRemove}
          disabled={!PermissionService.hasAccess('media_delete')}
        />
        {onSwitchChange && (
          <Switcher
            onSwitchChange={onSwitchChange}
            switchCheck={switchCheck}
            disabled={disabled || !PermissionService.hasAccess('media_edit')}
          />
        )}
      </div>
    </div>
  );
};
export default ListEditBox;
