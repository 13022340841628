import React, { useEffect } from 'react';
import { Switch, Route, BrowserRouter as Router } from 'react-router-dom';
import { connect, useSelector } from 'react-redux';
import { getAuthState } from './Auth/actions';
import Loading from 'shared/components/loadings/Loading';
import Auth from './Auth';
import Main from './Main';

const App = ({ getAuthState }) => {
  const isAuthenticated = useSelector(store => store.isAuthenticated);
  useEffect(() => {
    if (isAuthenticated === null) getAuthState();
  }, [getAuthState, isAuthenticated]);

  return (
    <Router basename={process.env.PUBLIC_URL}>
      {isAuthenticated !== null && (
        <Switch>
          <Route path='/' component={isAuthenticated ? Main : Auth} />
        </Switch>
      )}
      {isAuthenticated == null && <Loading className='w-100 mt-5' />}
    </Router>
  );
};

export default connect(
  null,
  { getAuthState },
)(App);
