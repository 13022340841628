import { continents, getSortedWithContinents } from 'configs';
import React, { useState } from 'react';
import CheckBox from 'shared/components/forms/CheckBox';

const PlanCountryForm = ({ form, setForm, onClose, allCountries }) => {
  const [localState, setLocalState] = useState([...(form.blocked_countries || [])]);
  const sortedData = getSortedWithContinents(allCountries);

  const onSubmit = () => {
    setForm({ ...form, blocked_countries: [...localState] });
    onClose();
  };

  const onCountryChange = key => {
    const list = [...localState];
    if (localState?.includes(key)) {
      list.splice(list.indexOf(key), 1);
    } else {
      list.push(key);
    }
    setLocalState(list);
  };

  const onSelectAllToggle = bool => {
    if (!bool) {
      setLocalState([...allCountries.map(i => i.key)]);
    } else {
      setLocalState([]);
    }
  };

  const onContinentChange = (key, bool) => {
    const allItems = [...allCountries].filter(i => i.region === key);
    let tempList = [...localState];
    if (!bool) {
      tempList = [...new Set([...localState, ...allItems.map(i => i.key)])];
    } else {
      tempList = tempList.filter(i => !allItems.find(c => c.key === i));
    }
    setLocalState(tempList);
  };

  const isContinentSelected = key => {
    const allItems = [...allCountries].filter(i => i.region === key);
    const selected = [...allItems].filter(i => localState.includes(i.key));
    const indeterminate = selected.length && allItems.length !== selected.length;
    const checked = indeterminate || !selected.length;
    return { checked, indeterminate };
  };

  const isAllIndeterminate = localState.length && allCountries.length !== localState.length;
  const isAllChecked = isAllIndeterminate || !localState.length;

  return (
    <>
      <div className='modal-title bg-purple__light row px-4 py-3'>
        <div className='text-primary__light'>
          Country or Region Available ({allCountries.length})
        </div>
      </div>
      <div className='d-flex flex-column align-items-center h-100'>
        <div className='d-flex flex-column h-100 justify-content-between pt-3 w-100'>
          <div className='modal-scroll-content countries-list'>
            <div className='col-12 pb-5 px-5'>
              <CheckBox
                name={'select_all'}
                checked={isAllChecked}
                onChange={onSelectAllToggle.bind(null, !isAllChecked)}
                label={'Select All Countries'}
                labelClassName='is-small fw-500'
                className='d-flex align-items-center'
                indeterminate={isAllIndeterminate}
              />
            </div>
            {Object.keys(sortedData).map(item => {
              const { checked, indeterminate } = isContinentSelected(item);
              return (
                <div key={item}>
                  <div className='col-12 border-top px-5'>
                    <div className='continent-item'>
                      <CheckBox
                        name={item}
                        checked={checked}
                        onChange={onContinentChange.bind(null, item, !checked)}
                        label={continents[item]}
                        labelClassName='is-small'
                        className='d-flex align-items-center'
                        indeterminate={indeterminate}
                      />
                    </div>
                  </div>
                  <div className='d-flex flex-wrap px-10'>
                    {sortedData[item].map(country => {
                      return (
                        <div className='col-4 px-1 country-item' key={country.key}>
                          <CheckBox
                            name={country.key}
                            checked={!localState?.includes(country.key)}
                            onChange={onCountryChange.bind(null, country.key)}
                            label={country.name}
                            labelClassName='is-small'
                            className='d-flex align-items-center'
                          />
                        </div>
                      );
                    })}
                  </div>
                </div>
              );
            })}
          </div>
          <div className='text-right mt-4'>
            <button
              className='btn btn-outline-primary text-md btn-sm btn-crud weight-400 mx-2'
              onClick={onClose}
            >
              Cancel
            </button>
            <button
              className='btn btn-primary btn-sm btn-crud text-md weight-400 ml-1'
              onClick={onSubmit}
              type='button'
            >
              Done
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default PlanCountryForm;
