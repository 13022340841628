import React, { useState, useEffect } from 'react';
import { Line } from 'react-chartjs-2';
import ItemsList from './ItemsList';
import { bindDurationHours, duplicate, formatNumber } from 'utils/appHelpers';
import { formatDate, getSuggestetMax, modifyChartVerticalAxis } from 'utils/analyticsHelpers';
import NoChartData from './NoChartData';
import Loading from 'shared/components/loadings/Loading';

const defaultDescription = '* Total number of views when a video is watched at least 120 seconds';

const LineChart = ({
  data,
  biggestValue,
  unit,
  chartOptions,
  chartHolderClass,
  description = defaultDescription,
  hideOtherInfos = false,
  fetching,
}) => {
  const [chartData, setChartData] = useState(data);
  const [ignoreIndexes, setIgnoreIndexes] = useState([]);
  const [showAll, setShowAll] = useState(false);

  const isPricing = unit === '$';
  const isWatchtime = unit === 'mins';

  const options = {
    scales: {
      y: {
        suggestedMax: getSuggestetMax(biggestValue),
        ticks: {
          beginAtZero: true,
          callback: value => modifyChartVerticalAxis(value, isWatchtime, isPricing),
        },
      },
      x: {
        ticks: {
          autoSkipPadding: 20,
          callback: index => formatDate(data.labels[index], 'DD/MM'),
        },
      },
    },
    elements: {
      line: {
        tension: 0.4,
      },
      point: {
        radius: 6,
        pointHoverRadius: 6,
      },
    },
    plugins: {
      legend: {
        display: false,
      },
      tooltip: {
        displayColors: false,
        titleFont: {
          size: '16px',
          lineHeight: '24px',
        },
        callbacks: {
          titleFontColor: '#0066ff',
          bodyFontColor: '#000',
          bodyFontSize: 14,
          title: a => {
            const [tooltipItem] = a;
            let value = tooltipItem.raw;
            if (isWatchtime) value = bindDurationHours(+value);
            return isPricing
              ? `${unit}${formatNumber(value)}`
              : isWatchtime
              ? value
              : `${formatNumber(value)} ${unit}`;
          },
          label: ({ label }) => formatDate(label, 'MMM DD, YYYY'),
        },
      },
      datalabels: {
        display: false,
      },
    },
    ...chartOptions,
  };

  const filterSetIds = index => {
    let newIndexes = duplicate(ignoreIndexes);
    if (newIndexes.includes(index)) {
      newIndexes = newIndexes.filter(v => v !== index);
    } else {
      newIndexes.push(index);
    }
    setIgnoreIndexes(newIndexes);
  };

  const filterData = () => {
    const newData = duplicate(data);
    newData.datasets = newData.datasets.filter((v, i) => !ignoreIndexes.includes(i));
    setChartData(newData);
  };

  useEffect(() => {
    filterData();
  }, [ignoreIndexes]);

  useEffect(() => {
    setChartData(data);
  }, [data]);

  if (fetching)
    return (
      <div className='text-center py-6'>
        <Loading />
      </div>
    );

  return (
    <>
      {data.labels?.length ? (
        <>
          <div className={chartHolderClass}>
            <Line data={chartData} options={options} />
          </div>
          <ItemsList
            data={data.datasets}
            ignoreItems={ignoreIndexes}
            onChange={index => filterSetIds(index)}
            showAll={showAll}
            unit={unit}
          />
          {!hideOtherInfos && (
            <>
              <hr className='mt-3 mb-4' />
              <button
                className='btn btn-outline-primary btn-sm show-more-btn'
                onClick={() => setShowAll(!showAll)}
                disabled={data.datasets.length <= 5}
              >
                Show {showAll ? 'Less' : 'More'}
              </button>
              <p className='mt-6 mb-0 text-light text-sm'>{description}</p>
            </>
          )}
        </>
      ) : (
        <NoChartData />
      )}
    </>
  );
};

LineChart.defaultProps = {
  chartHolderClass: '',
  chartOptions: {},
};

export default LineChart;
