import React, { useEffect, useState } from 'react';
import { useSnackbar } from 'notistack';
import { useDispatch, useSelector } from 'react-redux';
import { Api } from 'utils/connectors';
import { confirmBox, downloadFile, getError } from 'utils/appHelpers';
import { onProductsSet, onPromosSet, setTotalValue } from 'app/Main/actions';
import { PermissionService } from 'services/PermissionService';
import Header from 'shared/components/layouts/Header';
import InfiniteScroll from 'react-infinite-scroll-component';
import ActionButton from 'shared/components/ActionButton';
import Loading from 'shared/components/loadings/Loading';
import PromoManageModal from './components/PromoManageModal';
import { sortDataWithKey } from 'utils/sortingHelper';
import { formatDate } from 'utils/analyticsHelpers';

const Promos = ({ location }) => {
  const productsTypes = useSelector(state => state.products);
  const products = [
    ...(productsTypes?.consumable?.content || []),
    ...(productsTypes?.renewable?.content || []),
  ];
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch();
  const promos = useSelector(state => state.promos) || {};
  const [manageModal, setManageModal] = useState({
    state: !!location.state?.form,
    promo: null,
  });

  const getData = async isNext => {
    try {
      const page = isNext ? promos.number : 0;
      const res = await Api.get('/promo-codes', {
        params: {
          size: 10,
          page: isNext ? page + 1 : page,
        },
      });
      if (isNext) res.data = [...promos.content, ...res.data];
      res.data = sortDataWithKey(res.data);
      dispatch(onPromosSet({ content: res.data, total_elements: res.data.length }));
      dispatch(setTotalValue('all_promos', res.data.length));
    } catch (err) {
      enqueueSnackbar(getError(err), { variant: 'error' });
    }
  };

  const getProducts = async productType => {
    try {
      const res = await Api.get('/products', {
        params: { type: String(productType).toUpperCase(), size: 1000, page: 0 },
      });
      res.data.content = sortDataWithKey(res.data.content);
      dispatch(onProductsSet({ [productType]: res.data }));
      dispatch(setTotalValue(`all_products_${productType}`, res.data.total_elements));
    } catch (err) {
      enqueueSnackbar(getError(err), { variant: 'error' });
    }
  };

  const onDownloadCodes = async item => {
    try {
      const data = await Api.get(`/promo-codes/${item.uuid}`);
      downloadFile(`${item.name}_codes.txt`, data.data);
    } catch (err) {
      enqueueSnackbar(getError(err), { variant: 'error' });
    }
  };

  const removeItem = async (id, index) => {
    try {
      await Api.delete(`/promo-codes/${id}`);
      const tempItems = [...promos.content];
      tempItems.splice(index, 1);
      dispatch(onPromosSet({ ...promos, content: tempItems }));
      enqueueSnackbar('Successfully done', { variant: 'success' });
      getData();
    } catch (err) {
      enqueueSnackbar(getError(err), { variant: 'error' });
    }
  };

  const onItemRemove = (item, index) => {
    const options = {
      onSuccess: removeItem.bind(null, item.uuid, index),
      message: `Are you sure you want to delete current promo code?`,
      confirm: 'Delete',
    };
    confirmBox(options);
  };

  const onItemEdit = e => {
    e.start_date = formatDate(e.start_date);
    e.end_date = formatDate(e.end_date);
    e.count = e.total_count;
    setManageModal({ promo: e, state: true });
  };

  const onItemCreate = () => {
    setManageModal({ promo: null, state: true });
  };

  const onModalClose = () => {
    setManageModal({ promo: null, state: false });
  };

  useEffect(() => {
    getData();
    getProducts('renewable');
    getProducts('consumable');
    //eslint-disable-next-line
  }, []);

  return (
    <>
      <Header>
        <div className='d-flex align-items-center'>Promo Codes</div>
      </Header>
      <main className='main-content'>
        <div className='px-3 bg-white pb-3'>
          <div className='bg-purple__light row px-4 py-2 d-flex align-items-center justify-content-between'>
            <div className='text-primary__light'>Created Promo Codes</div>
            <button
              className='btn btn-sm btn-primary btn-crud'
              onClick={onItemCreate}
              disabled={!PermissionService.hasAccess('promos_create')}
            >
              Create New
            </button>
          </div>
          {promos?.content ? (
            <InfiniteScroll
              dataLength={(promos.content && promos.content.length) || 0}
              next={() => getData(true)}
              hasMore={promos.content && promos.content.length < promos.total_elements}
              loader={<div>Loading...</div>}
            >
              {
                <div>
                  <table className='table'>
                    <thead>
                      <tr>
                        <th className='border-0 text-primary w-40'>#</th>
                        <th className='border-0 text-primary'>Name</th>
                        <th className='border-0 text-primary'>Product</th>
                        <th className='border-0 text-primary'>Start Date</th>
                        <th className='border-0 text-primary'>End Date</th>
                        <th className='border-0 text-primary text-center'>Quantity</th>
                        <th className='border-0 text-primary text-center'>Activated (quantity)</th>
                        <th className='border-0 text-primary text-right w-78' />
                      </tr>
                    </thead>
                    <tbody>
                      {promos.content.length
                        ? promos.content.map((item, index) => {
                            const activeProduct = products?.find(
                              i => i.id === item.in_app_product_id,
                            );

                            return (
                              <tr key={index}>
                                <td>{index + 1}</td>
                                <td>{item.name || '-'}</td>
                                <td>
                                  {activeProduct ? activeProduct.name : item.in_app_product_id}
                                </td>
                                <td>
                                  {item.start_date ? formatDate(item.start_date) : 'No Start Date'}
                                </td>
                                <td>
                                  {item.end_date ? formatDate(item.end_date) : 'No Expiration'}
                                </td>
                                <td className='text-center'>{item.total_count || '0'}</td>
                                <td className='text-center'>{item.used_count || '0'}</td>
                                <td>
                                  <div className='text-right'>
                                    <ActionButton
                                      className='mr-1'
                                      type='edit'
                                      onClick={onItemEdit.bind(null, item)}
                                      disabled={!PermissionService.hasAccess('promos_edit')}
                                    />
                                    <ActionButton
                                      onClick={onItemRemove.bind(null, item, index)}
                                      disabled={
                                        !PermissionService.hasAccess('promos_delete') ||
                                        item.used_count
                                      }
                                    />
                                    <ActionButton
                                      type='refresh'
                                      onClick={onDownloadCodes.bind(null, item)}
                                      disabled={!PermissionService.hasAccess('promos_view')}
                                    />
                                  </div>
                                </td>
                              </tr>
                            );
                          })
                        : null}
                    </tbody>
                  </table>
                </div>
              }
            </InfiniteScroll>
          ) : (
            <div className='text-center py-6'>
              <Loading />
            </div>
          )}
        </div>
      </main>
      {manageModal.state && (
        <PromoManageModal state={manageModal} onSuccess={getData} onClose={onModalClose} />
      )}
    </>
  );
};

export default Promos;
