import React from 'react';
import InputGroup from 'shared/components/forms/InputGroup';

const FrequencyContainer = ({ title, description, value = {}, errors = {}, onChange }) => {
  return (
    <div className='row border-bottom mb-3 mt-1'>
      <div className='col-4 mr-5 p-0'>
        <h6>{title}</h6>
        <p className='text-secondary small'>{description}</p>
      </div>
      <div className='col-7'>
        <InputGroup
          type='number'
          name='ad_config_detail.ad_frequency'
          value={value}
          onChange={onChange}
          className='col-6 p-0'
          errorText={errors[`ad_config_detail.ad_frequency`]}
        />
      </div>
    </div>
  );
};

export default FrequencyContainer;
