import React, { useEffect, useState } from 'react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import TextAreaGroup from '../forms/TextAreaGroup';

const colors = [
  '#040217',
  '#FFFFFF',
  '#FDD100',
  '#F8324D',
  '#18181A',
  '#020A17',
  '#7C92B3',
  '#CFE1FB',
  '#B8D1F9',
  '#ACCAF8',
  '#E7F0FD',
  '#15181F',
  '#03152F',
  '#E5E6EF',
  '#575757',
  '#BEBEBE',
  '#DDDDDD',
  '#feed00',
  '#30396d',
  '#6883A9',
];

const modules = {
  toolbar: [
    [{ header: '1' }, { header: '2' }],
    [{ size: [] }],
    [
      { color: [...colors] },
      { background: [...colors] },
      'bold',
      'italic',
      'underline',
      'strike',
      'blockquote',
    ],
    [{ list: 'ordered' }, { list: 'bullet' }, { indent: '-1' }, { indent: '+1' }],
    ['link'],
    ['clean'],
  ],
  clipboard: {
    // toggle to add extra line breaks when pasting HTML:
    matchVisual: false,
  },
};
/*
 * Quill editor formats
 * See https://quilljs.com/docs/formats/
 */
const formats = [
  'header',
  'size',
  'color',
  'background',
  'bold',
  'italic',
  'underline',
  'strike',
  'blockquote',
  'list',
  'bullet',
  'indent',
  'link',
];

const TextEditor = ({ initialValue = '', onSubmit, disabled, readOnly }) => {
  const [value, setValue] = useState(initialValue);
  const [simpleEditorMode, setSimpleEditorMode] = useState(false);
  const [isLigth, setIsLight] = useState(true);
  const [styleData, setStyleData] = useState('');
  const [showHtml, setShowHtml] = useState(false);

  const onDataSubmit = () => {
    if (value.indexOf(`</style>`) > 0) {
      onSubmit(value);
    } else {
      onSubmit(`${styleData} \n ${value}`);
    }
  };

  const onToggleEditorLightDark = () => {
    if (document.querySelector('.ql-editor')) {
      document.querySelector('.ql-editor').style.background = isLigth ? '#020A17' : '#fff';
    }
    if (document.querySelector('.html-view')) {
      document.querySelector('.html-view').style.background = isLigth ? '#020A17' : '#fff';
    }
    setIsLight(!isLigth);
  };

  useEffect(() => {
    if (initialValue) {
      const styleIndex = initialValue.indexOf(`</style>`) + 8;
      const style = initialValue.slice(0, styleIndex);
      setStyleData(style);
      setValue(initialValue);
    }
  }, [initialValue, simpleEditorMode]);

  return (
    <>
      <div className='col-md-12 p-0 mx-auto flex-fill d-flex flex-column'>
        <div className='d-flex'>
          <button
            className={`btn btn-sm mb-2 ${
              simpleEditorMode ? 'btn-primary' : 'btn-outline-primary'
            }`}
            onClick={() => setSimpleEditorMode(!simpleEditorMode)}
          >
            Show HTML code
          </button>
          <button
            className={`btn btn-sm mb-2 ml-2 ${!isLigth ? 'btn-primary' : 'btn-outline-primary'}`}
            onClick={onToggleEditorLightDark}
          >
            Show With Dark
          </button>
          <button
            className={`btn btn-sm mb-2 ml-2 ${showHtml ? 'btn-primary' : 'btn-outline-primary'}`}
            onClick={() => setShowHtml(!showHtml)}
          >
            Show UI
          </button>
        </div>
        {!showHtml && (
          <>
            {!simpleEditorMode && (
              <ReactQuill
                theme='snow'
                value={value}
                modules={modules}
                formats={formats}
                onChange={setValue}
              />
            )}
            {simpleEditorMode && (
              <TextAreaGroup
                name='description'
                value={value}
                rows='20'
                onChange={e => setValue(e.target.value)}
              />
            )}
          </>
        )}
      </div>
      {showHtml && <div className='html-view' dangerouslySetInnerHTML={{ __html: value }} />}
      {!readOnly && (
        <div className='position-sticky l-0 r-0 b-0 z-index-1 bg-white py-3'>
          <div className='container d-flex justify-content-center'>
            <button
              className='btn btn-primary weight-400'
              onClick={onDataSubmit}
              disabled={disabled}
            >
              Save
            </button>
          </div>
        </div>
      )}
    </>
  );
};

export default TextEditor;
