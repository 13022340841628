import React, { useEffect, useState } from 'react';
import { useSnackbar } from 'notistack';
import { useDispatch, useSelector } from 'react-redux';
import { onSetConfigurations } from 'app/Main/actions';
import { Api } from 'utils/connectors';
import { getError } from 'utils/appHelpers';
import Header from 'shared/components/layouts/Header';
import InfiniteScroll from 'react-infinite-scroll-component';
import ConfigurationManageModal from './components/ConfigurationManageModal';
import ActionButton from 'shared/components/ActionButton';
import Loading from 'shared/components/loadings/Loading';
import { PermissionService } from 'services/PermissionService';

const Configurations = () => {
  const { enqueueSnackbar } = useSnackbar();
  const configurations = useSelector(state => state.configurations);
  const dispatch = useDispatch();
  const [manageModal, setManageModal] = useState({ state: false });

  const getConfigurations = async () => {
    try {
      const { data } = await Api.get('/configs');
      const { content } = data;
      dispatch(onSetConfigurations(content));
    } catch (err) {
      enqueueSnackbar(getError(err), { variant: 'error' });
    }
  };

  const onConfigurationsEdit = id => {
    setManageModal({ data: configurations[id], state: true });
  };

  const onModalClose = () => {
    getConfigurations();
    setManageModal({ data: null, state: false });
  };

  useEffect(() => {
    getConfigurations();
  }, []);

  return (
    <>
      <Header>
        <div className='d-flex align-items-center'>Configurations</div>
      </Header>
      <main className='main-content'>
        <div className='px-3 bg-white pb-3'>
          <div className='bg-purple__light row px-4 py-3 d-flex align-items-center justify-content-between'>
            <div className='text-primary__light'>Configurations</div>
          </div>
          {configurations ? (
            <InfiniteScroll dataLength={0}>
              {
                <div>
                  <table className='table ads-table'>
                    <thead>
                      <tr>
                        <th className='border-0 text-primary'>Key</th>
                        <th className='border-0 text-primary'>Value</th>
                        <th className='border-0 text-primary'>Description</th>
                      </tr>
                    </thead>
                    <tbody>
                      {configurations.length
                        ? configurations.map((item, index) => {
                            return (
                              <tr key={index}>
                                <td title={`${item.key}`}>{item.key}</td>
                                <td title={`${item.value}`}>{item.value}</td>
                                <td title={`${item.description}`}>{item.description}</td>
                                <td>
                                  <div className='text-right'>
                                    <ActionButton
                                      type='edit'
                                      disabled={!PermissionService.hasAccess('configurations_edit')}
                                      onClick={() => onConfigurationsEdit(index)}
                                    />
                                  </div>
                                </td>
                              </tr>
                            );
                          })
                        : null}
                    </tbody>
                  </table>
                </div>
              }
            </InfiniteScroll>
          ) : (
            <div className='text-center py-6'>
              <Loading />
            </div>
          )}
        </div>
      </main>
      {manageModal.state && (
        <ConfigurationManageModal initialConfigurations={manageModal.data} onClose={onModalClose} />
      )}
    </>
  );
};

export default Configurations;
