import React, { useEffect, useState } from 'react';
import { useSnackbar } from 'notistack';
import { useDispatch, useSelector } from 'react-redux';
import { onSetAds } from 'app/Main/actions';
import { Api } from 'utils/connectors';
import { confirmBox, getError } from 'utils/appHelpers';
import InfiniteScroll from 'react-infinite-scroll-component';
import AdManageModal from './components/AdManageModal';
import CreateSpecialAddModal from './components/CreateSpecialAddModal';
import ActionButton from 'shared/components/ActionButton';
import Loading from 'shared/components/loadings/Loading';
import { useHistory } from 'react-router';

const AdsManager = () => {
  const { enqueueSnackbar } = useSnackbar();
  const { push } = useHistory();
  const data = useSelector(state => state.ads);
  const [isSpecialAdd, setIsSpecialAdd] = useState(false);
  const dispatch = useDispatch();

  const getAds = async () => {
    try {
      const { data } = await Api.get('/ad-configs');
      dispatch(onSetAds(data));
    } catch (err) {
      enqueueSnackbar(getError(err), { variant: 'error' });
    }
  };

  const removeItem = async id => {
    try {
      await Api.delete(`/ad-configs/${id}`);
      getAds();
      enqueueSnackbar('Successfully done', { variant: 'success' });
    } catch (err) {
      enqueueSnackbar(getError(err), { variant: 'error' });
    }
  };

  const onAdsRemove = id => {
    const options = {
      onSuccess: removeItem.bind(null, id),
      message: `Are you sure you want to delete current ad ?`,
      confirm: 'Delete',
    };
    confirmBox(options);
  };

  useEffect(() => {
    getAds();
  }, []);

  return (
    <>
      <div className='text-right mb-2'>
        <button
          type='button'
          className='btn btn-sm btn-primary btn-crud'
          onClick={() => {
            push(`/ads/list/${data.length + 1}`);
            setIsSpecialAdd(true);
          }}
        >
          Create Special Add
        </button>
      </div>
      <div className='px-3 bg-white pb-3'>
        <div className='bg-purple__light row px-4 py-3 d-flex align-items-center justify-content-between'>
          <div className='text-primary__light'>Ads List</div>
        </div>
        {data.length > 0 ? (
          <InfiniteScroll dataLength={0}>
            {
              <div>
                <table className='table ads-table-new table-hover'>
                  <thead>
                    <tr>
                      <th className='border-0 text-primary w-40'>#</th>
                      <th className='border-0 text-primary'>Name</th>
                      <th className='border-0 text-primary'>PreRoll</th>
                      <th className='border-0 text-primary'>MidRoll</th>
                      <th className='border-0 text-primary'>EndRoll</th>
                      <th className='border-0 text-primary w-140'>Frequency</th>
                      <th className='border-0 text-primary w-140'>Location</th>
                      <th className='border-0 text-primary text-right w-78'>Actions</th>
                    </tr>
                  </thead>
                  <tbody>
                    {data.map((group, groupIndex) => {
                      const details = group?.ad_config_detail || {};
                      const locations = Object.keys(group?.country_based_ad_configs || {});
                      const isGlobal = group?.global;
                      return (
                        <tr key={groupIndex}>
                          <td>{groupIndex + 1}</td>
                          <td>{group.name}</td>
                          <td className='pl-2'>
                            <div>
                              <span className='text-ellipsis mw-100 badge badge-pill'>
                                System: {details.preroll?.ad_system_type}
                              </span>
                            </div>
                            <div>
                              <span className='text-ellipsis mw-100 badge badge-pill'>
                                Count: {details.preroll?.max_count}
                              </span>
                            </div>
                          </td>
                          <td className='pl-2'>
                            <div>
                              <span className='text-ellipsis mw-100 badge badge-pill'>
                                System: {details.midroll?.ad_system_type}
                              </span>
                            </div>
                            <div>
                              <span className='text-ellipsis mw-100 badge badge-pill'>
                                Count: {details.midroll?.max_count}
                              </span>
                            </div>
                          </td>
                          <td className='pl-2'>
                            <div>
                              <span className='text-ellipsis mw-100 badge badge-pill'>
                                System: {details.endroll?.ad_system_type}
                              </span>
                            </div>
                            <div>
                              <span className='text-ellipsis mw-100 badge badge-pill'>
                                Count: {details.endroll?.max_count}
                              </span>
                            </div>
                          </td>
                          <td className='pl-2'>
                            <div>
                              <span className='text-ellipsis mw-100 badge badge-pill'>
                                Second: {details.ad_frequency}
                              </span>
                            </div>
                          </td>
                          <td className='pl-1'>
                            {locations
                              ? locations.map(loc => {
                                  if (loc === 'additionalProp3') return null;
                                  return (
                                    <span
                                      className='mx-1 text-ellipsis mw-100 text-uppercase badge badge-pill'
                                      key={loc}
                                    >
                                      {loc}
                                    </span>
                                  );
                                })
                              : null}
                          </td>
                          <td className='pl-2'>
                            <div className='d-flex justify-content-end '>
                              {!isGlobal && (
                                <ActionButton
                                  type='remove'
                                  onClick={onAdsRemove.bind(null, group.id, groupIndex)}
                                />
                              )}
                              <ActionButton
                                type='edit'
                                onClick={() => push(`/ads/list/${groupIndex + 1}`)}
                              />
                            </div>
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>
            }
          </InfiniteScroll>
        ) : (
          <div className='text-center py-6'>
            <Loading />
          </div>
        )}
      </div>
      <AdManageModal getAds={getAds} isSpecialAdd={isSpecialAdd} />
      {isSpecialAdd && <CreateSpecialAddModal getAds={getAds} setIsSpecialAdd={setIsSpecialAdd} />}
    </>
  );
};

export default AdsManager;
