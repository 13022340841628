import React, { useEffect, useState } from 'react';
import InputGroup from 'shared/components/forms/InputGroup';
import Modal from 'shared/components/modals/Modal';
import { Api } from 'utils/connectors';
import IconDrag from 'assets/images/icons/dd_icon.svg';
import DragList from 'shared/components/layouts/DragList';
import { confirmBox, reorder, getError } from 'utils/appHelpers';
import LangToggle from 'shared/components/LangToggle';
import { useSelector, useDispatch } from 'react-redux';
import { onSetCategories } from 'app/Main/actions';
import { useSnackbar } from 'notistack';
import ActionButton from 'shared/components/ActionButton';

const setCtgNames = (names, data, lang) => {
  const result = { ...names };
  data[lang].forEach(ctg => {
    result[lang] = result[lang] || {};
    result[lang][ctg.id] = ctg.name;
  });
  return result;
};

const CategoriesManageModal = ({ onClose, onSuccess, lang, setLang }) => {
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch();
  const categories = useSelector(state => state.categories);
  const [fetching, setFetching] = useState(false);
  const [name, setName] = useState('');
  const [tempNames, setTempNames] = useState({
    en: {},
    hy: {},
    ru: {},
  });
  const [editIndex, setEditIndex] = useState(null);

  const currentCategories = (categories && categories[lang]) || [];

  const onFormSubmit = async e => {
    e.preventDefault();
    try {
      setFetching(true);
      await Api.post(`/home/categories/types?locale=${lang}`, { name });
      if (onSuccess) await onSuccess();
      enqueueSnackbar('Successfully done', { variant: 'success' });
      setName('');
      setFetching(false);
    } catch (err) {
      enqueueSnackbar(getError(err), { variant: 'error' });
      setFetching(false);
    }
  };

  const onDragEndCategories = async e => {
    if (!e.destination) return;
    try {
      const tempCategories = { ...categories };
      tempCategories[lang] = reorder(tempCategories[lang], e.source.index, e.destination.index);
      dispatch(onSetCategories(tempCategories));
      await Api.put(`/home/categories/${e.draggableId}/types/reorder?order=${e.destination.index}`);
    } catch (err) {
      enqueueSnackbar(getError(err), { variant: 'error' });
    }
  };

  const onItemRemove = id => {
    const removeItem = async () => {
      try {
        await Api.delete(`/home/categories/${id}/types`);
        const tempCategories = { ...categories };
        tempCategories[lang] = tempCategories[lang].filter(i => i.id !== id);
        dispatch(onSetCategories(tempCategories));
        if (onSuccess) onSuccess();
        enqueueSnackbar('Successfully done', { variant: 'success' });
      } catch (err) {
        enqueueSnackbar(getError(err), { variant: 'error' });
      }
    };

    const options = {
      onSuccess: removeItem,
      message: `Are you sure you want to delete this item ?`,
      confirm: 'Delete',
    };
    confirmBox(options);
  };

  const onItemSave = async id => {
    setEditIndex(editIndex === id ? null : id);
    if (editIndex !== id) return;
    try {
      await Api.put(`/home/categories/${id}/types?locale=${lang}`, {
        id,
        name: tempNames[lang][id],
      });
      if (onSuccess) onSuccess();
      enqueueSnackbar('Successfully done', { variant: 'success' });
    } catch (err) {
      enqueueSnackbar(getError(err), { variant: 'error' });
    }
  };

  useEffect(() => {
    setCtgNames(tempNames, categories, lang);
    //eslint-disable-next-line
  }, [categories]);

  return (
    <Modal size='sm' onClose={onClose} className='mx-2 mt-2'>
      <div className='modal-title bg-purple__light row px-4 py-3 mb-6'>
        <div className='text-primary__light'>Manage Categories</div>
      </div>
      <div className='categories-modal'>
        <div className='d-flex justify-content-start mb-2'>
          <LangToggle lang={lang} setLang={setLang} />
        </div>
        <form onSubmit={onFormSubmit} className='pt-3 d-flex align-items-start'>
          <InputGroup
            type='text'
            name='name'
            value={name}
            placeholder={'Name'}
            onChange={({ target }) => setName(target.value)}
            required
            containerClass='flex-fill add-input'
          />
          <button className='btn btn-primary text-nowrap add-btn' disabled={fetching} type='submit'>
            Add new
          </button>
        </form>
        <div className='categories-liest'>
          <DragList onDragEnd={onDragEndCategories} items={currentCategories}>
            {(item, index, snapshot) => {
              return (
                <div
                  className={`border__bottom d-flex justify-content-between align-items-center pb-3 border__${
                    editIndex === item.id ? 'primary' : 'default'
                  }`}
                  key={item.id}
                >
                  <div>
                    <ActionButton
                      className='mr-2'
                      type='edit'
                      onClick={onItemSave.bind(null, item.id)}
                      src={snapshot.isDragging ? IconDrag : false}
                    />
                  </div>
                  <div className='flex-fill'>
                    {editIndex === item.id ? (
                      <InputGroup
                        type='text'
                        name='name'
                        value={tempNames[lang][item.id] || item.name}
                        onChange={({ target }) =>
                          setTempNames({
                            ...tempNames,
                            [lang]: { ...tempNames[lang], [item.id]: target.value },
                          })
                        }
                        containerClass='mb-0 ctg-input'
                      />
                    ) : (
                      <span>{tempNames[lang][item.id] || item.name}</span>
                    )}
                  </div>
                  <div>
                    <ActionButton onClick={onItemRemove.bind(null, item.id, index)} />
                  </div>
                </div>
              );
            }}
          </DragList>
        </div>
        <div className='mt-3'>
          <button
            className='btn btn-outline-primary text-md py-2'
            onClick={() => {
              if (onSuccess) onSuccess();
              onClose();
            }}
          >
            Close
          </button>
        </div>
      </div>
    </Modal>
  );
};

export default CategoriesManageModal;
