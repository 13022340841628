import React, { useState } from 'react';
import { useSnackbar } from 'notistack';
import { Api } from 'utils/connectors';
import { getError, getImageUrl, hasImageType, imageUploadProgress } from 'utils/appHelpers';
import UploadZone from 'shared/components/UploadZone';
import ActionButton from 'shared/components/ActionButton';
import UpdateImageUpload from 'shared/components/UpdateImageUpload';
import UploadProgress from 'shared/components/UploadProgress';
import { PermissionService } from 'services/PermissionService';
import { MAX_SIZES } from 'configs/sizes';

const posterImages = [
  { name: 'Thumbnail', type: 'THUMBNAIL', maxSize: MAX_SIZES.THUMBNAIL },
  { name: 'Detail Mobile', type: 'DETAIL_MOBILE', maxSize: MAX_SIZES.DETAIL_MOBILE },
  { name: 'Detail Tablet', type: 'DETAIL_TABLET', maxSize: MAX_SIZES.DETAIL_TABLET },
  { name: 'Detail TV', type: 'DETAIL_TV', access: 'media_create', maxSize: MAX_SIZES.DETAIL_TV },
];

const ImagesTab = ({ media, updateMovieStore }) => {
  const [imageProgress, setImageProgress] = useState({});
  const { enqueueSnackbar } = useSnackbar();

  const removePosterImage = async image => {
    try {
      await Api.delete(`tv-shows/${media.id}/images?imageType=${image.type}`);
      const tempMedia = { ...media };
      tempMedia.image.types = tempMedia.image.types.filter(item => item.type !== image.type);
      updateMovieStore(tempMedia);
    } catch (err) {
      enqueueSnackbar(getError(err), { variant: 'error' });
    }
  };

  const onUploadFile = async (image, file, status) => {
    if (status?.[0]?.errors?.[0]?.code === 'file-too-large') {
      enqueueSnackbar(status?.[0]?.errors?.[0]?.message, { variant: 'error' });
      return;
    }
    if (!file.length) return;
    const config = {
      onUploadProgress: imageUploadProgress.bind(null, setImageProgress, image.type),
    };

    try {
      setImageProgress({ ...imageProgress, [image.type]: 1 });
      const form = new FormData();
      form.append('image', file[0]);
      const { data } = await Api.post(
        `tv-shows/${media.id}/images?imageType=${image.type}`,
        form,
        config,
      );
      updateMovieStore(data);
    } catch (err) {
      enqueueSnackbar(getError(err), { variant: 'error' });
    } finally {
      setImageProgress({ ...imageProgress, [image.type]: false });
    }
  };

  return (
    <div className='images-area mt-3'>
      {posterImages.map((item, k) => {
        const hasImage = media && hasImageType(media.image, item.type);
        if (hasImage) {
          if (imageProgress[item.type]) {
            return <UploadProgress key={item.type} progress={imageProgress[item.type]} />;
          }
          return (
            <div key={item.type} className='mb-3 d-flex align-items-center justify-content-between'>
              <div>
                {k + 1}
                <img
                  width='120'
                  height='80'
                  src={getImageUrl(media.image, item.type)}
                  alt='poster'
                  className='rounded__3 mx-3 object-fit__cover object-position__center'
                />
                {item.name}
              </div>
              <div className='d-flex'>
                <UpdateImageUpload
                  maxSize={item.maxSize}
                  onDrop={onUploadFile.bind(null, item)}
                  disabled={!PermissionService.hasAccess('media_edit')}
                />
                <ActionButton
                  onClick={removePosterImage.bind(null, item)}
                  disabled={!PermissionService.hasAccess('media_delete')}
                />
              </div>
            </div>
          );
        } else {
          return (
            PermissionService.hasAccess(item.access) && (
              <UploadZone
                maxSize={item.maxSize}
                key={item.type}
                imageProgress={imageProgress[item.type]}
                onDrop={onUploadFile.bind(null, item)}
                name={item.name}
              />
            )
          );
        }
      })}
    </div>
  );
};

export default ImagesTab;
