import React, { useState, useEffect } from 'react';
import { AnalyticsApi } from 'utils/connectors';
import { useDispatch, useSelector } from 'react-redux';
import { useSnackbar } from 'notistack';
import { constructSort, getError } from 'utils/appHelpers';
import { onAnalyticsSet, onFetchingSet } from 'app/Main/actions';
import { buildParams } from 'utils/analyticsHelpers';
import InfiniteScroll from 'react-infinite-scroll-component';
import Loading from 'shared/components/loadings/Loading';
import ContentTable from './components/ContentTable';

const MediaList = ({ match }) => {
  const { type } = match.params;
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const { filters, analytics } = useSelector(state => state);
  const [order, setOrder] = useState({
    key: 'watch_time',
    bool: false,
  });

  const { items, dates } = filters;

  const dataSource = analytics.content;

  const getMedia = async isNext => {
    try {
      dispatch(onFetchingSet(true));
      const page = isNext ? dataSource.page + 1 : 1;
      const [sortKey, sortDir] = constructSort(order).split(':');
      const { data } = await AnalyticsApi.post('/reporting/content/search', {
        ...buildParams(filters, type.replace('-', '_').toUpperCase()),
        sort_by: sortKey.toUpperCase(),
        order_by: sortDir.toUpperCase(),
        page,
        page_size: 20,
      });
      if (isNext) data.data = [...dataSource.data, ...data.data];
      dispatch(onAnalyticsSet({ content: { ...dataSource, ...data } }));
      dispatch(onFetchingSet(false));
    } catch (err) {
      enqueueSnackbar(getError(err), { variant: 'error' });
      dispatch(onFetchingSet(false));
    }
  };

  useEffect(() => {
    getMedia();
  }, [order, type, items, dates]);

  if (!dataSource || !dataSource.data) {
    return (
      <div className='bg-white d-flex justify-content-center py-10'>
        <Loading />
      </div>
    );
  }

  const dataLength = (dataSource.data && dataSource.data.length) || 0;
  const hasMore = dataSource.data && dataSource.data.length < dataSource.totalElements;

  return (
    <InfiniteScroll
      scrollableTarget='content-container'
      hasMore={hasMore}
      dataLength={dataLength}
      next={() => getMedia(true)}
      loader={<div>Loading...</div>}
    >
      <ContentTable data={dataSource.data} order={order} onOrderChange={setOrder} />
    </InfiniteScroll>
  );
};

export default MediaList;
