import * as React from 'react';
import sortIcon from 'assets/images/icons/sort.svg';

const THeader = ({ data, order, onOrderChange }) => {
  const handleClick = () => {
    if (!data.key) return;
    onOrderChange({ key: data.key, bool: order.key === data.key ? !order.bool : true });
  };

  return (
    <th
      key={data.name}
      className={`border-0 text-primary text-blue-dark ${data.key ? 'pointer' : ''}`}
      onClick={handleClick}
    >
      {data.name}
      {order.key === data.key && (
        <img src={sortIcon} alt='sort' className={`ml-1 ${order.bool ? 'flip-x' : ''}`} />
      )}
    </th>
  );
};

export default THeader;
