import React from 'react';
import Switch from 'react-switch';

const Switcher = ({
  onSwitchChange,
  switchCheck,
  className,
  labelClass,
  disabled = false,
  label,
}) => {
  const props = {
    onColor: '#B1B7EF',
    onHandleColor: '#5867DD',
    offColor: '#E5E6EF',
    offHandleColor: '#B2B8C1',
    handleDiameter: 20,
    height: 14,
    width: 36,
    uncheckedIcon: false,
    checkedIcon: false,
    disabled: disabled,
  };

  return (
    <span className={className}>
      <Switch onChange={onSwitchChange} checked={switchCheck} {...props} />
      {label && <span className={labelClass}>{label}</span>}
    </span>
  );
};

export default Switcher;
