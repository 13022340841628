import React, { useState, useEffect } from 'react';
import { useSnackbar } from 'notistack';
import InputGroup from 'shared/components/forms/InputGroup';
import TextAreaGroup from 'shared/components/forms/TextAreaGroup';
import Modal from 'shared/components/modals/Modal';
import { Api } from 'utils/connectors';
import { getError, imageUploadProgress } from 'utils/appHelpers';
import UploadZone from 'shared/components/UploadZone';
import ActionButton from 'shared/components/ActionButton';
import UpdateImageUpload from 'shared/components/UpdateImageUpload';
import UploadProgress from 'shared/components/UploadProgress';
import { MAX_SIZES } from 'configs/sizes';

const CastAndCrewManageModal = ({ onClose, onSuccess, data, lang }) => {
  const { enqueueSnackbar } = useSnackbar();
  const [fetching, setFetching] = useState(false);
  const [isDuplicate, setIsDuplicate] = useState(false);
  const [form, setForm] = useState({
    first_name: data?.first_name || '',
    last_name: data?.last_name || '',
    bio: data?.bio || '',
    img_url: data?.img_url || '',
  });

  const [imageProgress, setImageProgress] = useState({});
  const isEdit = !!data;

  const getAndCheckDuplicate = async (fname, lname) => {
    try {
      const { data } = await Api.get(`/casts`, {
        params: { page: 0, size: 10, locale: lang, name: fname },
      });
      const hasDuplicate = data.content.some(
        person => person.first_name === fname && person.last_name === lname,
      );
      return hasDuplicate;
    } catch (err) {
      enqueueSnackbar(getError(err), { variant: 'error' });
      return false;
    }
  };

  const onFormSubmit = async e => {
    e.preventDefault();

    if (!isEdit && !isDuplicate) {
      setFetching(true);
      const hasDuplicate = await getAndCheckDuplicate(form.first_name, form.last_name);
      setIsDuplicate(hasDuplicate);
      setFetching(false);
      if (hasDuplicate) return;
    }

    try {
      setFetching(true);
      const fd = new FormData();
      fd.append('image', form.img_url);
      let blob = new Blob([JSON.stringify(form)], {
        type: 'application/json',
      });
      fd.append('dto', blob);
      const url = isEdit ? `/casts/${data.id}?locale=${lang}` : `/casts?locale=${lang}`;
      const action = isEdit ? 'put' : 'post';
      const successMsg = `Successfully ${isEdit ? 'updated' : 'created'}`;
      isEdit ? await Api[action](url, form) : await Api[action](url, fd);
      enqueueSnackbar(successMsg, { variant: 'success' });
      onSuccess && onSuccess();
      onClose();
    } catch (err) {
      enqueueSnackbar(getError(err), { variant: 'error' });
    } finally {
      setFetching(false);
      setIsDuplicate(false);
    }
  };

  const handleChange = ({ target }) => {
    const { name, value } = target;
    setForm({ ...form, [name]: value });
    setIsDuplicate(false);
  };

  const onUploadFile = async (file, status) => {
    if (status?.[0]?.errors?.[0]?.code === 'file-too-large') {
      enqueueSnackbar(status?.[0]?.errors?.[0]?.message, { variant: 'error' });
      return;
    }
    if (!file.length) return;
    try {
      setImageProgress({ ...imageProgress, THUMBNAIL: 1 });
      if (isEdit) {
        const fd = new FormData();
        fd.append('image', file[0]);
        const { data: response } = await Api.post(`/casts/${data.id}/images`, fd, {
          onUploadProgress: imageUploadProgress.bind(null, setImageProgress, 'THUMBNAIL'),
        });
        setForm({ ...form, img_url: response.img_url });
        return;
      }
      setForm({ ...form, img_url: file[0] });
      onSuccess();
    } catch (err) {
      enqueueSnackbar(getError(err), { variant: 'error' });
    } finally {
      setImageProgress({ ...imageProgress, THUMBNAIL: false });
    }
  };

  const removeThumbnailImage = async () => {
    try {
      setForm({ ...form, img_url: '' });
      if (data) await Api.delete(`/casts/${data.id}/images`);
      onSuccess();
    } catch (err) {
      enqueueSnackbar(getError(err), { variant: 'error' });
    }
  };

  useEffect(() => {
    if (data) setForm(data);
  }, [data]);

  return (
    <Modal size='xs' onClose={onClose} className='mx-2 mt-2'>
      <div className='modal-title bg-purple__light row px-4 py-3'>
        <div className='text-primary__light'>{isEdit ? 'Update' : 'Create'} Person</div>
      </div>
      <div className='signup-modal d-flex flex-column align-items-center'>
        <form onSubmit={onFormSubmit} className='w-100 pt-3'>
          <InputGroup
            type='text'
            name='first_name'
            value={form.first_name}
            placeholder='First Name'
            onChange={handleChange}
            required
            autoFocus
          />
          <InputGroup
            type='text'
            name='last_name'
            value={form.last_name}
            placeholder='Last Name'
            onChange={handleChange}
          />
          <TextAreaGroup
            name='bio'
            value={form.bio}
            placeholder='Biography'
            onChange={handleChange}
            containerClass='mb-4'
          />
          {
            <>
              {form.img_url ? (
                imageProgress.THUMBNAIL ? (
                  <UploadProgress progress={imageProgress.THUMBNAIL} />
                ) : (
                  <div className='mb-3 d-flex align-items-center justify-content-between'>
                    <div>
                      <img
                        width='120'
                        height='80'
                        src={isEdit ? form.img_url : URL.createObjectURL(form.img_url)}
                        alt='poster'
                        className='rounded__3 mx-3 object-fit__cover object-position__center'
                      />
                      Image
                    </div>
                    <div className='d-flex'>
                      <UpdateImageUpload onDrop={onUploadFile} maxSize={MAX_SIZES.CAST_AND_CREW} />
                      <ActionButton onClick={removeThumbnailImage} />
                    </div>
                  </div>
                )
              ) : (
                <UploadZone
                  onDrop={onUploadFile}
                  maxSize={MAX_SIZES.CAST_AND_CREW}
                  imageProgress={imageProgress.THUMBNAIL}
                  name='Image'
                />
              )}
            </>
          }
          {isDuplicate && (
            <div className='alert alert-warning mt-3' role='alert'>
              The name is already in the list. Are you sure you want to include it again?
            </div>
          )}
          <div className='text-right mt-4'>
            <button
              className='btn btn-outline-primary text-md text-uppercase mnw-110 btn-sm py-2 weight-300 mx-2'
              onClick={onClose}
              disabled={fetching}
              type='button'
            >
              Cancel
            </button>
            <button
              className='btn btn-primary btn-sm py-2 text-md text-uppercase weight-300 mnw-110 ml-2'
              disabled={fetching}
              type='submit'
            >
              {isEdit ? 'Update' : isDuplicate ? 'Add' : 'Create'}
            </button>
          </div>
        </form>
      </div>
    </Modal>
  );
};

export default CastAndCrewManageModal;
