// Load vendors
import axios from 'axios';

// Load configs
import { HOST } from 'configs/host';

// Set config defaults when creating the instance
export const Api = axios.create({
  baseURL: `${HOST.API.URL}`,
  // THIS HEADERS IS IMPORTANT SEE THE URL ABOVE
  headers: { common: {} },
});

export const AppApi = axios.create({
  baseURL: `${HOST.API.APP_URL}`,
  // THIS HEADERS IS IMPORTANT SEE THE URL ABOVE
  headers: { common: {} },
});

export const AnalyticsApi = axios.create({
  baseURL: `${HOST.API.ANALYTICS_URL}`,
  // THIS HEADERS IS IMPORTANT SEE THE URL ABOVE
  headers: { common: {} },
});

export const setAuthToken = (authToken, api) => {
  api.defaults.headers.common['Authorization'] = `${HOST.API.AUTH_PREFIX} ${authToken}`;
};
