import React from 'react';
import { PermissionService } from 'services/PermissionService';

const TabsButtons = ({ tabs, tab, setTab, disabled }) => {
  return (
    <div>
      {tabs.map(({ name, key, access, badgeValue }, i) => {
        if (access && !PermissionService.hasAccess(access)) return null;
        return (
          <button
            key={i}
            disabled={disabled}
            className={`btn btn-sm weight-300 py-1 px-1 no-decoration no-outline ${
              tab === key ? 'btn-primary' : 'btn-link'
            }`}
            onClick={() => setTab(key)}
          >
            {name}
            {!!badgeValue && (
              <span className='badge bg-white d-inline text-dark ml-1'>{badgeValue}</span>
            )}
          </button>
        );
      })}
    </div>
  );
};

export default TabsButtons;
