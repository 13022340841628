import React, { useEffect } from 'react';
import { getDashKeySystem, getHLSKeySystem } from './playerLicense';
import Modal from './../modals/Modal';
import 'videojs-contrib-eme';
import videojs from 'video.js';

const MediaPreviewModal = ({ onClose, item, isMediaYoutube }) => {
  const getFileUrl = media => {
    const item = media.media_files || media.mediaFiles;
    if (!item) return null;
    if (videojs.browser.IS_ANY_SAFARI) {
      return item.HLS_Auto;
    } else {
      return item.DASH_ISO_Auto;
    }
  };

  const initPlayer = () => {
    try {
      const isHls = videojs.browser.IS_ANY_SAFARI;
      const isEncrypted = item.encrypted;
      const player = videojs('playerVideo');
      player.eme();

      player.on('ready', function() {
        const srcOptions = {
          src: getFileUrl(item),
          type: isHls ? 'application/x-mpegURL' : 'application/dash+xml',
        };
        if (isEncrypted) {
          srcOptions.keySystems = isHls
            ? getHLSKeySystem(item.movieId, item.id)
            : getDashKeySystem(item.movieId, item.id);
        }
        player.src(srcOptions);
      });
    } catch (err) {
      //eslint-disable-next-line
      console.log(err);
    }
  };

  useEffect(() => {
    if (!isMediaYoutube) initPlayer();
    return () => {
      if (!isMediaYoutube && videojs('playerVideo')) videojs('playerVideo').dispose();
    };
  }, []);

  return (
    <Modal onClose={onClose} className='mx-2 mt-2 modal-media d-flex flex-column' parentClassName="modal-media-wrapper">
      <div className='modal-title bg-purple__light row px-4 py-3 mb-6'>
        <div className='text-primary__light'>Video Preview</div>
      </div>
      {isMediaYoutube ? (
        <iframe
          title={'YouTube'}
          width='420'
          height='315'
          src={`https://www.youtube.com/embed/${item.remote_video_id}`}
          className='flex-fill'
        />
      ) : (
        <div className='d-flex justify-content-center'>
          <video
            id='playerVideo'
            height='315'
            className='video-js vjs-default-skin'
            controls
            preload='auto'
          />
        </div>
      )}
    </Modal>
  );
};

export default MediaPreviewModal;
