import React, { useEffect, useState } from 'react';
import TextEditor from 'shared/components/utils/TextEditor';
import { useSnackbar } from 'notistack';
import { getError } from 'utils/appHelpers';
import { Api } from 'utils/connectors';
import { PermissionService } from 'services/PermissionService';
import LangToggle from 'shared/components/LangToggle';
import PlarformToggle from 'shared/components/PlarformToggle';

const Privacy = () => {
  const { enqueueSnackbar } = useSnackbar();
  const [fetching, setFetching] = useState(false);
  const [lang, setLang] = useState('en');
  const [platform, setPlatform] = useState('WEB');
  const [content, setContent] = useState('');

  const getPageData = async () => {
    try {
      setFetching(true);
      const params = { locale: lang, 'device-type': platform };
      const data = await Api.get('/privacy-policy', { params });
      setContent(data.data);
    } catch (err) {
      setContent(' ');
      enqueueSnackbar(getError(err), { variant: 'error' });
    } finally {
      setFetching(false);
    }
  };

  const updatePageData = async value => {
    try {
      setFetching(true);
      const body = { value, device_type: platform };
      await Api.post('/privacy-policy', body, { params: { locale: lang } });
      enqueueSnackbar('Successfully Updated.', { variant: 'success' });
      setContent(value);
    } catch (err) {
      enqueueSnackbar(getError(err), { variant: 'error' });
    } finally {
      setFetching(false);
    }
  };

  useEffect(() => {
    getPageData();
  }, [lang, platform]);

  return (
    <div className='flex-fill d-flex flex-column'>
      <div className='d-flex align-items-center justify-content-between mb-3'>
        <PlarformToggle platform={platform} setPlatform={setPlatform} />
        <LangToggle lang={lang} setLang={setLang} exceptions={['ru']} />
      </div>
      <TextEditor
        initialValue={content}
        onSubmit={updatePageData}
        disabled={fetching}
        readOnly={!PermissionService.hasAccess('pages_edit')}
      />
    </div>
  );
};

export default Privacy;
