import React from 'react';

const platforms = [
  { name: 'Web', key: 'WEB' },
  { name: 'MACOS', key: 'MACOS' },
  { name: 'IOS', key: 'IOS' },
  { name: 'Android', key: 'ANDROID' },
  { name: 'WEBOS (LG)', key: 'TV_WEBOS' },
  { name: 'TIZEN (Samsung)', key: 'TV_TIZEN' },
  { name: 'Apple TV', key: 'TVOS' },
];

// WEB_CHROME,
// TVOS,
// WEB_IE,
// WEB_OPERA,
// UNKNOWN,
// WEB_SAFARI,
// IOS,
// WEB_FIREFOX,
// ANDROID,
// TV_TIZEN,
// MACOS,
// WEB,
// TV_WEBOS,
// WEB_YANDEX,
// WEB_EDGE;

const PlarformToggle = ({ platform, setPlatform, disabled, exceptions = [] }) => {
  return (
    <div className='lang-toggle border__inset d-flex'>
      {platforms.map(item => {
        if (exceptions.includes(item.key)) return null;
        const isActive = item.key === platform;
        return (
          <button
            key={item.key}
            className={`btn btn-sm weight-300 px-2 ${isActive ? 'active' : ''}`}
            onClick={setPlatform.bind(null, item.key)}
            disabled={disabled}
          >
            {item.name}
          </button>
        );
      })}
    </div>
  );
};

export default PlarformToggle;
