import React from 'react';
import { overviewCharts } from './../../configs/consts';
import ChartBox from './components/ChartBox';
import { PermissionService } from 'services/PermissionService';

const Overview = () => (
  <div className='row row-2 pull-t-5'>
    {overviewCharts.map(({ permission, ...restProps }) => {
      if (!PermissionService.hasAccess(permission)) return null;
      return <ChartBox {...restProps} key={restProps.storeKey} />;
    })}
  </div>
);

export default Overview;
