import React from 'react';
import UploadConvertOptions from './UploadConvertOptions';
import Modal from 'shared/components/modals/Modal';

const UploadOptionsModal = ({
  onClose,
  children,
  onHandleConvert,
  convertOptions,
  setConvertOptions,
  optionsState,
}) => {
  return (
    <Modal size='sm' onClose={onClose} className='mx-2 mt-2'>
      <div className='modal-title bg-purple__light row px-4 py-3 mb-6'>
        <div className='text-primary__light'>Upload Media</div>
      </div>
      <div className='mh-124'>
        <UploadConvertOptions
          optionsState={optionsState}
          convertOptions={convertOptions}
          setConvertOptions={setConvertOptions}
        />
      </div>
      {children}
      <div className='text-center mt-4'>
        <button
          className='btn btn-outline-primary text-md text-uppercase mnw-110 btn-sm py-2 weight-300 mx-2'
          onClick={onClose}
        >
          Close
        </button>
        <button
          className='btn btn-primary btn-sm py-2 text-md text-uppercase weight-300 mnw-110 ml-2'
          onClick={onHandleConvert}
        >
          Convert
        </button>
      </div>
    </Modal>
  );
};

export default UploadOptionsModal;
