import React, { useState } from 'react';
import Modal from 'shared/components/modals/Modal';
import { Api } from 'utils/connectors';
import { useSnackbar } from 'notistack';
import { getError, imageUploadProgress } from 'utils/appHelpers';
import InputGroup from 'shared/components/forms/InputGroup';
import TextAreaGroup from 'shared/components/forms/TextAreaGroup';
import ActionButton from 'shared/components/ActionButton';
import UploadZone from 'shared/components/UploadZone';
import UploadProgress from 'shared/components/UploadProgress';
import UpdateImageUpload from 'shared/components/UpdateImageUpload';

const initialState = {
  name: '',
  description: '',
  path: '',
  object_key: '',
};

const ManageAdsAssetsModal = ({ onClose, state, onSuccess }) => {
  const [fileProgress, setFileProgress] = useState({});
  const { enqueueSnackbar } = useSnackbar();
  const [fetching, setFetching] = useState(false);
  const isEdit = !!state.asset;
  const initialForm = state.asset ? {...state.asset, path: state.asset.object_key} : initialState;
  const [form, setForm] = useState(initialForm);
  const [file, setFile] = useState();

  const onFormChange = ({ target }) => {
    const { name, value } = target;
    setForm({ ...form, [name]: value });
  };

  const onSubmitItem = async (isEdit) => {
    const fd = new FormData();
    fd.append('asset', file);
    const body = { ...form, object_key: form.path };
    const blob = new Blob([JSON.stringify(body)], {
      type: 'application/json',
    });
    fd.append('dto', blob);
    setFileProgress({ ...fileProgress, video: 1 });
    const endpoint = isEdit ? `/ad-configs/ad-asset/${state.asset.id}` : `/ad-configs/ad-asset`;
    const method = isEdit ? 'put' : 'post';
    return Api[method](endpoint, fd, {
      onUploadProgress: imageUploadProgress.bind(null, setFileProgress, 'video'),
    });
  };

  const onFormSubmit = async e => {
    e.preventDefault();
    try {
      setFetching(true);
      await onSubmitItem(isEdit);
      if (onSuccess) onSuccess();
      enqueueSnackbar('Successfully done', { variant: 'success' });
      setFetching(false);
      onClose();
    } catch (err) {
      setFetching(false);
      enqueueSnackbar(getError(err), { variant: 'error' });
    } finally {
      setFileProgress({ ...fileProgress, video: false });
    }
  };

  const removeVideoFile = () => {
    setForm({ ...form, object_key: '' });
    setFile(null);
  };

  const onUploadFile = async file => {
    removeVideoFile();
    setTimeout(() => setFile(file[0]), 300);
  };

  return (
    <Modal size='xs' onClose={onClose} className='mx-2 mt-2'>
      <div className='modal-title bg-purple__light row px-4 py-3'>
        <div className='text-primary__light'>{state.asset ? 'Edit' : 'Create'} Ads Asset</div>
      </div>
      <div className='signup-modal d-flex flex-column align-items-center'>
        <form onSubmit={onFormSubmit} className='w-100 pt-3'>
          <div className='form-field-container d-flex justify-content-between overflow-initial'>
            <label className='fw-500 mb-0 col p-0'>Video File</label>
            <div className='col-7 p-0'>
              {form.object_key || file ? (
                fileProgress.video ? (
                  <UploadProgress className='p-3' progress={fileProgress.video} />
                ) : (
                  <div className='mb-3 d-flex align-items-center justify-content-start'>
                    {form.object_key && (
                      <div className='max-300-scroll'>
                        <a href={form.object_key} target='_blank' rel='noopener noreferrer'>
                          {form.object_key}
                        </a>
                      </div>
                    )}
                    {file && (
                      <video controls className='max-300-scroll' key={file}>
                        <source src={URL.createObjectURL(file)} type='video/mp4' />
                        Your browser does not support the video tag.
                      </video>
                    )}
                    <div className='d-flex ml-3'>
                      <UpdateImageUpload onDrop={onUploadFile} accept={['video/mp4']} />
                      <ActionButton onClick={removeVideoFile} />
                    </div>
                  </div>
                )
              ) : (
                <UploadZone
                  accept={['video/mp4']}
                  onDrop={onUploadFile}
                  fileProgress={fileProgress.video}
                  name='1920x1080 (16:9)'
                  className='py-3'
                />
              )}
            </div>
          </div>
          <InputGroup
            type='text'
            name='name'
            value={form.name || ''}
            placeholder={'Name'}
            outLabel='Name'
            containerClass='d-flex align-items-center justify-content-between'
            onChange={onFormChange}
            className='col-7 p-0'
            required
          />
          <InputGroup
            type='text'
            name='path'
            placeholder={isEdit ? '' : `Example: USA/IDRAM/`}
            value={form.path || ''}
            outLabel='S3 Folder PATH'
            containerClass='d-flex align-items-center justify-content-between'
            onChange={onFormChange}
            className='col-7 p-0'
            readOnly={isEdit}
            disabled={isEdit}
            required
          />
          <TextAreaGroup
            name='description'
            value={form.description || ''}
            placeholder='Description'
            outLabel='Description'
            containerClass='d-flex justify-content-between'
            className='col-7 p-0'
            onChange={onFormChange}
            required
          />
          <div className='text-right mt-4'>
            <button
              className='btn btn-outline-primary text-md btn-sm btn-crud weight-400 mx-2'
              onClick={onClose}
              type='button'
            >
              Cancel
            </button>
            <button
              className='btn btn-primary btn-sm btn-crud text-md weight-400 ml-1'
              disabled={fetching}
              type='submit'
            >
              {isEdit ? 'Update' : 'Create'}
            </button>
          </div>
        </form>
      </div>
    </Modal>
  );
};

export default ManageAdsAssetsModal;
