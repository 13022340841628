/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React from 'react';
import UseFocus from 'shared/hooks/useFocus';

const TextAreaGroup = ({
  name,
  value,
  id,
  onChange,
  onBlur,
  placeholder,
  disabled,
  required,
  icon,
  className,
  containerClass,
  inputClass = '',
  successText,
  errorText,
  maxCount,
  outLabel,
  outSubLabel,
  rows,
}) => {
  const [fieldRef, setFiledFocus] = UseFocus();

  const setInputClasses = () => {
    let classes = '';
    if (inputClass) classes += ` ${inputClass}`;
    if (value) classes += ' active';
    return classes;
  };

  const countIssue = value?.length > maxCount;

  return (
    <div className={`form-field-container ${containerClass || ''}`}>
      {outLabel && (
        <label className='fw-500 mb-0' htmlFor={name}>
          {outLabel}
          {outSubLabel && <p className='text-secondary small pr-2'>{outSubLabel}</p>}
        </label>
      )}
      <div
        className={`form-field position-relative${errorText ? ' is-error' : ''}${
          successText ? ' is-success' : ''
        } ${className || ''}`}
      >
        {icon && <div className='icon-area'>{icon}</div>}
        <textarea
          value={value}
          id={name}
          name={name}
          rows={rows}
          onChange={onChange}
          onBlur={onBlur}
          aria-describedby=''
          autoComplete='off'
          disabled={disabled}
          required={required}
          ref={fieldRef}
          className={setInputClasses()}
        />
        {placeholder && (
          <label
            htmlFor={name}
            onClick={e => {
              setFiledFocus();
              e.preventDefault();
            }}
          >
            {placeholder}
          </label>
        )}
      </div>
      {errorText && errorText !== true && (
        <p className='text-xs text-danger mt-1 mb-0 px-3' data-testid='dangerText'>
          {errorText}
        </p>
      )}
      {maxCount && (
        <small className={`form-text text-right ${countIssue ? 'text-danger' : 'text-muted'}`}>
          {`Characters left: ${maxCount - value?.length}`}
        </small>
      )}
    </div>
  );
};

export default TextAreaGroup;
