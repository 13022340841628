import React, { useState } from 'react';
import Header from 'shared/components/layouts/Header';
import LangToggle from 'shared/components/LangToggle';
import CastAndCrewList from './components/CastAndCrewList';
import RoleList from './components/RoleList';

const CastAndCrew = () => {
  const [lang, setLang] = useState('en');

  return (
    <>
      <Header>
        <div className='d-flex align-items-center'>Cast & Crew</div>
      </Header>
      <main className='main-content'>
        <div className='d-flex align-items-center justify-content-end mb-3'>
          <LangToggle lang={lang} setLang={setLang} />
        </div>
        <div className='row'>
          <div className='col-6'>
            <CastAndCrewList lang={lang} />
          </div>
          <div className='col-6 pl-0'>
            <RoleList lang={lang} />
          </div>
        </div>
      </main>
    </>
  );
};

export default CastAndCrew;
