import React, { useRef, useState, useEffect } from 'react';
import IconArrowClose from 'assets/images/icons/arrow_close.svg';

const SliderButton = ({ className, onClick }) => (
  <button className={`arrow-btn ${className}`} onClick={onClick}>
    <img src={IconArrowClose} alt='arrow' />
  </button>
);

const Slider = ({ items, activeIndex, onItemSelect }) => {
  const container = useRef();

  const [sliderEnabled, setSliderEnabled] = useState(false);

  const handleSliderInit = () => {
    if (!items.length) return;
    const elements = Array.from(container.current.children);
    const elemsTotalWidth = elements.reduce((a, b) => a + b.clientWidth + 10, 0);
    const containerWidth = container.current.clientWidth;
    setSliderEnabled(elemsTotalWidth > containerWidth);
  };

  const handlePrev = () => {
    container.current.scrollLeft -= container.current.clientWidth;
  };
  const handleNext = () => {
    container.current.scrollLeft += container.current.clientWidth;
  };

  useEffect(handleSliderInit, [items]);

  return (
    <div className='d-flex'>
      {sliderEnabled && <SliderButton className='arrow-btn-prev' onClick={handlePrev} />}
      <ul ref={container} className='scroll-container border-bottom my-3 d-flex'>
        {items.map((item, index) => {
          return (
            <li key={index} className={activeIndex === index ? 'active' : ''}>
              <button
                key={item.id}
                className={`btn btn-sm text-nowrap`}
                onClick={() => onItemSelect(index)}
              >
                {item.name}
              </button>
            </li>
          );
        })}
      </ul>
      {sliderEnabled && <SliderButton className='arrow-btn-next' onClick={handleNext} />}
    </div>
  );
};

Slider.defaultProps = {
  items: [],
};

export default Slider;
