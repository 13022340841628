import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Header from 'shared/components/layouts/Header';
import NavTabs from './NavTabs';
import Filters from './Filters';
import RevenueTypeSelect from './RevenueTypeSelect';
import CountTypeSelect from './CountTypeSelect';
import DateRangePicker from 'shared/components/forms/DateRangePicker';
import { useLocation } from 'react-router-dom';
import { navigation, subNavigation } from './../configs/consts';
import { onModifyFilters, onOverviewReset, onSetDates } from 'app/Main/actions';
import MediaSearch from './MediaSearch';
import CSVManager from './CSVManager';
import { onAnalyticsReset } from 'app/Main/actions/index';

const AnalyticsHeader = () => {
  const { pathname } = useLocation();
  const dispatch = useDispatch();
  const { filters, fetching } = useSelector(state => state);

  const handleChange = (name, value) => {
    dispatch(onModifyFilters({ ...filters, [name]: value }));
  };

  const handleDatesChange = dates => {
    // NOTE: Dropping the old data when the dates are changed
    dispatch(onAnalyticsReset());
    dispatch(onOverviewReset());
    dispatch(onSetDates(dates));
  };

  const urlKey = pathname.split(/\//g)[2];
  const isOverviewPage = pathname.includes('overview');
  const isContentPage = pathname.includes('content');
  const isRevenuePage = pathname.includes('revenue');

  return (
    <>
      <Header className='no-shadow border-bottom'>
        <div className='d-flex align-items-center flex-fill'>
          <p className='text-lg mb-0 weight-700 text-blue-dark'>Analytics</p>
          <NavTabs items={navigation} className='d-flex ml-15' />
          {isContentPage && <MediaSearch className='ml-5' />}
        </div>
      </Header>
      <div className='bg-white d-flex align-items-start justify-content-between px-6 pt-3 pb-1'>
        {!isOverviewPage && <Filters />}
        <div className='flex-fill d-flex align-items-start justify-content-end'>
          {isRevenuePage && (
            <>
              <RevenueTypeSelect
                name='revenue_type'
                value={filters.revenue_type}
                onChange={handleChange}
                disabled={fetching}
                className='mw-130 ml-3'
              />
              <CountTypeSelect
                name='metric_type'
                value={filters.metric_type}
                onChange={handleChange}
                disabled={fetching}
                className='mw-80 ml-3'
              />
            </>
          )}
          <DateRangePicker
            value={filters.dates}
            onChange={handleDatesChange}
            disabled={fetching}
            className='ml-3'
          />
          <CSVManager className='ml-3' />
        </div>
      </div>
      {!isOverviewPage && (
        <div className='bg-white d-flex align-items-center justify-content-between px-6 mh-57 border-top'>
          <NavTabs items={subNavigation[urlKey]} />
        </div>
      )}
    </>
  );
};

export default AnalyticsHeader;
