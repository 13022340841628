import React from 'react';
import moment from 'moment';
import IconCheck from 'assets/images/icons/check.svg';
import IconUncheck from 'assets/images/icons/uncheck.svg';

const MediaPublishState = ({ published, publishDate, className = '' }) => {
  const date = publishDate && moment(publishDate).format('MMM DD - HH:mm');
  const now = moment(publishDate).format('MMM DD - HH:mm')
  return (
    <div className={`d-flex align-items-center weight-500 ${className}`}>
      <img src={published ? IconCheck : IconUncheck} alt='check' className='mr-1' />
      <span className={published ? 'text-success' : publishDate ? 'text-info' : ''}>
        {published
          ? `Published at ${date || now}`
          : publishDate
          ? `Publish scheduled: ${date}`
          : 'Unpublished'}
      </span>
    </div>
  );
};

export default MediaPublishState;
