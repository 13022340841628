import { Api } from 'utils/connectors';
import { createReducer } from 'utils/reduxHelpers';
import { ON_LOGOUT } from 'app/Auth/actions';
import { saveToStore, getFromStore } from 'utils/storeHelpers';
import { dateRanges } from 'configs';
import { generateOverviewCSVData } from 'utils/analyticsHelpers';
import { bindDurationHours } from 'utils/appHelpers';
import cloneDeep from 'lodash/cloneDeep';

// Types
const ON_SET_TOTALS_VALUE = '@@movies/ON_SET_TOTALS_VALUE';
const ON_MOVIE_GET = '@@movies/ON_MOVIE_GET';
const ON_TVSHOW_GET = '@@movies/ON_TVSHOW_GET';
const ON_UPCOMING_GET = '@@movies/ON_UPCOMING_GET';
const ON_CATEGORIES_GET = '@@movies/ON_CATEGORIES_GET';
const ON_POSTERS_GET = '@@movies/ON_POSTERS_GET';
const ON_TAGS_GET = '@@movies/ON_TAGS_GET';
const ON_ADS_GET = '@@movies/ON_ADS_GET';
const ON_ADS_ASSETS_GET = '@@movies/ON_ADS_ASSETS_GET';
const ON_CONFIGURATIONS_GET = '@@movies/ON_CONFIGURATIONS_GET';
const ON_GENRES_GET = '@@movies/ON_GENRES_GET';
const ON_MOVIES_ITEMS_GET = '@@movies/ON_MOVIES_ITEMS_GET';
const ON_TVSHOWS_ITEMS_GET = '@@movies/ON_TVSHOWS_ITEMS_GET';
const ON_OPTION_SET = '@@option/ON_OPTION_SET';
const ON_USERS_GET = '@@option/ON_USERS_GET';
const ON_CHANNELS_GET = '@@option/ON_CHANNELS_GET';
const ON_CHANNEL_USERS_GET = '@@option/ON_CHANNEL_USERS_GET';
const ON_DATES_SET = '@@option/ON_DATES_SET';
const ON_FILTERS_MODIFY = '@@option/ON_FILTERS_MODIFY';
const ON_FILTERS_RESET = '@@option/ON_FILTERS_RESET';
const ON_ANALYTICS_SET = '@@option/ON_ANALYTICS_SET';
const ON_ANALYTICS_RESET = '@@option/ON_ANALYTICS_RESET';
const ON_OVERVIEW_SET = '@@option/ON_OVERVIEW_SET';
const ON_OVERVIEW_RESET = '@@option/ON_OVERVIEW_RESET';
const ON_CAST_AND_CREW_GET = '@@option/ON_CAST_AND_CREW_GET';
const ON_ROLES_GET = '@@option/ON_ROLES_GET';
const ON_FETCHING_SET = '@@option/ON_FETCHING_SET';
const ON_PRODUCTS_SET = '@@option/ON_PRODUCTS_SET';
const ON_PLANS_SET = '@@option/ON_PLANS_SET';
const ON_PROMOS_SET = '@@option/ON_PROMOS_SET';

// Dispatchers
export const onSetTotalsValue = payload => ({ type: ON_SET_TOTALS_VALUE, payload });
export const onSetMovies = payload => ({ type: ON_MOVIE_GET, payload });
export const onSetTVShows = payload => ({ type: ON_TVSHOW_GET, payload });
export const onSetUpcomings = payload => ({ type: ON_UPCOMING_GET, payload });
export const onSetCategories = payload => ({ type: ON_CATEGORIES_GET, payload });
export const onSetPosters = payload => ({ type: ON_POSTERS_GET, payload });
export const onSetTags = payload => ({ type: ON_TAGS_GET, payload });
export const onSetAds = payload => ({ type: ON_ADS_GET, payload });
export const onSetAdsAssets = payload => ({ type: ON_ADS_ASSETS_GET, payload });
export const onSetConfigurations = payload => ({ type: ON_CONFIGURATIONS_GET, payload });
export const onSetGenres = payload => ({ type: ON_GENRES_GET, payload });
export const onMovieItemGet = payload => ({ type: ON_MOVIES_ITEMS_GET, payload });
export const onTVShowItemGet = payload => ({ type: ON_TVSHOWS_ITEMS_GET, payload });
export const onOptionSet = payload => ({ type: ON_OPTION_SET, payload });
export const onSetUsers = payload => ({ type: ON_USERS_GET, payload });
export const onSetChannels = payload => ({ type: ON_CHANNELS_GET, payload });
export const onSetChannelUsers = payload => ({ type: ON_CHANNEL_USERS_GET, payload });
export const onSetDates = payload => ({ type: ON_DATES_SET, payload });
export const onModifyFilters = payload => ({ type: ON_FILTERS_MODIFY, payload });
export const onResetFilters = payload => ({ type: ON_FILTERS_RESET, payload });
export const onAnalyticsSet = payload => ({ type: ON_ANALYTICS_SET, payload });
export const onAnalyticsReset = payload => ({ type: ON_ANALYTICS_RESET, payload });
export const onOverviewSet = payload => ({ type: ON_OVERVIEW_SET, payload });
export const onOverviewReset = payload => ({ type: ON_OVERVIEW_RESET, payload });
export const onSetCastAndCrew = payload => ({ type: ON_CAST_AND_CREW_GET, payload });
export const onSetRoles = payload => ({ type: ON_ROLES_GET, payload });
export const onFetchingSet = payload => ({ type: ON_FETCHING_SET, payload });
export const onProductsSet = payload => ({ type: ON_PRODUCTS_SET, payload });
export const onPlansSet = payload => ({ type: ON_PLANS_SET, payload });
export const onPromosSet = payload => ({ type: ON_PROMOS_SET, payload });

const updateTotalsObj = {};

export const setTotalValue = (key, value, forceUpdate) => (dispatch, getState) => {
  const totals = getState().totals;
  if (totals[key] === null || totals[key] === undefined || forceUpdate || !updateTotalsObj[key]) {
    const updateBody = { ...totals, [key]: value };
    saveToStore('totals', JSON.stringify(updateBody));
    dispatch(onSetTotalsValue(updateBody));
    updateTotalsObj[key] = true;
  }
};

// Handlers
const totalsHandlers = {
  [ON_SET_TOTALS_VALUE]: (state, action) => ({ ...action.payload }),
  [ON_LOGOUT]: () => totalsInitialState,
};

const moviesHandlers = {
  [ON_MOVIE_GET]: (state, action) => ({ ...state, ...action.payload }),
  [ON_LOGOUT]: () => moviesInitialState,
};

const tvshowsHandlers = {
  [ON_TVSHOW_GET]: (state, action) => ({ ...state, ...action.payload }),
  [ON_LOGOUT]: () => tvshowsInitialState,
};

const upcomingHandlers = {
  [ON_UPCOMING_GET]: (state, action) => ({ ...state, ...action.payload }),
  [ON_LOGOUT]: () => upcomingInitialState,
};

const categoriesHandlers = {
  [ON_CATEGORIES_GET]: (state, action) => ({ ...state, ...action.payload }),
  [ON_LOGOUT]: () => categoriesInitialState,
};

const postersHandlers = {
  [ON_POSTERS_GET]: (state, action) => ({ ...state, ...action.payload }),
  [ON_LOGOUT]: () => postersInitialState,
};

const tagsHandlers = {
  [ON_TAGS_GET]: (state, action) => ({ ...state, ...action.payload }),
  [ON_LOGOUT]: () => tagsInitialState,
};

const adsHandlers = {
  [ON_ADS_GET]: (state, action) => action.payload,
  [ON_LOGOUT]: () => adsInitialState,
};

const adsAssetsHandlers = {
  [ON_ADS_ASSETS_GET]: (state, action) => action.payload,
  [ON_LOGOUT]: () => adsInitialState,
};

const configurationsHandlers = {
  [ON_CONFIGURATIONS_GET]: (state, action) => action.payload,
  [ON_LOGOUT]: () => configurationsInitialState,
};

const genresHandlers = {
  [ON_GENRES_GET]: (state, action) => ({ ...state, ...action.payload }),
  [ON_LOGOUT]: () => genresInitialState,
};

const moviesItemsHandlers = {
  [ON_MOVIES_ITEMS_GET]: (state, action) => ({ ...state, ...action.payload }),
  [ON_LOGOUT]: () => moviesItems,
};

const tvshowsItemsHandlers = {
  [ON_TVSHOWS_ITEMS_GET]: (state, action) => ({ ...state, ...action.payload }),
  [ON_LOGOUT]: () => tvshowsItems,
};

const optionsHandlers = {
  [ON_OPTION_SET]: (state, action) => ({ ...state, ...action.payload }),
};

const usersHelpers = {
  [ON_USERS_GET]: (state, action) => ({ ...state, ...action.payload }),
  [ON_LOGOUT]: () => usersInitialState,
};

const channelsHelpers = {
  [ON_CHANNELS_GET]: (state, action) => ({ ...state, ...action.payload }),
  [ON_LOGOUT]: () => channelsInitialState,
};

const channelUsersHandlers = {
  [ON_CHANNEL_USERS_GET]: (state, action) => {
    const { id, data } = action.payload;
    return { ...state, [id]: data };
  },
  [ON_LOGOUT]: () => channelUsersInitialState,
};

const filtersHandlers = {
  [ON_FILTERS_MODIFY]: (state, action) => action.payload,
  [ON_FILTERS_RESET]: (state, action) => ({ ...state, items: [] }),
  [ON_DATES_SET]: (state, action) => ({ ...state, dates: action.payload }),
  [ON_LOGOUT]: () => filtersInitialState,
};

const analyticsHandlers = {
  [ON_ANALYTICS_SET]: (state, action) => ({ ...state, ...action.payload }),
  [ON_ANALYTICS_RESET]: () => analyticsInitialState,
  [ON_LOGOUT]: () => analyticsInitialState,
};

const overviewHandlers = {
  [ON_OVERVIEW_SET]: (state, action) => {
    const key = Object.keys(action.payload)[0];
    const CSVPreparedData = cloneDeep(action.payload);
    if (key === 'watchtime') {
      CSVPreparedData.watchtime.rows = CSVPreparedData.watchtime.rows.map(item => {
        item.value = bindDurationHours(item.value);
        return item;
      });
    }
    const result = { ...state, ...action.payload };
    return { ...result, CSV: generateOverviewCSVData({ ...state, ...CSVPreparedData }) };
  },
  [ON_OVERVIEW_RESET]: () => overviewInitialState,
  [ON_LOGOUT]: () => overviewInitialState,
};

const castAndCrewHelpers = {
  [ON_CAST_AND_CREW_GET]: (state, action) => ({ ...state, ...action.payload }),
  [ON_LOGOUT]: () => castAndCrewInitialState,
};

const rolesHelpers = {
  [ON_ROLES_GET]: (state, action) => ({ ...state, ...action.payload }),
  [ON_LOGOUT]: () => rolesInitialState,
};

const fetchingHandlers = {
  [ON_FETCHING_SET]: (state, action) => action.payload,
  [ON_LOGOUT]: () => false,
};

const productsHandlers = {
  [ON_PRODUCTS_SET]: (state, action) => ({ ...state, ...action.payload }),
  [ON_LOGOUT]: () => productsInitialState,
};

const plansHandlers = {
  [ON_PLANS_SET]: (state, action) => ({ ...state, ...action.payload }),
  [ON_LOGOUT]: () => plansInitialState,
};

const promosHandlers = {
  [ON_PROMOS_SET]: (state, action) => ({ ...state, ...action.payload }),
  [ON_LOGOUT]: () => promosInitialState,
};

// Initial States
const initialTotals = getFromStore('totals') ? JSON.parse(getFromStore('totals')) : false;
const totalsInitialState = initialTotals || {
  all_movies: null,
  active_movies: null,
  inactive_movies: null,
  all_tvshows: null,
  active_tvshows: null,
  inactive_tvshows: null,
  all_upcoming: null,
  all_products: null,
  all_plans: null,
  all_promos: null,
};
const moviesInitialState = {};
const tvshowsInitialState = {};
const upcomingInitialState = {};
const categoriesInitialState = {};
const postersInitialState = {};
const tagsInitialState = {};
const adsInitialState = [];
const adsAssetsInitialState = [];
const configurationsInitialState = [];
const genresInitialState = {};
const moviesItems = {};
const tvshowsItems = {};
const optionsInitialState = { publish: { resolution: 'FHD', priority: 0, encrypt: false } };
const usersInitialState = {};
const channelsInitialState = {};
const channelUsersInitialState = {};
const productsInitialState = {};
const plansInitialState = {};
const promosInitialState = {};

const { name, startDate, endDate } = dateRanges.last_7_days;
const filtersInitialState = {
  dates: { name, from: startDate, to: endDate },
  items: {},
  // Revenue specific filters, sending when fetching revenue data
  revenue_type: 'rent',
  metric_type: 'sale',
};
const analyticsInitialState = {};
const overviewInitialState = {};
const castAndCrewInitialState = {};
const rolesInitialState = {};
const fetchingInitialState = false;

// Reducers
export const totalsValueReducer = createReducer(totalsInitialState, totalsHandlers);
export const moviesReducer = createReducer(moviesInitialState, moviesHandlers);
export const tvshowsReducer = createReducer(tvshowsInitialState, tvshowsHandlers);
export const upcomingReducer = createReducer(upcomingInitialState, upcomingHandlers);
export const categoriesReducer = createReducer(categoriesInitialState, categoriesHandlers);
export const postersReducer = createReducer(postersInitialState, postersHandlers);
export const tagsReducer = createReducer(tagsInitialState, tagsHandlers);
export const adsReducer = createReducer(adsInitialState, adsHandlers);
export const adsAssetsReducer = createReducer(adsAssetsInitialState, adsAssetsHandlers);
export const configurationsReducer = createReducer(
  configurationsInitialState,
  configurationsHandlers,
);
export const genresReducer = createReducer(genresInitialState, genresHandlers);
export const moviesItemsReducer = createReducer(moviesItems, moviesItemsHandlers);
export const tvshowsItemsReducer = createReducer(tvshowsItems, tvshowsItemsHandlers);
export const optionsReducer = createReducer(optionsInitialState, optionsHandlers);
export const usersReducer = createReducer(usersInitialState, usersHelpers);
export const channelsReducer = createReducer(channelsInitialState, channelsHelpers);
export const channelUsersReducer = createReducer(channelUsersInitialState, channelUsersHandlers);
export const filtersReducer = createReducer(filtersInitialState, filtersHandlers);
export const analyticsReducer = createReducer(analyticsInitialState, analyticsHandlers);
export const overviewReducer = createReducer(overviewInitialState, overviewHandlers);
export const castAndCrewReducer = createReducer(castAndCrewInitialState, castAndCrewHelpers);
export const rolesReducer = createReducer(rolesInitialState, rolesHelpers);
export const fetchingReducer = createReducer(fetchingInitialState, fetchingHandlers);
export const productsReducer = createReducer(productsInitialState, productsHandlers);
export const plansReducer = createReducer(plansInitialState, plansHandlers);
export const promosReducer = createReducer(promosHandlers, promosHandlers);

// Actions
export const getChannels = (isNext, search, lang) => async (dispatch, getState) => {
  const { channels } = getState();
  const activeChannel = channels && channels[lang];

  try {
    const page = isNext ? activeChannel.number : 0;
    const params = {
      size: 100,
      page: isNext ? page + 1 : page,
      name: search || undefined,
      locale: lang,
    };
    const res = await Api.get('/channels', { params });
    if (isNext) res.data.content = [...activeChannel.content, ...res.data.content];
    lang && dispatch(onSetChannels({ [lang]: res.data }));
  } catch (err) {
    //eslint-disable-next-line
    console.log('Get channels error: ', err);
  }
};
