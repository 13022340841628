import React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import AdsHeader from './components/AdsHeader';
import AdsManager from './routes/AdsManager';
import AdsAssets from './routes/AdsAssets';

const Ads = ({ match }) => {
  return (
    <>
      <AdsHeader />
      <main className='main-content d-flex flex-column'>
        <Switch>
          <Route path={`${match.path}/list/:id?/:location?`} component={AdsManager} />
          <Route path={`${match.path}/assets`} component={AdsAssets} />
          <Redirect to={`${match.path}/list`} />
        </Switch>
      </main>
    </>
  );
};

export default Ads;
