import React, { useState } from 'react';
import Header from 'shared/components/layouts/Header';
import ChannelsList from './components/ChannelsList';
import ChannelUsersList from './components/ChannelUsersList';
import LangToggle from 'shared/components/LangToggle';

const Channels = () => {
  const [selectedChannel, setSelectedChannel] = useState(null);
  const [lang, setLang] = useState('en');

  return (
    <>
      <Header>
        <div className='d-flex align-items-center'>Channels</div>
      </Header>
      <main className='main-content'>
        <div className='d-flex align-items-center justify-content-end mb-3'>
          <LangToggle lang={lang} setLang={setLang} />
        </div>
        <div className='row'>
          <div className='col-6'>
            <ChannelsList
              selectedChannel={selectedChannel}
              onSelectChannel={setSelectedChannel}
              lang={lang}
            />
          </div>
          <div className='col-6 pl-0'>
            <ChannelUsersList channel={selectedChannel} />
          </div>
        </div>
      </main>
    </>
  );
};
export default Channels;
