import React from 'react';

const Tabs = ({ items, value, onChange }) => {
  return (
    <div className='filters'>
      {items.map(item => (
        <button
          className={`btn ${
            value === item.value ? 'active border__bottom border__3 border__yellow' : ''
          }`}
          key={item.value}
          onClick={() => onChange(item.value)}
        >
          <span>{item.name}</span>
        </button>
      ))}
    </div>
  );
};

export default Tabs;
