import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Api } from 'utils/connectors';
import Modal from './modals/Modal';
import { useSnackbar } from 'notistack';
import { getError } from 'utils/appHelpers';
import DatePicker from 'react-datepicker';

const MediaPublishDialog = ({ onClose, type, media = {}, onSuccess = () => {} }) => {
  const { enqueueSnackbar } = useSnackbar();
  const { id, published, item, publish_date } = media;
  const options = useSelector(state => state.options);
  const [fetching, setFetching] = useState(false);
  const [publishStrategy, setPublishStrategy] = useState('immediate');
  const [publishDate, setPublishDate] = useState(publish_date ? new Date(publish_date) : null);

  const publishingOptionsAvailable =
    type === 'movie' && !published && item?.status === 'UPLOADED' && item?.media_type !== 'YOUTUBE';

  const getParams = () => {
    let params = {};
    if (publishingOptionsAvailable && options.publish) {
      params = { ...params, ...options.publish };
    }
    if (publishStrategy === 'schedule' && publishDate) {
      params = { ...params, publish_date: new Date(publishDate).getTime() };
    }
    const searchParams = new URLSearchParams(params);
    return searchParams ? `?${searchParams}` : '';
  };

  const handleSuccess = () => {
    const successParams = {};
    if (publishStrategy === 'schedule') {
      successParams.publish_date = new Date(publishDate).getTime();
    }
    onSuccess(successParams);
  };

  const toggleMediaPublish = async () => {
    if (fetching) return;
    setFetching(true);
    try {
      const params = getParams();
      const apiType = type === 'movie' ? 'movies' : 'tv-shows';
      const action = published ? 'unpublish' : 'publish';
      await Api.patch(`/${apiType}/${id}/${action}${params}`);
      handleSuccess();
    } catch (err) {
      enqueueSnackbar(getError(err), { variant: 'error' });
    }
  };

  useEffect(() => {
    if (published) toggleMediaPublish();
  }, [published]);

  if (published) return null;

  return (
    <Modal size='sm' onClose={onClose} className='mx-2 mt-2'>
      <div className='modal-title bg-purple__light row px-4 py-3 mb-6'>
        <div className='text-primary__light'>Publish Media</div>
      </div>
      <div className='mh-124 overflow-y-auto overflow-x-hidden'>
        <div className='mb-8'>
          {publishStrategy === 'schedule' ? (
            <div className='d-flex justify-content-center'>
              <DatePicker
                minDate={new Date()}
                selected={publishDate}
                onChange={date => setPublishDate(date)}
                inline
                showTimeSelect
                timeFormat='HH:mm'
                timeIntervals={15}
              />
            </div>
          ) : (
            <>
              <h5 className='mb-0'>Publish strategy</h5>
              <hr className='my-3' />
              <button
                className='publish-btn active'
                onClick={() => {
                  setPublishDate(null);
                  setPublishStrategy('immediate');
                }}
              >
                Immediate
              </button>
              <button className='publish-btn' onClick={() => setPublishStrategy('schedule')}>
                Schedule Date
              </button>
            </>
          )}
        </div>
      </div>

      <div className='text-center mt-4'>
        <button
          className='btn btn-outline-primary text-md text-uppercase mnw-110 btn-sm py-2 weight-300 mx-2'
          onClick={onClose}
        >
          Close
        </button>
        <button
          className='btn btn-primary btn-sm py-2 text-md text-uppercase weight-300 mnw-110 ml-2'
          onClick={toggleMediaPublish}
        >
          {publishStrategy === 'schedule' ? 'Schedule' : ''} Publish
        </button>
      </div>
    </Modal>
  );
};

export default MediaPublishDialog;
