export const MAX_SIZES = {
  POSTER_MOBILE: 150000,
  POSTER_TABLET: 300000,
  POSTER_TV: 580000,
  POSTER_WEB: 580000,
  CAST_AND_CREW: 20000,
  THUMBNAIL: 150000,
  DETAIL_MOBILE: 150000,
  DETAIL_TABLET: 300000,
  DETAIL_TV: 300000,
  UPCOMING_THUMBNAIL: 150000,
};
