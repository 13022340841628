import React from 'react';
import { useSelector } from 'react-redux';
import { bindDurationHours, formatNumber } from 'utils/appHelpers';
import { getDatesStr } from 'utils/analyticsHelpers';

const PageTitle = ({ total, title, unit, className }) => {
  const { filters } = useSelector(state => state);

  const isPricing = unit === '$';
  const isWatchtime = unit === 'mins';

  const formattedValue = isWatchtime ? bindDurationHours(total) : formatNumber(total);
  const totalValue = isPricing
    ? `${unit}${formattedValue}`
    : isWatchtime
    ? formattedValue
    : `${formattedValue} ${unit}`;

  return (
    <div className={className}>
      <p className='text-lg text-blue-dark text-capitalize weight-700 mb-1'>{title}</p>
      <h3 className='text-blue-dark text-capitalize mb-0'>{totalValue}</h3>
      <p className='text-light text-md mb-0'>{getDatesStr(filters.dates)}</p>
    </div>
  );
};

export default PageTitle;
