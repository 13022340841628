export const continents = {
  AF: 'Africa',
  AN: 'Antarctica',
  AS: 'Asia',
  EU: 'Europe',
  NA: 'North America',
  OC: 'Oceania',
  SA: 'South America',
};

export const countries = [
  {
    key: 'AD',
    name: 'Andorra',
    region: 'EU',
  },
  {
    key: 'AE',
    name: 'United Arab Emirates',
    region: 'AS',
  },
  {
    key: 'AF',
    name: 'Afghanistan',
    region: 'AS',
  },
  {
    key: 'AG',
    name: 'Antigua and Barbuda',
    region: 'NA',
  },
  {
    key: 'AI',
    name: 'Anguilla',
    region: 'NA',
  },
  {
    key: 'AL',
    name: 'Albania',
    region: 'EU',
  },
  {
    key: 'AM',
    name: 'Armenia',
    region: 'AS',
  },
  {
    key: 'AO',
    name: 'Angola',
    region: 'AF',
  },
  {
    key: 'AR',
    name: 'Argentina',
    region: 'SA',
  },
  {
    key: 'AS',
    name: 'American Samoa',
    region: 'OC',
  },
  {
    key: 'AT',
    name: 'Austria',
    region: 'EU',
  },
  {
    key: 'AU',
    name: 'Australia',
    region: 'OC',
  },
  {
    key: 'AW',
    name: 'Aruba',
    region: 'SA',
  },
  {
    key: 'AX',
    name: 'Åland Islands',
    region: 'EU',
  },
  {
    key: 'AZ',
    name: 'Azerbaijan',
    region: 'AS',
  },
  {
    key: 'BA',
    name: 'Bosnia and Herzegovina',
    region: 'EU',
  },
  {
    key: 'BB',
    name: 'Barbados',
    region: 'NA',
  },
  {
    key: 'BD',
    name: 'Bangladesh',
    region: 'AS',
  },
  {
    key: 'BE',
    name: 'Belgium',
    region: 'EU',
  },
  {
    key: 'BF',
    name: 'Burkina Faso',
    region: 'AF',
  },
  {
    key: 'BG',
    name: 'Bulgaria',
    region: 'EU',
  },
  {
    key: 'BH',
    name: 'Bahrain',
    region: 'AS',
  },
  {
    key: 'BI',
    name: 'Burundi',
    region: 'AF',
  },
  {
    key: 'BJ',
    name: 'Benin',
    region: 'AF',
  },
  {
    key: 'BL',
    name: 'Saint Barthélemy',
    region: 'NA',
  },
  {
    key: 'BM',
    name: 'Bermuda',
    region: 'NA',
  },
  {
    key: 'BN',
    name: 'Brunei Darussalam',
    region: 'AS',
  },
  {
    key: 'BO',
    name: 'Bolivia, Plurinational State of',
    region: 'SA',
  },
  {
    key: 'BQ',
    name: 'Caribbean Netherlands',
    region: 'SA',
  },
  {
    key: 'BR',
    name: 'Brazil',
    region: 'SA',
  },
  {
    key: 'BS',
    name: 'Bahamas',
    region: 'NA',
  },
  {
    key: 'BT',
    name: 'Bhutan',
    region: 'AS',
  },
  {
    key: 'BW',
    name: 'Botswana',
    region: 'AF',
  },
  {
    key: 'BY',
    name: 'Belarus',
    region: 'EU',
  },
  {
    key: 'BZ',
    name: 'Belize',
    region: 'NA',
  },
  {
    key: 'CA',
    name: 'Canada',
    region: 'NA',
  },
  {
    key: 'CC',
    name: 'Cocos (Keeling) Islands',
    region: 'AS',
  },
  {
    key: 'CD',
    name: 'Congo, the Democratic Republic of the',
    region: 'AF',
  },
  {
    key: 'CF',
    name: 'Central African Republic',
    region: 'AF',
  },
  {
    key: 'CG',
    name: 'Republic of the Congo',
    region: 'AF',
  },
  {
    key: 'CH',
    name: 'Switzerland',
    region: 'EU',
  },
  {
    key: 'CI',
    name: "Côte d'Ivoire",
    region: 'AF',
  },
  {
    key: 'CK',
    name: 'Cook Islands',
    region: 'OC',
  },
  {
    key: 'CL',
    name: 'Chile',
    region: 'SA',
  },
  {
    key: 'CM',
    name: 'Cameroon',
    region: 'AF',
  },
  {
    key: 'CN',
    name: "China (People's Republic of China)",
    region: 'AS',
  },
  {
    key: 'CO',
    name: 'Colombia',
    region: 'SA',
  },
  {
    key: 'CR',
    name: 'Costa Rica',
    region: 'NA',
  },
  {
    key: 'CU',
    name: 'Cuba',
    region: 'NA',
  },
  {
    key: 'CV',
    name: 'Cape Verde',
    region: 'AF',
  },
  {
    key: 'CW',
    name: 'Curaçao',
    region: 'SA',
  },
  {
    key: 'CX',
    name: 'Christmas Island',
    region: 'AS',
  },
  {
    key: 'CY',
    name: 'Cyprus',
    region: 'EU',
  },
  {
    key: 'CZ',
    name: 'Czech Republic',
    region: 'EU',
  },
  {
    key: 'DE',
    name: 'Germany',
    region: 'EU',
  },
  {
    key: 'DJ',
    name: 'Djibouti',
    region: 'AF',
  },
  {
    key: 'DK',
    name: 'Denmark',
    region: 'EU',
  },
  {
    key: 'DM',
    name: 'Dominica',
    region: 'NA',
  },
  {
    key: 'DO',
    name: 'Dominican Republic',
    region: 'NA',
  },
  {
    key: 'DZ',
    name: 'Algeria',
    region: 'AF',
  },
  {
    key: 'EC',
    name: 'Ecuador',
    region: 'SA',
  },
  {
    key: 'EE',
    name: 'Estonia',
    region: 'EU',
  },
  {
    key: 'EG',
    name: 'Egypt',
    region: 'AF',
  },
  {
    key: 'EH',
    name: 'Western Sahara',
    region: 'AF',
  },
  {
    key: 'ER',
    name: 'Eritrea',
    region: 'AF',
  },
  {
    key: 'ES',
    name: 'Spain',
    region: 'EU',
  },
  {
    key: 'ET',
    name: 'Ethiopia',
    region: 'AF',
  },
  {
    key: 'FI',
    name: 'Finland',
    region: 'EU',
  },
  {
    key: 'FJ',
    name: 'Fiji',
    region: 'OC',
  },
  {
    key: 'FK',
    name: 'Falkland Islands (Malvinas)',
    region: 'SA',
  },
  {
    key: 'FM',
    name: 'Micronesia, Federated States of',
    region: 'OC',
  },
  {
    key: 'FO',
    name: 'Faroe Islands',
    region: 'EU',
  },
  {
    key: 'FR',
    name: 'France',
    region: 'EU',
  },
  {
    key: 'GA',
    name: 'Gabon',
    region: 'AF',
  },
  {
    key: 'GB-ENG',
    name: 'England',
    region: 'EU',
  },
  {
    key: 'GB-NIR',
    name: 'Northern Ireland',
    region: 'EU',
  },
  {
    key: 'GB-SCT',
    name: 'Scotland',
    region: 'EU',
  },
  {
    key: 'GB-WLS',
    name: 'Wales',
    region: 'EU',
  },
  {
    key: 'GB',
    name: 'United Kingdom',
    region: 'EU',
  },
  {
    key: 'GD',
    name: 'Grenada',
    region: 'NA',
  },
  {
    key: 'GE',
    name: 'Georgia',
    region: 'AS',
  },
  {
    key: 'GF',
    name: 'French Guiana',
    region: 'SA',
  },
  {
    key: 'GG',
    name: 'Guernsey',
    region: 'EU',
  },
  {
    key: 'GH',
    name: 'Ghana',
    region: 'AF',
  },
  {
    key: 'GI',
    name: 'Gibraltar',
    region: 'EU',
  },
  {
    key: 'GL',
    name: 'Greenland',
    region: 'NA',
  },
  {
    key: 'GM',
    name: 'Gambia',
    region: 'AF',
  },
  {
    key: 'GN',
    name: 'Guinea',
    region: 'AF',
  },
  {
    key: 'GP',
    name: 'Guadeloupe',
    region: 'NA',
  },
  {
    key: 'GQ',
    name: 'Equatorial Guinea',
    region: 'AF',
  },
  {
    key: 'GR',
    name: 'Greece',
    region: 'EU',
  },
  {
    key: 'GS',
    name: 'South Georgia and the South Sandwich Islands',
    region: 'SA',
  },
  {
    key: 'GT',
    name: 'Guatemala',
    region: 'NA',
  },
  {
    key: 'GU',
    name: 'Guam',
    region: 'OC',
  },
  {
    key: 'GW',
    name: 'Guinea-Bissau',
    region: 'AF',
  },
  {
    key: 'GY',
    name: 'Guyana',
    region: 'SA',
  },
  {
    key: 'HK',
    name: 'Hong Kong',
    region: 'AS',
  },
  {
    key: 'HM',
    name: 'Heard Island and McDonald Islands',
    region: 'AN',
  },
  {
    key: 'HN',
    name: 'Honduras',
    region: 'NA',
  },
  {
    key: 'HR',
    name: 'Croatia',
    region: 'EU',
  },
  {
    key: 'HT',
    name: 'Haiti',
    region: 'NA',
  },
  {
    key: 'HU',
    name: 'Hungary',
    region: 'EU',
  },
  {
    key: 'ID',
    name: 'Indonesia',
    region: 'AS',
  },
  {
    key: 'IE',
    name: 'Ireland',
    region: 'EU',
  },
  {
    key: 'IL',
    name: 'Israel',
    region: 'AS',
  },
  {
    key: 'IM',
    name: 'Isle of Man',
    region: 'EU',
  },
  {
    key: 'IN',
    name: 'India',
    region: 'AS',
  },
  {
    key: 'IO',
    name: 'British Indian Ocean Territory',
    region: 'AF',
  },
  {
    key: 'IQ',
    name: 'Iraq',
    region: 'AS',
  },
  {
    key: 'IR',
    name: 'Iran, Islamic Republic of',
    region: 'AS',
  },
  {
    key: 'IS',
    name: 'Iceland',
    region: 'EU',
  },
  {
    key: 'IT',
    name: 'Italy',
    region: 'EU',
  },
  {
    key: 'JE',
    name: 'Jersey',
    region: 'EU',
  },
  {
    key: 'JM',
    name: 'Jamaica',
    region: 'NA',
  },
  {
    key: 'JO',
    name: 'Jordan',
    region: 'AS',
  },
  {
    key: 'JP',
    name: 'Japan',
    region: 'AS',
  },
  {
    key: 'KE',
    name: 'Kenya',
    region: 'AF',
  },
  {
    key: 'KG',
    name: 'Kyrgyzstan',
    region: 'AS',
  },
  {
    key: 'KH',
    name: 'Cambodia',
    region: 'AS',
  },
  {
    key: 'KI',
    name: 'Kiribati',
    region: 'OC',
  },
  {
    key: 'KM',
    name: 'Comoros',
    region: 'AF',
  },
  {
    key: 'KN',
    name: 'Saint Kitts and Nevis',
    region: 'NA',
  },
  {
    key: 'KP',
    name: "Korea, Democratic People's Republic of",
    region: 'AS',
  },
  {
    key: 'KR',
    name: 'Korea, Republic of',
    region: 'AS',
  },
  {
    key: 'KW',
    name: 'Kuwait',
    region: 'AS',
  },
  {
    key: 'KY',
    name: 'Cayman Islands',
    region: 'NA',
  },
  {
    key: 'KZ',
    name: 'Kazakhstan',
    region: 'AS',
  },
  {
    key: 'LA',
    name: "Laos (Lao People's Democratic Republic)",
    region: 'AS',
  },
  {
    key: 'LB',
    name: 'Lebanon',
    region: 'AS',
  },
  {
    key: 'LC',
    name: 'Saint Lucia',
    region: 'NA',
  },
  {
    key: 'LI',
    name: 'Liechtenstein',
    region: 'EU',
  },
  {
    key: 'LK',
    name: 'Sri Lanka',
    region: 'AS',
  },
  {
    key: 'LR',
    name: 'Liberia',
    region: 'AF',
  },
  {
    key: 'LS',
    name: 'Lesotho',
    region: 'AF',
  },
  {
    key: 'LT',
    name: 'Lithuania',
    region: 'EU',
  },
  {
    key: 'LU',
    name: 'Luxembourg',
    region: 'EU',
  },
  {
    key: 'LV',
    name: 'Latvia',
    region: 'EU',
  },
  {
    key: 'LY',
    name: 'Libya',
    region: 'AF',
  },
  {
    key: 'MA',
    name: 'Morocco',
    region: 'AF',
  },
  {
    key: 'MC',
    name: 'Monaco',
    region: 'EU',
  },
  {
    key: 'MD',
    name: 'Moldova, Republic of',
    region: 'EU',
  },
  {
    key: 'ME',
    name: 'Montenegro',
    region: 'EU',
  },
  {
    key: 'MF',
    name: 'Saint Martin',
    region: 'NA',
  },
  {
    key: 'MG',
    name: 'Madagascar',
    region: 'AF',
  },
  {
    key: 'MH',
    name: 'Marshall Islands',
    region: 'OC',
  },
  {
    key: 'MK',
    name: 'North Macedonia',
    region: 'EU',
  },
  {
    key: 'ML',
    name: 'Mali',
    region: 'AF',
  },
  {
    key: 'MM',
    name: 'Myanmar',
    region: 'AS',
  },
  {
    key: 'MN',
    name: 'Mongolia',
    region: 'AS',
  },
  {
    key: 'MO',
    name: 'Macao',
    region: 'AS',
  },
  {
    key: 'MP',
    name: 'Northern Mariana Islands',
    region: 'OC',
  },
  {
    key: 'MQ',
    name: 'Martinique',
    region: 'NA',
  },
  {
    key: 'MR',
    name: 'Mauritania',
    region: 'AF',
  },
  {
    key: 'MS',
    name: 'Montserrat',
    region: 'NA',
  },
  {
    key: 'MT',
    name: 'Malta',
    region: 'EU',
  },
  {
    key: 'MU',
    name: 'Mauritius',
    region: 'AF',
  },
  {
    key: 'MV',
    name: 'Maldives',
    region: 'AS',
  },
  {
    key: 'MW',
    name: 'Malawi',
    region: 'AF',
  },
  {
    key: 'MX',
    name: 'Mexico',
    region: 'NA',
  },
  {
    key: 'MY',
    name: 'Malaysia',
    region: 'AS',
  },
  {
    key: 'MZ',
    name: 'Mozambique',
    region: 'AF',
  },
  {
    key: 'NA',
    name: 'Namibia',
    region: 'AF',
  },
  {
    key: 'NC',
    name: 'New Caledonia',
    region: 'OC',
  },
  {
    key: 'NE',
    name: 'Niger',
    region: 'AF',
  },
  {
    key: 'NF',
    name: 'Norfolk Island',
    region: 'OC',
  },
  {
    key: 'NG',
    name: 'Nigeria',
    region: 'AF',
  },
  {
    key: 'NI',
    name: 'Nicaragua',
    region: 'NA',
  },
  {
    key: 'NL',
    name: 'Netherlands',
    region: 'EU',
  },
  {
    key: 'NO',
    name: 'Norway',
    region: 'EU',
  },
  {
    key: 'NP',
    name: 'Nepal',
    region: 'AS',
  },
  {
    key: 'NR',
    name: 'Nauru',
    region: 'OC',
  },
  {
    key: 'NU',
    name: 'Niue',
    region: 'OC',
  },
  {
    key: 'NZ',
    name: 'New Zealand',
    region: 'OC',
  },
  {
    key: 'OM',
    name: 'Oman',
    region: 'AS',
  },
  {
    key: 'PA',
    name: 'Panama',
    region: 'NA',
  },
  {
    key: 'PE',
    name: 'Peru',
    region: 'SA',
  },
  {
    key: 'PF',
    name: 'French Polynesia',
    region: 'OC',
  },
  {
    key: 'PG',
    name: 'Papua New Guinea',
    region: 'OC',
  },
  {
    key: 'PH',
    name: 'Philippines',
    region: 'AS',
  },
  {
    key: 'PK',
    name: 'Pakistan',
    region: 'AS',
  },
  {
    key: 'PL',
    name: 'Poland',
    region: 'EU',
  },
  {
    key: 'PM',
    name: 'Saint Pierre and Miquelon',
    region: 'NA',
  },
  {
    key: 'PN',
    name: 'Pitcairn',
    region: 'OC',
  },
  {
    key: 'PR',
    name: 'Puerto Rico',
    region: 'NA',
  },
  {
    key: 'PS',
    name: 'Palestine',
    region: 'AS',
  },
  {
    key: 'PT',
    name: 'Portugal',
    region: 'EU',
  },
  {
    key: 'PW',
    name: 'Palau',
    region: 'OC',
  },
  {
    key: 'PY',
    name: 'Paraguay',
    region: 'SA',
  },
  {
    key: 'QA',
    name: 'Qatar',
    region: 'AS',
  },
  {
    key: 'RE',
    name: 'Réunion',
    region: 'AF',
  },
  {
    key: 'RO',
    name: 'Romania',
    region: 'EU',
  },
  {
    key: 'RS',
    name: 'Serbia',
    region: 'EU',
  },
  {
    key: 'RU',
    name: 'Russian Federation',
    region: 'EU',
  },
  {
    key: 'RW',
    name: 'Rwanda',
    region: 'AF',
  },
  {
    key: 'SA',
    name: 'Saudi Arabia',
    region: 'AS',
  },
  {
    key: 'SB',
    name: 'Solomon Islands',
    region: 'OC',
  },
  {
    key: 'SC',
    name: 'Seychelles',
    region: 'AF',
  },
  {
    key: 'SD',
    name: 'Sudan',
    region: 'AF',
  },
  {
    key: 'SE',
    name: 'Sweden',
    region: 'EU',
  },
  {
    key: 'SG',
    name: 'Singapore',
    region: 'AS',
  },
  {
    key: 'SH',
    name: 'Saint Helena, Ascension and Tristan da Cunha',
    region: 'AF',
  },
  {
    key: 'SI',
    name: 'Slovenia',
    region: 'EU',
  },
  {
    key: 'SJ',
    name: 'Svalbard and Jan Mayen Islands',
    region: 'EU',
  },
  {
    key: 'SK',
    name: 'Slovakia',
    region: 'EU',
  },
  {
    key: 'SL',
    name: 'Sierra Leone',
    region: 'AF',
  },
  {
    key: 'SM',
    name: 'San Marino',
    region: 'EU',
  },
  {
    key: 'SN',
    name: 'Senegal',
    region: 'AF',
  },
  {
    key: 'SO',
    name: 'Somalia',
    region: 'AF',
  },
  {
    key: 'SR',
    name: 'Suriname',
    region: 'SA',
  },
  {
    key: 'SS',
    name: 'South Sudan',
    region: 'AF',
  },
  {
    key: 'ST',
    name: 'Sao Tome and Principe',
    region: 'AF',
  },
  {
    key: 'SV',
    name: 'El Salvador',
    region: 'NA',
  },
  {
    key: 'SX',
    name: 'Sint Maarten (Dutch part)',
    region: 'NA',
  },
  {
    key: 'SY',
    name: 'Syrian Arab Republic',
    region: 'AS',
  },
  {
    key: 'SZ',
    name: 'Swaziland',
    region: 'AF',
  },
  {
    key: 'TC',
    name: 'Turks and Caicos Islands',
    region: 'NA',
  },
  {
    key: 'TD',
    name: 'Chad',
    region: 'AF',
  },
  {
    key: 'TF',
    name: 'French Southern Territories',
    region: 'AN',
  },
  {
    key: 'TG',
    name: 'Togo',
    region: 'AF',
  },
  {
    key: 'TH',
    name: 'Thailand',
    region: 'AS',
  },
  {
    key: 'TJ',
    name: 'Tajikistan',
    region: 'AS',
  },
  {
    key: 'TK',
    name: 'Tokelau',
    region: 'OC',
  },
  {
    key: 'TL',
    name: 'Timor-Leste',
    region: 'AS',
  },
  {
    key: 'TM',
    name: 'Turkmenistan',
    region: 'AS',
  },
  {
    key: 'TN',
    name: 'Tunisia',
    region: 'AF',
  },
  {
    key: 'TO',
    name: 'Tonga',
    region: 'OC',
  },
  {
    key: 'TR',
    name: 'Turkey',
    region: 'AS',
  },
  {
    key: 'TT',
    name: 'Trinidad and Tobago',
    region: 'SA',
  },
  {
    key: 'TV',
    name: 'Tuvalu',
    region: 'OC',
  },
  {
    key: 'TW',
    name: 'Taiwan (Republic of China)',
    region: 'AS',
  },
  {
    key: 'TZ',
    name: 'Tanzania, United Republic of',
    region: 'AF',
  },
  {
    key: 'UA',
    name: 'Ukraine',
    region: 'EU',
  },
  {
    key: 'UG',
    name: 'Uganda',
    region: 'AF',
  },
  {
    key: 'UM',
    name: 'US Minor Outlying Islands',
    region: 'NA',
  },
  {
    key: 'US',
    name: 'United States',
    region: 'NA',
  },
  {
    key: 'UY',
    name: 'Uruguay',
    region: 'SA',
  },
  {
    key: 'UZ',
    name: 'Uzbekistan',
    region: 'AS',
  },
  {
    key: 'VA',
    name: 'Holy See (Vatican City State)',
    region: 'EU',
  },
  {
    key: 'VC',
    name: 'Saint Vincent and the Grenadines',
    region: 'NA',
  },
  {
    key: 'VE',
    name: 'Venezuela, Bolivarian Republic of',
    region: 'SA',
  },
  {
    key: 'VG',
    name: 'Virgin Islands, British',
    region: 'NA',
  },
  {
    key: 'VI',
    name: 'Virgin Islands, U.S.',
    region: 'NA',
  },
  {
    key: 'VN',
    name: 'Vietnam',
    region: 'AS',
  },
  {
    key: 'VU',
    name: 'Vanuatu',
    region: 'OC',
  },
  {
    key: 'WF',
    name: 'Wallis and Futuna Islands',
    region: 'OC',
  },
  {
    key: 'WS',
    name: 'Samoa',
    region: 'OC',
  },
  {
    key: 'XK',
    name: 'Kosovo',
    region: 'EU',
  },
  {
    key: 'YE',
    name: 'Yemen',
    region: 'AS',
  },
  {
    key: 'YT',
    name: 'Mayotte',
    region: 'AF',
  },
  {
    key: 'ZA',
    name: 'South Africa',
    region: 'AF',
  },
  {
    key: 'ZM',
    name: 'Zambia',
    region: 'AF',
  },
  {
    key: 'ZW',
    name: 'Zimbabwe',
    region: 'AF',
  },
];

export const allLanguages = [
  { "code": "hy", "name": "Armenian" },
  { "code": "en", "name": "English" },
  { "code": "ru", "name": "Russian" },
  { "code": "aa", "name": "Afar" },
  { "code": "ab", "name": "Abkhazian" },
  { "code": "ae", "name": "Avestan" },
  { "code": "af", "name": "Afrikaans" },
  { "code": "ak", "name": "Akan" },
  { "code": "am", "name": "Amharic" },
  { "code": "an", "name": "Aragonese" },
  { "code": "ar", "name": "Arabic" },
  { "code": "as", "name": "Assamese" },
  { "code": "av", "name": "Avaric" },
  { "code": "ay", "name": "Aymara" },
  { "code": "az", "name": "Azerbaijani" },
  { "code": "ba", "name": "Bashkir" },
  { "code": "be", "name": "Belarusian" },
  { "code": "bg", "name": "Bulgarian" },
  { "code": "bh", "name": "Bihari languages" },
  { "code": "bi", "name": "Bislama" },
  { "code": "bm", "name": "Bambara" },
  { "code": "bn", "name": "Bengali" },
  { "code": "bo", "name": "Tibetan" },
  { "code": "br", "name": "Breton" },
  { "code": "bs", "name": "Bosnian" },
  { "code": "ca", "name": "Catalan; Valencian" },
  { "code": "ce", "name": "Chechen" },
  { "code": "ch", "name": "Chamorro" },
  { "code": "co", "name": "Corsican" },
  { "code": "cr", "name": "Cree" },
  { "code": "cs", "name": "Czech" },
  {
    "code": "cu",
    "name": "Church Slavic; Old Slavonic; Church Slavonic; Old Bulgarian; Old Church Slavonic"
  },
  { "code": "cv", "name": "Chuvash" },
  { "code": "cy", "name": "Welsh" },
  { "code": "da", "name": "Danish" },
  { "code": "de", "name": "German" },
  { "code": "dv", "name": "Divehi; Dhivehi; Maldivian" },
  { "code": "dz", "name": "Dzongkha" },
  { "code": "ee", "name": "Ewe" },
  { "code": "el", "name": "Greek, Modern (1453-)" },
  { "code": "eo", "name": "Esperanto" },
  { "code": "es", "name": "Spanish; Castilian" },
  { "code": "et", "name": "Estonian" },
  { "code": "eu", "name": "Basque" },
  { "code": "fa", "name": "Persian" },
  { "code": "ff", "name": "Fulah" },
  { "code": "fi", "name": "Finnish" },
  { "code": "fj", "name": "Fijian" },
  { "code": "fo", "name": "Faroese" },
  { "code": "fr", "name": "French" },
  { "code": "fy", "name": "Western Frisian" },
  { "code": "ga", "name": "Irish" },
  { "code": "gd", "name": "Gaelic; Scomttish Gaelic" },
  { "code": "gl", "name": "Galician" },
  { "code": "gn", "name": "Guarani" },
  { "code": "gu", "name": "Gujarati" },
  { "code": "gv", "name": "Manx" },
  { "code": "ha", "name": "Hausa" },
  { "code": "he", "name": "Hebrew" },
  { "code": "hi", "name": "Hindi" },
  { "code": "ho", "name": "Hiri Motu" },
  { "code": "hr", "name": "Croatian" },
  { "code": "ht", "name": "Haitian; Haitian Creole" },
  { "code": "hu", "name": "Hungarian" },
  { "code": "hz", "name": "Herero" },
  {
    "code": "ia",
    "name": "Interlingua (International Auxiliary Language Association)"
  },
  { "code": "id", "name": "Indonesian" },
  { "code": "ie", "name": "Interlingue; Occidental" },
  { "code": "ig", "name": "Igbo" },
  { "code": "ii", "name": "Sichuan Yi; Nuosu" },
  { "code": "ik", "name": "Inupiaq" },
  { "code": "io", "name": "Ido" },
  { "code": "is", "name": "Icelandic" },
  { "code": "it", "name": "Italian" },
  { "code": "iu", "name": "Inuktitut" },
  { "code": "ja", "name": "Japanese" },
  { "code": "jv", "name": "Javanese" },
  { "code": "ka", "name": "Georgian" },
  { "code": "kg", "name": "Kongo" },
  { "code": "ki", "name": "Kikuyu; Gikuyu" },
  { "code": "kj", "name": "Kuanyama; Kwanyama" },
  { "code": "kk", "name": "Kazakh" },
  { "code": "kl", "name": "Kalaallisut; Greenlandic" },
  { "code": "km", "name": "Central Khmer" },
  { "code": "kn", "name": "Kannada" },
  { "code": "ko", "name": "Korean" },
  { "code": "kr", "name": "Kanuri" },
  { "code": "ks", "name": "Kashmiri" },
  { "code": "ku", "name": "Kurdish" },
  { "code": "kv", "name": "Komi" },
  { "code": "kw", "name": "Cornish" },
  { "code": "ky", "name": "Kirghiz; Kyrgyz" },
  { "code": "la", "name": "Latin" },
  { "code": "lb", "name": "Luxembourgish; Letzeburgesch" },
  { "code": "lg", "name": "Ganda" },
  { "code": "li", "name": "Limburgan; Limburger; Limburgish" },
  { "code": "ln", "name": "Lingala" },
  { "code": "lo", "name": "Lao" },
  { "code": "lt", "name": "Lithuanian" },
  { "code": "lu", "name": "Luba-Katanga" },
  { "code": "lv", "name": "Latvian" },
  { "code": "mg", "name": "Malagasy" },
  { "code": "mh", "name": "Marshallese" },
  { "code": "mi", "name": "Maori" },
  { "code": "mk", "name": "Macedonian" },
  { "code": "ml", "name": "Malayalam" },
  { "code": "mn", "name": "Mongolian" },
  { "code": "mr", "name": "Marathi" },
  { "code": "ms", "name": "Malay" },
  { "code": "mt", "name": "Maltese" },
  { "code": "my", "name": "Burmese" },
  { "code": "na", "name": "Nauru" },
  {
    "code": "nb",
    "name": "Bokmål, Norwegian; Norwegian Bokmål"
  },
  { "code": "nd", "name": "Ndebele, North; North Ndebele" },
  { "code": "ne", "name": "Nepali" },
  { "code": "ng", "name": "Ndonga" },
  { "code": "nl", "name": "Dutch; Flemish" },
  { "code": "nn", "name": "Norwegian Nynorsk; Nynorsk, Norwegian" },
  { "code": "no", "name": "Norwegian" },
  { "code": "nr", "name": "Ndebele, South; South Ndebele" },
  { "code": "nv", "name": "Navajo; Navaho" },
  { "code": "ny", "name": "Chichewa; Chewa; Nyanja" },
  { "code": "oc", "name": "Occitan (post 1500)" },
  { "code": "oj", "name": "Ojibwa" },
  { "code": "om", "name": "Oromo" },
  { "code": "or", "name": "Oriya" },
  { "code": "os", "name": "Ossetian; Ossetic" },
  { "code": "pa", "name": "Panjabi; Punjabi" },
  { "code": "pi", "name": "Pali" },
  { "code": "pl", "name": "Polish" },
  { "code": "ps", "name": "Pushto; Pashto" },
  { "code": "pt", "name": "Portuguese" },
  { "code": "qu", "name": "Quechua" },
  { "code": "rm", "name": "Romansh" },
  { "code": "rn", "name": "Rundi" },
  { "code": "ro", "name": "Romanian; Moldavian; Moldovan" },
  { "code": "rw", "name": "Kinyarwanda" },
  { "code": "sa", "name": "Sanskrit" },
  { "code": "sc", "name": "Sardinian" },
  { "code": "sd", "name": "Sindhi" },
  { "code": "se", "name": "Northern Sami" },
  { "code": "sg", "name": "Sango" },
  { "code": "si", "name": "Sinhala; Sinhalese" },
  { "code": "sk", "name": "Slovak" },
  { "code": "sl", "name": "Slovenian" },
  { "code": "sm", "name": "Samoan" },
  { "code": "sn", "name": "Shona" },
  { "code": "so", "name": "Somali" },
  { "code": "sq", "name": "Albanian" },
  { "code": "sr", "name": "Serbian" },
  { "code": "ss", "name": "Swati" },
  { "code": "st", "name": "Sotho, Southern" },
  { "code": "su", "name": "Sundanese" },
  { "code": "sv", "name": "Swedish" },
  { "code": "sw", "name": "Swahili" },
  { "code": "ta", "name": "Tamil" },
  { "code": "te", "name": "Telugu" },
  { "code": "tg", "name": "Tajik" },
  { "code": "th", "name": "Thai" },
  { "code": "ti", "name": "Tigrinya" },
  { "code": "tk", "name": "Turkmen" },
  { "code": "tl", "name": "Tagalog" },
  { "code": "tn", "name": "Tswana" },
  { "code": "to", "name": "Tonga (Tonga Islands)" },
  { "code": "tr", "name": "Turkish" },
  { "code": "ts", "name": "Tsonga" },
  { "code": "tt", "name": "Tatar" },
  { "code": "tw", "name": "Twi" },
  { "code": "ty", "name": "Tahitian" },
  { "code": "ug", "name": "Uighur; Uyghur" },
  { "code": "uk", "name": "Ukrainian" },
  { "code": "ur", "name": "Urdu" },
  { "code": "uz", "name": "Uzbek" },
  { "code": "ve", "name": "Venda" },
  { "code": "vi", "name": "Vietnamese" },
  { "code": "vo", "name": "Volapük" },
  { "code": "wa", "name": "Walloon" },
  { "code": "wo", "name": "Wolof" },
  { "code": "xh", "name": "Xhosa" },
  { "code": "yi", "name": "Yiddish" },
  { "code": "yo", "name": "Yoruba" },
  { "code": "za", "name": "Zhuang; Chuang" },
  { "code": "zh", "name": "Chinese" },
  { "code": "zu", "name": "Zulu" }
]

export const currencies = [
  {
    value: 'AMD',
    name: 'AMD',
  },
  {
    value: 'USD',
    name: 'USD',
  },
  {
    value: 'EUR',
    name: 'EUR',
  },
  {
    value: 'RUB',
    name: 'RUB',
  }
];

export const getSortedWithContinents = countries => {
  const result = {};
  countries.forEach(item => {
    result[item.region] = result[item.region] || [];
    result[item.region].push(item);
  });
  return result;
};
