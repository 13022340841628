import React, { useEffect, useState } from 'react';
import { useSnackbar } from 'notistack';
import { useDispatch, useSelector } from 'react-redux';
import { onSetPosters } from 'app/Main/actions';
import { Api } from 'utils/connectors';
import { getImageUrl, reorder, confirmBox, getError } from 'utils/appHelpers';
import Header from 'shared/components/layouts/Header';
import InfiniteScroll from 'react-infinite-scroll-component';
import ListEditBox from 'shared/components/ListEditBox';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import PostersManageModal from './components/PostersManageModal';
import CreateButton from 'shared/components/CreateButton';
import { PermissionService } from 'services/PermissionService';

const Posters = () => {
  const { enqueueSnackbar } = useSnackbar();
  const posters = useSelector(state => state.posters);
  const dispatch = useDispatch();
  const [manageModal, setManageModal] = useState({
    state: false,
    data: null,
  });

  const getPosters = async isNext => {
    try {
      const page = isNext ? posters.number : 0;
      const { data } = await Api.get('/home/posters', {
        params: {
          size: 100,
          page: isNext ? page + 1 : page,
        },
      });

      if (isNext) data.content = [...posters.content, ...data.content];
      dispatch(onSetPosters({ ...data, content: data.content }));
    } catch (err) {
      enqueueSnackbar(getError(err), { variant: 'error' });
    }
  };

  const onDragEnd = async e => {
    if (!e.destination) return;
    try {
      const items = reorder(posters.content, e.source.index, e.destination.index);
      dispatch(onSetPosters({ ...posters, content: items }));
      await Api.put(`/home/posters/${e.draggableId}/reorder?order=${e.destination.index}`);
    } catch (err) {
      enqueueSnackbar(getError(err), { variant: 'error' });
    }
  };

  const removeItem = async (id, index) => {
    try {
      await Api.delete(`/home/posters/${id}`);
      const tempItems = [...posters.content];
      tempItems.splice(index, 1);
      dispatch(onSetPosters({ ...posters, content: tempItems }));
    } catch (err) {
      enqueueSnackbar(getError(err), { variant: 'error' });
    }
  };

  const onItemRemove = (id, index) => {
    const options = {
      onSuccess: removeItem.bind(null, id, index),
      message: `Are you sure you want to delete this poster?`,
      confirm: 'Delete',
    };
    confirmBox(options);
  };

  const toggleItem = async (item, index) => {
    try {
      const action = item.published ? 'unpublish' : 'publish';
      await Api.patch(`/home/posters/${item.id}/${action}`);
      const tempItems = [...posters.content];
      tempItems[index].published = !item.published;
      dispatch(onSetPosters({ ...posters, content: tempItems }));
    } catch (err) {
      enqueueSnackbar(getError(err), { variant: 'error' });
    }
  };

  const checkItemPublish = item => {
    const { media, image } = item;
    if (!media || !image) {
      return false;
    }
    return true;
  };

  const onTogglePublish = (item, index) => {
    if (!item.published && !checkItemPublish(item)) {
      enqueueSnackbar(`You can't publish the item, media is incomplete`);
      return;
    }
    if (item.published) {
      const options = {
        onSuccess: toggleItem.bind(null, item, index),
        message: `Are you sure you want to unpublish this poster?`,
        confirm: 'Unpublish',
      };
      confirmBox(options);
      return;
    }
    toggleItem(item, index);
  };

  const onItemEdit = e => {
    setManageModal({ data: e, state: true });
  };

  const onItemCreate = () => {
    setManageModal({ data: null, state: true });
  };

  const onModalClose = () => {
    setManageModal({ data: null, state: false });
  };

  useEffect(() => {
    getPosters();
    //eslint-disable-next-line
  }, []);

  return (
    <>
      <Header>
        <div className='d-flex align-items-center'>Posters</div>
      </Header>
      <main className='main-content'>
        <div className='mx-2 px-3 bg-white'>
          <div className='inner-filter d-flex justify-content-between align-items-center py-3'>
            <div className='text-primary__light'>Posters List</div>
            <div className='d-flex'>
              <CreateButton
                name='ADD POSTER'
                onClick={onItemCreate}
                disabled={!PermissionService.hasAccess('media_create')}
              />
            </div>
          </div>
          <InfiniteScroll
            dataLength={(posters.content && posters.content.length) || 0}
            next={() => getPosters(true)}
            hasMore={posters.content && posters.content.length < posters.total_elements}
            loader={<div>Loading...</div>}
          >
            <DragDropContext onDragEnd={onDragEnd}>
              <Droppable droppableId='droppable'>
                {(provided, snapshot) => (
                  <div {...provided.droppableProps} className='mt-5' ref={provided.innerRef}>
                    {posters.content &&
                      posters.content.map((item, index) => (
                        <Draggable
                          key={item.id}
                          draggableId={String(item.id)}
                          index={index}
                          isDragDisabled={!PermissionService.hasAccess('media_edit')}
                        >
                          {(provided, snapshot) => {
                            const img = getImageUrl(item.media.image);
                            return (
                              <div
                                className='mb-3'
                                ref={provided.innerRef}
                                {...provided.draggableProps}
                                {...provided.dragHandleProps}
                              >
                                <ListEditBox
                                  isDragging={snapshot.isDragging}
                                  num={index + 1}
                                  key={item.id}
                                  title={item.media.title}
                                  description={item.media.description}
                                  published={item.published}
                                  img={img}
                                  onSwitchChange={onTogglePublish.bind(null, item, index)}
                                  switchCheck={item.published}
                                  onItemRemove={onItemRemove.bind(null, item.id, index)}
                                  onItemEdit={onItemEdit.bind(null, item)}
                                />
                              </div>
                            );
                          }}
                        </Draggable>
                      ))}
                    {provided.placeholder}
                  </div>
                )}
              </Droppable>
            </DragDropContext>
          </InfiniteScroll>
        </div>
      </main>
      {manageModal.state && (
        <PostersManageModal
          state={manageModal}
          posters={posters}
          onSuccess={getPosters}
          onClose={onModalClose}
        />
      )}
    </>
  );
};

export default Posters;
