import React, { useEffect, useState } from 'react';
import Modal from 'shared/components/modals/Modal';
import PlanGeneralForm from './PlanGeneralForm';
import PlanCountryForm from './PlanCountryForm';
import { useSnackbar } from 'notistack';
import { Api } from 'utils/connectors';
import { getError } from 'utils/appHelpers';
import { countries } from 'configs/countries';
import { useSelector } from 'react-redux';
import { onProductsSet, setTotalValue } from 'app/Main/actions';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router';
import { sortDataWithKey } from 'utils/sortingHelper';

const getInitialState = () => {
  return {
    name: '',
    description: '',
    published: false,
    in_app_product_ids: [],
    blocked_countries: [],
  };
};

const PlanManageModal = ({ onClose, state, onSuccess, lang }) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const productType = 'renewable';
  const products = useSelector(state => state.products[productType]);
  const { enqueueSnackbar } = useSnackbar();
  const [modal, setModal] = useState({
    name: 'general',
    state: null,
  });
  const [fetching, setFetching] = useState(false);
  const [form, setForm] = useState(
    state.lastForm ? state.lastForm : state.plan ? state.plan : { ...getInitialState() },
  );

  const createPlan = body => {
    return Api.post(`/subscription-plans?locale=${lang}`, body);
  };

  const updatePlan = body => {
    return Api.put(`/subscription-plans/${state.plan.id}?locale=${lang}`, { ...body });
  };

  const onFormChange = ({ target }) => {
    const { name, value } = target;
    setForm({ ...form, [name]: value });
  };

  const onTogglePublish = value => {
    setForm({ ...form, published: value });
  };

  const getProducts = async () => {
    try {
      const res = await Api.get('/products', {
        params: { type: String(productType).toUpperCase(), size: 1000, page: 0 },
      });
      res.data.content = sortDataWithKey(res.data.content);
      dispatch(onProductsSet({ [productType]: res.data }));
      dispatch(setTotalValue(`all_products_${productType}`, res.data.total_elements));
    } catch (err) {
      enqueueSnackbar(getError(err), { variant: 'error' });
    }
  };

  const getSubscriptionData = async id => {
    try {
      const { data } = await Api.get(`/subscription-plans/${id}`, { params: { locale: lang } });
      data.in_app_product_ids = data.in_app_products.map(i => i.id);
      setForm(data);
    } catch (err) {
      enqueueSnackbar(getError(err), { variant: 'error' });
    }
  };

  const constructForm = form => {
    const {
      name,
      description,
      apple_group_identifier,
      google_group_identifier,
      published,
      in_app_product_ids = [],
      blocked_countries,
      img_url,
    } = form;
    const result = {
      apple_group_identifier,
      google_group_identifier,
      name,
      description,
      published,
      in_app_product_ids: in_app_product_ids,
      blocked_countries,
      img_url,
    };
    return result;
  };

  const onFormSubmit = async e => {
    e.preventDefault();
    try {
      setFetching(true);
      const body = constructForm({ ...form });
      const fd = new FormData();
      fd.append('image', body.img_url);
      let blob = new Blob([JSON.stringify(body)], {
        type: 'application/json',
      });
      fd.append('dto', blob);
      state.plan ? await updatePlan(body) : await createPlan(fd);
      if (onSuccess) onSuccess();
      enqueueSnackbar('Successfully done', { variant: 'success' });
      setFetching(false);
      onClose();
    } catch (err) {
      enqueueSnackbar(getError(err), { variant: 'error' });
      setFetching(false);
    }
  };

  const onSelectModalState = (name, state = null) => {
    setModal({ name, state });
  };

  const onProductToggle = item => {
    const tempForm = { ...form };
    const exist = tempForm.in_app_product_ids.includes(item.id);
    if (exist) {
      tempForm.in_app_product_ids.splice(tempForm.in_app_product_ids.indexOf(item.id), 1);
    } else {
      tempForm.in_app_product_ids.push(item.id);
    }
    setForm(tempForm);
  };

  const onToggleAllProducts = bool => {
    const tempForm = { ...form };
    if (!bool) {
      tempForm.in_app_product_ids = [...products?.content.map(i => i.id)];
    } else {
      tempForm.in_app_product_ids = [];
    }
    setForm(tempForm);
  };

  const onModalClose = () => {
    if (modal.name === 'general') {
      onClose();
    } else {
      onSelectModalState('general', null);
    }
  };

  const goToProductCreation = e => {
    e.preventDefault();
    history.push('/products/renewable', { form });
  };

  useEffect(() => {
    getProducts();
    if (state?.plan && !state.lastForm) getSubscriptionData(state.plan.id);
  }, []);

  return (
    <Modal size='xs' onClose={onModalClose} className='mx-2 mt-2 plan-manage-modal'>
      {modal.name === 'general' && (
        <PlanGeneralForm
          form={form}
          setForm={setForm}
          onFormSubmit={onFormSubmit}
          onTogglePublish={onTogglePublish}
          onFormChange={onFormChange}
          fetching={fetching}
          isEdit={state.plan}
          onClose={onModalClose}
          onEditCountry={() => onSelectModalState('country')}
          onProductToggle={onProductToggle}
          onToggleAllProducts={onToggleAllProducts}
          allCountries={countries}
          products={products?.content || []}
          goToProductCreation={goToProductCreation}
          onSuccess={getProducts}
          lang={lang}
        />
      )}
      {modal.name === 'country' && (
        <PlanCountryForm
          form={form}
          setForm={setForm}
          onClose={onModalClose}
          allCountries={countries}
        />
      )}
    </Modal>
  );
};

export default PlanManageModal;
