import React, { useEffect, useState, memo } from 'react';
import { Api } from 'utils/connectors';
import { useSnackbar } from 'notistack';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { confirmBox, getError, getQueryParam } from 'utils/appHelpers';
import { onSetRoles } from 'app/Main/actions';
import InfiniteScroll from 'react-infinite-scroll-component';
import CreateButton from 'shared/components/CreateButton';
import StateHolder from 'shared/components/StateHolder';
import RoleManageModal from './RoleManageModal';
import Loading from 'shared/components/loadings/Loading';
import ActionButton from 'shared/components/ActionButton';
import { PermissionService } from 'services/PermissionService';
import SearchBox from 'shared/components/forms/SearchBox';

const RolesList = ({ lang }) => {
  const { push } = useHistory();
  const { enqueueSnackbar } = useSnackbar();
  const roles = useSelector(state => state.roles[lang]) ?? {};
  const dispatch = useDispatch();
  const [fetching, setFetching] = useState(false);
  const [search, setSearch] = useState('');
  const [manageModal, setManageModal] = useState({
    data: null,
    state: false,
  });

  const action = getQueryParam('action', null);

  const getData = async isNext => {
    if (fetching) return;
    try {
      setFetching(true);
      const page = isNext ? roles.number : 0;
      const params = {
        size: 100,
        page: isNext ? page + 1 : page,
        name: search || undefined,
        locale: lang,
      };
      const res = await Api.get(`/casts/roles`, { params });
      if (isNext) res.data.content = [...roles.content, ...res.data.content];
      dispatch(onSetRoles({ [lang]: res.data }));
    } catch (err) {
      enqueueSnackbar(getError(err), { variant: 'error' });
    } finally {
      setFetching(false);
    }
  };

  const removeItem = async (id, index) => {
    try {
      await Api.delete(`/casts/roles/${id}?locale=${lang}`);
      const tempItems = [...roles.content];
      tempItems.splice(index, 1);
      dispatch(onSetRoles({ [lang]: { ...roles, content: tempItems } }));
      enqueueSnackbar('Successfully removed.', { variant: 'success' });
      getData();
    } catch (err) {
      enqueueSnackbar(getError(err), { variant: 'error' });
    }
  };

  const onItemRemove = (id, index) => {
    const options = {
      onSuccess: () => removeItem(id, index),
      message: `Are you sure you want to delete this item?`,
      confirm: 'Delete',
    };
    confirmBox(options);
  };

  const onItemEdit = data => {
    setManageModal({ data, state: true });
  };

  const onItemCreate = () => setManageModal({ data: null, state: true });

  const onModalClose = () => {
    setManageModal({ data: null, state: false });
    push('/cast-and-crew');
  };

  useEffect(() => {
    getData();
  }, [lang]);

  useEffect(() => {
    if (action === 'create-role') onItemCreate();
  }, [action]);

  return (
    <>
      <div
        className='bg-white h-whitespace-height-minus-lang overflow-auto'
        id='rolesListContainer'
      >
        <div className='bg-purple__light px-4 py-3 d-flex align-items-center justify-content-between'>
          <div className='text-primary__light'>Roles</div>
        </div>
        <div className='d-flex align-items-center justify-content-between pt-4 pb-2 px-4'>
          <SearchBox
            onChange={getData}
            search={search}
            setSearch={setSearch}
            className='col-12 col-md-7'
          />
          {PermissionService.hasAccess('cast_and_crew_create') && (
            <CreateButton name='CREATE' onClick={onItemCreate} size='sm' />
          )}
        </div>
        {!roles.content ? (
          <StateHolder>
            <Loading />
          </StateHolder>
        ) : !roles.content.length ? (
          <StateHolder>
            <span className='mb-4'>There is no data yet.</span>
          </StateHolder>
        ) : (
          <>
            <InfiniteScroll
              scrollableTarget='rolesListContainer'
              dataLength={(roles.content && roles.content.length) || 0}
              next={() => getData(true)}
              hasMore={roles.content && roles.content.length < roles.total_elements}
              loader={<div className='text-center'>Loading...</div>}
            >
              {roles.content && (
                <div>
                  <table className='table'>
                    <thead>
                      <tr>
                        <th className='border-0 text-primary pl-5'>Role</th>
                        <th className='border-0 pr-5' />
                      </tr>
                    </thead>
                    <tbody>
                      {roles.content.map((item, index) => (
                        <tr key={item.id}>
                          <td className='pl-5'>
                            {item.name ?? (
                              <i className='text-muted'>Missing role title for selected locale</i>
                            )}
                          </td>
                          <td className='pr-5'>
                            <div className='d-flex align-items-center justify-content-end'>
                              {PermissionService.hasAccess('cast_and_crew_edit') && (
                                <ActionButton type='edit' onClick={() => onItemEdit(item)} />
                              )}
                              {PermissionService.hasAccess('cast_and_crew_delete') && (
                                <ActionButton onClick={() => onItemRemove(item.id, index)} />
                              )}
                            </div>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              )}
            </InfiniteScroll>
          </>
        )}
      </div>
      {manageModal.state && (
        <RoleManageModal
          data={manageModal.data}
          onSuccess={getData}
          onClose={onModalClose}
          lang={lang}
        />
      )}
    </>
  );
};

export default memo(RolesList);
