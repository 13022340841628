import React, { useEffect, useState } from 'react';
import { useSnackbar } from 'notistack';
import { useDispatch, useSelector } from 'react-redux';
import { onSetCategories } from 'app/Main/actions';
import { Api } from 'utils/connectors';
import { getImageUrl, confirmBox, reorder, getError } from 'utils/appHelpers';
import Header from 'shared/components/layouts/Header';
import ListEditBox from 'shared/components/ListEditBox';
import LangToggle from 'shared/components/LangToggle';
import IconPlus from 'assets/images/icons/plus.svg';
import Loading from 'shared/components/loadings/Loading';
import DragList from 'shared/components/layouts/DragList';
import CategoriesManageModal from './components/CategoriesManageModal';
import HomeItemManageModal from './components/HomeItemManageModal';
import CreateButton from 'shared/components/CreateButton';
import { PermissionService } from 'services/PermissionService';

const Categories = () => {
  const { enqueueSnackbar } = useSnackbar();
  const [categoryModal, setCategoryModal] = useState(false);
  const categories = useSelector(state => state.categories);
  const dispatch = useDispatch();
  const [activeCategory, setActiveCategory] = useState(0);
  const [lang, setLang] = useState('en');
  const [manageModal, setManageModal] = useState({
    state: false,
    data: null,
  });

  const currentCategories = (categories && categories[lang]) || [];
  const currentData = currentCategories[activeCategory];

  const getTypes = async () => {
    try {
      const res = await Api.get('/home/categories/types', { params: { locale: lang } });
      dispatch(onSetCategories({ ...categories, [lang]: res.data }));
    } catch (err) {
      enqueueSnackbar(getError(err), { variant: 'error' });
    }
  };

  const onDragEnd = async e => {
    if (!e.destination) return;
    try {
      const tempCategories = { ...categories };
      tempCategories[lang][activeCategory].items = reorder(
        tempCategories[lang][activeCategory].items,
        e.source.index,
        e.destination.index,
      );
      dispatch(onSetCategories(tempCategories));
      await Api.put(
        `/home/items/${e.draggableId}/reorder?categoryId=${currentData.id}&order=${
          e.destination.index
        }`,
      );
    } catch (err) {
      enqueueSnackbar(getError(err), { variant: 'error' });
    }
  };

  const removeItem = async (id, index) => {
    try {
      await Api.delete(`/home/items/${id}`);
      const tempCategories = { ...categories };
      tempCategories[lang][activeCategory].items.splice(index, 1);
      dispatch(onSetCategories(tempCategories));
      enqueueSnackbar('Successfully done', { variant: 'success' });
    } catch (err) {
      enqueueSnackbar(getError(err), { variant: 'error' });
    }
  };

  const onItemRemove = (id, index) => {
    const options = {
      onSuccess: removeItem.bind(null, id, index),
      message: `Are you sure you want to delete the upcoming media?`,
      confirm: 'Delete',
    };
    confirmBox(options);
  };

  const toggleItem = async (item, index) => {
    try {
      const action = item.published ? 'unpublish' : 'publish';
      await Api.patch(`/home/items/${item.id}/${action}`);
      const tempCategories = { ...categories };
      tempCategories[lang][activeCategory].items[index].published = !item.published;
      if (!item.published) {
        tempCategories[lang][activeCategory].items[index].publish_date = null;
      }
      dispatch(onSetCategories(tempCategories));
      enqueueSnackbar('Successfully done', { variant: 'success' });
    } catch (err) {
      enqueueSnackbar(getError(err), { variant: 'error' });
    }
  };

  const onItemEdit = e => {
    setManageModal({ data: e, state: true });
  };

  const onItemCreate = () => {
    setManageModal({ data: null, state: true });
  };

  const onModalClose = () => {
    setManageModal({ data: null, state: false });
  };

  useEffect(() => {
    getTypes();
    //eslint-disable-next-line
  }, [lang]);

  if (!currentData) return <Loading className='w-100 mt-5' />;

  return (
    <>
      <Header>
        <div className='d-flex align-items-center'>Categories</div>
      </Header>
      <main className='main-content'>
        <div className='d-flex justify-content-end px-3 mb-2'>
          <LangToggle lang={lang} setLang={setLang} />
        </div>
        <div className='mx-2 px-3 bg-white'>
          <div className='bg-purple__light row px-3 py-2 d-flex align-items-center justify-content-between'>
            <div className='d-flex'>
              <div className='d-flex align-items-center flex-wrap'>
                {currentCategories &&
                  currentCategories.map((item, i) => (
                    <button
                      key={i}
                      className={`btn btn-sm weight-300 no-decoration no-outline mt-1 ${
                        activeCategory === i ? 'btn-primary' : 'btn-link'
                      }`}
                      onClick={() => setActiveCategory(i)}
                    >
                      {item.name}
                    </button>
                  ))}
              </div>
              <div className='border__left border__default pl-3 ml-3 d-flex'>
                <button
                  className='btn btn-sm weight-300 btn-link'
                  onClick={() => setCategoryModal(true)}
                  disabled={!PermissionService.hasAccess('media_create')}
                >
                  <img src={IconPlus} alt='plus' />
                </button>
              </div>
            </div>
          </div>
          <div className='inner-filter d-flex justify-content-between align-items-center py-3'>
            <div className='text-primary__light'>Movies</div>
            <div className='d-flex'>
              <CreateButton
                name='ADD HOME ITEM'
                onClick={onItemCreate}
                disabled={!PermissionService.hasAccess('media_create')}
              />
            </div>
          </div>
          <div className='mt-5 pb-3'>
            <DragList
              onDragEnd={onDragEnd}
              items={currentData.items}
              isDragDisabled={!PermissionService.hasAccess('media_edit')}
            >
              {(item, index) => {
                const img = getImageUrl(item.media.image);
                return (
                  <ListEditBox
                    num={index + 1}
                    key={item.id}
                    title={item.media.title}
                    description={item.media.description}
                    published={item.published}
                    img={img}
                    onSwitchChange={toggleItem.bind(null, item, index)}
                    onItemRemove={onItemRemove.bind(null, item.id, index)}
                    onItemEdit={onItemEdit.bind(null, item)}
                    switchCheck={item.published}
                    disabled={false}
                  />
                );
              }}
            </DragList>
          </div>
        </div>
      </main>
      {categoryModal && (
        <CategoriesManageModal
          onSuccess={getTypes}
          onClose={() => setCategoryModal(false)}
          lang={lang}
          setLang={setLang}
        />
      )}
      {manageModal.state && (
        <HomeItemManageModal
          state={manageModal}
          onSuccess={getTypes}
          onClose={onModalClose}
          categories={currentCategories}
          activeCategory={currentData}
        />
      )}
    </>
  );
};

export default Categories;
