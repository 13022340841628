import React, { useState, useRef } from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import IconCarretDown from 'assets/images/icons/carret-down.svg';
import { ReactComponent as IconTimesGrayOutlined } from 'assets/images/icons/times-gray-outlined.svg';
import useOutsideClick from 'shared/hooks/useOutsideClick';

const generateGroupedSelectedItems = (arr, isLimitReached, isSuperShort) => {
  const superShortGroupedItems = `${arr[0]}, ${arr[1]} +${arr.length - 2}`;
  const shortGroupedItems = `${arr[0]}, ${arr[1]}, ${arr[2]}, ${arr[3]} +${arr.length - 4}`;
  const groupedItem = (isSuperShort ? superShortGroupedItems : shortGroupedItems).replace('_', ' ');
  return isLimitReached ? [groupedItem] : arr;
};

const FilterItem = ({ filterKey, selectedItems, onChange, disabled }) => {
  const container = useRef();
  const [open, setOpen] = useState(!disabled);
  const { pathname } = useLocation();
  const { filters } = useSelector(state => state);

  const isRevenuePage = pathname.includes('revenue');
  const limit = isRevenuePage ? 3 : 5;
  const isLimitReached = selectedItems.length >= limit;

  const handleClick = () => {
    !disabled && setOpen(!open);
  };

  const toggleFilter = (e, value) => {
    e.stopPropagation();
    !disabled && onChange({ key: filterKey, value });
  };

  useOutsideClick(container, () => setOpen(false));

  // const filterOptions = {
  //   countries: countries || [],
  //   device_types: device_types || [],
  //   channels: channels?.content.map(item => item.name) || [],
  // };

  const shortSelectedItems = generateGroupedSelectedItems(
    selectedItems,
    isLimitReached,
    isRevenuePage,
  );

  return (
    <div
      ref={container}
      className={`filter-item mr-3 mb-2 analytics-dropdown ${disabled ? 'disabled' : ''} ${
        open ? 'open' : ''
      }`}
      onClick={handleClick}
      role='presentation'
    >
      <div className='d-inline-flex'>
        <span className='text-capitalize'>
          {filterKey.replace('_', ' ')}
          <img
            src={IconCarretDown}
            alt='carret'
            className={`ml-1 transition-150 ${open ? 'flip-x' : ''}`}
            width='20'
            height='20'
          />
        </span>
        <ul className='dropdown-list dropdown-list__dense'>
          {!!filters[filterKey].length &&
            filters[filterKey].map(({ name, count }) => {
              return (
                <li
                  className={`dropdown-item text-capitalize ${
                    selectedItems.includes(name) ? 'dropdown-item__selected' : ''
                  }`}
                  key={name}
                  onClick={e => toggleFilter(e, name)}
                  role='presentation'
                >
                  {name.replace('_', ' ')}
                </li>
              );
            })}
        </ul>
      </div>
      <ul className='selected-filters-list'>
        {shortSelectedItems.map(name => (
          <li className='text-light ml-2 text-nowrap text-capitalize' key={name}>
            {name.replace('_', ' ')}
            {!isLimitReached && (
              <IconTimesGrayOutlined
                className='filter-remove-btn'
                width='20'
                height='20'
                onClick={e => toggleFilter(e, name)}
              />
            )}
          </li>
        ))}
      </ul>
    </div>
  );
};

export default FilterItem;
