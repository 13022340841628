import React, { useEffect, useState } from 'react';
import { useSnackbar } from 'notistack';
import { useDispatch, useSelector } from 'react-redux';
import { onSetUpcomings, setTotalValue } from 'app/Main/actions';
import { Api } from 'utils/connectors';
import { getImageUrl, reorder, confirmBox, getError } from 'utils/appHelpers';
import Header from 'shared/components/layouts/Header';
import InfiniteScroll from 'react-infinite-scroll-component';
import ListEditBox from 'shared/components/ListEditBox';
import LangToggle from 'shared/components/LangToggle';
import DragList from 'shared/components/layouts/DragList';
import UpcomingManageModal from './components/UpcomingManageModal';
import CreateButton from 'shared/components/CreateButton';
import { PermissionService } from 'services/PermissionService';

const Upcoming = () => {
  const { enqueueSnackbar } = useSnackbar();
  const upcoming = useSelector(state => state.upcoming);
  const dispatch = useDispatch();
  const [lang, setLang] = useState('en');
  const [manageModal, setManageModal] = useState({
    state: false,
    id: null,
  });

  const current = (upcoming && upcoming[lang]) || {};

  const getMovies = async isNext => {
    try {
      const page = isNext ? current.number : 0;
      const res = await Api.get('/upcoming-medias', {
        params: {
          size: 20,
          page: isNext ? page + 1 : page,
          locale: lang,
        },
      });
      if (isNext) res.data.content = [...current.content, ...res.data.content];
      dispatch(onSetUpcomings({ ...upcoming, [lang]: res.data }));
      dispatch(setTotalValue('all_upcoming', res.data.total_elements));
    } catch (err) {
      enqueueSnackbar(getError(err), { variant: 'error' });
    }
  };

  const onDragEnd = async e => {
    if (!e.destination) return;
    try {
      const items = reorder(current.content, e.source.index, e.destination.index);
      dispatch(onSetUpcomings({ ...upcoming, [lang]: { ...current, content: items } }));
      await Api.put(`/upcoming-medias/${e.draggableId}/reorder?order=${e.destination.index}`);
    } catch (err) {
      enqueueSnackbar(getError(err), { variant: 'error' });
    }
  };

  const removeItem = async (id, index) => {
    try {
      await Api.delete(`/upcoming-medias/${id}`);
      const tempItems = [...current.content];
      tempItems.splice(index, 1);
      dispatch(onSetUpcomings({ ...upcoming, [lang]: { ...current, content: tempItems } }));
      dispatch(setTotalValue('all_upcoming', --current.total_elements, true));
    } catch (err) {
      enqueueSnackbar(getError(err), { variant: 'error' });
    }
  };

  const onItemRemove = (id, index) => {
    const options = {
      onSuccess: removeItem.bind(null, id, index),
      message: `Are you sure you want to delete the upcoming media?`,
      confirm: 'Delete',
    };
    confirmBox(options);
  };

  const toggleItem = async (item, index) => {
    try {
      const action = item.published ? 'unpublish' : 'publish';
      await Api.patch(`/upcoming-medias/${item.id}/${action}`);
      const tempItems = [...current.content];
      tempItems[index].published = !item.published;
      dispatch(onSetUpcomings({ ...upcoming, [lang]: { ...current, content: tempItems } }));
    } catch (err) {
      enqueueSnackbar(getError(err), { variant: 'error' });
    }
  };

  const checkItemPublish = item => {
    const { title, description, image, status } = item;
    if (!title || !description || !image || !['UPLOADED', 'COMPLETE'].includes(status)) {
      return false;
    }
    return true;
  };

  const onTogglePublish = (item, index) => {
    if (!item.published && !checkItemPublish(item)) {
      enqueueSnackbar(`You can't publish the item, media is incomplete`);
      return;
    }
    toggleItem(item, index);
  };

  const onItemEdit = e => {
    setManageModal({ id: e.id, state: true });
  };

  const onItemCreate = () => {
    setManageModal({ id: null, state: true });
  };

  const onModalClose = () => {
    setManageModal({ id: null, state: false });
  };

  const getSelectedMedia = () => {
    if (!manageModal.id || !current || !current.content) return manageModal;
    return { ...manageModal, data: current.content.find(i => i.id === manageModal.id) };
  };

  useEffect(() => {
    getMovies();
    //eslint-disable-next-line
  }, [lang]);

  return (
    <>
      <Header>
        <div className='d-flex align-items-center'>Upcoming</div>
      </Header>
      <main className='main-content'>
        <div className='d-flex justify-content-end px-3 mb-2'>
          <LangToggle lang={lang} setLang={setLang} />
        </div>
        <div className='mx-2 px-3 bg-white'>
          <div className='inner-filter d-flex justify-content-between align-items-center py-3'>
            <div className='text-primary__light'>Medias</div>
            <div className='d-flex'>
              <CreateButton
                name='ADD UPCOMING MEDIA'
                onClick={onItemCreate}
                disabled={!PermissionService.hasAccess('media_create')}
              />
            </div>
          </div>
          <InfiniteScroll
            dataLength={(current.content && current.content.length) || 0}
            next={() => getMovies(true)}
            hasMore={current.content && current.content.length < current.total_elements}
            loader={<div>Loading...</div>}
          >
            <DragList
              onDragEnd={onDragEnd}
              items={current.content}
              isDragDisabled={!PermissionService.hasAccess('media_edit')}
            >
              {(item, index, snapshot) => {
                const img = getImageUrl(item.image);
                return (
                  <ListEditBox
                    isDragging={snapshot.isDragging}
                    num={index + 1}
                    key={item.id}
                    title={item.title}
                    description={item.description}
                    published={item.published}
                    img={img}
                    onSwitchChange={onTogglePublish.bind(null, item, index)}
                    switchCheck={item.published}
                    onItemRemove={onItemRemove.bind(null, item.id, index)}
                    onItemEdit={onItemEdit.bind(null, item)}
                  />
                );
              }}
            </DragList>
          </InfiniteScroll>
        </div>
      </main>
      {manageModal.state && (
        <UpcomingManageModal
          lang={lang}
          setLang={setLang}
          state={getSelectedMedia()}
          onSuccess={getMovies}
          onClose={onModalClose}
        />
      )}
    </>
  );
};

export default Upcoming;
