import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { confirmBox, getError } from 'utils/appHelpers';
import { Api } from 'utils/connectors';
import { useSnackbar } from 'notistack';
import { onSetAdsAssets } from 'app/Main/actions';
import { PermissionService } from 'services/PermissionService';
import Loading from 'shared/components/loadings/Loading';
import ActionButton from 'shared/components/ActionButton';
import ManageAdsAssetsModal from './components/ManageAdsAssetsModal';

const AdsAssets = () => {
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch();
  const data = useSelector(state => state.adsAssets);
  const [manageModal, setManageModal] = useState({ state: null });

  const getAds = async () => {
    try {
      const { data } = await Api.get('/ad-configs/ad-asset');
      dispatch(onSetAdsAssets(data));
    } catch (err) {
      enqueueSnackbar(getError(err), { variant: 'error' });
    }
  };

  const onModalClose = () => {
    setManageModal({ plan: null, state: false });
  };

  const onItemCreateClick = () => {
    setManageModal({ asset: null, state: true });
  };

  const onItemEditClick = item => {
    setManageModal({ asset: item, state: true });
  };

  const removeItem = async (id) => {
    try {
      await Api.delete(`/ad-configs/ad-asset/${id}`);
      enqueueSnackbar('Successfully removed.', { variant: 'success' });
      getAds();
    } catch (err) {
      enqueueSnackbar(getError(err), { variant: 'error' });
    }
  };

  const onItemRemoveClick = (id) => {
    const options = {
      onSuccess: () => removeItem(id),
      message: `Are you sure you want to delete this item?`,
      confirm: 'Delete',
    };
    confirmBox(options);
  };

  useEffect(() => {
    getAds();
  }, []);

  return (
    <div className='flex-fill d-flex flex-column'>
      <div className='px-3 bg-white pb-3'>
        <div className='bg-purple__light row px-4 py-2 d-flex align-items-center justify-content-between'>
          <div className='text-primary__light'>Ads Assets</div>
          <button
            className='btn btn-sm btn-primary btn-crud'
            onClick={onItemCreateClick}
            disabled={!PermissionService.hasAccess('ads_assets_create')}
          >
            Upload Video
          </button>
        </div>
        {!!data?.length ? (
          <div>
            <table className='table'>
              <thead>
                <tr>
                  <th className='border-0 text-primary w-40'>#</th>
                  <th className='border-0 text-primary'>Id</th>
                  <th className='border-0 text-primary'>Name</th>
                  <th className='border-0 text-primary'>Description</th>
                  <th className='border-0 text-primary'>Key</th>
                  <th className='border-0 text-primary text-right w-78' />
                </tr>
              </thead>
              <tbody>
                {data.map((item, index) => {
                  return (
                    <tr key={index}>
                      <td>{index + 1}</td>
                      <td>{item.id}</td>
                      <td>{item.name || '-'}</td>
                      <td className='td-desc'><div>{item.description || '-'}</div></td>
                      <td>
                        <a href={item.object_key} target='_blank' rel='noopener noreferrer'>
                          Video Link
                        </a>
                      </td>
                      <td>
                        <div className='text-right'>
                          <ActionButton
                            className='mr-1'
                            type='edit'
                            onClick={onItemEditClick.bind(null, item)}
                            disabled={!PermissionService.hasAccess('ads_assets_create')}
                          />
                          <ActionButton
                            onClick={onItemRemoveClick.bind(null, item.id)}
                            disabled={!PermissionService.hasAccess('ads_assets_create')}
                          />
                        </div>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        ) : (
          <div className='text-center py-6'>
            <Loading />
          </div>
        )}
      </div>
      {manageModal.state && (
        <ManageAdsAssetsModal
          state={manageModal}
          onSuccess={getAds}
          onClose={onModalClose}
        />
      )}
    </div>
  );
};

export default AdsAssets;
