import React, { useRef, useEffect } from 'react';
import IconTimes from 'assets/images/icons/close.svg';

const Modal = ({
  children,
  size = 'md',
  showDismissButton,
  preventOutsideClick,
  onClose,
  className,
  hideCloseButton,
  type = 'standard',
  parentClassName = '',
  openForm,
}) => {
  const container = useRef();
  const scrollRef = useRef();
  const handleKeydown = e => e.keyCode === 27 && handleClose();

  const handleClose = e => {
    if (preventOutsideClick) return;
    container.current.classList.remove('active');
    setTimeout(() => onClose && onClose(), 300);
  };

  useEffect(() => {
    container.current.classList.add('active');
    if (preventOutsideClick) return;
    document.addEventListener('keydown', handleKeydown, true);
    //eslint-disable-next-line
    return () => document.removeEventListener('keydown', handleKeydown, true);
    //eslint-disable-next-line
  }, []);

  useEffect(() => {
    document.body.classList.add('overflow-hidden');
    return () => document.body.classList.remove('overflow-hidden');
  }, []);

  useEffect(() => {
    scrollRef.current.scrollTop = 0;
  }, [openForm]);

  return (
    <div
      ref={container}
      role='presentation'
      className={`d-flex align-items-center justify-content-center modal modal__${size} modal_type_${type} ${parentClassName}`}
    >
      <div className='modal-overflow-blue' />
      <div
        className={`modal-content ${className}`}
        onClick={e => {
          e.stopPropagation();
        }}
        role='presentation'
        ref={scrollRef}
      >
        {!openForm && !hideCloseButton && (
          <button className='modal-close' onClick={onClose}>
            <img src={IconTimes} alt='times' />
          </button>
        )}
        {showDismissButton && (
          <button type='button' className='modal-dismiss' onClick={handleClose} />
        )}
        {children}
      </div>
    </div>
  );
};

export default Modal;
