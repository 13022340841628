import { seoTitleLength, seoDescriptionLength } from 'configs';
import React from 'react';
import { PermissionService } from 'services/PermissionService';
import InputGroup from 'shared/components/forms/InputGroup';
import TextAreaGroup from 'shared/components/forms/TextAreaGroup';

const defaultMedia = {
  og_title: '',
  og_url: '',
  og_description: '',
};

const SEOTab = ({ media = defaultMedia, updateMovieStore, onSubmit, fetching }) => {
  const handleChange = ({ target }) => {
    const { name, value } = target;
    updateMovieStore({ ...media, [name]: value });
  };

  const titleHasError = media.og_title?.length > seoTitleLength;
  const descriptionHasError = media.og_description?.length > seoDescriptionLength;
  const actionsDisabled = fetching || !PermissionService.hasAccess('media_create');

  return (
    <div className='pt-7 pb-2 px-1'>
      <form onSubmit={onSubmit}>
        <div className='row mb-4'>
          <div className='col-4'>
            <h6 className='mb-1'>SEO Title</h6>
            <p className='text-xs text-muted mb-0'>
              The title you set here will appear in search engine results, like Google Search. It
              will not be visible on your website or in the apps.
            </p>
          </div>
          <div className='col-8'>
            <InputGroup
              type='text'
              name='og_title'
              value={media.og_title}
              onChange={handleChange}
              maxCount={60}
              containerClass='mb-3'
              disabled={fetching}
              errorText={titleHasError ? 'Is too long (maximum is 60 characters)' : ''}
              required
            />
          </div>
        </div>
        <div className='row mb-4'>
          <div className='col-4'>
            <h6 className='mb-1'>URL Handle</h6>
            <p className='text-xs text-muted mb-0'>Set the permalink to your video page.</p>
          </div>
          <div className='col-8'>
            <InputGroup
              type='text'
              name='og_url'
              value={media.og_url}
              onChange={handleChange}
              containerClass='mb-3'
              disabled={fetching}
              required
            />
          </div>
        </div>
        <div className='row mb-4'>
          <div className='col-4'>
            <h6 className='mb-1'>SEO Description</h6>
            <p className='text-xs text-muted mb-0'>
              This description will appear in the search engine results and will not be visible on
              your website or in the apps.
            </p>
          </div>
          <div className='col-8'>
            <TextAreaGroup
              name='og_description'
              value={media.og_description}
              onChange={handleChange}
              maxCount={170}
              disabled={fetching}
              errorText={descriptionHasError ? 'Is too long (maximum is 170 characters)' : ''}
            />
          </div>
        </div>
        <div className='pt-3 text-right'>
          <button
            type='submit'
            className='btn btn-sm btn-outline-primary mnw-100 py-1 text-uppercase weight-400 fz-12'
            disabled={actionsDisabled || titleHasError || descriptionHasError}
          >
            Save
          </button>
        </div>
      </form>
    </div>
  );
};

export default SEOTab;
