import React, { useState } from 'react';
import { Api } from 'utils/connectors';
import { connect } from 'react-redux';
import { loginUser } from './../../actions';
import InputGroup from 'shared/components/forms/InputGroup';
import { getError } from 'utils/appHelpers';

const Login = ({ loginUser }) => {
  const [fetch, setFetch] = useState(false);
  const [errorText, setError] = useState('');
  const [form, setForm] = useState({
    email: '',
    password: '',
  });

  const handleChange = ({ target }) => {
    const { name, value } = target;
    setForm({ ...form, [name]: value });
  };

  const onFormSubmit = async e => {
    setFetch(true);
    e.preventDefault();
    try {
      const data = await Api.post('/auth', form);
      loginUser(data.data);
    } catch (err) {
      setError(err?.response?.status === 404 ? 'User not found' : getError(err));
      setFetch(false);
    }
  };

  return (
    <div className='col-md-7 col-12 right-part px-md-7 py-md-6 p-3 d-flex flex-column justify-content-center'>
      <form
        onSubmit={onFormSubmit}
        className='col-8 d-flex flex-column justify-content-center m-auto'
      >
        <InputGroup
          type='email'
          name='email'
          value={form.email}
          placeholder={'Login'}
          onChange={handleChange}
          errorText={!!errorText}
          required
          className='is-one-line'
          containerClass='mb-3'
          autoFocus
        />
        <InputGroup
          type='password'
          name='password'
          value={form.password}
          placeholder={'Password'}
          onChange={handleChange}
          errorText={errorText}
          required
          className='is-one-line'
          containerClass='mb-3'
        />
        <p>
          <button type='submit' className='btn btn-primary' disabled={fetch}>
            Sign In
          </button>
        </p>
      </form>
    </div>
  );
};

export default connect(
  null,
  { loginUser },
)(Login);
