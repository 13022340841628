import React, { useState } from 'react';
import InputGroup from 'shared/components/forms/InputGroup';
import TextAreaGroup from 'shared/components/forms/TextAreaGroup';
import Switcher from 'shared/components/forms/Switcher';
import DropMenuMultiSelect from 'shared/components/forms/DropMenuMultiSelect';
import UploadZone from 'shared/components/UploadZone';
import UploadProgress from 'shared/components/UploadProgress';
import UpdateImageUpload from 'shared/components/UpdateImageUpload';
import ActionButton from 'shared/components/ActionButton';
import { Api } from 'utils/connectors';
import { getError, imageUploadProgress } from 'utils/appHelpers';
import { useSnackbar } from 'notistack';

const PlanGeneralForm = ({
  form,
  setForm,
  onFormSubmit,
  onTogglePublish,
  onFormChange,
  fetching,
  isEdit,
  onClose,
  onEditCountry,
  allCountries,
  products,
  onProductToggle,
  onToggleAllProducts,
  goToProductCreation,
  onSuccess,
}) => {
  const [imageProgress, setImageProgress] = useState({});
  const { enqueueSnackbar } = useSnackbar();

  const removeThumbnailImage = async () => {
    try {
      setForm({ ...form, img_url: '' });
      if (form) await Api.delete(`/subscription-plans/${form.id}/thumbnail`);
      onSuccess();
    } catch (err) {
      enqueueSnackbar(getError(err), { variant: 'error' });
    }
  };

  const onUploadFile = async file => {
    try {
      setImageProgress({ ...imageProgress, THUMBNAIL: 1 });
      if (isEdit) {
        const fd = new FormData();
        fd.append('image', file[0]);
        const { data: response } = await Api.post(`/subscription-plans/${form.id}/thumbnail`, fd, {
          onUploadProgress: imageUploadProgress.bind(null, setImageProgress, 'THUMBNAIL'),
        });
        setForm({ ...form, img_url: response.img_url });
        return;
      }
      setForm({ ...form, img_url: file[0] });
      onSuccess();
    } catch (err) {
      enqueueSnackbar(getError(err), { variant: 'error' });
    } finally {
      setImageProgress({ ...imageProgress, THUMBNAIL: false });
    }
  };

  return (
    <>
      <div className='modal-title bg-purple__light row px-4 py-3'>
        <div className='text-primary__light'>
          {isEdit ? 'Edit Subscription Plan' : 'Create Subscription Plan'}
        </div>
      </div>
      <div className='d-flex flex-column align-items-center h-100'>
        <form
          onSubmit={onFormSubmit}
          className='d-flex flex-column h-100 justify-content-between pt-3 w-100'
        >
          <div>
            <div className='form-field-container d-flex justify-content-between overflow-initial'>
              <label className='fw-500 mb-0 mt-4 col p-0'>Subscription Image</label>
              <div className='col-7 p-0'>
                {form.img_url ? (
                  imageProgress.THUMBNAIL ? (
                    <UploadProgress progress={imageProgress.THUMBNAIL} />
                  ) : (
                    <div className='mb-3 d-flex align-items-center justify-content-start'>
                      <div>
                        <img
                          height='80'
                          src={isEdit ? form?.img_url : URL.createObjectURL(form?.img_url)}
                          alt='poster'
                          className='rounded__3 object-fit__cover object-position__center'
                        />
                      </div>
                      <div className='d-flex ml-3'>
                        <UpdateImageUpload onDrop={onUploadFile} />
                        <ActionButton onClick={removeThumbnailImage} />
                      </div>
                    </div>
                  )
                ) : (
                  <UploadZone
                    onDrop={onUploadFile}
                    imageProgress={imageProgress.THUMBNAIL}
                    name='Image'
                  />
                )}
              </div>
            </div>
            <InputGroup
              type='text'
              name='name'
              value={form.name || ''}
              placeholder={'Name'}
              outLabel='Title'
              containerClass='d-flex align-items-center justify-content-between'
              onChange={onFormChange}
              className='col-7 p-0'
              required
            />
            <TextAreaGroup
              name='description'
              value={form.description || ''}
              placeholder='Description'
              outLabel='Description'
              containerClass='d-flex justify-content-between'
              className='col-7 p-0'
              onChange={onFormChange}
            />
            <div className='form-field-container d-flex justify-content-between'>
              <label className='fw-500 mb-0 col p-0'>Countries</label>
              <div className='col-7 p-0 d-flex align-items-center'>
                {form.blocked_countries?.length
                  ? `${allCountries.length - form.blocked_countries.length} of ${
                      allCountries.length
                    } countries selected`
                  : 'All Countries Selected'}
                <button
                  className='btn btn-sm text-light-blue ml-1 btn-no-shadow'
                  onClick={onEditCountry}
                >
                  Edit
                </button>
              </div>
            </div>
            <hr className='mb-4' />
            <div className='form-field-container d-flex justify-content-between align-items-center overflow-initial'>
              <label className='fw-500 mb-0 col p-0'>Subscriptions</label>
              <div className='col-7 p-0'>
                <DropMenuMultiSelect
                  title='Selected Subscriptions'
                  placeholder='Select from list'
                  activeItems={form.in_app_product_ids}
                  onChange={onProductToggle}
                  items={products}
                  onToggleAll={onToggleAllProducts}
                  footerLeftSide={
                    // eslint-disable-next-line jsx-a11y/anchor-is-valid
                    <a href='#' onClick={goToProductCreation}>
                      Create New Plan
                    </a>
                  }
                />
              </div>
            </div>
            <InputGroup
              type='text'
              name='apple_group_identifier'
              value={form.apple_group_identifier ? form.apple_group_identifier : ''}
              placeholder={'Apple Group ID'}
              outLabel='Apple Group ID'
              containerClass='d-flex align-items-center justify-content-between'
              onChange={onFormChange}
              className='col-7 p-0'
              required
            />
            <InputGroup
              type='text'
              name='google_group_identifier'
              value={form.google_group_identifier ? form.google_group_identifier : ''}
              placeholder={'Google Group ID'}
              outLabel='Google Group ID'
              containerClass='d-flex align-items-center justify-content-between'
              onChange={onFormChange}
              className='col-7 p-0'
              required
            />
            <div className='form-field-container d-flex justify-content-between'>
              <label className='fw-500 mb-0 col p-0'>Publish</label>
              <div className='col-7 p-0'>
                <Switcher
                  className='d-flex align-items-center'
                  labelClass='text-sm ml-2'
                  switchCheck={form.published || false}
                  onSwitchChange={onTogglePublish}
                />
              </div>
            </div>
          </div>
          <div className='text-right mt-4'>
            <button
              className='btn btn-outline-primary text-md btn-sm btn-crud weight-400 mx-2'
              onClick={onClose}
              type='button'
            >
              Cancel
            </button>
            <button
              className='btn btn-primary btn-sm btn-crud text-md weight-400 ml-1'
              disabled={fetching || !form.in_app_product_ids?.length}
              type='submit'
            >
              {isEdit ? 'Update' : 'Create'}
            </button>
          </div>
        </form>
      </div>
    </>
  );
};

export default PlanGeneralForm;
