import React, { useRef, useState } from 'react';
import Modal from '../modals/Modal';
import ReactSelect from '../forms/ReactSelect';
import MediaVideoUploader from '../MediaVideoUploader';
import { Api } from 'utils/connectors';
import { confirmBox, getError } from 'utils/appHelpers';
import { useSnackbar } from 'notistack';
import { PermissionService } from 'services/PermissionService';
import { allLanguages } from 'configs';
import ActionButton from '../ActionButton';
import CheckBox from '../forms/CheckBox';

const MediaAudioUploaderModal = ({ onClose, movieId, item }) => {
  const { enqueueSnackbar } = useSnackbar();
  const [localTracks, setLocalTrack] = useState(item.audio_tracks || []);
  const [localSubtitles, setLocalSubtitles] = useState(item.subtitles || []);
  const initialForm = { lang: '', movieId: movieId ? Number(movieId) : null, mediaId: item?.id };
  const [formTrack, setFormTrack] = useState({ ...initialForm });
  const newAddedTrack = useRef();
  const [formSubtitle, setFormSubtitle] = useState({ ...initialForm });
  const [fetching, setFetching] = useState(false);

  const onAudioFileSuccess = () => {
    setLocalTrack([...localTracks, { ...newAddedTrack.current }]);
    setFormTrack({ ...initialForm });
  };

  const createAndGetAudioTrackId = async () => {
    try {
      const endpoint = `/medias/${movieId}/audio_track/${item.id}`;
      const lang = formTrack.lang.code;
      const { data } = await Api.put(endpoint, undefined, { params: { lang } });
      newAddedTrack.current = data;
      return item.id;
    } catch (err) {
      enqueueSnackbar(getError(err), { variant: 'error' });
      return null;
    }
  };

  const createAndUploadSubtitle = async (file, selLang, isReplace) => {
    try {
      setFetching(true);
      const endpoint = `/medias/${movieId}/subtitle/${item.id}`;
      const lang = selLang || formSubtitle.lang.code;
      const form = new FormData();
      const newFile = new File([file], 'subtitle.vtt', { type: 'text/vtt' });
      form.append('subtitle', newFile);
      await Api.put(endpoint, form, { params: { lang }, headers: { 'Content-Type': 'txt/vtt' } });
      const langName = allLanguages.find(i => i.code === lang)?.name;
      if (isReplace) {
        const tempSubtitles = [...localSubtitles];
        const index = tempSubtitles.findIndex(i => i.lang === lang);
        tempSubtitles[index] = { lang: lang, display_name: langName, is_active: true };
        setLocalSubtitles(tempSubtitles);
      } else {
        setLocalSubtitles([
          ...localSubtitles,
          { lang: lang, display_name: langName, is_active: true },
        ]);
      }
      setFormSubtitle({ ...initialForm });
    } catch (err) {
      enqueueSnackbar(getError(err), { variant: 'error' });
      return null;
    } finally {
      setFetching(false);
    }
  };

  const onChangeDefaultTrack = async (track, index) => {
    try {
      await Api.post(`/medias/${movieId}/${item.id}/audio_track/${track.id}`);
      const tempTracks = [...localTracks];
      tempTracks.forEach(i => (i.auto_select = false));
      tempTracks[index] = { ...track, auto_select: !track.auto_select };
      setLocalTrack(tempTracks);
    } catch (err) {
      enqueueSnackbar(getError(err), { variant: 'error' });
    }
  };

  const onSubtitleReplace = item => {
    const input = document.createElement('input');
    input.type = 'file';
    input.onchange = e => {
      const file = e.target.files[0];
      createAndUploadSubtitle(file, item.lang, true);
    };
    input.click();
  };

  const onSubtitleRemove = async (subItem, index) => {
    try {
      await Api.delete(`/medias/${movieId}/subtitle/${item.id}?lang=${subItem.lang}`);
      const tempSubtitles = [...localSubtitles];
      tempSubtitles.splice(index, 1);
      setLocalSubtitles(tempSubtitles);
    } catch (err) {
      enqueueSnackbar(getError(err), { variant: 'error' });
    }
  };

  const onAudioRemove = async (audioItem, index) => {
    try {
      await Api.delete(`/medias/${movieId}/audio_track/${item.id}?lang=${audioItem.lang}`);
      const tempTracks = [...localTracks];
      tempTracks.splice(index, 1);
      setLocalTrack(tempTracks);
    } catch (err) {
      enqueueSnackbar(getError(err), { variant: 'error' });
    }
  };

  const onSubtitleRemoveHandler = (item, index) => {
    const options = {
      onSuccess: onSubtitleRemove.bind(null, item, index),
      message: `Are you sure you want to delete the (${item.lang}) subtitle?`,
      confirm: 'Delete',
    };
    confirmBox(options);
  };

  const onAudioRemoveHandler = async (item, index) => {
    const options = {
      onSuccess: onAudioRemove.bind(null, item, index),
      message: `Are you sure you want to delete the (${item.lang}) audio?`,
      confirm: 'Delete',
    };
    confirmBox(options);
  };

  return (
    <Modal
      onClose={onClose}
      size='lg'
      className='mx-2 mt-2 modal-media d-flex flex-column'
      parentClassName='modal-media-wrapper'
    >
      <div className='modal-title bg-purple__light row px-4 py-3 mb-6'>
        <div className='text-primary__light'>Media Audio Settings</div>
      </div>
      <div className='d-flex border-bottom'>
        <div className='col-6 pl-0'>
          <p className='text-lg mb-2'>Current Audio Tracks</p>
          <div>
            <table className='table'>
              <thead>
                <tr>
                  <th className='border-0 text-primary w-40'>#</th>
                  <th className='border-0 text-primary'>Name</th>
                  <th className='border-0 text-primary'>Lang</th>
                  <th className='border-0 text-primary'>Status</th>
                  <th className='border-0 text-center text-primary'>Default</th>
                  <th className='border-0 text-primary text-right'>Actions</th>
                </tr>
              </thead>
              <tbody>
                {localTracks?.map((item, index) => {
                  return (
                    <tr key={index}>
                      <td>{index + 1}</td>
                      <td>{item.display_name || '-'}</td>
                      <td>{item.lang || '-'}</td>
                      <td>{item.is_active ? 'Active' : 'Inactive'}</td>
                      <td className='d-flex justify-content-center'>
                        <CheckBox
                          className='ml-1'
                          disabled={item.auto_select}
                          name={`isDefault${index}`}
                          checked={item.auto_select}
                          onChange={onChangeDefaultTrack.bind(null, item, index)}
                        />
                      </td>
                      <td className='text-right'>
                        <ActionButton
                          type='remove'
                          onClick={onAudioRemoveHandler.bind(null, item, index)}
                          disabled={!PermissionService.hasAccess('media_delete')}
                        />
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        </div>
        <div className='col-6 border-left'>
          <p className='text-lg mb-2'>Add New Audio Track</p>
          <div className='pt-2'>
            <ReactSelect
              placeholder='Select Audio Language'
              options={allLanguages}
              value={formTrack.lang}
              getOptionValue={i => i.code}
              getOptionLabel={i => i.name}
              className={'w-100'}
              onChange={value => setFormTrack({ ...formTrack, lang: value })}
              disabled={fetching}
            />
          </div>
          <MediaVideoUploader
            disabled={!formTrack.lang || fetching}
            onFileSuccess={onAudioFileSuccess}
            placeholder='Upload Audio Track'
            lang={formTrack.lang?.code}
            uploadOptions={{
              type: 'AUDIO_TRACK',
              id: movieId,
              getItemId: createAndGetAudioTrackId,
            }}
          />
        </div>
      </div>
      <div className='d-flex mt-10 border-top pt-3'>
        <div className='col-6 pl-0'>
          <p className='text-lg mb-2'>Current Audio Subtitles</p>
          <div>
            <table className='table'>
              <thead>
                <tr>
                  <th className='border-0 text-primary w-40'>#</th>
                  <th className='border-0 text-primary'>Name</th>
                  <th className='border-0 text-primary'>Lang</th>
                  <th className='border-0 text-primary'>Status</th>
                  <th className='border-0 text-primary text-right'>Actions</th>
                </tr>
              </thead>
              <tbody>
                {localSubtitles?.map((item, index) => {
                  return (
                    <tr key={index}>
                      <td>{index + 1}</td>
                      <td>{item.display_name || '-'}</td>
                      <td>{item.lang || '-'}</td>
                      <td>{item.is_active ? 'Active' : 'Inactive'}</td>
                      <td className='text-right'>
                        <ActionButton
                          className='mr-1'
                          type='refresh'
                          onClick={onSubtitleReplace.bind(null, item)}
                          disabled={!PermissionService.hasAccess('media_delete')}
                        />
                        <ActionButton
                          type='remove'
                          onClick={onSubtitleRemoveHandler.bind(null, item, index)}
                          disabled={!PermissionService.hasAccess('media_delete')}
                        />
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        </div>
        <div className='col-6 border-left'>
          <p className='text-lg mb-2'>Add New Subtitle</p>
          <div className='pt-2'>
            <ReactSelect
              placeholder='Select Audio Language'
              options={allLanguages}
              value={formSubtitle.lang}
              getOptionValue={i => i.code}
              getOptionLabel={i => i.name}
              className={'w-100'}
              onChange={value => setFormSubtitle({ ...formTrack, lang: value })}
              disabled={fetching}
            />
          </div>
          <MediaVideoUploader
            disabled={!formSubtitle.lang || fetching}
            placeholder='Upload Subtitle'
            lang={formSubtitle.lang?.code}
            progressKey={movieId}
            uploadOptions={{
              type: 'SUBTITLE',
              id: movieId,
              getItemId: createAndUploadSubtitle,
            }}
          />
        </div>
      </div>
    </Modal>
  );
};

export default MediaAudioUploaderModal;
