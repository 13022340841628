import { createReducer } from 'utils/reduxHelpers';

const ON_VIDEO_UPLOAD = '@@media/ON_VIDEO_UPLOAD';

export const onVideoUpload = payload => ({ type: ON_VIDEO_UPLOAD, payload });

const uploadHandlers = {
  [ON_VIDEO_UPLOAD]: (state, action) => ({ ...action.payload }),
};

const uploadMediaInitialState = {};

export const uploadMediaReducer = createReducer(uploadMediaInitialState, uploadHandlers);

export const setVideoUpload = proccess => async (dispatch, getState) => {
  const uploads = getState().uploader;
  dispatch(onVideoUpload({ ...uploads, ...proccess }));
};
