import React from 'react';
import defaultImg from 'assets/images/thumbnail-default.jpg';
import { getMediaNavigationUrl } from 'utils/analyticsHelpers';
import { useHistory } from 'react-router-dom';
import MediaTooltip from './MediaTooltip';
import { contentTableHeaders } from 'app/Main/routes/Analytics/configs/consts';
import THeader from '../../../components/THeader';
import { bindDurationHours } from 'utils/appHelpers';

const ContentTable = ({ data, order, onOrderChange }) => {
  const history = useHistory();

  const navigateItem = media => {
    const { category, title } = media;
    history.push({
      pathname: getMediaNavigationUrl(media),
      search: category === 'MOVIE' ? `?category=${category}&title=${title}` : '',
    });
  };

  return (
    <table className='bg-white table table-hover'>
      <thead>
        <tr>
          {contentTableHeaders.map(header => (
            <THeader key={header.name} data={header} order={order} onOrderChange={onOrderChange} />
          ))}
        </tr>
      </thead>
      <tbody>
        {data.map((item, k) => (
          <MediaTooltip key={item.mediaId} data={item}>
            <tr onClick={() => navigateItem(item)} className='pointer'>
              <td className='w-140'>
                <img
                  src={item.image_url ?? defaultImg}
                  alt='img'
                  width='72'
                  height='40'
                  className='radius-4'
                />
              </td>
              <td>
                {item.category === 'TV_SHOW' && (
                  <span className='badge badge-secondary media-badge'>TV Show</span>
                )}
                {item.title}
              </td>
              <td>{item.view_count}</td>
              <td>{bindDurationHours(item.watch_time)}</td>
              <td>{bindDurationHours(item.average)}</td>
            </tr>
          </MediaTooltip>
        ))}
      </tbody>
    </table>
  );
};

export default ContentTable;
