import React, { useState } from 'react';
import { useSnackbar } from 'notistack';
import InputGroup from 'shared/components/forms/InputGroup';
import Modal from 'shared/components/modals/Modal';
import { Api } from 'utils/connectors';
import { getError, getImageUrl, imageUploadProgress } from 'utils/appHelpers';
import LangToggle from 'shared/components/LangToggle';
import TextAreaGroup from 'shared/components/forms/TextAreaGroup';
import Select from 'react-select';
import UploadZone from 'shared/components/UploadZone';
import ActionButton from 'shared/components/ActionButton';
import UpdateImageUpload from 'shared/components/UpdateImageUpload';
import UploadProgress from 'shared/components/UploadProgress';

const EpisodesManageModal = ({
  onClose,
  state,
  updateMovieStore,
  media,
  lang,
  setLang,
  activeSeason,
  updateMovie,
}) => {
  const seaosonsOptions = media.seasons.map(item => ({ label: item.name, value: item.id }));
  const activeOptions = seaosonsOptions.find(i => i.value === media.seasons[activeSeason].id);

  const { enqueueSnackbar } = useSnackbar();
  const [fetching, setFetching] = useState(false);
  const [episode, setEpisode] = useState(state.data);
  const [aSeason, setASeason] = useState(activeOptions);
  const [imageProgress, setImageProgress] = useState({});
  const [form, setForm] = useState({
    title: episode.title || '',
    description: episode.description || '',
  });

  const handleChange = ({ target }) => {
    const { name, value } = target;
    setForm({ ...form, [name]: value });
  };

  const onFormSubmit = async e => {
    e.preventDefault();
    try {
      setFetching(true);
      const { data } = await Api.put(
        `tv-shows/${media.id}/episodes/${episode.id}`,
        { ...episode, ...form },
        {
          params: {
            locale: lang,
            seasonId: aSeason.value,
          },
        },
      );
      const tempMedia = { ...media };
      const index = media.seasons[activeSeason].episodes.findIndex(i => i.id === episode.id);
      tempMedia.seasons[activeSeason].episodes[index] = data;
      updateMovieStore(data);
      updateMovie();
      onClose();
    } catch (err) {
      enqueueSnackbar(getError(err), { variant: 'error' });
      setFetching(false);
    }
  };

  const onUploadFile = async file => {
    const config = {
      onUploadProgress: imageUploadProgress.bind(null, setImageProgress, 'THUMBNAIL'),
    };

    try {
      setImageProgress({ ...imageProgress, THUMBNAIL: 1 });
      const form = new FormData();
      form.append('image', file[0]);
      const { data } = await Api.post(
        `tv-shows/${media.id}/episodes/${episode.id}/images`,
        form,
        config,
      );
      const tempMedia = { ...media };
      const index = media.seasons[activeSeason].episodes.findIndex(i => i.id === episode.id);
      setEpisode(data);
      tempMedia.seasons[activeSeason].episodes[index] = data;
      updateMovieStore(tempMedia);
    } catch (err) {
      enqueueSnackbar(getError(err), { variant: 'error' });
    } finally {
      setImageProgress({ ...imageProgress, THUMBNAIL: false });
    }
  };

  const removeThumbnailImage = async () => {
    try {
      await Api.delete(`tv-shows/${media.id}/episodes/${episode.id}/images`);
      const tempMedia = { ...media };
      const index = media.seasons[activeSeason].episodes.findIndex(i => i.id === episode.id);
      delete tempMedia.seasons[activeSeason].episodes[index].image;
      updateMovieStore(tempMedia);
    } catch (err) {
      enqueueSnackbar(getError(err), { variant: 'error' });
    }
  };

  return (
    <Modal size='xs' onClose={onClose} className='mx-2 mt-2'>
      <div className='modal-title bg-purple__light row px-4 py-3'>
        <div className='text-primary__light'>Edit episode</div>
      </div>
      {!episode && (
        <div className='d-flex justify-content-end px-3 mb-2'>
          <LangToggle lang={lang} setLang={setLang} />
        </div>
      )}
      <div className='signup-modal d-flex flex-column align-items-center'>
        <form onSubmit={onFormSubmit} className='w-100 pt-3'>
          <InputGroup
            type='text'
            name='title'
            value={form.title}
            placeholder={'Name'}
            onChange={handleChange}
            required
          />
          <TextAreaGroup
            name='description'
            value={form.description}
            placeholder={'Description'}
            onChange={handleChange}
            className='mb-4'
          />
          <Select
            value={aSeason}
            onChange={setASeason}
            options={seaosonsOptions}
            className='mb-4'
            placeholder='Category'
          />
          {episode.image ? (
            imageProgress.THUMBNAIL ? (
              <UploadProgress progress={imageProgress.THUMBNAIL} />
            ) : (
              <div className='mb-3 d-flex align-items-center justify-content-between'>
                <div>
                  <img
                    width='120'
                    height='80'
                    src={getImageUrl(episode.image, 'THUMBNAIL')}
                    alt='poster'
                    className='rounded__3 mx-3 object-fit__cover object-position__center'
                  />
                  Thumbnail
                </div>
                <div className='d-flex'>
                  <UpdateImageUpload onDrop={onUploadFile} />
                  <ActionButton onClick={removeThumbnailImage} />
                </div>
              </div>
            )
          ) : (
            <UploadZone
              onDrop={onUploadFile}
              imageProgress={imageProgress.THUMBNAIL}
              name='Thumbnail'
            />
          )}
          <div className='text-right mt-4'>
            <button
              type='button'
              className='btn btn-outline-primary text-md text-uppercase mnw-110 btn-sm py-2 weight-300 mx-2'
              onClick={onClose}
            >
              Close
            </button>
            <button
              className='btn btn-primary btn-sm py-2 text-md text-uppercase weight-300 mnw-110 ml-2'
              disabled={fetching}
              type='submit'
            >
              Save
            </button>
          </div>
        </form>
      </div>
    </Modal>
  );
};

export default EpisodesManageModal;
