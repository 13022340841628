import { allLanguages } from 'configs';
import React from 'react';
import RadioBox from 'shared/components/forms/RadioBox';
import ReactSelect from 'shared/components/forms/ReactSelect';
import Switcher from 'shared/components/forms/Switcher';

const qualityOptions = [
  { name: 'HD 1080', value: 'FHD' },
  { name: 'HD', value: 'HD' },
  { name: 'SD', value: 'SD' },
];

const priorityOptions = [
  { name: 'Low', value: 0 },
  { name: 'Medium', value: 1 },
  { name: 'High', value: 2 },
  { name: 'Immediately', value: 3 },
];

const UploadConvertOptions = ({ convertOptions, setConvertOptions, optionsState }) => {
  const handleChange = (name, value) => {
    setConvertOptions({ ...convertOptions, [name]: value });
  };

  return (
    <div className='publish-options'>
      <div className='row'>
        <div className='col-4'>
          <p>Convert Type</p>
          <div className='d-flex flex-column'>
            {qualityOptions.map(item => {
              return (
                <RadioBox
                  key={item.value}
                  name='resolution'
                  checked={convertOptions.resolution === item.value}
                  onChange={() => handleChange('resolution', item.value)}
                  label={item.name}
                  labelClass='pl-2'
                  className='mb-3'
                />
              );
            })}
          </div>
        </div>
        <div className='col-4'>
          <p>Convert Priority</p>
          <div className='d-flex flex-column'>
            {priorityOptions.map(item => {
              return (
                <RadioBox
                  key={item.value}
                  name='priority'
                  checked={convertOptions.priority === item.value}
                  onChange={() => handleChange('priority', item.value)}
                  label={item.name}
                  labelClass='pl-2'
                  className='mb-3'
                />
              );
            })}
          </div>
        </div>
        <div className='col-4 pl-0'>
          <p>Default Audio Language</p>
          <div className='d-flex flex-column'>
            <ReactSelect
              placeholder='Select Language'
              options={allLanguages}
              value={optionsState?.audioTrackLang}
              getOptionValue={i => i.code}
              getOptionLabel={i => i.name}
              className={'w-100'}
              onChange={value => optionsState.setAudioTrackLang(value)}
              required
            />
          </div>
          <div className='drm-select mt-4'>
            <Switcher
              className='d-flex align-items-center'
              labelClass='ml-2'
              label='Add DRM encryption'
              switchCheck={convertOptions.encrypt}
              onSwitchChange={() => handleChange('encrypt', !convertOptions.encrypt)}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default UploadConvertOptions;
