import moment from 'moment';
import { chartColors } from 'app/Main/routes/Analytics/configs/consts';
import { capitalize, formatNumber } from 'utils/appHelpers';

export const generateBarChartData = rows => {
  let totalValues = 0;
  let biggestValue = 0;

  rows.map(item => {
    totalValues += item.value;
    biggestValue = biggestValue < item.value ? item.value : biggestValue;
    return item;
  });

  return { totalValues, biggestValue, rows };
};

export const generateChartData = data => {
  let lineChartLabels = [];
  let lineChartDatasets = [];

  let pieChartLabels = [];
  let pieChartDatasets = {
    data: [],
    backgroundColor: chartColors,
    borderColor: chartColors,
    borderWidth: 1,
  };

  let totalValues = 0;
  let biggestValue = 0;

  data.forEach((set, setIndex) => {
    if (!set.total) return;

    const setTotalValues = set.total || set.data.reduce((val, item) => val + item.value, 0);
    const data = set.data.map(({ value }) => {
      biggestValue = biggestValue < value ? value : biggestValue;
      return value;
    });
    totalValues += setTotalValues;
    lineChartLabels = set.data.map(({ date }) => date);
    pieChartLabels.push(set.name);

    if (setIndex < 5) {
      lineChartDatasets.push({
        data,
        label: set.name,
        backgroundColor: chartColors[setIndex],
        borderColor: chartColors[setIndex],
        pointBackgroundColor: 'transparent',
        pointHoverBackgroundColor: chartColors[setIndex],
        pointBorderColor: 'transparent',
        pointHoverBorderColor: chartColors[setIndex],
        // Non chart data values
        total: setTotalValues,
      });
      pieChartDatasets.data.push(setTotalValues);
    } else {
      const color = chartColors[setIndex] || chartColors[0];
      lineChartDatasets.push({
        label: set.name,
        backgroundColor: color,
        borderColor: color,
        total: setTotalValues,
      });
    }
  });

  return {
    line: {
      labels: lineChartLabels,
      datasets: lineChartDatasets,
    },
    pie: {
      labels: pieChartLabels,
      datasets: [pieChartDatasets],
    },
    biggestValue,
    totalValues,
  };
};

export const buildParams = (filters, group_by) => {
  const isRevenuePage = window.location.pathname.includes('revenue');
  const {
    dates: { from, to },
    items,
    media_id,
    episode_id,
    revenue_type,
    metric_type,
  } = filters;
  const params = {
    dates: {
      start_date: formatDate(from),
      end_date: formatDate(to),
    },
    filters: {
      ...items,
      ...(media_id && { media_ids: [media_id] }),
      ...(episode_id && { item_ids: [episode_id] }),
      ...(isRevenuePage && { revenue_type, metric_type }),
    },
    ...(group_by && { group_by }),
  };
  return params;
};

export const getDatesStr = (dates = {}) => {
  const { from, to, name } = dates;
  if (name) {
    return name;
  } else if (from && to) {
    return `${formatDate(from, 'MMM DD, YYYY')} - ${formatDate(to, 'MMM DD, YYYY')}`;
  } else {
    return 'Select Dates';
  }
};

export const formatDate = (date, format = 'YYYY-MM-DD') => moment(date).format(format);

export const getSuggestetMax = (value = 0) => value + value / 20;

export const getMediaNavigationUrl = ({ category, mediaId, id, ep_id }) => {
  const isTvShow = category === 'TV_SHOW';
  let url = isTvShow
    ? `/analytics/content/tv-show/${mediaId || id}`
    : `/analytics/content/country/${mediaId || id}${ep_id ? `/${ep_id}` : ''}`;
  return url;
};

export const generateDefaultCSVData = data => {
  if (!data || !data.length) return null;

  const result = [];
  const totalSetsLength = data.length;
  const totalItemsLength = data[0].data.length;

  for (let x = 0; x < totalItemsLength; x++) {
    if (!result[x]) result[x] = [];
    for (let y = 0; y < totalSetsLength; y++) {
      if (y === 0) {
        result[x].push(formatDate(data[y].data[x].date, 'DD/MM/YYYY'));
      }
      result[x].push(data[y].data[x].value);
    }
  }

  const titles = ['Date', ...data.map(({ name }) => capitalize(name))];
  result.unshift(titles);

  return result;
};

export const generateOverviewCSVData = data => {
  delete data.CSV;
  const result = [];
  for (let key in data) {
    result.push({ name: key, data: data[key].rows });
  }
  return generateDefaultCSVData(result);
};

export const generateUsageCSVData = data => {
  const preparedData = [{ name: 'Data Usage', data }];
  return generateDefaultCSVData(preparedData);
};

export const generateTvShowsCSVData = ({ title, data }) => {
  if (!data || !data.length) return null;

  const result = [[title]];
  data.forEach(({ name, movies }, i) => {
    result.push(i === 0 ? [name, 'Views', 'Watchtime', 'Average'] : [name]);
    movies.forEach(({ title, view_count, watch_time, average }) => {
      result.push([title, view_count, watch_time, average]);
    });
  });

  return result;
};

export const minutesToSeconds = minutes => Math.round(minutes / 60);

export const convertArraySecondsToMinutes = (data, keys = ['value']) => {
  const allKeys = [].concat(keys);
  return data.map(item => {
    allKeys.forEach(key => (item[key] = minutesToSeconds(item[key])));
    return item;
  });
};

export const roundVerticalAxisValue = dur => {
  let newDur = 0;
  const intervals = [];
  for (let i = 1; i < 10; i++) {
    intervals.push(i);
  }
  for (let i = 10; i < 100; i += 10) {
    intervals.push(i);
  }
  for (let i = 100; i < 1000; i += 50) {
    intervals.push(i);
  }
  for (let i = 1000; i < 10000; i += 100) {
    intervals.push(i);
  }
  for (let i = 10000; i < 100000; i += 1000) {
    intervals.push(i);
  }
  for (let i = 100000; i < 1000000; i += 10000) {
    intervals.push(i);
  }
  for (let i = 1000000; i < 10000000; i += 100000) {
    intervals.push(i);
  }
  for (let i = 10000000; i < 100000000; i += 1000000) {
    intervals.push(i);
  }
  for (let i = 100000000; i < 1000000000; i += 10000000) {
    intervals.push(i);
  }

  intervals.forEach((v, k) => {
    if (dur >= v && intervals[k + 1] && dur < intervals[k + 1]) {
      newDur = v;
    }
  });
  return newDur;
};

export const modifyChartVerticalAxis = (value, isWatchtime, isPricing) => {
  const fn = formatNumber;
  if (isWatchtime) {
    const h = roundVerticalAxisValue(Math.floor(value / 60 / 60));
    const m = roundVerticalAxisValue(Math.round((value / 60) % 60));
    return h && h < 10 ? `${fn(h)}h ${fn(m)}m` : !h ? `${fn(m)}m` : `${fn(h)}h`;
  }
  if (Math.floor(value) === value) {
    const fValue = fn(value);
    return isPricing ? `$${fValue}` : fValue;
  }
  return value;
};
