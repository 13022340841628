import React from 'react';
import { NavLink } from 'react-router-dom';
import { PermissionService } from 'services/PermissionService';

const NavTabs = ({ className, items }) => {
  if (!items?.length) return null;

  return (
    <div className={`bg-white filters ${className}`}>
      {items.map(
        item =>
          PermissionService.hasAccess(item.permission) && (
            <NavLink to={item.route} className='btn' key={item.name}>
              {item.name}
            </NavLink>
          ),
      )}
    </div>
  );
};

export default NavTabs;
