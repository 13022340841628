import React, { useEffect, useState } from 'react';
import Modal from 'shared/components/modals/Modal';
import { Api } from 'utils/connectors';
import { useSnackbar } from 'notistack';
import { getError } from 'utils/appHelpers';
import Loading from 'shared/components/loadings/Loading';
import { formatDate } from 'utils/analyticsHelpers';

const CheckPhoneMTSModal = ({ onClose, id }) => {
  const { enqueueSnackbar } = useSnackbar();
  const [fetching, setFetching] = useState(false);
  const [data, setData] = useState();

  const getClientInfo = async () => {
    try {
      setFetching(true);
      const purchases = await Api.get(`/clients/${id}/history`);
      setData(purchases.data);
    } catch (err) {
      enqueueSnackbar(getError(err), { variant: 'error' });
    } finally {
      setFetching(false);
    }
  };

  useEffect(() => {
    getClientInfo();
  }, [id]);

  return (
    <Modal size='lg' onClose={onClose} className='mx-2 mt-2'>
      <div className='modal-title bg-purple__light row px-4 py-3'>
        <div className='text-primary__light'>Client Info</div>
      </div>
      <div className='signup-modal d-flex flex-column align-items-center'>
        {fetching && <Loading className='mt-5' />}
        {!fetching && data && (
          <>
            <div className='bg-white pb-3 w-100 mb-5'>
              <div className='bg-purple__light row px-4 py-2 d-flex align-items-center justify-content-between'>
                <div className='text-primary__light'>Subscription History</div>
              </div>
              <div className='max-300-scroll'>
                <table className='table'>
                  <thead>
                    <tr>
                      <th className='border-0 text-primary w-40'>Subscription Name</th>
                      <th className='border-0 text-primary'>Product Name</th>
                      <th className='border-0 text-primary'>Purchase Provider</th>
                      <th className='border-0 text-primary mxw-200'>Status</th>
                      <th className='border-0 text-primary'>Auto Renew Enabled</th>
                      <th className='border-0 text-primary'>Expiry Date</th>
                    </tr>
                  </thead>
                  <tbody>
                    {data?.user_subscription_plan_history?.length
                      ? data.user_subscription_plan_history.map((item, index) => {
                          return (
                            <tr key={index}>
                              <td>{item.subscription_plan_name}</td>
                              <td>{item.in_app_product_name}</td>
                              <td>{item.purchase_provider}</td>
                              <td>{item.status}</td>
                              <td className='text-center'>{item.auto_renew_enabled ? 'Yes' : 'No'}</td>
                              <td>
                                {item.expiry_date
                                  ? formatDate(item.expiry_date, 'MMM DD, YYYY')
                                  : '-'}
                              </td>
                            </tr>
                          );
                        })
                      : null}
                  </tbody>
                </table>
              </div>
            </div>
            <div className='bg-white pb-3 w-100'>
              <div className='bg-purple__light row px-4 py-2 d-flex align-items-center justify-content-between'>
                <div className='text-primary__light'>Purchase History</div>
              </div>
              <div className='max-300-scroll'>
                <table className='table'>
                  <thead>
                    <tr>
                      <th className='border-0 text-primary w-40'>Media Id</th>
                      <th className='border-0 text-primary'>Country</th>
                      <th className='border-0 text-primary'>Purchase Provider</th>
                      <th className='border-0 text-primary mxw-200'>Price</th>
                      <th className='border-0 text-primary mxw-200'>Sold Price</th>
                      <th className='border-0 text-primary'>Purchase Date</th>
                      <th className='border-0 text-primary'>First Play Date</th>
                      <th className='border-0 text-primary'>Expiry Date</th>
                    </tr>
                  </thead>
                  <tbody>
                    {data?.purchased_media_history?.length
                      ? data.purchased_media_history.map((item, index) => {
                          return (
                            <tr key={index}>
                              <td>{item.mediaId}</td>
                              <td>{item.countryName}</td>
                              <td>{item.purchasedVia}</td>
                              <td>
                                {item.price} {item.currencyCode}
                              </td>
                              <td>
                                {item.soldPrice} {item.currencyCode}
                              </td>
                              <td>
                                {item.purchasedDate
                                  ? formatDate(item.purchasedDate, 'MMM DD, YYYY')
                                  : '-'}
                              </td>
                              <td>
                                {item.firstPlayDate
                                  ? formatDate(item.firstPlayDate, 'MMM DD, YYYY')
                                  : '-'}
                              </td>
                              <td>
                                {item.expireDate
                                  ? formatDate(item.expireDate, 'MMM DD, YYYY')
                                  : '-'}
                              </td>
                            </tr>
                          );
                        })
                      : null}
                  </tbody>
                </table>
              </div>
            </div>
          </>
        )}
      </div>
    </Modal>
  );
};

export default CheckPhoneMTSModal;
