/* eslint-disable */
import React, { useEffect } from 'react';
import { Switch, Redirect } from 'react-router-dom';
import { PRoute } from 'services/PermissionService';
import { useDispatch } from 'react-redux';
import { getChannels } from './actions';
import Sidebar from 'shared/components/layouts/Sidebar';
import Analytics from './routes/Analytics';
import Movies from './routes/Movies';
import TVShows from './routes/TVShows';
import Upcoming from './routes/Upcoming';
import Posters from './routes/Posters';
import Categories from './routes/Categories';
import Genres from './routes/Genres';
import Tags from './routes/Tags';
import CastAndCrew from './routes/CastAndCrew';
import MoviesManage from './routes/MoviesManage';
import TVShowsManage from './routes/TVShowsManage';
import Users from './routes/Users';
import Channels from './routes/Channels';
import Configurations from './routes/Configurations';
import Pages from './routes/Pages';
import Products from './routes/Products';
import Plans from './routes/Plans';
import Promos from './routes/Promos';
import Clients from './routes/Clients';
import Finance from './routes/Finance';
import Ads from './routes/Ads';

const Main = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    // Getting initial data
    dispatch(getChannels(null, null, 'en'));
  }, []);

  return (
    <div>
      <Sidebar />
      <div className='page-container'>
        <Switch>
          <PRoute access='analytics_view' path='/analytics' component={Analytics} />
          <PRoute access='media_create' path='/movies/new' component={MoviesManage} />
          <PRoute access='movies_view' path='/movies/:id' component={MoviesManage} />
          <PRoute access='movies_view' path='/movies' component={Movies} />
          <PRoute access='media_create' path='/tv-shows/new' component={TVShowsManage} />
          <PRoute access='tvshow_view' path='/tv-shows/:id' component={TVShowsManage} />
          <PRoute access='tvshow_view' path='/tv-shows' component={TVShows} />
          <PRoute access='upcoming_view' path='/upcoming' component={Upcoming} />
          <PRoute access='poster_view' path='/posters' component={Posters} />
          <PRoute access='categories_view' path='/categories' component={Categories} />
          <PRoute access='genres_view' path='/genres' component={Genres} />
          <PRoute access='tags_view' path='/tags' component={Tags} />
          <PRoute access='cast_and_crew_view' path='/cast-and-crew' component={CastAndCrew} />
          <PRoute access='users_view' path='/users' component={Users} />
          <PRoute access='channels_view' path='/channels' component={Channels} />
          <PRoute access='ads_view' path='/ads' component={Ads} />
          <PRoute access='configurations_view' path='/configurations' component={Configurations} />
          <PRoute access='pages_view' path='/pages' component={Pages} />
          <PRoute access='products_view' path='/products/:type' component={Products} />
          <PRoute access='plans_view' path='/plans' component={Plans} />
          <PRoute access='promos_view' path='/promos' component={Promos} />
          <PRoute access='finance_view' path='/finance' component={Finance} />
          <PRoute access='clients_view' path='/clients' component={Clients} />
          <Redirect to='/movies' />
        </Switch>
      </div>
    </div>
  );
};

export default Main;
