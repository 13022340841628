/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React from 'react';
import UseFocus from 'shared/hooks/useFocus';

const InputGroup = ({
  name,
  value,
  onChange,
  onBlur,
  placeholder,
  type,
  disabled,
  required,
  className,
  autoFocus,
  readOnly,
  containerClass,
  inputClass = '',
  successText,
  errorText,
  min,
  max,
  maxCount,
  maxLength,
  outLabel,
  subLabel,
}) => {
  const [fieldRef, setFiledFocus] = UseFocus();

  const setInputClasses = () => {
    let classes = '';
    if (inputClass) classes += ` ${inputClass}`;
    if (value) classes += ' active';
    if (disabled) classes += ' disabled-bg';
    return classes;
  };

  const handleBlur = e => {
    let valueHasUpdated = false;
    if (type === 'number' && e.target.value) {
      if (min && e.target.value < min) {
        e.target.value = min;
        valueHasUpdated = true;
      }
      if (max && e.target.value > max) {
        e.target.value = max;
        valueHasUpdated = true;
      }
    }
    valueHasUpdated && onChange(e);
    onBlur && onBlur(e);
  };

  const countIssue = value?.length > maxCount;

  return (
    <div className={`form-field-container ${containerClass || ''}`}>
      {outLabel && (
        <label className='fw-500 mb-0' htmlFor={name}>
          {outLabel}
          {subLabel && <p className='text-secondary small pr-2 mb-0'>{subLabel}</p>}
        </label>
      )}
      <div
        className={`form-field position-relative${errorText ? ' is-error' : ''}${
          successText ? ' is-success' : ''
        } ${className || ''}`}
      >
        <input
          id={name}
          name={name}
          type={type}
          value={value}
          onChange={onChange}
          onBlur={handleBlur}
          min={min}
          max={max}
          autoComplete='off'
          disabled={disabled}
          required={required}
          autoFocus={autoFocus}
          readOnly={readOnly}
          className={setInputClasses()}
          ref={fieldRef}
        />
        {placeholder && (
          <label
            className={disabled && !value ? 'disabled-bg' : ''}
            htmlFor={name}
            onClick={e => {
              setFiledFocus();
              e.preventDefault();
            }}
          >
            {placeholder}
          </label>
        )}
      </div>
      {errorText && errorText !== true && (
        <p className='text-xs text-danger mt-1 mb-0 px-3' data-testid='dangerText'>
          {errorText}
        </p>
      )}
      {maxLength && (
        <small className='form-text text-muted text-right'>
          {value?.length ?? 0} / {maxLength}
        </small>
      )}
      {maxCount && (
        <small className={`form-text text-right ${countIssue ? 'text-danger' : 'text-muted'}`}>
          {`Characters left: ${maxCount - value?.length}`}
        </small>
      )}
    </div>
  );
};

export default InputGroup;
