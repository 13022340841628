import { dateRanges } from 'configs';
import React, { useEffect, useState } from 'react';
import DateRangePicker from 'shared/components/forms/DateRangePicker';
import Header from 'shared/components/layouts/Header';
import { getError } from 'utils/appHelpers';
import { useSnackbar } from 'notistack';
import { Api } from 'utils/connectors';
import Loading from 'shared/components/loadings/Loading';
import InfiniteScroll from 'react-infinite-scroll-component';
import LineChart from '../Analytics/components/LineChart';
import moment from 'moment';
import ReactSelect from 'react-select';

const { startDate, endDate } = dateRanges.last_30_days;

const subscriberTypes = [
  { value: 'ALL', label: 'All' },
  { value: 'VIVA_MTS', label: 'MTS' },
  { value: 'PROMO_CODE', label: 'Promo Code' },
  { value: 'EXCLUDE_VIVA_MTS_AND_PROMO_CODE', label: 'Excluded MTS And Promo' },
];

const Finance = () => {
  const { enqueueSnackbar } = useSnackbar();
  const [fetching, setFetching] = useState(false);
  const [data, setData] = useState([]);
  const [filters, setFilters] = useState({
    dates: { from: startDate, to: endDate },
    type: 'ALL',
  });

  const handleDatesChange = dates => {
    setFilters({ ...filters, dates });
  };

  const handleTypeChange = typeItem => {
    setFilters({ ...filters, type: typeItem.value });
  };

  const getData = async () => {
    if (!filters.dates.from || !filters.dates.to) {
      enqueueSnackbar('Please Select Valid Date', { variant: 'error' });
      return;
    }

    try {
      setFetching(true);
      const { data } = await Api.get('/reports/active-subscribers', {
        params: {
          from_date: moment(filters.dates.from).format('DD-MM-YYYY'),
          to_date: moment(filters.dates.to).format('DD-MM-YYYY'),
          subscribers_type: filters.type,
        },
      });
      setData(data);
    } catch (err) {
      enqueueSnackbar(getError(err), { variant: 'error' });
    } finally {
      setFetching(false);
    }
  };

  const countMTSSubs = data.map(v => v.mts_total_subscribers || 0);
  const countMTSDailyNewSubs = data.map(v => v.mts_daily_new_subscribers || 0);
  const countStandardDailyNewSubs = data.map(v => v.standard_daily_new_subscribers || 0);
  const countStandardSubs = data.map(v => v.standard_total_subscribers || 0);

  const allDataSet = [
    {
      label: 'Count of MTS subscribers',
      data: countMTSSubs,
      fill: false,
      borderColor: 'rgb(75, 192, 192)',
      tension: 0.1,
      total: countMTSSubs.reduce((arg, c) => arg + c, 0),
    },
    {
      label: 'Standard subscribers (excluded MTS and Promo)',
      data: countStandardSubs,
      fill: false,
      borderColor: 'rgb(54, 162, 235)',
      tension: 0.1,
      total: countStandardSubs.reduce((arg, c) => arg + c, 0),
    },
  ];

  const filterDataSet = [
    {
      label: 'Count',
      data: data.map(v => v.count),
      fill: false,
      borderColor: 'rgb(75, 192, 192)',
      tension: 0.1,
    },
  ];

  useEffect(() => {
    getData();
  }, [filters.dates, filters.type]);

  const activeType = subscriberTypes.find(i => i.value === filters.type);
  const isAllType = filters.type === 'ALL';

  return (
    <>
      <Header>
        <div className='d-flex align-items-center'>Finance</div>
      </Header>
      <div className='main-content'>
        <div className='d-flex align-items-start justify-content-between mb-3'>
          <div className='flex-fill d-flex align-items-start justify-content-between'>
            <div className='col-3 p-0'>
              <ReactSelect
                className='mb-0'
                value={activeType}
                onChange={handleTypeChange}
                options={subscriberTypes}
                placeholder='Select Subscribers Type'
              />
            </div>
            <DateRangePicker
              value={filters.dates}
              onChange={handleDatesChange}
              disabled={fetching}
              className='ml-3'
            />
          </div>
        </div>
        <div className='pb-3 d-flex'>
          <div className='bg-white col-8'>
            <div className='bg-purple__light row px-4 py-2 d-flex align-items-center justify-content-between'>
              <div className='text-primary__light'>Active Subscriptions</div>
            </div>
            <div className='py-3'>
              {!!data?.length && !fetching && (
                <InfiniteScroll
                  dataLength={data.length || 0}
                  next={() => getData(true)}
                  hasMore={false}
                  loader={<div>Loading...</div>}
                >
                  <div>
                    <table className='table'>
                      <thead>
                        <tr>
                          <th className='border-0 text-primary w-40'>Date</th>
                          {!isAllType && (
                            <th className='border-0 text-primary text-center'>Count</th>
                          )}
                          {isAllType && (
                            <th className='border-0 text-primary text-center'>MTS Total</th>
                          )}
                          {isAllType && (
                            <th className='border-0 text-primary text-center'>Promo Code Total</th>
                          )}
                          {isAllType && (
                            <th className='border-0 text-primary text-center'>Standard Total</th>
                          )}
                          {isAllType && (
                            <th className='border-0 text-primary text-center'>
                              Standard Daily New
                            </th>
                          )}
                          {isAllType && (
                            <th className='border-0 text-primary text-center'>MTS Daily New</th>
                          )}
                        </tr>
                      </thead>
                      <tbody>
                        {data.map((item, index) => {
                          return (
                            <tr key={index}>
                              <td>{item.date}</td>
                              {!isAllType && <td className='text-center'>{item.count || 0}</td>}
                              {isAllType && (
                                <td className='text-center'>{item.mts_total_subscribers || 0}</td>
                              )}
                              {isAllType && (
                                <td className='text-center'>
                                  {item.promo_code_total_subscribers || 0}
                                </td>
                              )}
                              {isAllType && (
                                <td className='text-center'>
                                  {item.standard_total_subscribers || 0}
                                </td>
                              )}
                              {isAllType && (
                                <td className='text-center'>
                                  {item.standard_daily_new_subscribers || 0}
                                </td>
                              )}
                              {isAllType && (
                                <td className='text-center'>
                                  {item.mts_daily_new_subscribers || 0}
                                </td>
                              )}
                            </tr>
                          );
                        })}
                      </tbody>
                    </table>
                  </div>
                </InfiniteScroll>
              )}
              {fetching && (
                <div className='text-center py-6'>
                  <Loading />
                </div>
              )}
              {!data?.length && !fetching && (
                <h5 className='text-light text-center op-4 mb-0'>No results to show...</h5>
              )}
            </div>
          </div>
          <div className='col-4 pr-0'>
            <div className='mb-3'>
              <div className='bg-purple__light px-4 py-2 d-flex align-items-center justify-content-between'>
                <div className='text-primary__light'>Totals</div>
              </div>
              <div className='bg-white p-3 finance-chart'>
                <div className='d-flex align-items-center justify-content-between'>
                  <div className='text- text-primary'>MTS Daily New</div>
                  <div>Total: {countMTSDailyNewSubs.reduce((arg, c) => arg + c, 0)}</div>
                </div>
                <div className='d-flex align-items-center justify-content-between'>
                  <div className='text- text-primary'>Standard Daily New</div>
                  <div>Total: {countStandardDailyNewSubs.reduce((arg, c) => arg + c, 0)}</div>
                </div>
              </div>
            </div>
            <div>
              <div className='bg-purple__light px-4 py-2 d-flex align-items-center justify-content-between'>
                <div className='text-primary__light'>Chart</div>
              </div>
              <div className='bg-white p-3 finance-chart'>
                <LineChart
                  hideOtherInfos={true}
                  fetching={fetching}
                  data={{
                    labels: data.map(v => v.date),
                    datasets: isAllType ? allDataSet : filterDataSet,
                  }}
                  unit='Subscribers'
                  biggestValue={100}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Finance;
