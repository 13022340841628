import React from 'react';
import { useSelector } from 'react-redux';

const HeadFilters = ({ items, status, setStatus }) => {
  const totals = useSelector(state => state.totals);

  const handleClick = status => {
    window.scroll({ top: 0 });
    setStatus(status);
  };

  return (
    <div className='filters ml-13'>
      {items.map(item => (
        <button
          className={`btn ${
            status === item.status ? 'active border__bottom border__3 border__yellow' : ''
          }`}
          key={item.status}
          onClick={() => handleClick(item.status)}
        >
          <span>{item.name}</span>{' '}
          {totals[item.total] && <span className='op-6'>{`(${totals[item.total]})`}</span>}
        </button>
      ))}
    </div>
  );
};

export default HeadFilters;
