import React, { useState, useEffect } from 'react';
import { useSnackbar } from 'notistack';
import { Api } from 'utils/connectors';
import DragList from 'shared/components/layouts/DragList';
import IconDrag from 'assets/images/icons/dd_icon.svg';
import { confirmBox, getError, reorder } from 'utils/appHelpers';
import { PermissionService } from 'services/PermissionService';
import CreateButton from 'shared/components/CreateButton';
import StateHolder from 'shared/components/StateHolder';
import Loading from 'shared/components/loadings/Loading';
import ActionButton from 'shared/components/ActionButton';
import CastAndCrewTabManageModal from './CastAndCrewTabManageModal';
import SearchBox from 'shared/components/forms/SearchBox';

const imagePlaceholder =
  'https://cdn.pixabay.com/photo/2016/08/08/09/17/avatar-1577909_960_720.png';

const CastAndCrewTab = ({ media, type, lang, updateMovieStore }) => {
  const { enqueueSnackbar } = useSnackbar();
  const [search, setSearch] = useState('');
  const [data, setData] = useState({});
  const [manageModal, setManageModal] = useState({
    data: null,
    state: false,
  });

  const getData = async () => {
    try {
      const params = {
        size: 100,
        page: 0,
        name: search || undefined,
        locale: lang,
      };
      const { data } = await Api.get(`/casts/medias/${media.id}`, { params });
      setData(data);
      updateMovieStore({ ...media, media_cast_and_crew: data });
    } catch (err) {
      enqueueSnackbar(getError(err), { variant: 'error' });
    }
  };

  const onDragEnd = async e => {
    if (!e.destination) return;
    try {
      const updatedContent = reorder(data.content, e.source.index, e.destination.index);
      setData({ ...data, content: updatedContent });
      await Api.put(
        `/medias/${media.id}/casts/${e.draggableId}/reorder?order=${e.destination.index}`,
      );
    } catch (err) {
      enqueueSnackbar(getError(err), { variant: 'error' });
    }
  };

  const removeItem = async id => {
    try {
      await Api.delete(`/medias/${media.id}/unassign/casts/${id}`);
      await getData();
      enqueueSnackbar('Successfully removed', { variant: 'success' });
    } catch (err) {
      enqueueSnackbar(getError(err), { variant: 'error' });
    }
  };

  const onItemRemove = id => {
    const options = {
      onSuccess: () => removeItem(id),
      message: `Are you sure you want to delete this item?`,
      confirm: 'Delete',
    };
    confirmBox(options);
  };

  const onItemEdit = data => {
    setManageModal({ data, state: true });
  };

  const onItemCreate = () => setManageModal({ data: null, state: true });

  const onModalClose = () => setManageModal({ data: null, state: false });

  useEffect(() => {
    getData();
  }, []);

  useEffect(() => {
    if (media.media_cast_and_crew) {
      setData(media.media_cast_and_crew);
    }
  }, [media]);

  return (
    <>
      {!data.content ? (
        <StateHolder>
          <Loading />
        </StateHolder>
      ) : (
        <>
          <div className='d-flex align-items-center justify-content-between pt-4'>
            <SearchBox
              onChange={getData}
              search={search}
              setSearch={setSearch}
              className='col-12 col-md-7'
              autoFocus
            />
            {PermissionService.hasAccess('cast_and_crew_create') && (
              <CreateButton name='ADD NEW' onClick={onItemCreate} size='sm' />
            )}
          </div>
          <div className='cast-list'>
            <DragList
              onDragEnd={onDragEnd}
              items={data.content}
              isDragDisabled={!PermissionService.hasAccess('cast_and_crew_edit')}
              draggableIdKey='media_cast_role_link_id'
            >
              {(item, index, snapshot) => {
                return (
                  <div
                    className={`border-bottom d-flex justify-content-between align-items-center pb-2`}
                    key={item.media_cast_role_link_id}
                  >
                    <div className='flex-fill d-flex align-items-center'>
                      <span className='mr-2'>
                        {snapshot.isDragging ? <img src={IconDrag} alt={'icon'} /> : index + 1}
                      </span>
                      <img
                        src={item.img_url || imagePlaceholder}
                        alt={item.first_name}
                        width={35}
                        height={35}
                        className='object-fit__cover object-position__center rounded__50 pull-y-1 mr-3'
                      />{' '}
                      {!item.first_name && !item.last_name ? (
                        <i className='text-muted'>Missing name for selected locale</i>
                      ) : (
                        `${item.first_name} ${item.last_name}`
                      )}
                      <div className='badge badge-primary ml-2'>{item.role}</div>
                    </div>
                    <div className='d-flex align-items-center justify-content-end'>
                      {PermissionService.hasAccess('cast_and_crew_edit') && (
                        <ActionButton type='edit' onClick={() => onItemEdit(item)} />
                      )}
                      {PermissionService.hasAccess('cast_and_crew_delete') && (
                        <ActionButton onClick={() => onItemRemove(item.media_cast_role_link_id)} />
                      )}
                    </div>
                  </div>
                );
              }}
            </DragList>
          </div>
        </>
      )}
      {manageModal.state && (
        <CastAndCrewTabManageModal
          data={manageModal.data}
          mediaId={media.id}
          getData={getData}
          onClose={onModalClose}
          lang={lang}
        />
      )}
    </>
  );
};

export default CastAndCrewTab;
