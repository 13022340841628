import React, { useEffect, useState } from 'react';
import { useSnackbar } from 'notistack';
import { useDispatch, useSelector } from 'react-redux';
import { onSetTags } from 'app/Main/actions';
import { Api } from 'utils/connectors';
import { confirmBox, getError } from 'utils/appHelpers';
import Header from 'shared/components/layouts/Header';
import InfiniteScroll from 'react-infinite-scroll-component';
import TagManageModal from './components/TagManageModal';
import ActionButton from 'shared/components/ActionButton';
import CreateButton from 'shared/components/CreateButton';
import { PermissionService } from 'services/PermissionService';
import SearchBox from 'shared/components/forms/SearchBox';
import Loading from 'shared/components/loadings/Loading';

const Tags = () => {
  const { enqueueSnackbar } = useSnackbar();
  const tags = useSelector(state => state.tags);
  const dispatch = useDispatch();
  const [search, setSearch] = useState('');
  const [manageModal, setManageModal] = useState({
    state: false,
    tag: null,
  });

  const getTags = async isNext => {
    try {
      const page = isNext ? tags.number : 0;
      const res = await Api.get('/tags', {
        params: {
          size: 100,
          page: isNext ? page + 1 : page,
          name: search,
        },
      });
      if (isNext) res.data.content = [...tags.content, ...res.data.content];
      dispatch(onSetTags(res.data));
    } catch (err) {
      enqueueSnackbar(getError(err), { variant: 'error' });
    }
  };

  const removeItem = async (id, index) => {
    try {
      await Api.delete(`/tags/${id}`);
      const tempItems = [...tags.content];
      tempItems.splice(index, 1);
      dispatch(onSetTags({ ...tags, content: tempItems }));
      enqueueSnackbar('Successfully done', { variant: 'success' });
    } catch (err) {
      enqueueSnackbar(getError(err), { variant: 'error' });
    }
  };

  const onItemRemove = (id, index) => {
    const options = {
      onSuccess: removeItem.bind(null, id, index),
      message: `Are you sure you want to delete current tag?`,
      confirm: 'Delete',
    };
    confirmBox(options);
  };

  const onItemEdit = e => {
    setManageModal({ tag: e, state: true });
  };

  const onItemCreate = () => {
    setManageModal({ tag: null, state: true });
  };

  const onModalClose = () => {
    setManageModal({ tag: null, state: false });
  };

  useEffect(() => {
    getTags();
    //eslint-disable-next-line
  }, []);

  return (
    <>
      <Header>
        <div className='d-flex align-items-center'>Tags</div>
      </Header>
      <main className='main-content'>
        <div className='px-3 bg-white pb-3'>
          <div className='bg-purple__light row px-4 py-3 d-flex align-items-center justify-content-between'>
            <div className='text-primary__light'>Media Tags</div>
          </div>
          <div className='inner-filter d-flex justify-content-between align-items-center pt-4 pb-1 px-2'>
            <SearchBox search={search} onChange={getTags} setSearch={setSearch} />
            <div className='d-flex'>
              <CreateButton
                name='ADD TAG'
                onClick={onItemCreate}
                disabled={!PermissionService.hasAccess('media_create')}
              />
            </div>
          </div>
          {tags.content && !!tags.content.length ? (
            <InfiniteScroll
              dataLength={(tags.content && tags.content.length) || 0}
              next={() => getTags(true)}
              hasMore={tags.content && tags.content.length < tags.total_elements}
              loader={<div>Loading...</div>}
              className='px-2'
            >
              {tags.content.map((item, index) => {
                return (
                  <div className='border-bottom d-flex justify-content-between py-3' key={item.id}>
                    <div>
                      <span className='weight-700 mnw-30 d-inline-block'>{index + 1}</span>{' '}
                      <span>{item.name}</span>
                    </div>
                    <div>
                      <ActionButton
                        className='mr-1'
                        type='edit'
                        onClick={onItemEdit.bind(null, item)}
                        disabled={!PermissionService.hasAccess('media_edit')}
                      />
                      <ActionButton
                        className='mr-2'
                        onClick={onItemRemove.bind(null, item.id, index)}
                        disabled={!PermissionService.hasAccess('media_delete')}
                      />
                    </div>
                  </div>
                );
              })}
            </InfiniteScroll>
          ) : (
            <div className='text-center py-6'>{search ? 'No results found' : <Loading />}</div>
          )}
        </div>
      </main>
      {manageModal.state && (
        <TagManageModal state={manageModal} onSuccess={getTags} onClose={onModalClose} />
      )}
    </>
  );
};

export default Tags;
