import React from 'react';
import ReactSelect from 'react-select';
import { useSnackbar } from 'notistack';
import { getError } from 'utils/appHelpers';
import { Api } from 'utils/connectors';

const ListStatusChanger = ({ status, productID, onSuccess, type = 'product' }) => {
  const { enqueueSnackbar } = useSnackbar();

  const endpointByType = {
    product: 'products',
    plan: 'subscription-plans',
  }

  const onChange = async value => {
    try {
      await Api.patch(`${endpointByType[type]}/${productID}?status=${value.key}`);
      if (onSuccess) onSuccess();
    } catch (err) {
      enqueueSnackbar(getError(err), { variant: 'error' });
    }
  };

  const isPublished = status === 'PUBLISHED';
  const isUnpublished = status === 'UNPUBLISHED';
  const isArchived = status === 'ARCHIVED';

  const options = [
    { key: 'UNPUBLISHED', name: 'Unpublished', isDisabled: isArchived || isPublished },
    { key: 'PUBLISHED', name: 'Published', isDisabled: isArchived },
    { key: 'ARCHIVED', name: 'Archived', isDisabled: isUnpublished },
  ];

  const activeStatus = options.find(i => i.key === status);

  return (
    <div className='w-150'>
      <ReactSelect
        value={activeStatus}
        onChange={onChange}
        options={options}
        placeholder='Status'
        getOptionValue={i => i.key}
        getOptionLabel={i => i.name}
        menuPosition='fixed'
        isOptionDisabled={option => option.isDisabled}
      />
    </div>
  );
};

export default ListStatusChanger;
