import React from 'react';
import { useSnackbar } from 'notistack';
import { Api } from 'utils/connectors';
import { getError } from 'utils/appHelpers';
import MediaVideoItem from 'shared/components/MediaVideoItem';
import Loading from 'shared/components/loadings/Loading';
import MediaVideoUploader from 'shared/components/MediaVideoUploader/index';
import { useSelector } from 'react-redux';

const TrailerTab = ({ media, updateMovieStore, getMovie, type }) => {
  const uploader = useSelector(state => state.uploader);
  const { enqueueSnackbar } = useSnackbar();

  const onItemRemove = async () => {
    try {
      const mediaType = type === 'MOVIE' ? 'movies' : 'tv-shows';
      await Api.delete(`/${mediaType}/${media.id}/trailer`);
      const tempMedia = { ...media };
      delete tempMedia.trailer;
      updateMovieStore(tempMedia);
    } catch (err) {
      enqueueSnackbar(getError(err), { variant: 'error' });
    }
  };

  const onFileSuccess = () => {
    getMovie();
  };

  const createAndGetTrailerId = async () => {
    try {
      const mediaType = type === 'MOVIE' ? 'movies' : 'tv-shows';
      const endpoint = `/${mediaType}/${media.id}/trailer`;
      const { data } = await Api.put(endpoint, { id: media.id });
      const tempMedia = { ...media };
      tempMedia.trailer = { mediaId: data.media_id };
      updateMovieStore(tempMedia);
      return data.media_id;
    } catch (err) {
      enqueueSnackbar(getError(err), { variant: 'error' });
      return null;
    }
  };

  if (!media) return <Loading className='mt-3' />;

  const showVideoItem = media.trailer;
  const progress = media.trailer && uploader[media.trailer.mediaId];

  return (
    <div className='video-area mt-3'>
      {showVideoItem ? (
        <MediaVideoItem
          video={media.trailer}
          onItemRemove={onItemRemove}
          videoProgress={progress}
          onEpisodeVideoCancel={onItemRemove}
        />
      ) : (
        <MediaVideoUploader
          onFileSuccess={onFileSuccess}
          placeholder='Upload Trailer'
          uploadOptions={{
            type,
            id: media.id,
            getItemId: createAndGetTrailerId,
            isTrailer: true,
          }}
        />
      )}
    </div>
  );
};

export default TrailerTab;
