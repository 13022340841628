import React from 'react';
import Tooltip from 'shared/components/layouts/Tooltip';
import defaultImg from 'assets/images/thumbnail-default.jpg';

const MediaTooltip = ({ data, children }) => (
  <Tooltip
    enterDelay={500}
    enterNextDelay={500}
    title={
      <div>
        <img src={data.image_url ?? defaultImg} alt='img' className='w-100 mb-4' />
        <p className='text-lg weight-900 mb-0'>{data.title}</p>
        {data.category === 'TV_SHOW' && (
          <p className='text-light mb-0'>
            <span className='mr-2'>Seazons 10</span>
            <span>Series 100</span>
          </p>
        )}
      </div>
    }
  >
    {children}
  </Tooltip>
);

export default MediaTooltip;
