import React from 'react';
import Dropzone from 'react-dropzone';
import ActionButton from './ActionButton';

const UpdateImageUpload = ({ onDrop, disabled, maxSize, accept }) => {
  return (
    <Dropzone onDrop={onDrop} disabled={disabled} maxSize={maxSize} accept={accept}>
      {({ getRootProps, getInputProps }) => {
        return (
          <div {...getRootProps()}>
            <ActionButton type='refresh' disabled={disabled} />
            <input {...getInputProps()} />
          </div>
        );
      }}
    </Dropzone>
  );
};

export default UpdateImageUpload;
