import React, { useState } from 'react';
import Modal from 'shared/components/modals/Modal';
import { useSnackbar } from 'notistack';
import { Api } from 'utils/connectors';
import { getError } from 'utils/appHelpers';
import { useSelector } from 'react-redux';
import InputGroup from 'shared/components/forms/InputGroup';
import ReactSelect from 'shared/components/forms/ReactSelect';
import { formatDate } from 'utils/analyticsHelpers';
import Switcher from 'shared/components/forms/Switcher';

const getInitialState = () => {
  return {
    in_app_product_id: '',
    start_date: formatDate(new Date()),
    end_date: formatDate(new Date()),
    is_user_complete_profile_required: false,
    type: '',
    code: '',
    count: '',
    name: '',
  };
};

const types = [{ name: 'One time', value: 'ONE_TIME' }, { name: 'Permanent', value: 'PERMANENT' }];

const PromoManageModal = ({ onClose, state, onSuccess }) => {
  const productsTypes = useSelector(state => state.products);
  const products = [
    ...(productsTypes?.consumable?.content || []),
    ...(productsTypes?.renewable?.content || []),
  ];
  const { enqueueSnackbar } = useSnackbar();
  const [fetching, setFetching] = useState(false);
  const [form, setForm] = useState(state.promo ? state.promo : { ...getInitialState() });
  const isEdit = state.promo;

  const createPlan = body => {
    return Api.post(`/promo-codes`, { ...body });
  };

  const updatePlan = body => {
    return Api.put(`/promo-codes/${state.promo.uuid}`, { ...body });
  };

  const onFormChange = ({ target }) => {
    const { name, value } = target;
    setForm({ ...form, [name]: value });
  };

  const onFormSubmit = async e => {
    e.preventDefault();
    try {
      setFetching(true);
      const body = { ...form };
      isEdit ? await updatePlan(body) : await createPlan(body);
      if (onSuccess) onSuccess();
      enqueueSnackbar('Successfully done', { variant: 'success' });
      setFetching(false);
      onClose();
    } catch (err) {
      enqueueSnackbar(getError(err), { variant: 'error' });
      setFetching(false);
    }
  };

  const onModalClose = () => {
    onClose();
  };

  const activeProduct = products?.find(i => i.id === form.in_app_product_id);
  const activeType = types?.find(i => i.value === form.type);
  const codeDisabled = form.type === 'ONE_TIME';

  return (
    <Modal size='xs' onClose={onModalClose} className='mx-2 mt-2 plan-manage-modal'>
      <div className='modal-title bg-purple__light row px-4 py-3'>
        <div className='text-primary__light'>
          {isEdit ? 'Edit Promo Code' : 'Create Promo Code'}
        </div>
      </div>
      <div className='d-flex flex-column align-items-center h-100'>
        <form
          onSubmit={onFormSubmit}
          className='d-flex flex-column h-100 justify-content-between pt-3 w-100'
        >
          <InputGroup
            type='text'
            name='name'
            value={form.name || ''}
            placeholder={'Name'}
            outLabel='Name'
            containerClass='d-flex align-items-center justify-content-between'
            onChange={onFormChange}
            className='col-7 p-0'
            required
          />
          <div className='mb-4 d-flex align-items-center justify-content-between'>
            <label className='fw-500 mb-0'>Select Product</label>
            <div className='col-7 p-0'>
              <ReactSelect
                value={activeProduct}
                onChange={value => setForm({ ...form, in_app_product_id: value.id })}
                options={products}
                className='w-100'
                placeholder='Select Product'
                getOptionValue={i => i.id}
                getOptionLabel={i => i.name}
                disabled={isEdit}
              />
            </div>
          </div>
          <div className='mb-4 d-flex align-items-center justify-content-between'>
            <label className='fw-500 mb-0'>Select Type</label>
            <div className='col-7 p-0'>
              <ReactSelect
                value={activeType}
                onChange={value => setForm({ ...form, type: value.value })}
                options={types}
                className='w-100'
                placeholder='Select Type'
                getOptionValue={i => i.value}
                getOptionLabel={i => i.name}
                disabled={isEdit}
              />
            </div>
          </div>
          <InputGroup
            type='text'
            name='code'
            value={codeDisabled ? '' : form.code || ''}
            placeholder={'Code'}
            outLabel='Code'
            subLabel={codeDisabled ? 'Code will be generated automatically' : ''}
            containerClass='d-flex align-items-center justify-content-between'
            onChange={onFormChange}
            className='col-7 p-0'
            disabled={codeDisabled}
            required
          />
          <div className='mb-4 d-flex align-items-center justify-content-between'>
            <label className='fw-500 mb-0'>Complete Profile</label>
            <div className='col-7 p-0'>
              <Switcher
                className='d-flex align-items-center'
                labelClass='text-sm ml-2'
                switchCheck={form.is_user_complete_profile_required || false}
                onSwitchChange={value =>
                  setForm({ ...form, is_user_complete_profile_required: value })
                }
              />
            </div>
          </div>
          <InputGroup
            type='number'
            name='count'
            value={form.count || ''}
            placeholder={'Total Count'}
            outLabel='Total Count'
            subLabel='Leave empty for unlimited'
            containerClass='d-flex align-items-center justify-content-between'
            onChange={onFormChange}
            className='col-7 p-0'
            disabled={isEdit}
            required
          />
          <InputGroup
            type='date'
            name='start_date'
            value={form.start_date || ''}
            placeholder={'Start Date'}
            outLabel='Start Date'
            containerClass='d-flex align-items-center justify-content-between'
            onChange={onFormChange}
            className='col-7 p-0'
            disabled={isEdit}
          />
          <InputGroup
            type='date'
            name='end_date'
            value={form.end_date || ''}
            placeholder={'End Date'}
            outLabel='End Date'
            containerClass='d-flex align-items-center justify-content-between'
            onChange={onFormChange}
            className='col-7 p-0'
          />
          <div className='text-right mt-4'>
            <button
              className='btn btn-outline-primary text-md btn-sm btn-crud weight-400 mx-2'
              onClick={onClose}
              type='button'
            >
              Cancel
            </button>
            <button
              className='btn btn-primary btn-sm btn-crud text-md weight-400 ml-1'
              disabled={fetching || !form.in_app_product_id}
              type='submit'
            >
              {isEdit ? 'Update' : 'Create'}
            </button>
          </div>
        </form>
      </div>
    </Modal>
  );
};

export default PromoManageModal;
