import React, { useState, useEffect, memo } from 'react';
import { useSnackbar } from 'notistack';
import { useHistory, useParams } from 'react-router-dom';
import Modal from 'shared/components/modals/Modal';
import { Api } from 'utils/connectors';
import { confirmBox, getError } from 'utils/appHelpers';
import cloneDeep from 'lodash/cloneDeep';
import update from 'lodash/update';
import RollContainer from './RollContainer';
import FrequencyContainer from './FrequencyContainer';
import LocationsContainer from './LocationsContainer';
import { useSelector } from 'react-redux';
import InputGroup from 'shared/components/forms/InputGroup';
import CountrySelectContainer from './CountrySelectContainer';

const CreateSpecialAddModal = ({ getAds, setIsSpecialAdd }) => {
  const { id, location } = useParams();
  const { enqueueSnackbar } = useSnackbar();
  const { push } = useHistory();
  const ads = useSelector(state => state.ads);
  const [fetching, setFetching] = useState(false);
  const [newAdd, setNewAdd] = useState({});
  const [errors, setErrors] = useState({});
  const [newLocation, setNewLocation] = useState(false);
  const [tempId, setTempId] = useState(true);
  const [dataForId, setDataForId] = useState(true);
  const [stateForAddCountryBased, setStateForAddCountryBased] = useState({});

  const handleClose = () => {
    push(`/ads/list`);
    setNewAdd(null);
    setIsSpecialAdd(false);
    setNewLocation(false);
  };

  const handleChangeIdModal = e => {
    let { name, value } = e.target;
    if (name.includes('_count') || name.includes('frequency')) value = +value;
    const newState = update(cloneDeep(newAdd), name, () => value);
    setNewAdd(newState);
  };

  const handleChangeLocationModal = e => {
    let { name, value } = e.target;
    if (name.includes('_count') || name.includes('frequency')) value = +value;
    const newState = update(cloneDeep(stateForAddCountryBased), name, () => value);
    setStateForAddCountryBased(newState);
  };

  const validateForm = obj => {
    const errors = {};
    if (!newAdd.name) {
      errors['name'] = 'The add must have a name';
    }
    if (obj.preroll.max_count < 1 || !obj.preroll.max_count) {
      errors['ad_config_detail.preroll.max_count'] = 'The Preroll Count must 1 at least';
    }
    if (obj.preroll && !obj.preroll.url) {
      errors['ad_config_detail.preroll.url'] = 'The Preroll URL must be a valid URL';
    }
    if (obj.midroll.max_count < 1 || !obj.midroll.max_count) {
      errors['ad_config_detail.midroll.max_count'] = 'The Max Midroll Count must 1 at least';
    }
    if (obj.midroll && !obj.midroll.url) {
      errors['ad_config_detail.midroll.url'] = 'The Midroll URL must be a valid URL';
    }
    if (obj.endroll.max_count < 1 || !obj.endroll.max_count) {
      errors['ad_config_detail.endroll.max_count'] = 'The Max Endroll Count must 1 at least';
    }
    if (obj.endroll && !obj.endroll.url) {
      errors['ad_config_detail.endroll.url'] = 'The Endroll URL must be a valid URL';
    }
    if (obj.ad_frequency < 30 || !obj.ad_frequency) {
      errors['ad_config_detail.ad_frequency'] = 'The frequency must 30 at least';
    }

    setErrors(errors);
    return !Object.keys(errors).length;
  };

  const onFormSubmit = async e => {
    e.preventDefault();
    const isFormValid = dataForId
      ? validateForm(newAdd.ad_config_detail)
      : validateForm(stateForAddCountryBased.ad_config_detail);
    if (!isFormValid) return;
    if (location) {
      if (location !== 'new-country-add') {
        newAdd.country_based_ad_configs[location] = stateForAddCountryBased.ad_config_detail;
        if (!validateForm(newAdd.ad_config_detail)) {
          push(`/ads/list/${id}`);
          return;
        }
      } else {
        newAdd.country_based_ad_configs = {
          ...newAdd.country_based_ad_configs,
          [stateForAddCountryBased.country_based_ad_configs]:
            stateForAddCountryBased.ad_config_detail,
        };
        setNewAdd(newAdd);
        setNewLocation(false);
        setStateForAddCountryBased({});
        push(`/ads/list/${id}`);
        return;
      }
    }
    try {
      setFetching(true);
      await Api.post(`/ad-configs/`, newAdd);
      enqueueSnackbar('Successfully done', { variant: 'success' });
      handleClose();
      getAds();
    } catch (err) {
      enqueueSnackbar(getError(err), { variant: 'error' });
    } finally {
      setFetching(false);
      setNewLocation(false);
      setIsSpecialAdd(false);
    }
  };

  const removeCountry = async key => {
    const dataAfterDelete = cloneDeep(newAdd);
    delete dataAfterDelete.country_based_ad_configs[key];
    setNewAdd(dataAfterDelete);
  };

  const onAcountryRemove = key => {
    const options = {
      onSuccess: removeCountry.bind(null, key),
      message: `Are you sure you want to delete new country add ?`,
      confirm: 'Delete',
    };
    confirmBox(options);
  };

  useEffect(() => {
    if (location) {
      setDataForId(false);
    } else setDataForId(true);
  }, [location]);

  useEffect(() => {
    if (!ads || !ads.length) return;
    if (location && newAdd) {
      setDataForId(false);
      setErrors({});
      if (location !== 'new-country-add') {
        const locationData = newAdd.country_based_ad_configs[location];
        if (locationData) setStateForAddCountryBased({ ad_config_detail: locationData });
        return;
      } else {
        setNewLocation(true);
        const newCountry = cloneDeep(newAdd);
        newCountry.ad_config_detail.ad_frequency = 30;
        newCountry.ad_config_detail.preroll.ad_system_type = 'ADFOX';
        newCountry.ad_config_detail.preroll.url = '';
        newCountry.ad_config_detail.preroll.max_count = 1;
        newCountry.ad_config_detail.midroll.ad_system_type = 'ADFOX';
        newCountry.ad_config_detail.midroll.url = '';
        newCountry.ad_config_detail.midroll.max_count = 1;
        newCountry.ad_config_detail.endroll.ad_system_type = 'ADFOX';
        newCountry.ad_config_detail.endroll.url = '';
        newCountry.ad_config_detail.endroll.max_count = 1;
        newCountry.country_based_ad_configs = 'am';
        delete newCountry.name;
        delete newCountry.global;
        setStateForAddCountryBased(newCountry);
        return;
      }
    }
    if (id && !location && tempId) {
      const newAdd = cloneDeep(ads[0]);
      newAdd.name = '';
      newAdd.global = false;
      newAdd.ad_config_detail.ad_frequency = 30;
      newAdd.ad_config_detail.preroll.max_count = 1;
      newAdd.ad_config_detail.midroll.max_count = 1;
      newAdd.ad_config_detail.endroll.max_count = 1;
      newAdd.ad_config_detail.preroll.url = '';
      newAdd.ad_config_detail.midroll.url = '';
      newAdd.ad_config_detail.endroll.url = '';
      newAdd.ad_config_detail.preroll.ad_system_type = 'ADFOX';
      newAdd.ad_config_detail.midroll.ad_system_type = 'ADFOX';
      newAdd.ad_config_detail.endroll.ad_system_type = 'ADFOX';
      newAdd.country_based_ad_configs = {};
      delete newAdd.id;
      setNewAdd(newAdd);
      setTempId(false);
    }
  }, [ads, id, location]);

  if (!newAdd) return null;

  if (dataForId) {
    newAdd.ad_config_detail = newAdd.ad_config_detail || {};
    newAdd.country_based_ad_configs = newAdd.country_based_ad_configs || {};
  } else {
    stateForAddCountryBased.ad_config_detail = stateForAddCountryBased.ad_config_detail || {};
    stateForAddCountryBased.country_based_ad_configs =
      stateForAddCountryBased.country_based_ad_configs || {};
  }

  return (
    <Modal size='xs' className='mx-2 mt-2 ads-new-modal vahe_modal' onClose={handleClose}>
      <h6 className='modal-title bg-purple__light row px-6 py-3'>
        {location ? 'Add Country Based' : 'Create Special Ad'}
      </h6>
      <form onSubmit={onFormSubmit} className='d-flex flex-column h-100 pt-3 w-100 px-3'>
        {newLocation && (
          <CountrySelectContainer
            title={'Country'}
            onChange={handleChangeLocationModal}
            selectedCountry={stateForAddCountryBased.country_based_ad_configs}
          />
        )}
        {!newLocation && dataForId && (
          <div className='row border-bottom mb-3 mt-1'>
            <div className='col-4 mr-5 p-0'>
              <h6>Name</h6>
            </div>
            <div className='col-7 pl-5'>
              <div className='row d-flex align-items-center'>
                <InputGroup
                  type='text'
                  name='name'
                  placeholder='Enter Name'
                  value={newAdd.name}
                  onChange={handleChangeIdModal}
                  errorText={errors[`name`]}
                  className='col-12 mb-3 pl-0'
                />
              </div>
            </div>
          </div>
        )}
        <RollContainer
          className={newLocation ? 'mt-3' : ''}
          title='PreRoll'
          description='Lorem ipsum dolor sit.'
          source='preroll'
          values={dataForId ? newAdd.ad_config_detail : stateForAddCountryBased.ad_config_detail}
          errors={errors}
          onChange={dataForId ? handleChangeIdModal : handleChangeLocationModal}
        />
        <RollContainer
          title='MidRoll'
          description='Lorem ipsum dolor sit amet.'
          source='midroll'
          values={dataForId ? newAdd.ad_config_detail : stateForAddCountryBased.ad_config_detail}
          errors={errors}
          onChange={dataForId ? handleChangeIdModal : handleChangeLocationModal}
        />
        <RollContainer
          title='EndRoll'
          description='Lorem ipsum dolor sit.'
          source='endroll'
          values={dataForId ? newAdd.ad_config_detail : stateForAddCountryBased.ad_config_detail}
          errors={errors}
          onChange={dataForId ? handleChangeIdModal : handleChangeLocationModal}
        />
        <FrequencyContainer
          title='Frequency'
          description='Lorem ipsum dolor sit amet.'
          value={
            dataForId
              ? newAdd.ad_config_detail.ad_frequency
              : stateForAddCountryBased.ad_config_detail.ad_frequency
          }
          errors={errors}
          onChange={dataForId ? handleChangeIdModal : handleChangeLocationModal}
        />
        {!location && (
          <LocationsContainer
            title='Location'
            values={newAdd.country_based_ad_configs}
            onAcountryRemove={onAcountryRemove}
          />
        )}
        <div className='btn-toolbar justify-content-between mt-4'>
          <div>
            {location && (
              <button
                className='btn btn-outline-primary text-md text-uppercase mnw-110 btn-sm py-2 weight-300 mx-2'
                onClick={() => {
                  if (newLocation) {
                    setNewLocation(false);
                  }
                  push(`/ads/list/${id}`);
                  setErrors({});
                }}
                type='button'
                disabled={fetching}
              >
                Back
              </button>
            )}
          </div>
          <div>
            <button
              className='btn btn-outline-primary text-md text-uppercase mnw-110 btn-sm py-2 weight-300 mx-2'
              onClick={handleClose}
              type='button'
              disabled={fetching}
            >
              Cancel
            </button>
            <button
              className='btn btn-primary btn-sm py-2 text-md text-uppercase weight-300 mnw-110 ml-2'
              type='submit'
              disabled={fetching}
            >
              {newLocation ? 'Add' : 'Save'}
            </button>
          </div>
        </div>
      </form>
    </Modal>
  );
};

export default memo(CreateSpecialAddModal);
