import React, { useEffect, useState } from 'react';
import { useSnackbar } from 'notistack';
import { getError, getImageUrl, confirmBox, reorder } from 'utils/appHelpers';
import { Api } from 'utils/connectors';
import Loading from 'shared/components/loadings/Loading';
import CreateButton from 'shared/components/CreateButton';
import SelectMediaModal from 'shared/components/SelectMediaModal';
import DragList from 'shared/components/layouts/DragList';
import ListEditBox from 'shared/components/ListEditBox';
import { PermissionService } from 'services/PermissionService';

const RelatedTab = ({ media, updateMovieStore }) => {
  const [mediaModal, setMediaModal] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const related = media.related;

  const getRelatedMedias = async () => {
    try {
      const { data } = await Api.get(`/medias/${media.id}/related`);
      updateMovieStore({ ...media, related: data.related || [] });
    } catch (err) {
      enqueueSnackbar(getError(err), { variant: 'error' });
    }
  };

  const onSelectMedias = async medias => {
    try {
      const params = { relatedMedias: medias.map(i => i.id).join(',') };
      await Api.put(`/medias/${media.id}/related`, {}, { params });
      await getRelatedMedias();
    } catch (err) {
      enqueueSnackbar(getError(err), { variant: 'error' });
    }
  };

  const onDragEnd = async e => {
    if (!e.destination) return;
    try {
      const tempMedia = { ...media };
      tempMedia.related = reorder(tempMedia.related, e.source.index, e.destination.index);
      updateMovieStore(tempMedia);
      await Api.put(
        `/medias/${media.id}/related/${e.draggableId}/reorder?order=${e.destination.index}`,
      );
    } catch (err) {
      enqueueSnackbar(getError(err), { variant: 'error' });
    }
  };

  const removeItem = async (id, index) => {
    try {
      await Api.delete(`/medias/${media.id}/related/${id}`);
      await getRelatedMedias();
      enqueueSnackbar('Successfully done', { variant: 'success' });
    } catch (err) {
      enqueueSnackbar(getError(err), { variant: 'error' });
    }
  };

  const onItemRemove = (id, index) => {
    const options = {
      onSuccess: removeItem.bind(null, id, index),
      message: `Are you sure you want to delete the related media?`,
      confirm: 'Delete',
    };
    confirmBox(options);
  };

  useEffect(() => {
    getRelatedMedias();
    //eslint-disable-next-line
  }, []);

  if (!related) return <Loading className='mt-3 w-100' />;

  return (
    <div className='mt-3'>
      <div className='d-flex justify-content-between align-items-end mt-4 mb-3'>
        <p className='sub-title mb-0'>Related TV Shows</p>
        <CreateButton
          className='is-small'
          name='ADD RELATED MEDIA'
          onClick={() => setMediaModal(true)}
          disabled={!PermissionService.hasAccess('media_edit')}
        />
      </div>
      <DragList
        onDragEnd={onDragEnd}
        items={related}
        isDragDisabled={!PermissionService.hasAccess('media_edit')}
      >
        {(item, index) => {
          const img = getImageUrl(item.image);
          return (
            <ListEditBox
              num={index + 1}
              key={item.id}
              title={item.title}
              description={item.description}
              published={item.published}
              img={img}
              onItemRemove={onItemRemove.bind(null, item.id, index)}
              switchCheck={item.published}
              disabled={false}
            />
          );
        }}
      </DragList>
      {mediaModal && (
        <SelectMediaModal
          medias={related.map(i => i.id)}
          onClose={() => setMediaModal(false)}
          onSelectMedia={onSelectMedias}
          isMulti={true}
        />
      )}
    </div>
  );
};

export default RelatedTab;
