import { HOST } from 'configs';
import { Api } from 'utils/connectors';
import videojs from 'video.js';
import { getLocalToken } from 'utils/tokenHelpers';

export const certificateUri =
  'https://fp-keyos.licensekeyserver.com/cert/51832652682f8cd6805a74490d46cc0d.der';

export const drmType = videojs.browser.IS_ANY_SAFARI ? 'FAIRPLAY' : 'WIDEVINE';

// eslint-disable-next-line
export const base64DecodeUint8Array = function (a) { var b = window.atob(a), c = b.length, d = new Uint8Array(new ArrayBuffer(c)); for (var i = 0; i < c; i++)d[i] = b.charCodeAt(i); return d };

// eslint-disable-next-line
export const base64EncodeUint8Array = function (a) { for (var d, e, f, g, h, i, j, b = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789+/=", c = "", k = 0; k < a.length;)d = a[k++], e = k < a.length ? a[k++] : Number.NaN, f = k < a.length ? a[k++] : Number.NaN, g = d >> 2, h = (3 & d) << 4 | e >> 4, i = (15 & e) << 2 | f >> 6, j = 63 & f, isNaN(e) ? i = j = 64 : isNaN(f) && (j = 64), c += b.charAt(g) + b.charAt(h) + b.charAt(i) + b.charAt(j); return c };

// eslint-disable-next-line
export const arrayToString = function (a) { var b = new Uint16Array(a.buffer); return String.fromCharCode.apply(null, b) };

export const getHLSKeySystem = (movieId, mediaId) => {
  return {
    'com.apple.fps.1_0': {
      certificateUri: certificateUri,

      getContentId: function(emeOptions, data) {
        const contentId = arrayToString(data);
        if (contentId.indexOf('skd://') > -1) return contentId.split('skd://')[1].substr(0, 32);
        // eslint-disable-next-line no-throw-literal
        throw 'Invalid Content ID format. The format of the Content ID must be the following: skd://xxx where xxx is the Key ID in hex format.';
      },

      getLicense: async function(emeOptions, contentId, keyMessage, callback) {
        const data = 'spc=' + base64EncodeUint8Array(keyMessage) + '&assetId=' + contentId;
        const episodeIDParam = mediaId ? `?episode_id=${mediaId}` : '';
        const url = `/medias/${movieId}/license${episodeIDParam}`;
        return Api.post(url, data, {
          headers: {
            contentType: 'application/x-www-form-urlencoded',
            'drm-type': drmType,
          },
        })
          .then(result => {
            callback(null, base64DecodeUint8Array(result.data));
          })
          .catch(err => {
            callback(err);
          });
      },
    },
  };
};

export const getDashKeySystem = (movieId, mediaId) => {
  return {
    'com.widevine.alpha': {
      certificateUri: certificateUri,
      audioRobustness: 'SW_SECURE_CRYPTO',
      videoRobustness: 'SW_SECURE_CRYPTO',
      getLicense: function(emeOptions, keyMessage, callback) {
        const episodeIDParam = mediaId ? `?episode_id=${mediaId}` : '';
        const headers = {
          Authorization: `${HOST.API.AUTH_PREFIX} ${getLocalToken()}`,
          'drm-type': drmType,
        };
        const apiParams = {
          url: `${HOST.API.URL}/medias/${movieId}/license/${episodeIDParam}`,
          method: 'POST',
          body: new Uint8Array(keyMessage),
          headers: headers,
          responseType: 'arraybuffer',
        };

        videojs.xhr(apiParams, (err, res, body) => {
          if (err) {
            callback(err);
            return;
          }
          callback(null, body);
        });
      },
    },
  };
};
