import React from 'react';
import { Link } from 'react-router-dom';
import logo from 'assets/images/logo.svg';
import { Switch, Route, Redirect } from 'react-router-dom';

import Login from './routes/Login/index';
import Verify from './routes/Verify/index';

const Auth = () => {
  return (
    <div className='signin-page min-vh-100 d-flex flex-column flex-md-row'>
      <div className='col-md-5 left-part px-md-7 py-md-6 p-3 d-flex flex-column justify-content-between text-white'>
        <Link to='/login'>
          <img src={logo} alt='logo' />
        </Link>
        <h4 className='weight-500'>Welcome To Kinodaran!</h4>
        <p className='mb-0 weight-300'>2020 &copy; Kinodaran. All Right Reserved</p>
      </div>
      <Switch>
        <Route path='/login' component={Login} />
        <Route path='/verify' component={Verify} />
        <Redirect to='/login' />
      </Switch>
    </div>
  );
};

export default Auth;
