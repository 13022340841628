import React, { useEffect, useState } from 'react';
import { useSnackbar } from 'notistack';
import { useDispatch, useSelector } from 'react-redux';
import { onSetMovies, setTotalValue } from 'app/Main/actions';
import { Api } from 'utils/connectors';
import { Link } from 'react-router-dom';
import { constructSort, getError, getQueryParam } from 'utils/appHelpers';
import Header from 'shared/components/layouts/Header';
import MovieBox from 'shared/components/MovieBox';
import InfiniteScroll from 'react-infinite-scroll-component';
import SortBox from 'shared/components/forms/SortBox';
import SearchBox from 'shared/components/forms/SearchBox';
import HeadFilters from 'shared/components/forms/HeadFilters';
import IconPlusFilled from 'assets/images/icons/plus_filled.svg';
import LoadingLine from 'shared/components/loadings/LoadingLine';
import { PermissionService } from 'services/PermissionService';

const statusFilters = [
  { name: 'All', status: 'all', total: 'all_movies' },
  { name: 'Published', status: 'true', total: 'active_movies' },
  { name: 'Unpublished', status: 'false', total: 'inactive_movies' },
];

const sortFilters = [
  { name: 'Last Modified', key: 'updateDate' },
  { name: 'Create Date', key: 'createDate' },
  { name: 'Name', key: 'title' },
];

const Movies = ({ history }) => {
  const { enqueueSnackbar } = useSnackbar();
  const { movies, channels } = useSelector(state => state);
  const dispatch = useDispatch();
  const [fetching, setFetching] = useState(false);
  const [status, setStatus] = useState(getQueryParam('status', 'all'));
  const [search, setSearch] = useState(getQueryParam('qs', ''));
  const [channelsOptions, setChannelsOptions] = useState([]);
  const [selectedChannel, setSelectedChannel] = useState({
    key: getQueryParam('channel') || null,
  });
  const [sort, setSort] = useState({
    key: getQueryParam('orderKey', 'updateDate'),
    bool: getQueryParam('order', false) === 'true',
  });

  const isBackNavigation = window.localStorage.getItem('isBackNavigation');

  const getMovies = async isNext => {
    if (isBackNavigation && movies?.content) {
      window.localStorage.removeItem('isBackNavigation');
      return;
    }
    try {
      setFetching(true);
      history.replace(getPageUrl());
      const page = isNext ? movies.number : 0;
      const res = await Api.get('/medias', {
        params: {
          published: status === 'all' ? null : status,
          category: 'MOVIE',
          text: search || undefined,
          size: 20,
          page: isNext ? page + 1 : page,
          sort: constructSort(sort),
          channel_id: selectedChannel.key || undefined,
        },
      });
      if (isNext) res.data.content = [...movies.content, ...res.data.content];
      if (status !== 'all') {
        const name = status === 'true' ? 'active_movies' : 'inactive_movies';
        dispatch(setTotalValue(name, res.data.total_elements));
      }
      dispatch(setTotalValue('all_movies', res.data.total_elements));
      dispatch(onSetMovies(res.data));
    } catch (err) {
      enqueueSnackbar(getError(err), { variant: 'error' });
    } finally {
      setFetching(false);
    }
  };

  const getPageUrl = () => {
    const qs = encodeURIComponent(search);
    const page = movies ? movies.number || 0 : 0;
    return `/movies?page=${page}&qs=${qs}&orderKey=${sort.key}&order=${sort.bool}&status=${status}${
      selectedChannel.key ? `&channel=${selectedChannel.key}` : ''
    }`;
  };

  useEffect(() => {
    getMovies();
    //eslint-disable-next-line
  }, [status, sort, selectedChannel.key]);

  useEffect(() => {
    if (channels.en && channels.en.content.length) {
      const channelsList = channels.en.content.map(i => ({ name: i.name, key: i.id }));
      setChannelsOptions([{ name: 'All', key: null }, ...channelsList]);
    }
  }, [channels.en]);

  return (
    <>
      {fetching && <LoadingLine />}
      <Header>
        <div className='d-flex align-items-center'>
          <p className='text-lg mb-0'>Movies</p>
          <HeadFilters items={statusFilters} status={status} setStatus={setStatus} />
        </div>
      </Header>
      <main className='main-content'>
        <div>
          <div className='filter-block'>
            <div className='d-flex justify-content-between flex-column flex-md-row'>
              <SearchBox
                onChange={getMovies}
                search={search}
                setSearch={setSearch}
                className='col-md-3 col-12'
              />
              <div className='flex-fill text-nowrap d-flex align-items-center justify-content-end mt-3 mt-md-0'>
                {PermissionService.hasAccess('media_channel_assign') && (
                  <SortBox
                    sort={selectedChannel}
                    setSort={setSelectedChannel}
                    items={channelsOptions}
                    label='Select channel'
                    className='mr-2'
                  />
                )}
                <SortBox
                  sort={sort}
                  setSort={setSort}
                  items={sortFilters}
                  label='Sort by'
                  className='mr-2'
                />
                {PermissionService.hasAccess('media_create') && (
                  <Link
                    to='/movies/new'
                    className='rounded__30 border-style border__light d-flex align-items-center p-2 no-decoration'
                  >
                    <img src={IconPlusFilled} alt='plus' className='mr-3' width='22' />{' '}
                    <span className='text-light pr-2'>ADD MEDIA</span>
                  </Link>
                )}
              </div>
            </div>
          </div>
          <InfiniteScroll
            dataLength={(movies.content && movies.content.length) || 0}
            next={() => getMovies(true)}
            hasMore={movies.content && movies.content.length < movies.total_elements}
            loader={<div>Loading...</div>}
          >
            <div className='overflow-hidden'>
              <div className='movies d-flex flex-wrap mt-5'>
                {movies.content &&
                  movies.content.map(item => (
                    <MovieBox
                      item={item}
                      key={item.id}
                      publishable
                      published={item.published}
                      className='pointer'
                      onClick={() => history.push(`/movies/${item.id}`)}
                    />
                  ))}
              </div>
            </div>
          </InfiniteScroll>
        </div>
      </main>
    </>
  );
};

export default Movies;
