import React, { useState, useRef } from 'react';
import IconPlusFilled from 'assets/images/icons/plus_filled.svg';
import useOutsideClick from 'shared/hooks/useOutsideClick';

const filterTypes = [
  {
    name: 'Country',
    key: 'countries',
  },
  {
    name: 'Device type',
    key: 'device_types',
  },
  // {
  //   name: 'Channel',
  //   key: 'channels',
  // },
];

const AddFilterButton = ({ selectedKeys, disabled, onChange }) => {
  const container = useRef();
  const [open, setOpen] = useState(false);

  const handleClick = () => {
    !disabled && setOpen(!open);
  };

  const toggleFilter = filter => {
    if (disabled) return;
    onChange(filter);
    setOpen(false);
  };

  useOutsideClick(container, () => setOpen(false));

  return (
    <div
      ref={container}
      className={`d-inline-flex mr-3 mb-2 analytics-dropdown ${open ? 'open' : ''}`}
    >
      <button
        className={`filter-item no-decoration ${disabled ? 'disabled' : ''}`}
        onClick={handleClick}
      >
        <span>ADD FILTER</span>
        <img src={IconPlusFilled} alt='plus' className='ml-3' width='20' height='20' />
      </button>
      <ul className='dropdown-list'>
        {filterTypes.map(({ key, name }) => (
          <li
            key={key}
            className={`dropdown-item ${
              selectedKeys.includes(key) ? 'dropdown-item__selected' : ''
            }`}
            onClick={() => toggleFilter(key)}
            role='presentation'
          >
            {name}
          </li>
        ))}
      </ul>
    </div>
  );
};

export default AddFilterButton;
