import React from 'react';
import { Switch, Redirect } from 'react-router-dom';
import AnalyticsHeader from './components/AnalyticsHeader';
import Overview from './routes/Overview';
import Views from './routes/Views';
import Watchtime from './routes/Watchtime';
import Content from './routes/Content';
// import Usage from './routes/Usage';
import Revenue from './routes/Revenue';
import { PRoute } from 'services/PermissionService';

const Analytics = ({ match }) => (
  <>
    <AnalyticsHeader />
    <div className='main-content pt-6'>
      <Switch>
        <PRoute access='analytics_view' path={`${match.path}/overview`} component={Overview} />
        <PRoute access='analytics_views_view' path={`${match.path}/views`} component={Views} />
        <PRoute
          access='analytics_watchtime_view'
          path={`${match.path}/watchtime`}
          component={Watchtime}
        />
        <PRoute
          access='analytics_content_view'
          path={`${match.path}/content`}
          component={Content}
        />
        {/* <PRoute access='analytics_usage_view' path={`${match.path}/usage`} component={Usage} /> */}
        <PRoute
          access='analytics_revenue_view'
          path={`${match.path}/revenue`}
          component={Revenue}
        />
        <Redirect from='*' to={`${match.path}/overview`} />
      </Switch>
    </div>
  </>
);

export default Analytics;
