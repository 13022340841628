/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React from 'react';
import ReactSelect from 'shared/components/forms/ReactSelect';

const SelectGroup = ({
  name,
  value,
  onChange,
  disabled,
  className,
  containerClass,
  successText,
  errorText,
  outLabel,
  options,
  placeholder,
  menuPlacement = 'auto'
}) => {
  return (
    <div className={`form-field-container overflow-initial ${containerClass || ''}`}>
      {outLabel && (
        <label className='fw-500 mb-0' htmlFor={name}>
          {outLabel}
        </label>
      )}
      <div
        className={`form-field-select position-relative${errorText ? ' is-error' : ''}${
          successText ? ' is-success' : ''
        } ${className || ''}`}
      >
        <ReactSelect
          placeholder={placeholder}
          options={options}
          value={value}
          getOptionValue={i => i.value}
          getOptionLabel={i => i.name}
          onChange={onChange}
          disabled={disabled}
          menuPlacement={menuPlacement}
        />
      </div>
      {errorText && errorText !== true && (
        <p className='text-xs text-danger mt-1 mb-0 px-3' data-testid='dangerText'>
          {errorText}
        </p>
      )}
    </div>
  );
};

export default SelectGroup;
