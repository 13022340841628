import React from 'react';
import { Redirect, Route } from 'react-router-dom';
import { predefinedPermissions, perRoleDeniedPermissions } from 'configs';

const getAvailablePermissions = role => {
  const availablePermissions = {};
  const deniedPermissions = perRoleDeniedPermissions[role];
  for (let perm in predefinedPermissions) {
    if (!deniedPermissions.includes(perm)) availablePermissions[perm] = true;
  }
  return availablePermissions;
};

export const PermissionService = {
  permissions: [],
  setUserPermissions: function(role) {
    this.permissions = getAvailablePermissions(role);
  },
  cleanUserPermissions: function() {
    this.permissions = [];
  },
  hasAccess: function(perm) {
    if (!perm) return true;
    if (typeof perm === 'string') perm = [perm];
    return perm.every(item => this.permissions[item]);
  },
  hasSomeAccess: function(perm) {
    if (!perm) return true;
    if (typeof perm === 'string') perm = [perm];
    return perm.some(item => this.permissions[item]);
  },
};

export const PRoute = ({ access, redirectUrl = '/', ...restProps }) => {
  if (access && !PermissionService.hasAccess(access)) return <Redirect to={redirectUrl} />;
  return <Route {...restProps} />;
};
