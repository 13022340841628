export const navigation = [
  {
    name: 'Overview',
    route: '/analytics/overview',
    permission: 'analytics_view',
  },
  {
    name: 'Views',
    route: '/analytics/views',
    permission: 'analytics_views_view',
  },
  {
    name: 'Watchtime',
    route: '/analytics/watchtime',
    permission: 'analytics_watchtime_view',
  },
  {
    name: 'Content',
    route: '/analytics/content',
    permission: 'analytics_content_view',
  },
  // {
  //   name: 'Data Usage',
  //   route: '/analytics/usage',
  //   permission: 'analytics_usage_view',
  // },
  {
    name: 'Revenue',
    route: '/analytics/revenue',
    permission: 'analytics_revenue_view',
  },
];

export const subNavigation = {
  overview: [],
  views: [
    {
      name: 'Countries',
      route: '/analytics/views/country',
    },
    {
      name: 'Device type',
      route: '/analytics/views/device_type',
    },
  ],
  watchtime: [
    {
      name: 'Countries',
      route: '/analytics/watchtime/country',
    },
    {
      name: 'Device type',
      route: '/analytics/watchtime/device_type',
    },
  ],
  content: [
    {
      name: 'All',
      route: '/analytics/content/all',
    },
    {
      name: 'Movies',
      route: '/analytics/content/movie',
    },
    {
      name: 'TV Shows',
      route: '/analytics/content/tv-show',
    },
  ],
  usage: [
    {
      name: 'Countries',
      route: '/analytics/usage/country',
    },
    {
      name: 'Device type',
      route: '/analytics/usage/device_type',
    },
  ],
  revenue: [
    {
      name: 'Countries',
      route: '/analytics/revenue/country',
    },
    {
      name: 'Device type',
      route: '/analytics/revenue/device_type',
    },
    // {
    //   name: 'Streams',
    //   route: '/analytics/revenue/stream',
    // },
  ],
};

// TODO: here
export const overviewCharts = [
  {
    apiUrl: '/reporting/view-count/overview',
    title: 'Views',
    unit: 'views',
    storeKey: 'views',
    redirectUrl: '/analytics/views',
    permission: 'analytics_views_view',
  },
  {
    apiUrl: '/reporting/watch-time/overview',
    title: 'Watchtime',
    unit: 'mins',
    storeKey: 'watchtime',
    redirectUrl: '/analytics/watchtime',
    permission: 'analytics_watchtime_view',
  },
  {
    apiUrl: '/reporting/usage/overview',
    title: 'Data Usage',
    unit: 'GBs',
    storeKey: 'usage',
    redirectUrl: '/analytics/usage',
    permission: 'analytics_usage_view',
  },
  {
    apiUrl: '/reporting/revenue/overview',
    title: 'Revenue',
    unit: '$',
    storeKey: 'revenue',
    redirectUrl: '/analytics/revenue',
    permission: 'analytics_revenue_view',
  },
];

export const contentTableHeaders = [
  { name: 'Images' },
  { name: 'Video/TV Show Name' },
  { name: 'Views', key: 'view_count' },
  { name: 'Watchtime', key: 'watch_time' },
  { name: 'Average Watchtime Per View', key: 'average' },
];

export const chartColors = [
  '#9C8FFF',
  '#5BEBD1',
  '#FC8A91',
  '#FFEAA0',
  '#5394EF',
  '#FF6633',
  '#FFB399',
  '#FF33FF',
  '#FFFF99',
  '#00B3E6',
  '#E6B333',
  '#3366E6',
  '#999966',
  '#99FF99',
  '#B34D4D',
  '#80B300',
  '#809900',
  '#E6B3B3',
  '#6680B3',
  '#66991A',
  '#FF99E6',
  '#CCFF1A',
  '#FF1A66',
  '#E6331A',
  '#33FFCC',
  '#66994D',
  '#B366CC',
  '#4D8000',
  '#B33300',
  '#CC80CC',
  '#66664D',
  '#991AFF',
  '#E666FF',
  '#4DB3FF',
  '#1AB399',
  '#E666B3',
  '#33991A',
  '#CC9999',
  '#B3B31A',
  '#00E680',
  '#4D8066',
  '#809980',
  '#E6FF80',
  '#1AFF33',
  '#999933',
  '#FF3380',
  '#CCCC00',
  '#66E64D',
  '#4D80CC',
  '#9900B3',
  '#E64D66',
  '#4DB380',
  '#FF4D4D',
  '#99E6E6',
  '#6666FF',
  '#9C8FFF',
  '#5BEBD1',
  '#FC8A91',
  '#FFEAA0',
  '#5394EF',
  '#FF6633',
  '#FFB399',
  '#FF33FF',
  '#FFFF99',
  '#00B3E6',
  '#E6B333',
  '#3366E6',
  '#999966',
  '#99FF99',
  '#B34D4D',
  '#80B300',
  '#809900',
  '#E6B3B3',
  '#6680B3',
  '#66991A',
  '#FF99E6',
  '#CCFF1A',
  '#FF1A66',
  '#E6331A',
  '#33FFCC',
  '#66994D',
  '#B366CC',
  '#4D8000',
  '#B33300',
  '#CC80CC',
  '#66664D',
  '#991AFF',
  '#E666FF',
  '#4DB3FF',
  '#1AB399',
  '#E666B3',
  '#33991A',
  '#CC9999',
  '#B3B31A',
  '#00E680',
  '#4D8066',
  '#809980',
  '#E6FF80',
  '#1AFF33',
  '#999933',
  '#FF3380',
  '#CCCC00',
  '#66E64D',
  '#4D80CC',
  '#9900B3',
  '#E64D66',
  '#4DB380',
  '#FF4D4D',
  '#99E6E6',
  '#6666FF',
];
