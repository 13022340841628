import React, { useState } from 'react';
import { countries } from 'configs/countries';
import SearchBox from 'shared/components/forms/SearchBox';
import Loading from 'shared/components/loadings/Loading';
import _ from 'lodash';
import { PermissionService } from 'services/PermissionService';

const CountrySelection = ({
  value,
  onChange,
  onSubmit,
  fetching = false,
  showActionButtons,
  leftListTitle = 'Available countries',
  rightListTitle = 'Blocked countries',
}) => {
  const [search, setSearch] = useState({
    available: '',
    blocked: '',
  });

  const handleSearch = (value, field) => {
    setSearch({ ...search, [field]: value });
  };

  const selectAll = () => {
    const allKeys = countries.map(({ key }) => key);
    onChange(allKeys);
  };

  const unselectAll = () => onChange([]);

  const selectItem = key => {
    if (actionsDisabled) return;
    onChange([...value, key]);
  };

  const unselectItem = key => {
    const filteredKeys = value.filter(item => item !== key);
    onChange(filteredKeys);
  };

  const filterArr = (arr, str) => {
    const copied = _.cloneDeep(arr);
    return _.filter(copied, ({ name }) => new RegExp(str, 'gi').test(name));
  };

  const availableList = filterArr(countries, search.available);
  const blockedList = filterArr(countries, search.blocked);
  const actionsDisabled = fetching || !PermissionService.hasAccess('media_create');

  return (
    <div>
      <div className='row'>
        <div className='col-6 py-2 d-flex align-items-center justify-content-between'>
          <span>{leftListTitle}</span>
          <button
            className='btn btn-sm btn-outline-primary'
            onClick={selectAll}
            disabled={actionsDisabled}
          >
            Select All
          </button>
        </div>
        <div className='col-6 py-2 d-flex align-items-center justify-content-between'>
          <span>{rightListTitle}</span>
          <button
            className='btn btn-sm btn-outline-primary'
            onClick={unselectAll}
            disabled={actionsDisabled}
          >
            Unselect All
          </button>
        </div>
        <div className='col-6 mb-2'>
          <div className='position-relative'>
            <SearchBox
              search={search.available}
              onChange={() => {}}
              setSearch={value => handleSearch(value, 'available')}
              className='w-100'
            />
          </div>
        </div>
        <div className='col-6 mb-2'>
          <div className='position-relative'>
            <SearchBox
              search={search.blocked}
              onChange={() => {}}
              setSearch={value => handleSearch(value, 'blocked')}
              className='w-100'
            />
          </div>
        </div>
      </div>
      <div className='row'>
        <div className='col-6 d-flex flex-column'>
          <div className='border flex-fill overflow-auto h-500-px mxh-335 p-2'>
            <ul className='p-0 no-list-style'>
              {availableList.map(({ key, name }) => {
                const isSelected = value.includes(key);
                if (isSelected) return null;
                return (
                  <li
                    key={key}
                    className='mb-2 mw-100 indicator-container indicator-container-sm'
                    onClick={() => selectItem(key)}
                    role='presentation'
                  >
                    <img
                      src={`https://flagcdn.com/h40/${key.toLowerCase()}.png`}
                      width='30'
                      height='30'
                      alt={name}
                      className='object-fit__cover object-position__center rounded__50 mr-2'
                    />
                    {name}
                  </li>
                );
              })}
            </ul>
          </div>
        </div>
        <div className='col-6 d-flex flex-column'>
          <div className='border flex-fill overflow-auto h-500-px mxh-335 p-2'>
            <ul className='p-0 no-list-style'>
              {fetching && <Loading className='w-100 mt-10 mb-10' />}
              {!fetching &&
                blockedList.map(({ key, name }) => {
                  const isSelected = value.includes(key);
                  if (!isSelected) return null;
                  return (
                    <li
                      key={key}
                      className='mb-2 mw-100 indicator-container indicator-container-sm'
                      onClick={() => unselectItem(key)}
                      role='presentation'
                    >
                      <img
                        src={`https://flagcdn.com/h40/${key.toLowerCase()}.png`}
                        width='30'
                        height='30'
                        alt={name}
                        className='object-fit__cover object-position__center rounded__50 mr-2'
                      />
                      {name}
                    </li>
                  );
                })}
            </ul>
          </div>
        </div>
      </div>
      {showActionButtons && (
        <div className='pt-3 pb-2 text-right'>
          <button
            type='button'
            className='btn btn-sm btn-outline-primary mnw-100 py-1 text-uppercase weight-400 fz-12'
            onClick={onSubmit}
            disabled={actionsDisabled}
          >
            Save
          </button>
        </div>
      )}
    </div>
  );
};

export default CountrySelection;
