import React, { useState, useEffect } from 'react';
import { useSnackbar } from 'notistack';
import InputGroup from 'shared/components/forms/InputGroup';
import Modal from 'shared/components/modals/Modal';
import cloneDeep from 'lodash/cloneDeep';
import { Api } from 'utils/connectors';
import { getError } from 'utils/appHelpers';

const ConfigurationManageModal = ({ onClose, initialConfigurations }) => {
  const { enqueueSnackbar } = useSnackbar();
  const [fetching, setFetching] = useState(false);
  const [sendingData, setSendingData] = useState(null);
  const [errors, setErrors] = useState([]);

  const validateForm = sendingData => {
    const errors = [];
    if (!sendingData.value.length) {
      errors.push('The value field is required');
    }
    if (!sendingData.description.length) {
      errors.push('The description field is required');
    }
    setErrors(errors);
    return !errors.length;
  };

  const handleChange = ({ target }) => {
    const { name, value } = target;
    setSendingData({ ...sendingData, [name]: value });
  };

  const handleChangeDescription = description => {
    const duplicate = cloneDeep(sendingData);
    duplicate.description = description;
    setSendingData(duplicate);
  };
  const onFormSubmit = async e => {
    e.preventDefault();
    const isFormValid = validateForm(sendingData);
    if (!isFormValid) return;
    try {
      setFetching(true);
      await Api.put('/configs', sendingData);
      enqueueSnackbar('Successfully done', { variant: 'success' });
      onClose();
    } catch (err) {
      enqueueSnackbar(getError(err), { variant: 'error' });
      setFetching(false);
    }
  };

  const initPageData = () => {
    setSendingData(initialConfigurations);
  };

  useEffect(() => {
    if (initialConfigurations) initPageData();
  }, [initialConfigurations]);

  if (!sendingData) return null;

  return (
    <Modal size='xs' onClose={onClose} className='mx-2 mt-2'>
      <div className='modal-title bg-purple__light row px-4 py-3'>
        <div className='text-primary__light'>{initialConfigurations.key}</div>
      </div>
      <div className='signup-modal d-flex flex-column align-items-center'>
        <form onSubmit={onFormSubmit} className='w-100 pt-3'>
          Value
          <InputGroup
            type={'text'}
            name='value'
            value={sendingData.value}
            onChange={handleChange}
            containerClass='mb-3'
            required
          />
          Description
          <InputGroup
            type={'text'}
            name='description'
            value={sendingData.description}
            onChange={({ target }) => handleChangeDescription(target.value)}
            containerClass='mb-3'
            required
          />
          <div className='mr-4'>
            {errors.map((err, i) => (
              <p className='text-danger mb-1' key={i}>
                <small>{err}</small>
              </p>
            ))}
          </div>
          <div className='text-right mt-4'>
            <button
              type='button'
              className='btn btn-outline-primary text-md text-uppercase mnw-110 btn-sm py-2 weight-300 mx-2'
              onClick={onClose}
              disabled={fetching}
            >
              Cancel
            </button>
            <button
              type='submit'
              className='btn btn-primary btn-sm py-2 text-md text-uppercase weight-300 mnw-110 ml-2'
              disabled={fetching}
              onClick={onFormSubmit}
            >
              Save
            </button>
          </div>
        </form>
      </div>
    </Modal>
  );
};

export default ConfigurationManageModal;
