import { useEffect } from 'react';

const useOutsideClick = (ref, handler, optRef) => {
  useEffect(() => {
    const listener = event => {
      if (
        !ref.current ||
        ref.current.contains(event.target) ||
        (optRef && optRef.current.contains(event.target))
      ) {
        return;
      }
      handler(event);
    };

    document.addEventListener('mousedown', listener);

    return () => {
      document.removeEventListener('mousedown', listener);
    };
    //eslint-disable-next-line
  }, [ref, handler]);
};

export default useOutsideClick;
