import React, { useState } from 'react';
import { useSnackbar } from 'notistack';
import { Api } from 'utils/connectors';
import { getError, confirmBox, reorder } from 'utils/appHelpers';
import DragList from 'shared/components/layouts/DragList';
import ActionButton from 'shared/components/ActionButton';
import IconDrag from 'assets/images/icons/dd_icon.svg';
import SeasonsManageModal from './SeasonsManageModal';
import CreateButton from 'shared/components/CreateButton';
import { PermissionService } from 'services/PermissionService';

const SeasonsTab = ({ media, lang, setLang, updateMovieStore }) => {
  const { enqueueSnackbar } = useSnackbar();
  const [manageModal, setManageModal] = useState({
    state: false,
    data: null,
    media,
  });

  const onDragEnd = async e => {
    if (!e.destination) return;
    try {
      const tempMedia = { ...media };
      tempMedia.seasons = reorder(tempMedia.seasons, e.source.index, e.destination.index);
      updateMovieStore(tempMedia);
      await Api.put(
        `/tv-shows/${media.id}/seasons/${e.draggableId}/reorder?order=${e.destination.index}`,
      );
    } catch (err) {
      enqueueSnackbar(getError(err), { variant: 'error' });
    }
  };

  const removeItem = async (id, index) => {
    try {
      await Api.delete(`/tv-shows/${media.id}/seasons/${id}`);
      const tempMedia = { ...media };
      tempMedia.seasons.splice(index, 1);
      updateMovieStore(tempMedia);
      enqueueSnackbar('Successfully done', { variant: 'success' });
    } catch (err) {
      enqueueSnackbar(getError(err), { variant: 'error' });
    }
  };

  const onItemRemove = (id, index) => {
    const options = {
      onSuccess: removeItem.bind(null, id, index),
      message: `Are you sure you want to delete the season ?`,
      confirm: 'Delete',
    };
    confirmBox(options);
  };

  const onItemEdit = e => {
    setManageModal({ data: e, state: true, media });
  };

  const onItemCreate = () => {
    setManageModal({ data: null, state: true, media });
  };

  const onModalClose = () => {
    setManageModal({ data: null, state: false });
  };

  return (
    <div>
      <div className='d-flex justify-content-between align-items-end mt-4 mb-3'>
        <p className='sub-title mb-0'>Seasons list</p>
        <CreateButton
          className='is-small'
          name='ADD SEASON'
          onClick={onItemCreate}
          disabled={!PermissionService.hasAccess('media_create')}
        />
      </div>
      {media && media.seasons ? (
        <div className='seasons-list'>
          <DragList
            onDragEnd={onDragEnd}
            items={media.seasons}
            isDragDisabled={!PermissionService.hasAccess('media_edit')}
          >
            {(item, index, snapshot) => {
              return (
                <div
                  className={`border-bottom d-flex justify-content-between align-items-center pb-2 border__$`}
                  key={item.id}
                >
                  <div className='flex-fill'>
                    <span className='mr-2'>
                      {snapshot.isDragging ? <img src={IconDrag} alt={'icon'} /> : index + 1}
                    </span>
                    {item.name}
                  </div>
                  <div>
                    <ActionButton
                      type='edit'
                      onClick={onItemEdit.bind(null, item, index)}
                      disabled={!PermissionService.hasAccess('media_edit')}
                    />
                    <ActionButton
                      onClick={onItemRemove.bind(null, item.id, index)}
                      disabled={!PermissionService.hasAccess('media_delete')}
                    />
                  </div>
                </div>
              );
            }}
          </DragList>
        </div>
      ) : (
        'No any season yet'
      )}
      {manageModal.state && (
        <SeasonsManageModal
          state={manageModal}
          lang={lang}
          setLang={setLang}
          onClose={onModalClose}
          updateMovieStore={updateMovieStore}
        />
      )}
    </div>
  );
};

export default SeasonsTab;
