import React, { useEffect, useState } from 'react';
import { AnalyticsApi } from 'utils/connectors';
import { useSnackbar } from 'notistack';
import { useSelector, useDispatch } from 'react-redux';
import { getError, constructSort, bindDurationHours } from 'utils/appHelpers';
import BackButton from 'shared/components/BackButton';
import Loading from 'shared/components/loadings/Loading';
import { buildParams, generateTvShowsCSVData, getMediaNavigationUrl } from 'utils/analyticsHelpers';
import SeasonTabs from './components/SeasonTabs';
import defaultImg from 'assets/images/thumbnail-default.jpg';
import { contentTableHeaders } from 'app/Main/routes/Analytics/configs/consts';
import THeader from '../../components/THeader';
import { onAnalyticsSet } from 'app/Main/actions';

const TVShowList = ({ match, history }) => {
  const { enqueueSnackbar } = useSnackbar();
  const { id } = match.params;
  const dispatch = useDispatch();
  const { filters, analytics } = useSelector(state => state);
  const [data, setData] = useState(null);
  const [activeTab, setActiveTab] = useState(0);
  const [order, setOrder] = useState({
    key: 'watch_time',
    bool: false,
  });
  const { items, dates } = filters;
  const dataSource = analytics.content ?? {};

  const navigateItem = (media, title, season, episode) => {
    history.push({
      pathname: getMediaNavigationUrl({ ...media, id, ep_id: media.id }),
      search: `?category=TV_SHOW&title=${title}&season=${season}&episode=${episode}`,
    });
  };

  const getData = async () => {
    try {
      const [sortKey, sortDir] = constructSort(order).split(':');
      const { data } = await AnalyticsApi.post(`/reporting/content/search/tv-shows/${id}`, {
        ...buildParams(filters),
        sort_by: sortKey.toUpperCase(),
        order_by: sortDir.toUpperCase(),
      });
      dispatch(
        onAnalyticsSet({
          content: {
            ...dataSource,
            tvShowsById: {
              ...dataSource.tvShowsById,
              [id]: { data, CSV: generateTvShowsCSVData(data) },
            },
          },
        }),
      );
    } catch (err) {
      enqueueSnackbar(getError(err), { variant: 'error' });
    }
  };

  useEffect(() => {
    getData();
  }, [id, order, items, dates]);

  useEffect(() => {
    if (dataSource?.tvShowsById?.[id]) {
      setData(dataSource.tvShowsById[id].data);
    }
  }, [dataSource]);

  if (!data) {
    return (
      <div className='bg-white d-flex justify-content-center py-15'>
        <Loading />
      </div>
    );
  }
  if (!data.data.length) {
    return <div className='text-center py-15'>No Records Found...</div>;
  }

  const { movies, name } = data.data[activeTab];

  return (
    <div>
      <div className='d-flex align-items-center mb-4'>
        <BackButton /> <span className='text-light mr-l'>TV Shows / {data.title}</span>
      </div>
      <div className='bg-purple__light px-4 py-3 d-flex align-items-center justify-content-between'>
        <div className='text-primary__light'>{data.title}</div>
        <SeasonTabs activeTab={activeTab} data={data.data} onChange={setActiveTab} />
      </div>
      <table className='bg-white table table-hover'>
        <thead>
          <tr>
            {contentTableHeaders.map(header => (
              <THeader key={header.name} data={header} order={order} onOrderChange={setOrder} />
            ))}
          </tr>
        </thead>
        <tbody>
          {movies.map((item, k) => (
            <tr
              key={item.id}
              onClick={() => navigateItem(item, data.title, name, item.title)}
              className='pointer'
            >
              <td className='w-140'>
                <img
                  src={item.image_url ?? defaultImg}
                  alt='img'
                  width='72'
                  height='40'
                  className='radius-4'
                />
              </td>
              <td>{item.title}</td>
              <td>{item.view_count}</td>
              <td>{bindDurationHours(item.watch_time)}</td>
              <td>{bindDurationHours(item.average)}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default TVShowList;
