import React, { useEffect, useState } from 'react';
import { useSnackbar } from 'notistack';
import { useDispatch, useSelector } from 'react-redux';
import { Api } from 'utils/connectors';
import { confirmBox, getError } from 'utils/appHelpers';
import { onPlansSet, setTotalValue } from 'app/Main/actions';
import { PermissionService } from 'services/PermissionService';
import { sortDataWithKey } from 'utils/sortingHelper';
import Header from 'shared/components/layouts/Header';
import InfiniteScroll from 'react-infinite-scroll-component';
import ActionButton from 'shared/components/ActionButton';
import Loading from 'shared/components/loadings/Loading';
import PlanManageModal from './components/PlanManageModal';
import LangToggle from 'shared/components/LangToggle';
import defaultImg from 'assets/images/thumbnail-default.jpg';
import ListStatusChanger from 'shared/components/ListStatusChanger';

const Plans = ({ location }) => {
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch();
  const allPlans = useSelector(state => state.plans) || {};
  const [lang, setLang] = useState('en');
  const [manageModal, setManageModal] = useState({
    state: !!location.state?.form,
    plan: null,
    lastForm: location.state?.form,
  });

  const plans = allPlans[lang];
  const getPlans = async isNext => {
    try {
      // const page = isNext ? plans.number : 0;
      const res = await Api.get('/subscription-plans', {
        params: {
          locale: lang,
          // size: 100,
          // page: isNext ? page + 1 : page,
        },
      });
      // if (isNext) res.data = [...plans.content, ...res.data];
      res.data = sortDataWithKey(res.data);
      dispatch(onPlansSet({ [lang]: { content: res.data, total_elements: res.data.length } }));
      dispatch(setTotalValue('all_plans', res.data.length));
    } catch (err) {
      enqueueSnackbar(getError(err), { variant: 'error' });
    }
  };

  const removeItem = async (id, index) => {
    try {
      await Api.delete(`/subscription-plans/${id}`);
      const tempItems = [...plans.content];
      tempItems.splice(index, 1);
      dispatch(onPlansSet({ ...plans, content: tempItems }));
      enqueueSnackbar('Successfully done', { variant: 'success' });
      getPlans();
    } catch (err) {
      enqueueSnackbar(getError(err), { variant: 'error' });
    }
  };

  const onItemRemove = (id, index) => {
    const options = {
      onSuccess: removeItem.bind(null, id, index),
      message: `Are you sure you want to delete current plans?`,
      confirm: 'Delete',
    };
    confirmBox(options);
  };

  const onItemEdit = e => {
    setManageModal({ plan: e, state: true });
  };

  const onItemCreate = () => {
    setManageModal({ plan: null, state: true });
  };

  const onModalClose = () => {
    setManageModal({ plan: null, state: false });
  };

  useEffect(() => {
    getPlans();
    //eslint-disable-next-line
  }, [lang]);

  return (
    <>
      <Header>
        <div className='d-flex align-items-center'>Subscription Plans</div>
      </Header>
      <main className='main-content'>
        <div className='d-flex align-items-center justify-content-end mb-3'>
          <LangToggle lang={lang} setLang={setLang} />
        </div>
        <div className='px-3 bg-white pb-3'>
          <div className='bg-purple__light row px-4 py-2 d-flex align-items-center justify-content-between'>
            <div className='text-primary__light'>Created Plans</div>
            <button
              className='btn btn-sm btn-primary btn-crud'
              onClick={onItemCreate}
              disabled={!PermissionService.hasAccess('plan_create')}
            >
              Create New
            </button>
          </div>
          {plans?.content ? (
            <InfiniteScroll
              dataLength={(plans.content && plans.content.length) || 0}
              next={() => getPlans(true)}
              hasMore={plans.content && plans.content.length < plans.total_elements}
              loader={<div>Loading...</div>}
            >
              {
                <div>
                  <table className='table'>
                    <thead>
                      <tr>
                        <th className='border-0 text-primary w-40'>#</th>
                        <th className='border-0 text-primary'>Image</th>
                        <th className='border-0 text-primary'>Name</th>
                        <th className='border-0 text-primary mxw-200'>Description</th>
                        <th className='border-0 text-primary'>Subscriptions</th>
                        <th className='border-0 text-primary'>Countries</th>
                        <th className='border-0 text-primary'>Status</th>
                        <th className='border-0 text-primary text-right w-78' />
                      </tr>
                    </thead>
                    <tbody>
                      {plans.content.length
                        ? plans.content.map((item, index) => {
                            const firstProduct = item.in_app_products
                              ? item.in_app_products[0]
                              : null;
                            const hasMore = item.in_app_products.length > 1;
                            return (
                              <tr key={index}>
                                <td>{index + 1}</td>
                                <td>
                                  <img
                                    src={item.img_url || defaultImg}
                                    alt={'should be a pic'}
                                    width={64}
                                    height={36}
                                  />
                                </td>
                                <td>{item.name || '-'}</td>
                                <td className='mxw-200'>{item.description || '-'}</td>
                                <td>
                                  {firstProduct && (
                                    <span className='badge badge-light mr-1'>
                                      {firstProduct.name} -
                                      <span className='text-secondary'>${firstProduct.price}</span>
                                    </span>
                                  )}
                                  {hasMore && (
                                    <span className='badge badge-light mr-1'>
                                      {item.in_app_products.length - 1} more
                                    </span>
                                  )}
                                </td>
                                <td>-</td>
                                <td>
                                  <ListStatusChanger productID={item.id} status={item.status} onSuccess={getPlans} type='plan' />
                                </td>
                                <td>
                                  <div className='text-right'>
                                    <ActionButton
                                      className='mr-1'
                                      type='edit'
                                      onClick={onItemEdit.bind(null, item)}
                                      disabled={!PermissionService.hasAccess('plans_edit')}
                                    />
                                    <ActionButton
                                      onClick={onItemRemove.bind(null, item.id, index)}
                                      disabled={!PermissionService.hasAccess('plans_delete')}
                                    />
                                  </div>
                                </td>
                              </tr>
                            );
                          })
                        : null}
                    </tbody>
                  </table>
                </div>
              }
            </InfiniteScroll>
          ) : (
            <div className='text-center py-6'>
              <Loading />
            </div>
          )}
        </div>
      </main>
      {manageModal.state && (
        <PlanManageModal
          lang={lang}
          state={manageModal}
          onSuccess={getPlans}
          onClose={onModalClose}
        />
      )}
    </>
  );
};

export default Plans;
