import React from 'react';
import { useBeforeunload } from 'react-beforeunload';

const withMb = bytes => `${(bytes / (1024 * 1024)).toFixed(1)}MB`;

const bindProgress = (loadedBytes, totalBytes) => {
  let loaded = loadedBytes / (1024 * 1024);
  let total = totalBytes / (1024 * 1024);
  return Math.floor(Math.round((loaded * 100) / total));
};

const VideoUploadProgress = ({ progress, className = 'my-4', onEpisodeVideoCancel }) => {
  useBeforeunload(e => {
    e.preventDefault();
  });
  if (!progress) return null;
  const fileName = progress?.request?.body?.name;

  const onCancel = () => {
    progress.request.abort();
    if (onEpisodeVideoCancel) onEpisodeVideoCancel();
  };

  return (
    <div className={className}>
      <p className='text-xs text-primary__light op-8 mb-0 d-flex justify-content-between'>
        <span className='text-body'>{fileName}</span>
        <span>
          Uploaded {withMb(progress.loaded)} / {withMb(progress.total)}
        </span>
      </p>
      <div className='progress'>
        <div
          className='progress-bar'
          role='progressbar'
          aria-valuenow='0'
          aria-valuemin='0'
          aria-valuemax='100'
          style={{ width: `${bindProgress(progress.loaded, progress.total)}%` }}
        >
          {bindProgress(progress.loaded, progress.total)}
          {bindProgress(progress.loaded, progress.total) > 2 && '%'}
        </div>
        {progress.request && (
          <button onClick={onCancel} className='btn progress-cancel'>
            Cancel
          </button>
        )}
      </div>
    </div>
  );
};

export default VideoUploadProgress;
