import React, { useState } from 'react';
import { useSnackbar } from 'notistack';
import InputGroup from 'shared/components/forms/InputGroup';
import Modal from 'shared/components/modals/Modal';
import { Api } from 'utils/connectors';
import { getError } from 'utils/appHelpers';

const TagManageModal = ({ onClose, state, onSuccess }) => {
  const { enqueueSnackbar } = useSnackbar();
  const [fetching, setFetching] = useState(false);
  const [name, setName] = useState(state.tag ? state.tag.name : '');

  const createTag = () => Api.post('/tags', { name });

  const updateTag = () => Api.put(`/tags/${state.tag.id}`, { name });

  const onFormSubmit = async e => {
    e.preventDefault();
    try {
      setFetching(true);
      state.tag ? await updateTag() : await createTag();
      if (onSuccess) onSuccess();
      enqueueSnackbar('Successfully done', { variant: 'success' });
      setFetching(false);
      onClose();
    } catch (err) {
      enqueueSnackbar(getError(err), { variant: 'error' });
      setFetching(false);
    }
  };

  return (
    <Modal size='xs' onClose={onClose} className='mx-2 mt-2'>
      <div className='modal-title bg-purple__light row px-4 py-3'>
        <div className='text-primary__light'>{state.tag ? 'Edit Tag' : 'Create Tag'}</div>
      </div>
      <div className='signup-modal d-flex flex-column align-items-center'>
        <form onSubmit={onFormSubmit} className='w-100 pt-3'>
          <InputGroup
            type='text'
            name='name'
            value={name}
            placeholder={'Name'}
            onChange={({ target }) => setName(target.value)}
            required
          />
          <div className='text-right mt-4'>
            <button
              className='btn btn-outline-primary text-md text-uppercase mnw-110 btn-sm py-2 weight-300 mx-2'
              onClick={onClose}
              type='button'
            >
              Close
            </button>
            <button
              className='btn btn-primary btn-sm py-2 text-md text-uppercase weight-300 mnw-110 ml-2'
              disabled={fetching}
              type='submit'
            >
              Save
            </button>
          </div>
        </form>
      </div>
    </Modal>
  );
};

export default TagManageModal;
