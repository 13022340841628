import React, { useState } from 'react';
import { useSnackbar } from 'notistack';
import InputGroup from 'shared/components/forms/InputGroup';
import Modal from 'shared/components/modals/Modal';
import { Api } from 'utils/connectors';
import { getError } from 'utils/appHelpers';
import LangToggle from 'shared/components/LangToggle';

const SeasonsManageModal = ({ onClose, state, updateMovieStore, lang, setLang }) => {
  const { enqueueSnackbar } = useSnackbar();
  const [fetching, setFetching] = useState(false);
  const [name, setName] = useState(state.data ? state.data.name : '');

  const createItem = async () => {
    try {
      const { data } = await Api.post(`tv-shows/${state.media.id}/seasons?locale=${lang}`, {
        name,
      });
      const tempMedia = { ...state.media };
      tempMedia.seasons = tempMedia.seasons || [];
      tempMedia.seasons.push(data);
      updateMovieStore(tempMedia);
      onClose();
    } catch (err) {
      enqueueSnackbar(getError(err), { variant: 'error' });
    }
  };

  const updateItem = async () => {
    try {
      const { data } = await Api.put(
        `tv-shows/${state.media.id}/seasons/${state.data.id}?locale=${lang}`,
        { name },
      );
      const tempMedia = { ...state.media };
      const index = tempMedia.seasons.findIndex(item => item.id === state.data.id);
      tempMedia.seasons[index] = data;
      updateMovieStore(tempMedia);
      onClose();
    } catch (err) {
      enqueueSnackbar(getError(err), { variant: 'error' });
    }
  };

  const onFormSubmit = async e => {
    e.preventDefault();
    try {
      setFetching(true);
      state.data ? await updateItem() : await createItem();
    } catch (err) {
      enqueueSnackbar(getError(err), { variant: 'error' });
      setFetching(false);
    }
  };

  return (
    <Modal size='xs' onClose={onClose} className='mx-2 mt-2'>
      <div className='modal-title bg-purple__light row px-4 py-3'>
        <div className='text-primary__light'>{state.data ? 'Edit' : 'Create'} season</div>
      </div>
      {!state.data && (
        <div className='d-flex justify-content-end px-3 mb-2'>
          <LangToggle lang={lang} setLang={setLang} />
        </div>
      )}
      <div className='signup-modal d-flex flex-column align-items-center'>
        <form onSubmit={onFormSubmit} className='w-100 pt-3'>
          <InputGroup
            type='text'
            name='name'
            value={name}
            placeholder={'Name'}
            onChange={({ target }) => setName(target.value)}
            required
          />
          <div className='text-right mt-4'>
            <button
              type='button'
              className='btn btn-outline-primary text-md text-uppercase mnw-110 btn-sm py-2 weight-300 mx-2'
              onClick={onClose}
            >
              Close
            </button>
            <button
              className='btn btn-primary btn-sm py-2 text-md text-uppercase weight-300 mnw-110 ml-2'
              disabled={fetching}
              type='submit'
            >
              Save
            </button>
          </div>
        </form>
      </div>
    </Modal>
  );
};

export default SeasonsManageModal;
