import React from 'react';
import { Draggable, DragDropContext, Droppable } from 'react-beautiful-dnd';

const DragList = ({
  onDragEnd,
  items,
  children,
  isDragDisabled,
  draggableIdKey = 'id',
  className = 'mt-5',
}) => {
  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <Droppable droppableId='droppable'>
        {(provided, snapshot) => (
          <div {...provided.droppableProps} className={className} ref={provided.innerRef}>
            {items &&
              items.map((item, index) => (
                <Draggable
                  key={item[draggableIdKey]}
                  draggableId={String(item[draggableIdKey])}
                  index={index}
                  isDragDisabled={isDragDisabled}
                >
                  {(provided, snapshot) => {
                    return (
                      <div
                        className='mb-3'
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                      >
                        {children(item, index, snapshot)}
                      </div>
                    );
                  }}
                </Draggable>
              ))}
            {provided.placeholder}
          </div>
        )}
      </Droppable>
    </DragDropContext>
  );
};

export default DragList;
