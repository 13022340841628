import React, { useRef, useState } from 'react';
import { useSnackbar } from 'notistack';
import { Api } from 'utils/connectors';
import { getError } from 'utils/appHelpers';
import Modal from 'shared/components/modals/Modal';
import InitialForm from './components/InitialForm';
import CountryBasedForm from './components/CountryBasedForm';

const initialState = {
  name: '',
  description: '',
  price: '',
  product_country_based_price_amd: {},
};

const ProductManageModall = ({ type, onClose, state, onSuccess, title, lang }) => {
  const isSubscription = type === 'renewable';
  const { enqueueSnackbar } = useSnackbar();
  const [fetching, setFetching] = useState(false);
  const [form, setForm] = useState(state.product ? state.product : initialState);
  const [openForm, setOpenForm] = useState(false);
  const cancelCountryBased = useRef(form.product_country_based_price_amd);

  const createProduct = body => {
    return Api.post(`/products?locale=${lang}`, { ...body });
  };

  const updateProduct = body => {
    return Api.put(`/products/${state.product.id}?locale=${lang}`, { ...body });
  };

  const onFormChange = ({ target }) => {
    const { name, value } = target;
    setForm({ ...form, [name]: value });
  };

  const onBack = () => {
    setForm({ ...form, product_country_based_price_amd: cancelCountryBased.current });
  };

  const onFormSubmit = async e => {
    e.preventDefault();
    if (openForm) {
      setOpenForm(false);
      return;
    }
    try {
      setFetching(true);
      const body = { ...form, type: String(type).toUpperCase() };
      body.price = Number(body.price);
      body.price_amd = Number(body.price_amd);
      const res = state.product ? await updateProduct(body) : await createProduct(body);
      if (onSuccess) onSuccess(res);
      enqueueSnackbar('Successfully done', { variant: 'success' });
      setFetching(false);
      onClose();
    } catch (err) {
      enqueueSnackbar(getError(err), { variant: 'error' });
      setFetching(false);
    }
  };

  return (
    <Modal openForm={openForm} size='xs' onClose={onClose} className='mx-2 mt-2'>
      <div className='modal-title bg-purple__light row px-4 py-3'>
        <div className='text-primary__light'>
          {openForm
            ? 'Country Base Price Editor'
            : state.product
            ? `Edit ${title}`
            : `Create ${title}`}
        </div>
      </div>
      <div className='d-flex flex-column align-items-center h-100'>
        <form
          onSubmit={onFormSubmit}
          className='d-flex flex-column h-100 justify-content-between pt-3 w-100'
        >
          {!openForm ? (
            <InitialForm
              form={form}
              isSubscription={isSubscription}
              onFormChange={onFormChange}
              onOpenForm={() => setOpenForm(true)}
            />
          ) : (
            <CountryBasedForm
              data={form.product_country_based_price_amd}
              onFormChange={onFormChange}
            />
          )}
          <div className='d-flex align-items-center justify-content-between'>
            <div>
              {openForm && (
                <button
                  className='btn btn-outline-primary text-md btn-sm btn-crud weight-400 mx-2'
                  type='button'
                  onClick={() => {
                    onBack();
                    setOpenForm(false);
                  }}
                >
                  Back
                </button>
              )}
            </div>
            <div className='d-flex align-items-center'>
              <button
                className='btn btn-outline-primary text-md btn-sm btn-crud weight-400 mx-2'
                type='button'
                onClick={onClose}
              >
                Cancel
              </button>
              <button
                className='btn btn-primary btn-sm btn-crud text-md weight-400 ml-1'
                disabled={fetching}
                type='submit'
              >
                {openForm ? 'Add' : state.product ? 'Update' : 'Create'}
              </button>
            </div>
          </div>
        </form>
      </div>
    </Modal>
  );
};

export default ProductManageModall;
