import React from 'react';
import Header from 'shared/components/layouts/Header';
import { PermissionService } from 'services/PermissionService';
import { NavLink } from 'react-router-dom';

export const navigation = [
  {
    name: 'List',
    route: '/ads/list',
    permission: 'ads_view',
  },
  {
    name: 'Assets',
    route: '/ads/assets',
    permission: 'ads_assets_view',
  },
];

const AdsHeader = () => {
  return (
    <>
      <Header className='no-shadow border-bottom'>
        <div className='d-flex align-items-center flex-fill'>
          <p className='text-lg mb-0 weight-700 text-blue-dark'>Ads</p>
          <div className='bg-white filters d-flex ml-15'>
            {navigation.map(item => {
              if (!PermissionService.hasAccess(item.permission)) return null;
              return (
                <NavLink to={item.route} className='btn' key={item.name}>
                  {item.name}
                </NavLink>
              );
            })}
          </div>
        </div>
      </Header>
    </>
  );
};

export default AdsHeader;
